import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider, Button, Select, MenuItem, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Modal from './extra/Modal';
import { format, addDays, isBefore, parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
      padding: '20px',
  },
  listContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  filterContainer: {
    display: "flex",
    justifyContent: 'space-between',
    marginBottom: "20px",
    flexWrap: 'wrap',
  },
  select: {
    minWidth: '150px',
    marginBottom: '10px',
  },
  datePicker: {
    minWidth: '150px',
  },
}));

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
};

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Multiplicamos por 1000 si el timestamp está en segundos

  // Obteniendo los componentes de la fecha
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  // Obteniendo los componentes de la hora
  const hours = String(date.getHours() % 12 || 12).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determinando si es AM o PM
  const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
  
  // Formateando la fecha y hora
  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate} ${formattedTime} ${amOrPm}`;
};

const CountdownTimer = ({ timestamp }) => {
  const calculateTimeLeft = () => {
    const difference = timestamp - Math.floor(Date.now() / 1000);

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: String(Math.floor(difference / 3600)).padStart(2, '0'),
        minutes: String(Math.floor((difference % 3600) / 60)).padStart(2, '0'),
        seconds: String(Math.floor(difference % 60)).padStart(2, '0'),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <ListItem>
      <ListItemText
        primary={
          <span style={{ color: '#87CEEB' }}>
            Comienza en: {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
          </span>
        }
      />
    </ListItem>
  );
};

const HomeComponent = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [visiblePages, setVisiblePages] = useState([1]);
  const [filter, setFilter] = useState("todo");
  const [originalData, setOriginalData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [countryData, setCountryData] = useState(['Asia', 'Europe', 'Costa Rica', 'USA', 'Netherlands', 'Argentina', 'Australia', 'Peru', 'Portugal', 'Romania', 'Russia', 'Saudi Arabia', 'Spain', 'Uruguay', 'Paraguay', 'England', 'United Arab Emirates', 'Bolivia', 'Chile', 'China', 'Colombia', 'Croatia', 'Ecuador', 'El Salvador', 'France', 'Germany', 'Guatemala', 'Honduras', 'Italy', 'Israel', 'Japan', 'Mexico', 'Brazil', 'Dominican Republic', 'Qatar']);
  const itemsPerPage = 5;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalData([]);
    setIsModalOpen(false);
  };

  const handleClick = (item) => {
    console.log('Viewing item:', item);
    setModalData(item);
    handleOpenModal();
  };

  // useEffect(() => {
  //   const fetchDataForDate = async () => {
  //     const startDate = parse('2024-04-01', 'yyyy-MM-dd', new Date());
  //     const endDate = parse('2024-04-10', 'yyyy-MM-dd', new Date());
  //     let currentDate = startDate;
  //     while (isBefore(currentDate, addDays(endDate, 1))) {
  //       const formattedDate = format(currentDate, 'yyyy-MM-dd');
  //       const response = await axios.get('https://api.sofascore.com/api/v1/sport/football/scheduled-events/' + formattedDate);
  //       let dataGame = response.data.events;
  //       let $data = [];
  //       dataGame.forEach((game) => {
        
  //         const gameDate = new Date(game.startTimestamp * 1000);
  //         const gameYear = gameDate.getFullYear();
  //         const gameMonth = gameDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
  //         const gameDay = gameDate.getDate();
  
  //         // Obtener la fecha seleccionada
  //         const selectedYear = selectedDate.getFullYear();
  //         const selectedMonth = selectedDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
  //         const selectedDay = selectedDate.getDate();
  //         if (gameYear === selectedYear && gameMonth === selectedMonth && gameDay === selectedDay) {
  //           if (game.status.type === "notstarted") {
  //             $data.push({
  //               gameId: game.id,
  //               customId: game.customId,
  //               country: game.tournament.category.name,
  //               tournament: game.tournament.name,
  //               tournamentId: game.tournament.id,
  //               uniqueTournament: game.tournament.uniqueTournament.id,
  //               season: typeof game.season.id != 'undefined' ? game.season.id : 0,
  //               homeTeam: game.homeTeam.name,
  //               homeId: game.homeTeam.id,
  //               awayTeam: game.awayTeam.name,
  //               awayId: game.awayTeam.id,
  //               date: formatTimestamp(game.startTimestamp),
  //               timestamp: game.startTimestamp,
  //               status: "notstarted"
  //             });
  //           }
  //           if (game.status.type === "inprogress") {
  //             $data.push({
  //               gameId: game.id,
  //               customId: game.customId,
  //               country: game.tournament.category.name,
  //               tournament: game.tournament.name,
  //               tournamentId: game.tournament.id,
  //               uniqueTournament: game.tournament.uniqueTournament.id,
  //               season: typeof game.season.id != 'undefined' ? game.season.id : 0,
  //               homeTeam: game.homeTeam.name,
  //               homeId: game.homeTeam.id,
  //               homeScore: game.homeScore.current !== undefined ? game.homeScore.current : game.homeScore.display,
  //               awayTeam: game.awayTeam.name,
  //               awayScore: game.awayScore.current !== undefined ? game.awayScore.current : game.awayScore.display,
  //               awayId: game.awayTeam.id,
  //               date: formatTimestamp(game.startTimestamp),
  //               timestamp: game.startTimestamp,
  //               status: "En Progreso",
  //               desc: game.status.description
  //             });
  //           }
  //           if (game.status.type === "finished") {
  //             $data.push({
  //               gameId: game.id,
  //               customId: game.customId,
  //               country: game.tournament.category.name,
  //               tournament: game.tournament.name,
  //               tournamentId: game.tournament.id,
  //               uniqueTournament: game.tournament.uniqueTournament.id,
  //               season: typeof game.season.id != 'undefined' ? game.season.id : 0,
  //               homeTeam: game.homeTeam.name,
  //               homeId: game.homeTeam.id,
  //               homeScore: game.homeScore.current !== undefined ? game.homeScore.current : game.homeScore.display,
  //               awayTeam: game.awayTeam.name,
  //               awayId: game.awayTeam.id,
  //               awayScore: game.awayScore.current !== undefined ? game.awayScore.current : game.awayScore.display,
  //               date: formatTimestamp(game.startTimestamp),
  //               timestamp: game.startTimestamp,
  //               status: "Finalizado"
  //             });
  //           }
  //         }
  //       });        
  //       setData($data);
  //       handleOpenModal();
  //       currentDate = addDays(currentDate, 1); 
  //       const timer = setTimeout(() => {
  //       }, 500)
  //       clearTimeout(timer);
  //     }
  //   }
  //   fetchDataForDate();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedDate = formatDate(selectedDate);
        const response = await axios.get('https://api.sofascore.com/api/v1/sport/football/scheduled-events/' + formattedDate);
        let dataGame = response.data.events;
        let $data = [];
        dataGame.forEach((game) => {
        
          const gameDate = new Date(game.startTimestamp * 1000);
          const gameYear = gameDate.getFullYear();
          const gameMonth = gameDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
          const gameDay = gameDate.getDate();
  
          // Obtener la fecha seleccionada
          const selectedYear = selectedDate.getFullYear();
          const selectedMonth = selectedDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
          const selectedDay = selectedDate.getDate();
          if (gameYear === selectedYear && gameMonth === selectedMonth && gameDay === selectedDay && countryData.includes(game.tournament.category.name)) {
            if (game.status.type === "notstarted") {
              $data.push({
                tournamentName: game.tournament.category.name + " > " + game.tournament.name,
                gameId: game.id,
                customId: game.customId,
                country: game.tournament.category.name,
                tournament: game.tournament.name,
                tournamentId: game.tournament.id,
                uniqueTournament: game.tournament.uniqueTournament.id,
                season: typeof game.season.id != 'undefined' ? game.season.id : 0,
                homeTeam: game.homeTeam.name,
                homeId: game.homeTeam.id,
                awayTeam: game.awayTeam.name,
                awayId: game.awayTeam.id,
                date: formatTimestamp(game.startTimestamp),
                timestamp: game.startTimestamp,
                status: "notstarted"
              });
            }
            if (game.status.type === "inprogress") {
              $data.push({
                tournamentName: game.tournament.category.name + " > " + game.tournament.name,
                gameId: game.id,
                customId: game.customId,
                country: game.tournament.category.name,
                tournament: game.tournament.name,
                tournamentId: game.tournament.id,
                uniqueTournament: game.tournament.uniqueTournament.id,
                season: typeof game.season.id != 'undefined' ? game.season.id : 0,
                homeTeam: game.homeTeam.name,
                homeId: game.homeTeam.id,
                homeScore: game.homeScore.display !== undefined ? game.homeScore.display : game.homeScore.current,
                awayTeam: game.awayTeam.name,
                awayScore: game.awayScore.display !== undefined ? game.awayScore.display : game.awayScore.current,
                awayId: game.awayTeam.id,
                date: formatTimestamp(game.startTimestamp),
                timestamp: game.startTimestamp,
                status: "En Progreso",
                desc: game.status.description
              });
            }
            if (game.status.type === "finished") {
              $data.push({
                tournamentName: game.tournament.category.name + " > " + game.tournament.name,
                gameId: game.id,
                customId: game.customId,
                country: game.tournament.category.name,
                tournament: game.tournament.name,
                tournamentId: game.tournament.id,
                uniqueTournament: game.tournament.uniqueTournament.id,
                season: typeof game.season.id != 'undefined' ? game.season.id : 0,
                homeTeam: game.homeTeam.name,
                homeId: game.homeTeam.id,
                homeScore: game.homeScore.display !== undefined ? game.homeScore.display : game.homeScore.current,
                awayTeam: game.awayTeam.name,
                awayId: game.awayTeam.id,
                awayScore: game.awayScore.display !== undefined ? game.awayScore.display : game.awayScore.current,
                date: formatTimestamp(game.startTimestamp),
                timestamp: game.startTimestamp,
                status: "Finalizado"
              });
            }
          }
        });
        setData($data);
        setOriginalData($data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Llamar a fetchData cada minuto

    return () => clearInterval(intervalId);
  }, [selectedDate]);

  useEffect(() => {
    const totalItems = data.length;
    const totalPagesCount = Math.ceil(totalItems / itemsPerPage);
    setTotalPages(totalPagesCount);

    // Actualizar las páginas visibles
    updateVisiblePages(1);
  }, [data]);
  
  useEffect(() => {
    // Actualizar los datos filtrados cuando cambie el filtro
    updateFilteredData();
  }, [filter]);
    
  const updateVisiblePages = (currentPage) => {
    let maxVisiblePages = 3; // Máximo de páginas visibles

    if (window.innerWidth >= 768) {
      maxVisiblePages = 6;
    }

    if (window.innerWidth >= 1024) {
      maxVisiblePages = 10;
    }
  
    let startPage = currentPage - Math.floor(maxVisiblePages / 2);
    let endPage = currentPage + Math.floor(maxVisiblePages / 2);
  
    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxVisiblePages); // Limitar la última página al total de páginas disponibles
    }
  
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisiblePages + 1); // Asegurar que el inicio de las páginas sea válido
    }
  
    const visiblePagesArray = [];
    for (let i = startPage; i <= endPage; i++) {
      visiblePagesArray.push(i);
    }
  
    setVisiblePages(visiblePagesArray);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    updateVisiblePages(newPage);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const updateFilteredData = () => {
    let filteredData = originalData; // Por defecto, mostrar todos los datos
  
    if (filter !== "todo") {
      // Si se selecciona una opción diferente a "Todo", aplicar el filtro
      filteredData = originalData.filter(item => item.status === filter);
    }
  
    const totalFilteredItems = filteredData.length;
  
    // Actualizar el total de páginas basado en los datos filtrados
    const totalPagesCount = Math.ceil(totalFilteredItems / itemsPerPage);
    setTotalPages(totalPagesCount);
  
    // Actualizar los datos y la paginación
    setData(filteredData);
    setPage(1); // Reiniciar la página a 1 cuando se aplica un filtro
    updateVisiblePages(1); // Actualizar las páginas visibles
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>        
          <div className={classes.listContainer}>
            <div className={classes.filterContainer}>
              <Select
                className={classes.select}
                value={filter}
                onChange={handleFilterChange}
              >
                <MenuItem value="todo">Todo</MenuItem>
                <MenuItem value="notstarted">Sin Comenzar</MenuItem>
                <MenuItem value="En Progreso">En Progreso</MenuItem>
                <MenuItem value="Finalizado">Finalizados</MenuItem>
              </Select>
            
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Seleccione una fecha"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            <List component="nav">
                {paginatedData.map((item) => (
                <React.Fragment key={item.id}>
                    <Divider style={{ margin: '0 16px' }} />
                    <ListItem>
                    <ListItemText primary={item.tournamentName} />
                    </ListItem>
                    {(item.status === "En Progreso" || item.status === "Finalizado") && (
                    <ListItem>
                    <ListItemText primary={item.homeTeam + '(' + item.homeScore + ') vs ' + item.awayTeam + '(' + item.awayScore + ')'} />
                    </ListItem>
                    )}
                    {item.status === "notstarted" && (
                    <ListItem>
                    <ListItemText primary={item.homeTeam + ' vs ' + item.awayTeam} />
                    </ListItem>
                    )}
                    {item.status === "notstarted" && (
                      <CountdownTimer timestamp={item.timestamp} />
                    )}
                    {(item.status === "En Progreso" || item.status === "Finalizado") && (
                    <ListItem>
                      <ListItemText primary={
                        <b style={item.status === "En Progreso" ? { color: '#E68F01' } : { color: '#C90000' }}>
                          {item.status === "En Progreso" ? item.desc === "Penalties" ? "En Penaltis" : item.status : item.status}
                        </b>
                      } />
                    </ListItem>
                    )}
                    <ListItem style={{ justifyContent: 'center' }}>
                    <ListItemText primary={item.date} />
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityIcon />}
                        onClick={() => handleClick(item)}
                      >
                        View
                      </Button>
                    </ListItem>
                    <Divider style={{ margin: '0 16px' }} />
                </React.Fragment>
                ))}
            </List>
            <div className={classes.pagination}>
              <Button color="primary" onClick={() => handlePageChange(null, page - 1)} disabled={page === 1}>
                Previous
              </Button>
              {visiblePages.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  color={pageNumber === page ? "secondary" : "primary"}
                  onClick={() => handlePageChange(null, pageNumber)}
                >
                  {pageNumber}
                </Button>
              ))}
              <Button
                color="primary"
                onClick={() => handlePageChange(null, page + 1)}
                disabled={page === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <Modal isOpen={isModalOpen} handleClose={handleCloseModal} data={modalData} />
    </div>
  );
};

export default HomeComponent;
