// H2HMatch.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Box  } from '@material-ui/core';
import { db, storage } from '../../Firebase';
import { collection, where, getDocs, getDoc, addDoc, onSnapshot, query, doc, setDoc } from 'firebase/firestore';

const setCollectionNoFound = async (idGame, table) => {
  try {
    // const docRef = doc(db, "gameStatistics", data.gameId.toString());
    // const docSnap = await getDoc(docRef);
  
    // if(!docSnap.exists()){
      // const gameStatistcsCollection = await setDoc(doc(db, table, idGame), {idGame: idGame});
      // console.log(gameStatistcsCollection);
    // }    
  } catch (error) { 
    // console.error(error)
  }
}

async function fetchAndProcessGameShotmap(gameId) {
  let stats = { 
    gg: 0,
    ng: 0,
    home: {
      goal: 0, 
      goalR: 0,
    }, 
    away: {
      goal: 0, 
      goalR: 0,
    } 
  };
  try {
    // const docRef = doc(db, "shotmapNotFound", gameId.toString());
    // const docSnap = await getDoc(docRef);
    // if(!docSnap.exists()) {
      const response = await axios.get(`https://api.sofascore.com/api/v1/event/${gameId}/shotmap`);
      if (response.status === 200) {
          const dataStatist = response.data.shotmap;
          let totalGoal = {home: 0, away: 0}
          dataStatist.forEach(statis => {
              if (statis.shotType === 'goal' /*&& statis.situation !== 'penalty'*/) {
                if(statis.isHome){
                  totalGoal.home += 1;
                  stats.home.goal += 1;
                  stats.away.goalR += 1;
                } else {
                  totalGoal.away += 1;
                  stats.away.goal += 1;
                  stats.home.goalR += 1;
                }
              }
          });

          if(totalGoal.home > 0 && totalGoal.away > 0) {
            stats.gg += 1;
          } else {
            stats.ng += 1;
          }
      }
    // }
  } catch (error) {
    setCollectionNoFound(gameId.toString(), "shotmapNotFound");
      // console.error(`Error fetching data for game ID ${gameId}:`, error);
  }
  return stats;
}

async function fetchAndProcessGameStatistics(gameId) {
  let stats = { 
    home: {
      totalCorner: [],
      corners: 0, 
      fouls: 0, 
      cardY: 0, 
      cardR: 0, 
      ballPossession: 0, 
      shotOnTarget: 0,
      shotOffTarget: 0,
      BlockedShots: 0,
      ShotsInsideBox: 0,
      ShotsOutsideBox: 0,
      BigChances: 0,
      BigChancesMissed: 0,
      GoalkeeperSaves: 0,
      totalShot: 0,
      totalShots: [],
      totalShotOnTarget: [],
      totalShotOffTarget: [],
      totalBigChances: [],
      totalBigChancesMissed: [],
      totalGoalkeeperSaves: [],
      totalBlockedShots: [],
      totalFouls: [],
      totalYCard: [],
      totalRCard: [],
      totalBallPossession: [],
      totalShotsInsideBox: [],
      totalShotsOutsideBox: [],
    }, 
    away: {
      totalCorner: [],
      corners: 0, 
      fouls: 0, 
      cardY: 0, 
      cardR: 0, 
      ballPossession: 0, 
      shotOnTarget: 0,
      shotOffTarget: 0,
      BlockedShots: 0,
      ShotsInsideBox: 0,
      ShotsOutsideBox: 0,
      BigChances: 0,
      BigChancesMissed: 0,
      GoalkeeperSaves: 0,
      totalShot: 0,
      totalShots: [],
      totalShotOnTarget: [],
      totalShotOffTarget: [],
      totalBigChances: [],
      totalBigChancesMissed: [],
      totalGoalkeeperSaves: [],
      totalBlockedShots: [],
      totalFouls: [],
      totalYCard: [],
      totalRCard: [],
      totalBallPossession: [],
      totalShotsInsideBox: [],
      totalShotsOutsideBox: [],
    } 
  };
  try {
    // const docRef = doc(db, "stastisticsNotFound", gameId.toString());
    // const docSnap = await getDoc(docRef);
    // if(!docSnap.exists()) {
      const response = await axios.get(`https://api.sofascore.com/api/v1/event/${gameId}/statistics`);
      if (response.status === 200) {
          const dataStatist = response.data.statistics[0].groups;
          dataStatist.forEach(statis => {
              if (statis.groupName === 'TVData') {
                  statis.statisticsItems.forEach(item => {
                      switch (item.name) {
                          case "Corner kicks":
                              stats.home.totalCorner.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalCorner.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);

                              stats.home.corners += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.corners += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Fouls":
                              stats.home.totalFouls.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalFouls.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.fouls += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.fouls += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Yellow cards":
                              stats.home.totalYCard.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalYCard.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.cardY += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.cardY += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Red cards":
                              stats.home.totalRCard.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalRCard.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.cardR += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.cardR += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          default:
                              break;
                      }
                  });
              }
              if (statis.groupName === 'Possession') {
                  statis.statisticsItems.forEach(item => {
                      switch (item.name) {
                          case "Ball possession":
                              stats.home.totalBallPossession.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalBallPossession.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.ballPossession += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.ballPossession += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          default:
                              break;
                      }
                  });
              }
              if (statis.groupName === 'Shots') {
                  statis.statisticsItems.forEach(item => {
                      switch (item.name) {
                          case "Total shots":
                            stats.home.totalShots.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                            stats.away.totalShots.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                            stats.home.totalShot += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                            stats.away.totalShot += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                            break;
                          case "Shots on target":
                              stats.home.totalShotOnTarget.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalShotOnTarget.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.shotOnTarget += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.shotOnTarget += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Shots off target":
                              stats.home.totalShotOffTarget.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalShotOffTarget.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.shotOffTarget += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.shotOffTarget += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Blocked shots":
                              stats.home.totalBlockedShots.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalBlockedShots.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.BlockedShots += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.BlockedShots += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          default:
                              break;
                      }
                  });
              }
              if (statis.groupName === 'Shots extra') {
                  statis.statisticsItems.forEach(item => {
                      switch (item.name) {
                          case "Big chances":
                              stats.home.totalBigChances.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalBigChances.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.BigChances += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.BigChances += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Big chances missed":
                              stats.home.totalBigChancesMissed.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalBigChancesMissed.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.BigChancesMissed += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.BigChancesMissed += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Shots inside box":
                              stats.home.totalShotsInsideBox.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalShotsInsideBox.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.ShotsInsideBox += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.ShotsInsideBox += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Shots outside box":
                              stats.home.totalShotsOutsideBox.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalShotsOutsideBox.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.ShotsOutsideBox += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.ShotsOutsideBox += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          case "Goalkeeper saves":
                              stats.home.totalGoalkeeperSaves.push(typeof item.homeValue !== 'undefined' ? item.homeValue : 0);
                              stats.away.totalGoalkeeperSaves.push(typeof item.awayValue !== 'undefined' ? item.awayValue : 0);
                              stats.home.GoalkeeperSaves += typeof item.homeValue !== 'undefined' ? item.homeValue : 0;
                              stats.away.GoalkeeperSaves += typeof item.awayValue !== 'undefined' ? item.awayValue : 0;
                              break;
                          default:
                              break;
                      }
                  });
              }
          });
      }
    // }
  } catch (error) {
    setCollectionNoFound(gameId.toString(), "stastisticsNotFound");
      // console.error(`Error fetching data for game ID ${gameId}:`, error);
  }
  return stats;
}

// Función para procesar estadísticas para un conjunto de juegos, ya sea en casa o fuera
async function processGameStatistics(arrGames) {
  // Uso de Promise.all para hacer solicitudes en paralelo y esperar a que todas se completen
  const statsPromises = arrGames.map(gameId => fetchAndProcessGameStatistics(gameId));
  const statsResults = await Promise.all(statsPromises);
  const shotmapPromises = arrGames.map(gameId => fetchAndProcessGameShotmap(gameId));
  const shotmapResults = await Promise.all(shotmapPromises);
  const combinedResults = statsResults.map((item, index) => ({
    stats: item,
    shotmap: shotmapResults[index]
  }));

  // Reducir los resultados a un solo objeto de estadísticas sumadas
  const finalResult = combinedResults.reduce((acc, game) => {
    // Procesar las estadísticas de 'home'
    acc.home.corners += game.stats.home.corners;
    acc.home.fouls += game.stats.home.fouls;
    acc.home.cardY += game.stats.home.cardY; 
    acc.home.cardR += game.stats.home.cardR;
    acc.home.ballPossession += game.stats.home.ballPossession;
    acc.home.shotOnTarget += game.stats.home.shotOnTarget;
    acc.home.shotOffTarget += game.stats.home.shotOffTarget;
    acc.home.totalShot += game.stats.home.totalShot;
    acc.home.BlockedShots += game.stats.home.BlockedShots;
    acc.home.ShotsInsideBox += game.stats.home.ShotsInsideBox;
    acc.home.ShotsOutsideBox += game.stats.home.ShotsOutsideBox;
    acc.home.BigChances += game.stats.home.BigChances;
    acc.home.BigChancesMissed += game.stats.home.BigChancesMissed;
    acc.home.GoalkeeperSaves += game.stats.home.GoalkeeperSaves;
    acc.home.goal += game.shotmap.home.goal;
    acc.home.goalR += game.shotmap.home.goalR;
    if(typeof game.stats.home.totalCorner[0] != 'undefined'){
      acc.home.totalCorner.push(game.stats.home.totalCorner[0]);
    }

    if(typeof game.stats.home.totalShots[0] != 'undefined'){
      acc.home.totalShots.push(game.stats.home.totalShots[0]);
    }
    if(typeof game.stats.home.totalShotOnTarget[0] != 'undefined'){
      acc.home.totalShotOnTarget.push(game.stats.home.totalShotOnTarget[0]);
    }
    if(typeof game.stats.home.totalShotOffTarget[0] != 'undefined'){
      acc.home.totalShotOffTarget.push(game.stats.home.totalShotOffTarget[0]);
    }
    if(typeof game.stats.home.totalBigChances[0] != 'undefined'){
      acc.home.totalBigChances.push(game.stats.home.totalBigChances[0]);
    }
    if(typeof game.stats.home.totalBigChancesMissed[0] != 'undefined'){
      acc.home.totalBigChancesMissed.push(game.stats.home.totalBigChancesMissed[0]);
    }
    if(typeof game.stats.home.totalGoalkeeperSaves[0] != 'undefined'){
      acc.home.totalGoalkeeperSaves.push(game.stats.home.totalGoalkeeperSaves[0]);
    }
    if(typeof game.stats.home.totalBlockedShots[0] != 'undefined'){
      acc.home.totalBlockedShots.push(game.stats.home.totalBlockedShots[0]);
    }
    if(typeof game.stats.home.totalFouls[0] != 'undefined'){
      acc.home.totalFouls.push(game.stats.home.totalFouls[0]);
    }
    if(typeof game.stats.home.totalYCard[0] != 'undefined'){
      acc.home.totalYCard.push(game.stats.home.totalYCard[0]);
    }
    if(typeof game.stats.home.totalRCard[0] != 'undefined'){
      acc.home.totalRCard.push(game.stats.home.totalRCard[0]);
    }
    if(typeof game.stats.home.totalBallPossession[0] != 'undefined'){
      acc.home.totalBallPossession.push(game.stats.home.totalBallPossession[0]);
    }
    if(typeof game.stats.home.totalShotsInsideBox[0] != 'undefined'){
      acc.home.totalShotsInsideBox.push(game.stats.home.totalShotsInsideBox[0]);
    }
    if(typeof game.stats.home.totalShotsOutsideBox[0] != 'undefined'){
      acc.home.totalShotsOutsideBox.push(game.stats.home.totalShotsOutsideBox[0]);
    }
    
    // Procesar las estadísticas de 'away'
    acc.away.corners += game.stats.away.corners;
    acc.away.fouls += game.stats.away.fouls;
    acc.away.cardY += game.stats.away.cardY;
    acc.away.cardR += game.stats.away.cardR;
    acc.away.ballPossession += game.stats.away.ballPossession;
    acc.away.shotOnTarget += game.stats.away.shotOnTarget;
    acc.away.shotOffTarget += game.stats.away.shotOffTarget;
    acc.away.totalShot += game.stats.away.totalShot;
    acc.away.BlockedShots += game.stats.away.BlockedShots;
    acc.away.ShotsInsideBox += game.stats.away.ShotsInsideBox;
    acc.away.ShotsOutsideBox += game.stats.away.ShotsOutsideBox;
    acc.away.BigChances += game.stats.away.BigChances;
    acc.away.BigChancesMissed += game.stats.away.BigChancesMissed;
    acc.away.GoalkeeperSaves += game.stats.away.GoalkeeperSaves;
    acc.away.goal += game.shotmap.away.goal;
    acc.away.goalR += game.shotmap.away.goalR;
    if(typeof game.stats.away.totalCorner[0] != 'undefined'){
      acc.away.totalCorner.push(game.stats.away.totalCorner[0]);
    } 

    if(typeof game.stats.away.totalShots[0] != 'undefined'){
      acc.away.totalShots.push(game.stats.away.totalShots[0]);
    }
    if(typeof game.stats.away.totalShotOnTarget[0] != 'undefined'){
      acc.away.totalShotOnTarget.push(game.stats.away.totalShotOnTarget[0]);
    }
    if(typeof game.stats.away.totalShotOffTarget[0] != 'undefined'){
      acc.away.totalShotOffTarget.push(game.stats.away.totalShotOffTarget[0]);
    }
    if(typeof game.stats.away.totalBigChances[0] != 'undefined'){
      acc.away.totalBigChances.push(game.stats.away.totalBigChances[0]);
    }
    if(typeof game.stats.away.totalBigChancesMissed[0] != 'undefined'){
      acc.away.totalBigChancesMissed.push(game.stats.away.totalBigChancesMissed[0]);
    }
    if(typeof game.stats.away.totalGoalkeeperSaves[0] != 'undefined'){
      acc.away.totalGoalkeeperSaves.push(game.stats.away.totalGoalkeeperSaves[0]);
    }
    if(typeof game.stats.away.totalBlockedShots[0] != 'undefined'){
      acc.away.totalBlockedShots.push(game.stats.away.totalBlockedShots[0]);
    }
    if(typeof game.stats.away.totalFouls[0] != 'undefined'){
      acc.away.totalFouls.push(game.stats.away.totalFouls[0]);
    }
    if(typeof game.stats.away.totalYCard[0] != 'undefined'){
      acc.away.totalYCard.push(game.stats.away.totalYCard[0]);
    }
    if(typeof game.stats.away.totalRCard[0] != 'undefined'){
      acc.away.totalRCard.push(game.stats.away.totalRCard[0]);
    }
    if(typeof game.stats.away.totalBallPossession[0] != 'undefined'){
      acc.away.totalBallPossession.push(game.stats.away.totalBallPossession[0]);
    }
    if(typeof game.stats.away.totalShotsInsideBox[0] != 'undefined'){
      acc.away.totalShotsInsideBox.push(game.stats.away.totalShotsInsideBox[0]);
    }
    if(typeof game.stats.away.totalShotsOutsideBox[0] != 'undefined'){
      acc.away.totalShotsOutsideBox.push(game.stats.away.totalShotsOutsideBox[0]);
    }
 
    
    

    acc.gg += game.shotmap.gg;
    acc.ng += game.shotmap.ng;

    return acc;
  }, { 
    gg: 0,
    ng: 0,
    home: { corners: 0, fouls: 0, cardY: 0, cardR: 0, ballPossession: 0, totalShot: 0, shotOnTarget: 0, shotOffTarget: 0, BlockedShots: 0, ShotsInsideBox: 0, ShotsOutsideBox: 0, BigChances: 0, BigChancesMissed: 0, GoalkeeperSaves: 0, goal: 0, goalR: 0, totalCorner: [], totalShots: [], totalShotOnTarget: [], totalShotOffTarget: [], totalBigChances: [], totalBigChancesMissed: [], totalGoalkeeperSaves: [], totalBlockedShots: [], totalFouls: [], totalYCard: [], totalRCard: [], totalBallPossession: [], totalShotsInsideBox: [], totalShotsOutsideBox: [] }, 
    away: { corners: 0, fouls: 0, cardY: 0, cardR: 0, ballPossession: 0, totalShot: 0, shotOnTarget: 0, shotOffTarget: 0, BlockedShots: 0, ShotsInsideBox: 0, ShotsOutsideBox: 0, BigChances: 0, BigChancesMissed: 0, GoalkeeperSaves: 0, goal: 0, goalR: 0, totalCorner: [], totalShots: [], totalShotOnTarget: [], totalShotOffTarget: [], totalBigChances: [], totalBigChancesMissed: [], totalGoalkeeperSaves: [], totalBlockedShots: [], totalFouls: [], totalYCard: [], totalRCard: [], totalBallPossession: [], totalShotsInsideBox: [], totalShotsOutsideBox: [] }
  });

  return finalResult;
} 

const H2HMatch = ({ data_, updateH2HData }) => {
  const [data, setData] = useState({
    match: 0, 
    homeGoal: 0, 
    awayGoal: 0, 
    home: { 
      totalGoals: [],
      name: "", 
      win: 0, 
      tie: 0, 
      loss: 0, 
      goals: {'-1.5': 0, '+1.5': 0, '+2.5': 0, 'Gol.F -1.5': 0, 'Gol.F +1.5': 0, 'Gol.F +2.5': 0, 'No Gol': 0, 'Gol.C -1.5': 0, 'Gol.C +1.5': 0, 'Gol.C +2.5': 0}
    }, 
    away: { 
      totalGoals: [],
      name: "", 
      win: 0, 
      tie: 0, 
      loss: 0, 
      goals: {'-1.5': 0, '+1.5': 0, '+2.5': 0, 'Gol.F -1.5': 0, 'Gol.F +1.5': 0, 'Gol.F +2.5': 0, 'No Gol': 0, 'Gol.C -1.5': 0, 'Gol.C +1.5': 0, 'Gol.C +2.5': 0}
    },
    statistics: {
        gg: 0,
        ng: 0,
        home:{
            totalCorner: [],
            goal: 0,
            goalR: 0,
            fouls: 0,
            corners: 0,
            yCard: 0,
            rCard: 0,
            ballPossession: 0,
            shotOnTarget: 0,
            shotOffTarget: 0,
            BlockedShots: 0,
            ShotsInsideBox: 0,
            ShotsOutsideBox: 0,
            BigChances: 0,
            BigChancesMissed: 0,
            goal: 0,
            goalR: 0,
            totalShot: 0,
            totalShots: [],
            totalShotOnTarget: [],
            totalShotOffTarget: [],
            totalBigChances: [],
            totalBigChancesMissed: [],
            totalGoalkeeperSaves: [],
            totalBlockedShots: [],
            totalFouls: [],
            totalYCard: [],
            totalRCard: [],
            totalBallPossession: [],
            totalShotsInsideBox: [],
            totalShotsOutsideBox: [],
            totalGoalsR: [],
        }, 
        away: { 
            totalCorner: [],
            goal: 0,
            goalR: 0,
            fouls: 0,
            corners: 0,
            yCard: 0,
            rCard: 0,
            ballPossession: 0,
            shotOnTarget: 0,
            shotOffTarget: 0,
            BlockedShots: 0,
            ShotsInsideBox: 0,
            ShotsOutsideBox: 0,
            BigChances: 0,
            BigChancesMissed: 0,
            goal: 0,
            goalR: 0,
            totalShot: 0,
            totalShots: [],
            totalShotOnTarget: [],
            totalShotOffTarget: [],
            totalBigChances: [],
            totalBigChancesMissed: [],
            totalGoalkeeperSaves: [],
            totalBlockedShots: [],
            totalFouls: [],
            totalYCard: [],
            totalRCard: [],
            totalBallPossession: [],
            totalShotsInsideBox: [],
            totalShotsOutsideBox: [],
            totalGoalsR: [],
        },
    }
  });

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://api.sofascore.com/api/v1/event/' + data_.customId + '/h2h/events');
          let dataGame = response.data.events;
          let $data = {
            match: 0, 
            homeGoal: 0, 
            awayGoal: 0, 
            home: { 
              totalGoals: [],
              name: data_.homeTeam, 
              win: 0, 
              tie: 0, 
              loss: 0, 
              goals: {'-1.5': 0, '+1.5': 0, '+2.5': 0, 'Gol.F -1.5': 0, 'Gol.F +1.5': 0, 'Gol.F +2.5': 0, 'No Gol': 0, 'Gol.C -1.5': 0, 'Gol.C +1.5': 0, 'Gol.C +2.5': 0}
            }, 
            away: { 
              totalGoals: [],
              name: data_.awayTeam, 
              win: 0, 
              tie: 0, 
              loss: 0, 
              goals: {'-1.5': 0, '+1.5': 0, '+2.5': 0, 'Gol.F -1.5': 0, 'Gol.F +1.5': 0, 'Gol.F +2.5': 0, 'No Gol': 0, 'Gol.C -1.5': 0, 'Gol.C +1.5': 0, 'Gol.C +2.5': 0}
            },
            statistics: {
                home:{
                    goal: 0,
                    goalR: 0,
                    fouls: 0,
                    corners: 0,
                    yCard: 0,
                    rCard: 0,
                    ballPossession: 0,
                    shotOnTarget: 0,
                    shotOffTarget: 0,
                    BlockedShots: 0,
                    ShotsInsideBox: 0,
                    ShotsOutsideBox: 0,
                    BigChances: 0,
                    BigChancesMissed: 0,
                    GoalkeeperSaves: 0,
                    goal: 0,
                    totalShot: 0,
                    totalGoalsR: [],
                }, 
                away: { 
                    goal: 0,
                    goalR: 0,
                    fouls: 0,
                    corners: 0,
                    yCard: 0,
                    rCard: 0,
                    ballPossession: 0,
                    shotOnTarget: 0,
                    shotOffTarget: 0,
                    BlockedShots: 0,
                    ShotsInsideBox: 0,
                    ShotsOutsideBox: 0,
                    BigChances: 0,
                    BigChancesMissed: 0,
                    GoalkeeperSaves: 0,
                    goal: 0,
                    totalShot: 0,
                    totalGoalsR: [],
                },
            }
          }; 
          let arrGames = [], goalH = [], goalA = [];
          dataGame.map(game => {            
            const gameDate = new Date(game.startTimestamp * 1000);
            const gameYear = gameDate.getFullYear();
            const gameMonth = (gameDate.getMonth() + 1).toString().padStart(2, "0");
            const gameDay = gameDate.getDate().toString().padStart(2, "0");
            const dateGame = gameYear + '-' + gameMonth + '-' + gameDay;

            const selectedDate = new Date(data_.timestamp * 1000);            
            const selectedYear = selectedDate.getFullYear();
            const selectedMonth = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
            const selectedDay = selectedDate.getDate().toString().padStart(2, "0");
            const dateSelected = selectedYear + '-' + selectedMonth + '-' + selectedDay;
            if(typeof game.tournament.uniqueTournament !== 'undefined') {
              if(dateSelected > dateGame && game.homeTeam.id == data_.homeId && game.tournament.uniqueTournament.id == data_.uniqueTournament) {
                if(arrGames.length >= 10) {
                  return;
                } 
                let homeScore = typeof game.homeScore.display !== 'undefined' ? game.homeScore.display : typeof game.homeScore.current !== 'undefined' ? game.homeScore.current : 0;
                let awayScore = typeof game.awayScore.display !== 'undefined' ? game.awayScore.display : typeof game.awayScore.current !== 'undefined' ? game.awayScore.current : 0;
                let totalScore = Number(homeScore) + Number(awayScore);
                $data.home.totalGoals.push(homeScore);
                $data.away.totalGoals.push(awayScore);
                $data.match += 1;
                $data.homeGoal += homeScore;
                $data.awayGoal += awayScore;
                arrGames.push(game.id);
                goalH.push(homeScore);
                goalA.push(awayScore);

                if(homeScore > awayScore) {
                  $data.home.win = Number($data.home.win) + 1;
                  $data.away.loss = Number($data.away.loss) + 1;
                } else if(homeScore == awayScore) {
                  $data.home.tie = Number($data.home.tie) + 1;
                  $data.away.tie = Number($data.away.tie) + 1;
                } else {
                  $data.away.win = Number($data.away.win) + 1;
                  $data.home.loss = Number($data.home.loss) + 1;
                }
                
                if(totalScore > 2.5){
                  $data.home.goals['+2.5'] = Number($data.home.goals['+2.5']) + 1;
                  $data.away.goals['+2.5'] = Number($data.away.goals['+2.5']) + 1;
                } else if(totalScore == 2) {                                        
                    $data.home.goals['+1.5'] = Number($data.home.goals['+1.5']) + 1;
                    $data.away.goals['+1.5'] = Number($data.away.goals['+1.5']) + 1;
                } else {
                    $data.home.goals['-1.5'] = Number($data.home.goals['-1.5']) + 1;
                    $data.away.goals['-1.5'] = Number($data.away.goals['-1.5']) + 1;
                }
                if(homeScore > 2.5){
                    $data.home.goals['Gol.F +2.5'] = Number($data.home.goals['Gol.F +2.5']) + 1;
                    $data.away.goals['Gol.C +2.5'] = Number($data.away.goals['Gol.C +2.5']) + 1;
                } else if(homeScore == 2) {                                        
                    $data.home.goals['Gol.F +1.5'] = Number($data.home.goals['Gol.F +1.5']) + 1;
                    $data.away.goals['Gol.C +1.5'] = Number($data.away.goals['Gol.C +1.5']) + 1;
                } else {
                  if(homeScore == 1) {
                    $data.home.goals['Gol.F -1.5'] = Number($data.home.goals['Gol.F -1.5']) + 1;
                    $data.away.goals['Gol.C -1.5'] = Number($data.away.goals['Gol.C -1.5']) + 1;
                  } else {
                    $data.away.goals['No Gol'] = Number($data.away.goals['No Gol']) + 1;
                  }
                }
                if(awayScore > 2.5){
                    $data.home.goals['Gol.C +2.5'] = Number($data.home.goals['Gol.C +2.5']) + 1;
                    $data.away.goals['Gol.F +2.5'] = Number($data.away.goals['Gol.F +2.5']) + 1;
                } else if(awayScore == 2) {                                        
                    $data.home.goals['Gol.C +1.5'] = Number($data.home.goals['Gol.C +1.5']) + 1;
                    $data.away.goals['Gol.F +1.5'] = Number($data.away.goals['Gol.F +1.5']) + 1;
                } else {
                    if(awayScore == 1) {
                      $data.home.goals['Gol.C -1.5'] = Number($data.home.goals['Gol.C -1.5']) + 1;
                      $data.away.goals['Gol.F -1.5'] = Number($data.away.goals['Gol.F -1.5']) + 1;
                    } else {
                      $data.home.goals['No Gol'] = Number($data.away.goals['No Gol']) + 1;
                    }
                }
              }
            } 
          }) 
          
          let statsGame = await processGameStatistics(arrGames);
          $data.statistics = statsGame; 
          // $data.statistics.home.goal = $data.homeGoal;
          // $data.statistics.home.goalR = $data.awayGoal;
          // $data.statistics.away.goal = $data.awayGoal;
          // $data.statistics.away.goalR = $data.homeGoal;
          $data.statistics.home.totalGoalsR = goalA;
          $data.statistics.away.totalGoalsR = goalH;
          console.log($data); 
           

 
          updateH2HData($data); 
          setData($data);


        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
  }, []);
 
  return (
  <div>
  <TableContainer>
    <Typography style={{ 'text-align': 'center' }} variant="h6">Tabla de Estadísticas H2H</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Equipo</TableCell>
          <TableCell>Ganadas</TableCell>
          <TableCell>Empates</TableCell>
          <TableCell>Perdidas</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-1.5</TableCell>
          <TableCell>+1.5</TableCell>
          <TableCell>+2.5</TableCell>
          <TableCell>-</TableCell>
          <TableCell>G.F -1.5</TableCell>
          <TableCell>G.F +1.5</TableCell>
          <TableCell>G.F +2.5</TableCell>
          <TableCell>-</TableCell>
          <TableCell>G.R -1.5</TableCell>
          <TableCell>G.R +1.5</TableCell>
          <TableCell>G.R +2.5</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{data.home.name}</TableCell>
          {data.home.win > data.away.win && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.win}</TableCell>
          ) || data.home.win === data.away.win && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.win}</TableCell>
          )|| data.home.win < data.away.win && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.win}</TableCell>
          )}

          {data.home.tie > data.away.tie && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.tie}</TableCell>
          ) || data.home.tie === data.away.tie && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.tie}</TableCell>
          ) || data.home.tie < data.away.tie && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.tie}</TableCell>
          )}

          {data.home.loss < data.away.loss && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.loss}</TableCell>
          ) || data.home.loss === data.away.loss && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.loss}</TableCell>
          ) || data.home.loss > data.away.loss && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.loss}</TableCell>
          )}

          <TableCell>-</TableCell>

          
          {Number(data.home.goals['-1.5']) > Number(data.away.goals['-1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['-1.5'])}</TableCell>
          ) || Number(data.home.goals['-1.5']) === Number(data.away.goals['-1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['-1.5'])}</TableCell>
          ) || Number(data.home.goals['-1.5']) < Number(data.away.goals['-1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['-1.5'])}</TableCell>
          )}

          {Number(data.home.goals['+1.5']) > Number(data.away.goals['+1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['+1.5'])}</TableCell>
          ) || Number(data.home.goals['+1.5']) === Number(data.away.goals['+1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['+1.5'])}</TableCell>
          ) || Number(data.home.goals['+1.5']) < Number(data.away.goals['+1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['+1.5'])}</TableCell>
          )}

          {Number(data.home.goals['+2.5']) < Number(data.away.goals['+2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['+2.5'])}</TableCell>
          ) || Number(data.home.goals['+2.5']) === Number(data.away.goals['+2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['+2.5'])}</TableCell>
          ) || Number(data.home.goals['+2.5']) > Number(data.away.goals['+2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['+2.5'])}</TableCell>
          )}

          <TableCell>-</TableCell>

          {Number(data.home.goals['Gol.F -1.5']) > Number(data.away.goals['Gol.F -1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.F -1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F -1.5']) === Number(data.away.goals['Gol.F -1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.F -1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F -1.5']) < Number(data.away.goals['Gol.F -1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.F -1.5'])}</TableCell>
          )}

          {Number(data.home.goals['Gol.F +1.5']) > Number(data.away.goals['Gol.F +1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.F +1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F +1.5']) === Number(data.away.goals['Gol.F +1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.F +1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F +1.5']) < Number(data.away.goals['Gol.F +1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.F +1.5'])}</TableCell>
          )}

          {Number(data.home.goals['Gol.F +2.5']) > Number(data.away.goals['Gol.F +2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.F +2.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F +2.5']) === Number(data.away.goals['Gol.F +2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.F +2.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.F +2.5']) < Number(data.away.goals['Gol.F +2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.F +2.5'])}</TableCell>
          )}

          <TableCell>-</TableCell>
          
          {Number(data.home.goals['Gol.C -1.5']) > Number(data.away.goals['Gol.C -1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.C -1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C -1.5']) === Number(data.away.goals['Gol.C -1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.C -1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C -1.5']) < Number(data.away.goals['Gol.C -1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.C -1.5'])}</TableCell>
          )}

          {Number(data.home.goals['Gol.C +1.5']) > Number(data.away.goals['Gol.C +1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.C +1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C +1.5']) === Number(data.away.goals['Gol.C +1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.C +1.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C +1.5']) < Number(data.away.goals['Gol.C +1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.C +1.5'])}</TableCell>
          )}

          {Number(data.home.goals['Gol.C +2.5']) > Number(data.away.goals['Gol.C +2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.home.goals['Gol.C +2.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C +2.5']) === Number(data.away.goals['Gol.C +2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.home.goals['Gol.C +2.5'])}</TableCell>
          ) || Number(data.home.goals['Gol.C +2.5']) < Number(data.away.goals['Gol.C +2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.home.goals['Gol.C +2.5'])}</TableCell>
          )}

        </TableRow>
        <TableRow>
          <TableCell>{data.away.name}</TableCell>
          {data.away.win > data.home.win && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.win}</TableCell>
          ) || data.away.win === data.home.win && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.win}</TableCell>
          )|| data.away.win < data.home.win && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.win}</TableCell>
          )}

          {data.away.tie > data.home.tie && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.tie}</TableCell>
          ) || data.away.tie === data.away.tie && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.tie}</TableCell>
          ) || data.away.tie < data.home.tie && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.tie}</TableCell>
          )}

          {data.away.loss < data.home.loss && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.loss}</TableCell>
          ) || data.away.loss === data.home.loss && (
            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.loss}</TableCell>
          ) || data.away.loss > data.home.loss && (
            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.loss}</TableCell>
          )}

          <TableCell>-</TableCell>
          
          {Number(data.away.goals['-1.5']) > Number(data.home.goals['-1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['-1.5'])}</TableCell>
          ) || Number(data.away.goals['-1.5']) === Number(data.home.goals['-1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['-1.5'])}</TableCell>
          )|| Number(data.away.goals['-1.5']) < Number(data.home.goals['-1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.win}</TableCell>
          )}

          {Number(data.away.goals['+1.5']) > Number(data.home.goals['+1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['+1.5'])}</TableCell>
          ) || Number(data.away.goals['+1.5']) === Number(data.home.goals['+1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['+1.5'])}</TableCell>
          ) || Number(data.away.goals['+1.5']) < Number(data.home.goals['+1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['+1.5'])}</TableCell>
          )}

          {Number(data.away.goals['+2.5']) < Number(data.home.goals['+2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['+2.5'])}</TableCell>
          ) || Number(data.away.goals['+2.5']) === Number(data.home.goals['+2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['+2.5'])}</TableCell>
          ) || Number(data.away.goals['+2.5']) > Number(data.home.goals['+2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['+2.5'])}</TableCell>
          )}

          <TableCell>-</TableCell>

          {Number(data.away.goals['Gol.F -1.5']) > Number(data.home.goals['Gol.F -1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.F -1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F -1.5']) === Number(data.home.goals['Gol.F -1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.F -1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F -1.5']) < Number(data.home.goals['Gol.F -1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.F -1.5'])}</TableCell>
          )}

          {Number(data.away.goals['Gol.F +1.5']) > Number(data.home.goals['Gol.F +1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.F +1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F +1.5']) === Number(data.home.goals['Gol.F +1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.F +1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F +1.5']) < Number(data.home.goals['Gol.F +1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.F +1.5'])}</TableCell>
          )}

          {Number(data.away.goals['Gol.F +2.5']) > Number(data.home.goals['Gol.F +2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.F +2.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F +2.5']) === Number(data.home.goals['Gol.F +2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.F +2.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.F +2.5']) < Number(data.home.goals['Gol.F +2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.F +2.5'])}</TableCell>
          )}

          <TableCell>-</TableCell>

          {Number(data.away.goals['Gol.C -1.5']) > Number(data.home.goals['Gol.C -1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.C -1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C -1.5']) === Number(data.home.goals['Gol.C -1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.C -1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C -1.5']) < Number(data.home.goals['Gol.C -1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.C -1.5'])}</TableCell>
          )}

          {Number(data.away.goals['Gol.C +1.5']) > Number(data.home.goals['Gol.C +1.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.C +1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C +1.5']) === Number(data.home.goals['Gol.C +1.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.C +1.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C +1.5']) < Number(data.home.goals['Gol.C +1.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.C +1.5'])}</TableCell>
          )}

          {Number(data.away.goals['Gol.C +2.5']) > Number(data.home.goals['Gol.C +2.5']) && (
          <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.away.goals['Gol.C +2.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C +2.5']) === Number(data.home.goals['Gol.C +2.5']) && (
              <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.away.goals['Gol.C +2.5'])}</TableCell>
          ) || Number(data.away.goals['Gol.C +2.5']) < Number(data.home.goals['Gol.C +2.5']) && (
              <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.away.goals['Gol.C +2.5'])}</TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  <h4>Corners HomeH2H PerGame: {isNaN(Math.round(data.statistics.home.corners / data.match)) ? 0 : Math.round(data.statistics.home.corners / data.match)} | Corners AwayH2H PerGame: {isNaN(Math.round(data.statistics.away.corners / data.match)) ? 0 : Math.round(data.statistics.away.corners / data.match)}</h4>
  </div>
  );
} 

export default H2HMatch;