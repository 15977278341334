import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { db, storage } from '../../Firebase';
import { collection, where, getDocs, getDoc, addDoc, onSnapshot, query, doc, setDoc } from 'firebase/firestore';
import TensorFlowComponent from './TensorFlowComponent';
 
const matchData = [
  {
      "homeScore": -26.5,
      "awayScore": 125.5,
      "result": "tie" 
  },
  {
      "homeScore": -87.5,
      "awayScore": 253.5,
      "result": "tie" 
  },
  {
      "homeScore": -111.5,
      "awayScore": 328,
      "result": "away winner" 
  },
  {
      "homeScore": 14.5,
      "awayScore": -85.5,
      "result": "home winner" 
  },
  {
      "homeScore": -126,
      "awayScore": 121.5,
      "result": "home winner" 
  },
  {
      "homeScore": -19,
      "awayScore": 107.5,
      "result": "away winner" 
  },
  {
      "homeScore": 116,
      "awayScore": -77.5,
      "result": "away winner" 
  },
  {
      "homeScore": 3.5,
      "awayScore": -125,
      "result": "tie" 
  },
  {
      "homeScore":  45.5,
      "awayScore": -86.5,
      "result": "away winner" 
  },
  {
      "homeScore": -100.5,
      "awayScore": 77.5,
      "result": "tie" 
  },
  {
      "homeScore": -160.5,
      "awayScore": 154.5,
      "result": "home winner" 
  },
  {
      "homeScore": -34.5,
      "awayScore": 145.5,
      "result": "tie" 
  },
  {
      "homeScore": 123,
      "awayScore": -172,
      "result": "home winner" 
  },
  {
      "homeScore": 62.5,
      "awayScore": 3.5,
      "result": "away winner" 
  },
  {
      "homeScore": 8.5,
      "awayScore": -81.5,
      "result": "tie" 
  },
  {
      "homeScore": -72,
      "awayScore": 234,
      "result": "away winner" 
  },
  {
      "homeScore": -125,
      "awayScore": 1.5,
      "result": "home winner" 
  },
  {
      "homeScore": -90,
      "awayScore": 63,
      "result": "home winner" 
  },
  {
      "homeScore": 0.5,
      "awayScore": 151.5,
      "result": "home winner" 
  },
  {
      "homeScore": 2.5,
      "awayScore": 31,
      "result": "tie" 
  },
  {
      "homeScore": 16,
      "awayScore": -21.5,
      "result": "home winner" 
  },
  {
      "homeScore": 96.5,
      "awayScore": -96.5,
      "result": "home winner" 
  },
  {
      "homeScore": -174.5,
      "awayScore": 18.5,
      "result": "home winner" 
  },
  {
      "homeScore": 77,
      "awayScore": -59.5,
      "result": "tie" 
  },
  {
      "homeScore": 9,
      "awayScore": 220.5,
      "result": "home winner" 
  },
  {
      "homeScore": 175.5,
      "awayScore": -87,
      "result": "home winner" 
  },
  {
      "homeScore": -77.5,
      "awayScore": 35.5,
      "result": "home winner" 
  }
];

// Imaginemos que las props data y dataSt se pasan al componente de la siguiente manera:
// <DetailedAnalysis data={data} dataSt={dataSt} />
const sumArray = (array) => array.reduce((sum, value) => sum + value, 0);

function calculateProbabilities(data, min, rec, max) {
  let nearMin = 0, nearRec = 0, nearMax = 0;
  const range = max - min; // El rango total entre el valor máximo y mínimo
  const tolerance = range * (0.15 / 100);; // 10% tolerance, adjust as needed
 
  data.forEach(value => {
      if (Math.abs(value - min) <= tolerance) nearMin++;
      if (Math.abs(value - rec) <= tolerance) nearRec++;
      if (Math.abs(value - max) <= tolerance) nearMax++;
  });

  const total = nearMin + nearRec + nearMax;
  return {
      minProbability: total > 0 ? (nearMin / total) * 100 : 0,
      recProbability: total > 0 ? (nearRec / total) * 100 : 0,
      maxProbability: total > 0 ? (nearMax / total) * 100 : 0
  };
}

function calculateWeightedAverage(stats, historicalData) {
  let weightedSumHomeMin = 0, weightedSumAwayMin = 0,weightedSumHomeRec = 0, weightedSumAwayRec = 0, weightedSumHomeMax = 0, weightedSumAwayMax = 0;
  let totalWeightHome = 0, totalWeightAway = 0;

  Object.keys(stats).forEach(key => {
      const { home, away } = stats[key];
      weightedSumHomeMin += isNaN(home.mean.lowerBound) ? 0 : home.mean.lowerBound * home.weight;
      weightedSumAwayMin += isNaN(away.mean.lowerBound) ? 0 : away.mean.lowerBound * away.weight;
      weightedSumHomeRec += isNaN(home.mean.adjustedMean) ? 0 : home.mean.adjustedMean * home.weight;
      weightedSumAwayRec += isNaN(away.mean.adjustedMean) ? 0 : away.mean.adjustedMean * away.weight;
      weightedSumHomeMax += isNaN(home.mean.upperBound) ? 0 : home.mean.upperBound * home.weight;
      weightedSumAwayMax += isNaN(away.mean.upperBound) ? 0 : away.mean.upperBound * away.weight;
      totalWeightHome += home.weight;
      totalWeightAway += away.weight;
  });

  const weightedAverageHomeMin = weightedSumHomeMin / totalWeightHome;
  const weightedAverageAwayMin = weightedSumAwayMin / totalWeightAway;
  const weightedAverageHomeRec = weightedSumHomeRec / totalWeightHome;
  const weightedAverageAwayRec = weightedSumAwayRec / totalWeightAway;
  const weightedAverageHomeMax = weightedSumHomeMax / totalWeightHome;
  const weightedAverageAwayMax = weightedSumAwayMax / totalWeightAway;

  // Calculating probabilities
  const probabilitiesHome = calculateProbabilities(historicalData.home, weightedAverageHomeMin, weightedAverageHomeRec, weightedAverageHomeMax);
  const probabilitiesAway = calculateProbabilities(historicalData.away, weightedAverageAwayMin, weightedAverageAwayRec, weightedAverageAwayMax);

  return {
      home: {min: weightedAverageHomeMin, rec: weightedAverageHomeRec, max: weightedAverageHomeMax, probabilities: probabilitiesHome},
      away: {min: weightedAverageAwayMin, rec: weightedAverageAwayRec, max: weightedAverageAwayMax, probabilities: probabilitiesAway}
  };
}

function calculateMean(data) {
  const sum = data.reduce((acc, value) => acc + (value || 0), 0);
  return data.length > 0 ? sum / data.length : 0;
}


function calculateMedian(data) {
  const cleanData = data.map(value => value || 0);
  const sortedData = cleanData.sort((a, b) => a - b);
  const mid = Math.floor(sortedData.length / 2);
  return sortedData.length % 2 !== 0 ? sortedData[mid] : (sortedData[mid - 1] + sortedData[mid]) / 2;
}


function calculateStdDev(data) {
  const mean = calculateMean(data);
  const squaredDiffs = data.map(value => {
    const cleanValue = value || 0;
    return (cleanValue - mean) ** 2;
  });
  const avgSquaredDiff = squaredDiffs.reduce((acc, value) => acc + value, 0) / data.length;
  return Math.sqrt(avgSquaredDiff);
}


function calculateEnhancedPrediction(data) {
  const mean = calculateMean(data);
  const median = calculateMedian(data);
  const stdDev = calculateStdDev(data);
  const adjustedMean = (mean + median) / 2; // Un promedio simple de mean y median
  const lowerBound = adjustedMean - stdDev; // Límite inferior del rango de predicción
  const upperBound = adjustedMean + stdDev; // Límite superior del rango de predicción

  return { adjustedMean, lowerBound, upperBound };
}

function findCommonParameters(gameOfInterest, allGames) {
  // Inicializar un objeto para almacenar los parámetros en común y su frecuencia
  let commonParameters = {};

  // Iterar sobre cada juego en la base de datos
  allGames.forEach(game => {
    // getTournament(game.gameId)
    game = JSON.parse(game.data);
    // Asegurarse de no comparar el juego con él mismo
    if (game.idGame !== gameOfInterest.idGame) {
      // Iterar sobre cada parámetro en el juego de interés
      for (let key in gameOfInterest) {
        if (key !== 'idGame' && key !== 'recommendedTeam') { // Ignorar idGame y recommendedTeam en la comparación
          if (gameOfInterest[key] === game[key]) {
            // Si el parámetro es común, aumentar su contador o inicializarlo
            if (commonParameters[key]) {
              commonParameters[key]++;
            } else {
              commonParameters[key] = 1;
            }
          }
        }
      }
    }
  });

  // Filtrar y mantener solo aquellos parámetros que se encontraron en común con al menos un otro juego
  let filteredCommonParameters = {};
  for (let param in commonParameters) {
    if (commonParameters[param] > 0) {
      filteredCommonParameters[param] = commonParameters[param];
    }
  }

  return filteredCommonParameters;
}

function roundToTwo(num) {
  if(num == 0) { return 0; }
  const parteEntera = Math.floor(num);
  const parteDecimal = num - parteEntera;

  if (parteDecimal < 0.25) {
    return parteEntera;
  } else if (parteDecimal < 0.75) {
    return parteEntera + 0.5;
  } else {
    return parteEntera + 1;
  }
} 

const setCollectionGames = async (data) => {
  try {
    // const docRef = doc(db, "gameStatistics", data.gameId.toString());
    // const docSnap = await getDoc(docRef);
  
    // if(!docSnap.exists()){
      // const gameStatistcsCollection = await setDoc(doc(db, "gameStatistics", data.gameId.toString()), data);
      // console.log(gameStatistcsCollection)
    // }    
  } catch (error) {
    // console.log(error)
  }
}

const getTournament = async (idGame) => {
  const response = await axios.get(`https://api.sofascore.com/api/v1/event/${idGame}`);
  const data = response.data;

}

const calculateAdvancedExpectedGoals = (shotsOnTarget, shotsInsideBox, shotsOutsideBox, bigChances) => {
  const xGPerShotOnTarget = 0.3;   // Probabilidad base de que un tiro a puerta sea gol
  const xGPerShotInsideBox = 0.2;  // Aumenta la probabilidad si el tiro es dentro del área
  const xGPerShotOutsideBox = 0.05; // Disminuye la probabilidad si el tiro es fuera del área
  const xGBigChanceBonus = 0.3;    // Bonus adicional si es una gran oportunidad

  let basicXG = shotsOnTarget * xGPerShotOnTarget;
  let adjustedXGInsideBox = shotsInsideBox * xGPerShotInsideBox;
  let adjustedXGOutsideBox = shotsOutsideBox * xGPerShotOutsideBox;
  let bigChancesXG = bigChances * xGBigChanceBonus;

  let totalXG = basicXG + adjustedXGInsideBox + adjustedXGOutsideBox + bigChancesXG;

  return totalXG;
}




// Función para calcular estadísticas descriptivas
function descriptiveStats(predictions, teamKey) {
  const stats = {};
  const accumulator = {};

  // Inicializar acumuladores para cada clave estadística
  predictions.forEach(prediction => {
    const teamStats = prediction[teamKey];
    Object.keys(teamStats).forEach(statKey => {
      if (!accumulator[statKey]) {
        accumulator[statKey] = { values: [], sum: 0, count: 0, min: Infinity, max: -Infinity };
        stats[statKey] = {};
      }
      teamStats[statKey].forEach(value => {
        accumulator[statKey].values.push(value);
        accumulator[statKey].sum += value;
        accumulator[statKey].count++;
        accumulator[statKey].min = Math.min(accumulator[statKey].min, value);
        accumulator[statKey].max = Math.max(accumulator[statKey].max, value);
      });
    });
  });

  // Calcular medias, medianas, rangos y desviaciones estándar, y almacenar los valores individuales
  Object.keys(accumulator).forEach(statKey => {
    const values = accumulator[statKey].values.sort((a, b) => a - b);
    const count = accumulator[statKey].count;
    const midIndex = Math.floor(count / 2);
    stats[statKey].values = values;
    stats[statKey].mean = accumulator[statKey].sum / count;
    stats[statKey].median = count % 2 !== 0 ? values[midIndex] : (values[midIndex - 1] + values[midIndex]) / 2;
    stats[statKey].range = accumulator[statKey].max - accumulator[statKey].min;
    stats[statKey].stdDev = Math.sqrt(values.reduce((varSum, value) => varSum + (value - stats[statKey].mean) ** 2, 0) / count);
  });

  return stats;
}

// Función para encontrar patrones usando correlación de Pearson
function pearsonCorrelation(set1, set2) {
  if (!set1 || !set2 || set1.length === 0 || set2.length === 0) {
    // console.error("One or both sets are empty");
    return null;
  }
  if (set1.length !== set2.length) {
    // console.error("Sets do not have the same length");
    return null;
  }
  const length = set1.length;
  const sum1 = set1.reduce((sum, value) => sum + value, 0);
  const sum2 = set2.reduce((sum, value) => sum + value, 0);
  const sum1Sq = set1.reduce((sum, value) => sum + value * value, 0);
  const sum2Sq = set2.reduce((sum, value) => sum + value * value, 0);
  const pSum = set1.reduce((sum, value, i) => sum + value * set2[i], 0);

  const num = pSum - (sum1 * sum2 / length);
  const den = Math.sqrt((sum1Sq - sum1 * sum1 / length) * (sum2Sq - sum2 * sum2 / length));

  if (den === 0) return 0;
  return num / den;
}

// Función para comparar grupos y encontrar diferencias significativas
function compareGroups(winningStats, losingStats) {
  const differences = {};
  Object.keys(winningStats).forEach(key => {
    const diff = winningStats[key].mean - losingStats[key].mean;
    differences[key] = {
      winningMean: winningStats[key].mean,
      losingMean: losingStats[key].mean,
      medianDiff: winningStats[key].median - losingStats[key].median,
      rangeDiff: winningStats[key].range - losingStats[key].range,
      stdDevDiff: winningStats[key].stdDev - losingStats[key].stdDev,
      difference: diff,
      percentDifference: (diff / losingStats[key].mean) * 100
    };
  });
  return differences;
}

function findPatterns(stats) {
  const patterns = {};
  const statsKeys = Object.keys(stats);

  for (let i = 0; i < statsKeys.length; i++) {
    for (let j = i + 1; j < statsKeys.length; j++) {
      const set1 = stats[statsKeys[i]].values;
      const set2 = stats[statsKeys[j]].values;
      const correlationKey = `${statsKeys[i]}_vs_${statsKeys[j]}`;
      patterns[correlationKey] = {
        pearsonCorrelation: pearsonCorrelation(set1, set2),
        stats1: statsKeys[i],
        stats2: statsKeys[j]
      };
    }
  }

  return patterns;
}

// Integración de todo el análisis
function advancedAnalysis(predictions) {
  const winningPredictions = predictions.filter(p => p.betStatus);
  const losingPredictions = predictions.filter(p => !p.betStatus);

  const winningStatsHome = descriptiveStats(winningPredictions, 'home');
  const losingStatsHome = descriptiveStats(losingPredictions, 'home');
  const winningStatsAway = descriptiveStats(winningPredictions, 'away');
  const losingStatsAway = descriptiveStats(losingPredictions, 'away');

  const differencesHome = compareGroups(winningStatsHome, losingStatsHome);
  const differencesAway = compareGroups(winningStatsAway, losingStatsAway);
  const winningPatternsHome = findPatterns(winningStatsHome);
  const winningPatternsAway = findPatterns(winningStatsAway);
  const losingPatternsHome = findPatterns(losingStatsHome);
  const losingPatternsAway = findPatterns(losingStatsAway);

  return {
    home: {
      differences: differencesHome,
      winningPatterns: winningPatternsHome,
      losingPatterns: losingPatternsHome
    },
    away: {
      differences: differencesAway,
      winningPatterns: winningPatternsAway,
      losingPatterns: losingPatternsAway
    }
  };
}

function predictMatchOutcome(analysisData, matchData) {
  console.log(matchData)
  const calculateAverages = (stats) => {
      const keys = Object.keys(stats);
      const totals = {};

      keys.forEach(key => {
        if(stats[key].length > 0) {
          totals[key] = stats[key].reduce((acc, value) => acc + value, 0) / stats[key].length;
        }
      });

      return totals;
  };

  const comparePerformance = (teamName, teamAverages, differences) => {
      return Object.keys(teamAverages).reduce((performance, stat) => {
          const meanDiffWinning = teamAverages[stat] - differences[stat].winningMean;
          const meanDiffLosing = teamAverages[stat] - differences[stat].losingMean;
          performance[stat] = {
              average: teamAverages[stat],
              winningMeanDiff: meanDiffWinning,
              losingMeanDiff: meanDiffLosing,
              percentFromWinning: (meanDiffWinning / differences[stat].winningMean) * 100,
              percentFromLosing: (meanDiffLosing / differences[stat].losingMean) * 100
          };
          return performance;
      }, {});
  };

  const evaluateMatchOutcome = (homePerf, awayPerf) => {
      const homeScore = Object.values(homePerf).reduce((acc, perf) => acc + perf.percentFromWinning, 0);
      const awayScore = Object.values(awayPerf).reduce((acc, perf) => acc + perf.percentFromWinning, 0);

      // const predictedClass = classifier.predict([[exampleMatch.homeScore, exampleMatch.awayScore]]);
      // const predictedResult = predictedClass[0] === 0 ? "tie" : (predictedClass[0] === 1 ? "home winner" : "away winner");
      // console.log("Predicted Result:", predictedResult);
 
      if(homeScore > 0 && awayScore < 0) {
        if(homeScore >= 75) {
          return "Home tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore);
        }else {
          return "El partido puede se un Gana/Empata para el equipo Home | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore);
        }
      } else if (homeScore < 0 && awayScore > 0) {
        if(awayScore >= 75) {
          return "Away tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore);
        } else {
          return "El partido puede se un Gana/Empata para el equipo Away | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore);
        }
      } else if((homeScore > 0 && awayScore > 0) && homeScore > awayScore) {
        const percentRatio = (awayScore / homeScore) * 100;
        if(percentRatio < 15) {
          return "Home tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore) + " PercentRatio: " + roundToTwo(percentRatio);
        } else {
          console.log(homeScore, awayScore)
          return "El partido puede se un Gana/Empata para el equipo Home, pero no es recomendable apostar | PercentRatio: " + roundToTwo(percentRatio);
        }
      } else if((homeScore > 0 && awayScore > 0) && homeScore < awayScore) {
        const percentRatio = (homeScore / awayScore) * 100;
        if(percentRatio < 15) {
          return "Away tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(homeScore) + " - AwayScore: " + roundToTwo(awayScore) + " PercentRatio: " + roundToTwo(percentRatio);
        } else {
          console.log(homeScore, awayScore)
          return "El partido puede se un Gana/Empata para el equipo Away, pero no es recomendable apostar | PercentRatio: " + roundToTwo(percentRatio);
        }
      } else {
        console.log("Original HomeScore" + homeScore);
        console.log("Original AwayScore" + awayScore);
        const newHomeScore = Math.abs(homeScore);
        const newAwayScore = Math.abs(awayScore);
        if(newHomeScore > newAwayScore) {
          const percentRatio = (newAwayScore / newHomeScore) * 100;
          if(percentRatio < 15) {
            return "Home tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(newHomeScore) + " - AwayScore: " + roundToTwo(newAwayScore) + " PercentRatio: " + roundToTwo(percentRatio);
          } else {
            return "El partido puede se un Gana/Empata para el equipo Home, pero no es recomendable apostar | PercentRatio: " + roundToTwo(percentRatio);
          }
        } else {
          const percentRatio = (newHomeScore / newAwayScore) * 100;
          if(percentRatio < 15) {
            return "Away tiene alta posibilidad de Ganar | HomeScore: " + roundToTwo(newHomeScore) + " - AwayScore: " + roundToTwo(newAwayScore) + " PercentRatio: " + roundToTwo(percentRatio);
          } else {
            return "El partido puede se un Gana/Empata para el equipo Away, pero no es recomendable apostar | PercentRatio: " + roundToTwo(percentRatio);
          }
        }
      }
      /*
      if ((homeScore > 0 && awayScore > 0) && (homeScore > awayScore)) {
        return "Home Gana/Empata | HomeScore: " + homeScore + " - AwayScore: " + awayScore;
      } else if ((homeScore > 0 && awayScore > 0) && (homeScore < awayScore)) {
        return "Away Gana/Empata | HomeScore: " + homeScore + " - AwayScore: " + awayScore;
      } else if (homeScore > 0 && awayScore < 0) {
          return "Home tiene alta posibilidad de Ganar | HomeScore: " + homeScore + " - AwayScore: " + awayScore;
      } else if (homeScore < 0 && awayScore > 0) {
          return "Away tiene alta posibilidad de Ganar | HomeScore: " + homeScore + " - AwayScore: " + awayScore;
      } else {
        return "El encuentro puede resultar un Empate | HomeScore: " + homeScore + " - AwayScore: " + awayScore;
      }
      */
  };

  const homeStats = calculateAverages(matchData.home);
  const awayStats = calculateAverages(matchData.away);

  const homePerformance = comparePerformance('home', homeStats, analysisData.home.differences);
  const awayPerformance = comparePerformance('away', awayStats, analysisData.away.differences);

  return evaluateMatchOutcome(homePerformance, awayPerformance);
}

/*
function predictMatchOutcome(analysisData, matchData) {
  const calculateAverages = (stats) => {
      const keys = Object.keys(stats);
      const totals = {};

      keys.forEach(key => {
          totals[key] = stats[key].reduce((acc, value) => acc + value, 0) / stats[key].length;
      });

      return totals;
  };

  const comparePerformance = (teamName, teamAverages, differences) => {
      return Object.keys(teamAverages).reduce((performance, stat) => {
          const meanDiff = teamAverages[stat] - differences[stat][teamName === 'home' ? 'winningMean' : 'losingMean'];
          performance[stat] = {
              average: teamAverages[stat],
              relativeToMean: meanDiff,
              percentRelativeToMean: (meanDiff / Math.abs(differences[stat][teamName === 'home' ? 'winningMean' : 'losingMean'])) * 100
          };
          return performance;
      }, {});
  };

  const homeStats = calculateAverages(matchData.home);
  const awayStats = calculateAverages(matchData.away);

  const homePerformance = comparePerformance('home', homeStats, analysisData.home.differences);
  const awayPerformance = comparePerformance('away', awayStats, analysisData.away.differences);

  console.log("Home Team Performance Overview:");
  console.log(homePerformance);

  console.log("Away Team Performance Overview:");
  console.log(awayPerformance);

  const homeTotalPerformance = Object.values(homePerformance).reduce((acc, perf) => acc + perf.percentRelativeToMean, 0);
  const awayTotalPerformance = Object.values(awayPerformance).reduce((acc, perf) => acc + perf.percentRelativeToMean, 0);

  const prediction = homeTotalPerformance > awayTotalPerformance ? "Home Team is likely to win" : "Away Team is likely to win or draw";

  return prediction;
}*/




const DetailedAnalysis = ({ data, dataSt, dataH2H, data_ }) => {
  const [analysisResult, setAnalysisResult] = useState({
    recommendedTeam: '',
    recommendedTeam_: '',
    idGame: 0,
    estimatedGoals: {min: 0, rec: 0, max: 0},
    estimatedGoalsHome: {min: 0, rec: 0, max: 0},
    estimatedGoalsAway: {min: 0, rec: 0, max: 0},
    estimatedGoalsRHome: {min: 0, rec: 0, max: 0},
    estimatedGoalsRAway: {min: 0, rec: 0, max: 0},
    estimatedCorners: {min: 0, rec: 0, max: 0},
    estimatedFouls: {min: 0, rec: 0, max: 0},
    estimatedCards: {min: 0, rec: 0, max: 0},
    estimatedBallPossessionHome: {min: 0, rec: 0, max: 0},
    estimatedBallPossessionAway: {min: 0, rec: 0, max: 0},
    estimatedShotOnTargetHome: {min: 0, rec: 0, max: 0},
    estimatedShotOnTargetAway: {min: 0, rec: 0, max: 0},
    estimatedBigChancesHome: {min: 0, rec: 0, max: 0},
    estimatedBigChancesAway: {min: 0, rec: 0, max: 0},
    estimatedKSavesHome: {min: 0, rec: 0, max: 0},
    estimatedKSavesAway: {min: 0, rec: 0, max: 0},
    estimatedDefenceHome: {min: 0, rec: 0, max: 0},
    estimatedDefenceAway: {min: 0, rec: 0, max: 0},
  });
  const [gameStatistcs, setGameStatistics] = useState([]);  
  const calculateWinRate = (team, statistics) => {
    let totalGames = team.win + team.tie + team.loss;

    if (totalGames > 0) {
        // Base win points with more weight to wins
        let winPoints = team.win + (team.tie * 0.7);  // Aumenta la ponderación de las victorias
        let winRate = winPoints / totalGames;
        // Sumando los valores de los arrays
        let totalShotsOnTarget = sumArray(statistics.totalShotOnTarget);
        let totalBigChances = sumArray(statistics.totalBigChances);
        let totalGoalkeeperSaves = sumArray(statistics.totalGoalkeeperSaves);
        let totalBallPossession = sumArray(statistics.totalBallPossession);

        // Factores calculados a partir de las sumas
        let offenseFactor = (totalShotsOnTarget + totalBigChances) / totalGames;
        let defenseFactor = totalGoalkeeperSaves / totalGames;
        let possessionFactor = (totalBallPossession / totalGames > 50) ? 1.05 : 0.95;

        // Ajuste del WinRate basado en factores de rendimiento
        let adjustedWinRate = winRate * Math.log1p(totalGames); // Factor de confianza basado en el número de juegos
        adjustedWinRate *= possessionFactor;  // Ajuste por posesión
        adjustedWinRate += 0.05 * (offenseFactor + defenseFactor); // Suma un peso extra de las estadísticas como ajuste

        return adjustedWinRate;
    } else { 
        return 0;
    } 
  };
   
  // const calculateWinRate = (team, statistics) => {
  //   let winPoints = team.win + team.tie * 0.7;
  //   let totalGames = team.win + team.tie + team.loss;

  //   if (totalGames > 0) {
  //       let winRate = winPoints / totalGames;
  //       // Ajustar la tasa de victorias aumentando la confianza cuanto más partidos se jueguen
  //       let confidenceFactor = Math.log1p(totalGames); // Uso del logaritmo natural incrementado para suavizar el efecto del número de juegos
  //       let adjustedWinRate = winRate * confidenceFactor;
  //       return adjustedWinRate;
  //   } else {
  //       return 0;
  //   }
  // };

  const calculateAverage = (values) => {
    // Verificar si 'values' es un array y no está vacío
    if (!Array.isArray(values) || values.length === 0) {
        return 0;
    }



    // No necesitas verificar si todos los elementos son números aquí si vas a convertir NaN a 0

    // Realizar el cálculo tratando NaN como 0
    let data = values.reduce((acc, val) => acc + (isNaN(val) ? 0 : val), 0);

    // Ahora simplemente verifica que 'values.length' no sea 0 para evitar división por 0
    if (values.length > 0) {
        return (data / values.length);
    } else {
        return 0;
    }
}; 
  useEffect(() => {
    const fetchGameStatistics = async () => {
      const gameStatisticsCollection = query(collection(db, 'gameStatistics'), where('gameId', '!=', data.idGame));
      const gameStatisticsSnapshot = await getDocs(gameStatisticsCollection);

      const gameStatisticsData = gameStatisticsSnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      console.log(gameStatisticsData) 
      setGameStatistics(gameStatisticsData); 
    } 

    // fetchGameStatistics(); 
    setGameStatistics(
      [
          {
              "gameId": 11351607,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,60,30,50,52,64,68,41,39,54,63,30,52,41,54,55,37,40,39,48,35,39,44,54,46],\"shotOnTarget\":[6,2,4,7,8,7,8,4,2,10,6,4,8,4,10,5,10,4,4,6,7,3,1,8,2],\"bigChances\":[2,0,0,7,2,2,4,2,3,4,2,0,2,2,4,0,7],\"goalR\":[2,1,1,0,0,2,2,0,1,0,2,1,0,0,0,1,2,2,0,2,1,2,4,3,3],\"goalKeeperSave\":[1,6,5,0,1,4,1,4,6,3,1,5,1,4,3,1,2,2,6,4,6,2,3,3,4],\"blockedShots\":[7,3,4,6,5,9,7,3,3,1,7,4,5,3,1,4,4,0],\"winH2H\":[3]},\"away\":{\"ballPossession\":[69,43,51,67,54,71,66,65,56,72,43,54,71,65,56,45,63,60,61,52,65,61,56,46,54],\"shotOnTarget\":[5,5,2,4,4,5,5,1,2,8,5,4,5,1,2,2,4,4,6,6,7,4,7,7,8],\"bigChances\":[1,1,1,3,3,3,3,5,3,3,1,3,3,5,3,1,2],\"goalR\":[3,0,0,2,0,1,0,2,0,1,0,0,1,2,0,0,3,1,2,1,3,2,0,2,0],\"goalKeeperSave\":[0,0,2,1,1,1,2,0,0,1,0,1,1,0,0,5,6,3,1,5,4,1,1,6,2],\"blockedShots\":[8,2,0,5,5,6,1,8,4,16,2,5,6,8,4,1,2,0],\"winH2H\":[6]}}",
              "datetime": "01/04/2024 13:0"
          },
          {
              "gameId": 11351611,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[35,35,32,45,46,37,32,36,35,27,35,32,46,32],\"shotOnTarget\":[6,2,3,4,1,4,3,4,4,3,6,3,1,3],\"bigChances\":[3,3,1,2,1,2,1,2,2,3,2,2],\"goalR\":[2,4,1,5,2,0,1,0,3,2,2,1,2,1],\"goalKeeperSave\":[2,5,2,4,2,5,6,1,3,7,2,2,2,6],\"blockedShots\":[4,2,4,7,3,1,1,7,2,3,4,4,3,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[53,65,45,46,63,55,54,49,33,57,65,46,55,33],\"shotOnTarget\":[5,1,3,4,4,3,8,4,5,0,1,4,3,5],\"bigChances\":[3,2,1,0,6,1,2,1,2,1,2,0,1,2],\"goalR\":[2,2,1,3,1,3,0,1,2,1,2,3,3,2],\"goalKeeperSave\":[2,0,6,2,1,5,2,1,5,4,0,2,5,5],\"blockedShots\":[2,6,3,3,7,4,2,1,0,7,6,3,4,0],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 5:30"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[61,56,63,31,43,36,34,47,46,73,61,63,36,73,45,30,43],\"shotOnTarget\":[9,4,2,3,1,6,4,3,3,10,9,2,6,10,3,1,4],\"bigChances\":[3,0,0,1,0,3,3,1,1,3,3,0,3,3,0,1],\"goalR\":[3,1,1,1,4,1,2,2,3,1,3,1,1,1,0,0,1],\"goalKeeperSave\":[1,3,2,4,3,6,5,5,2,2,1,2,6,2,5,8,3],\"blockedShots\":[7,2,3,0,5,3,0,4,3,1,7,3,3,1,6,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[66,60,55,60,65,64,42,62,66,49,66,60,62,49,55,70,57],\"shotOnTarget\":[5,5,6,3,9,5,4,8,6,2,5,3,8,2,5,8,4],\"bigChances\":[3,2,2,0,3,2,1,4,1,3,0,1,1,5],\"goalR\":[0,1,1,5,1,1,3,1,0,0,0,5,1,0,0,0,1],\"goalKeeperSave\":[2,2,1,3,1,1,3,5,4,3,2,3,5,3,3,1,3],\"blockedShots\":[3,0,1,3,2,7,3,3,3,1,3,3,3,1,6,6],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 8:0",
              "gameId": 11351613
          },
          {
              "gameId": 11351614,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[32,34,42,60,33,37,37,29,63,33,34,60,37,63,50,57,42],\"shotOnTarget\":[2,2,2,5,2,3,1,1,5,3,2,5,1,5,5,7,2],\"bigChances\":[1,0,2,2,1,1,1,0,1,0,2,1,0,4,5],\"goalR\":[0,2,1,1,1,0,1,0,2,2,2,1,1,2,2,2,0],\"goalKeeperSave\":[1,3,1,1,2,2,2,7,2,2,3,1,2,2,2,2,0],\"blockedShots\":[1,2,3,5,2,1,3,1,4,5,2,5,3,4,5,6],\"winH2H\":[1]},\"away\":{\"ballPossession\":[51,44,58,37,50,49,67,42,45,55,44,37,49,42,50,43,58],\"shotOnTarget\":[8,4,2,3,6,1,7,1,4,11,4,3,1,1,4,5,0],\"bigChances\":[0,3,1,1,0,4,0,4,3,3,1,0,0,2,1],\"goalR\":[0,4,0,0,1,3,3,5,1,0,4,0,3,5,2,0,1],\"goalKeeperSave\":[1,2,2,6,4,5,2,4,0,3,2,6,5,4,1,7,1],\"blockedShots\":[4,3,4,2,4,2,9,1,4,5,3,2,2,1,3,2],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 12:0"
          },
          {
              "datetime": "01/04/2024 10:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,44,40,71,55,67,31,61,64,52,44,71,67,61,52,52],\"shotOnTarget\":[2,4,3,7,1,12,7,3,2,7,4,7,12,3,7,0],\"bigChances\":[2,3,0,4,3,1,3,0,2,3,3,4,1,0,3,0],\"goalR\":[3,0,1,1,1,1,1,3,1,2,0,1,1,3,2,3,1,0,0,1],\"goalKeeperSave\":[1,4,5,0,3,0,7,3,3,5,4,0,0,3,5,4],\"blockedShots\":[3,5,3,7,3,9,4,5,3,1,5,7,9,5,1,4],\"winH2H\":[3]},\"away\":{\"ballPossession\":[46,47,39,67,51,55,52,40,59,42,46,39,51,52,59,48],\"shotOnTarget\":[5,5,4,3,3,9,2,4,5,3,5,4,3,2,5,7],\"bigChances\":[1,2,2,1,2,6,1,1,1,0,1,2,2,1,1,6],\"goalR\":[3,1,2,1,1,3,0,2,1,1,3,2,1,0,1,0,2,1,0,3],\"goalKeeperSave\":[6,1,7,1,3,0,4,3,2,3,6,7,3,4,2,0],\"blockedShots\":[1,2,2,3,11,3,6,3,11,4,1,2,11,6,11,5],\"winH2H\":[1]}}",
              "gameId": 11351615
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[53,34,44,35,48,54,37,41,31,67,34,44,48,37,59,59,44,53,65,55],\"shotOnTarget\":[4,4,6,1,10,11,7,4,4,7,4,6,10,7,2,1,5,5,2,4],\"bigChances\":[1,1,1,1,3,4,4,2,3,3,1,1,3,4,1,0,3,2,0],\"goalR\":[3,2,1,6,0,1,2,0,0,2,2,1,0,2,2,2,1,2,2,0],\"goalKeeperSave\":[5,5,3,2,5,1,1,3,3,2,5,3,5,1,4,0,1,4,2,0],\"blockedShots\":[4,2,3,1,3,2,3,3,1,4,2,3,3,3,1,4,2,4,3,2],\"winH2H\":[1]},\"away\":{\"ballPossession\":[23,52,40,24,32,67,61,45,59,24,23,40,32,61,41,41,56,47,35,45],\"shotOnTarget\":[2,2,3,3,3,4,8,2,3,4,2,3,3,8,6,2,2,6,4,1],\"bigChances\":[0,2,0,0,3,2,2,1,2,3,0,0,3,2,2,1,0,1,2],\"goalR\":[0,0,4,2,0,3,2,1,2,2,0,4,0,2,0,0,2,2,0,0],\"goalKeeperSave\":[3,5,3,0,5,1,1,2,2,0,3,3,5,1,2,1,3,3,2,4],\"blockedShots\":[2,2,4,6,1,6,5,4,6,2,2,4,1,5,5,1,4,4,1,3],\"winH2H\":[3]}}",
              "gameId": 11352319,
              "datetime": "02/04/2024 13:45"
          },
          {
              "datetime": "03/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[64,64,57,67,61,49,49,74,60,61,64,57,49,74,61],\"shotOnTarget\":[6,4,6,9,5,9,2,8,3,7,6,6,9,8,7],\"bigChances\":[3,3,4,3,4,5,1,5,2,6,3,4,5,5,6],\"goalR\":[1,0,1,3,1,0,1,2,2,0,1,1,0,2,0],\"goalKeeperSave\":[4,1,2,1,2,1,2,1,2,5,4,2,1,1,5],\"blockedShots\":[4,4,5,9,3,9,5,10,3,7,4,5,9,10,7],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,29,26,35,39,31,33,35,35,49,43,29,35,31,49],\"shotOnTarget\":[4,1,5,4,3,1,4,2,5,4,4,1,4,1,4],\"bigChances\":[3,1,2,0,1,0,1,1,2,0,3,1,0,0,0],\"goalR\":[2,3,1,1,1,3,4,2,0,2,2,3,1,3,2],\"goalKeeperSave\":[6,4,5,3,7,3,5,4,2,2,6,4,3,3,2],\"blockedShots\":[4,4,1,4,2,5,1,0,2,5,4,4,4,5,5],\"winH2H\":[0]}}",
              "gameId": 11352322
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[59,36,50,31,59,42,36,69,38,60,50,59,36,69,28,41],\"shotOnTarget\":[5,3,10,5,4,3,3,6,2,4,10,4,3,6,3,1],\"bigChances\":[2,1,5,5,3,2,3,2,1,0,5,3,3,2,3,1],\"goalR\":[1,2,0,0,2,3,1,1,2,1,0,2,1,1,0,1],\"goalKeeperSave\":[0,6,1,2,0,8,3,0,5,3,1,0,3,0,7,1],\"blockedShots\":[7,1,5,1,6,4,1,12,3,3,5,6,1,12,0,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[80,61,53,68,62,72,51,66,45,69,80,53,68,51,72,59],\"shotOnTarget\":[2,6,5,9,7,11,1,6,9,8,2,5,9,1,7,2],\"bigChances\":[1,1,0,1,4,1,3,7,3,1,0,1,2,0],\"goalR\":[1,1,2,3,1,1,2,1,2,0,1,2,3,2,2,0],\"goalKeeperSave\":[3,1,3,2,0,2,6,2,1,2,3,3,2,6,0,1],\"blockedShots\":[1,1,2,4,4,7,2,5,3,5,1,2,4,2,10,1],\"winH2H\":[1]}}",
              "gameId": 11352325,
              "datetime": "03/04/2024 13:30"
          },
          {
              "datetime": "02/04/2024 13:45",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[51,38,50,56,68,36,51,64,49,28,38,68,51,64,36,36,47,37],\"shotOnTarget\":[4,3,1,3,5,5,5,7,3,3,3,5,5,7,5,4,1,1],\"bigChances\":[1,1,1,1,3,0,2,4,1,2,1,3,2,4,1,2,2,1],\"goalR\":[1,4,3,2,2,3,2,0,1,1,4,2,2,0,0,1,1,0,2],\"goalKeeperSave\":[1,2,6,4,1,3,2,3,3,10,2,1,2,3,4,2,4,1],\"blockedShots\":[2,4,1,1,2,0,0,6,4,2,4,2,0,6,4,1,4,2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[66,43,62,42,40,43,51,68,56,32,66,62,40,43,64,64,53,63],\"shotOnTarget\":[7,6,3,4,6,3,4,4,3,6,7,3,6,3,4,3,5,1],\"bigChances\":[4,0,2,4,2,2,2,1,0,3,4,2,2,2,0,1,1,0],\"goalR\":[1,2,2,1,3,2,0,1,3,1,1,2,3,2,1,2,1,2,1],\"goalKeeperSave\":[3,2,0,1,3,4,3,1,1,3,3,0,3,4,4,2,0,0],\"blockedShots\":[6,3,2,7,0,0,0,2,7,5,6,2,0,0,5,7,3,4],\"winH2H\":[1]}}",
              "gameId": 11352330
          },
          {
              "datetime": "02/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[27,27,46,47,62,36,32,33,33,38,27,47,62,33,41],\"shotOnTarget\":[1,3,5,5,2,1,2,1,5,6,3,5,2,1,3],\"bigChances\":[1,1,3,4,0,0,2,2,1,5,1,4,0,2,3],\"goalR\":[3,0,3,3,1,5,1,2,3,1,0,3,1,2,3],\"goalKeeperSave\":[5,3,3,2,1,1,3,4,3,5,3,2,1,4,4],\"blockedShots\":[4,0,3,8,6,0,2,6,5,3,0,8,6,6,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[45,49,60,39,64,63,29,66,57,40,49,39,29,57,59],\"shotOnTarget\":[3,5,6,5,6,8,4,5,3,4,5,5,4,3,7],\"bigChances\":[1,1,3,2,5,4,1,1,0,2,1,2,1,0,2],\"goalR\":[1,3,2,4,0,0,3,2,3,1,3,4,3,3,2],\"goalKeeperSave\":[4,4,4,9,1,5,7,0,1,2,4,9,7,1,1],\"blockedShots\":[7,0,3,3,4,1,0,9,4,6,0,3,0,4,5],\"winH2H\":[1]}}",
              "gameId": 11352333
          },
          {
              "datetime": "02/04/2024 14:15",
              "gameId": 11352336,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,25,37,44,35,26,31,43,49,49,55,44,35,31,49,38,38,31,47,44,49,32,34,47,56],\"shotOnTarget\":[3,5,5,4,5,3,2,5,3,3,3,4,5,2,3,4,4,4,4,4,4,5,3,3,7],\"bigChances\":[2,3,2,1,3,2,2,3,2,0,2,1,3,2,2,2,2,2,0,0,1,3],\"goalR\":[1,1,5,0,0,0,0,2,1,3,1,0,0,0,1,1,0,1,3,1,3,0,0,1,0],\"goalKeeperSave\":[1,5,3,3,3,8,8,4,3,2,1,3,3,8,3,3,4,3,3,1,2,5,2,5,4],\"blockedShots\":[2,2,1,4,3,2,0,6,3,8,2,4,3,0,3,2,4,0,3,2,1,1,5],\"winH2H\":[5]},\"away\":{\"ballPossession\":[70,53,65,67,56,76,39,58,62,45,53,67,76,58,45,62,62,69,53,56,51,68,66,53,44],\"shotOnTarget\":[10,5,8,4,5,1,5,2,8,4,5,4,1,2,4,3,4,4,6,2,5,5,3,6,4],\"bigChances\":[3,4,3,4,2,1,1,1,4,1,4,4,1,1,1,0,1,0,5,2,2,0],\"goalR\":[1,2,1,0,0,1,4,2,2,3,2,0,1,2,3,1,1,2,2,0,2,1,1,0,2],\"goalKeeperSave\":[4,4,3,2,3,2,3,2,3,2,4,2,2,2,2,3,3,2,3,4,2,4,2,3,6],\"blockedShots\":[9,4,12,2,6,5,2,2,1,3,4,2,5,2,3,7,1,3,3,4,3,2,0],\"winH2H\":[4]}}"
          },
          {
              "gameId": 11352340,
              "datetime": "04/04/2024 14:15",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[46,32,55,72,78,68,64,49,55,49,32,78,64,55,48,66,57,61,62,54,44,49,29,46],\"shotOnTarget\":[4,5,3,4,6,5,4,8,3,3,5,6,4,3,2,6,6,1,6,8,6,5,2,3],\"bigChances\":[2,3,5,0,5,2,6,1,2,1,3,5,6,2,2,2,1,1,5,3],\"goalR\":[4,2,2,2,0,2,1,2,0,4,2,0,1,0,1,1,0,2,2,0,0,1,0,1],\"goalKeeperSave\":[1,7,7,3,1,4,4,4,4,9,7,1,4,4,5,1,4,1,2,2,5,6,2,4],\"blockedShots\":[0,0,3,6,5,7,2,2,5,0,0,5,2,5,1,8,7,10,7,4,5,5,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[55,65,49,42,45,69,32,65,46,55,55,49,42,32,52,34,43,39,38,46,56,51,71,54],\"shotOnTarget\":[5,4,4,1,9,3,1,3,7,5,5,4,1,1,6,2,4,3,4,2,5,7,2,5],\"bigChances\":[0,4,3,0,5,0,1,1,1,0,0,3,0,1,1,1,0,1,2,0],\"goalR\":[0,0,0,1,1,3,0,2,2,2,0,0,1,0,1,1,0,0,2,1,4,1,1,3],\"goalKeeperSave\":[2,4,6,3,2,1,8,3,1,0,2,6,3,8,1,5,6,1,4,7,2,4,1,3],\"blockedShots\":[2,3,0,5,8,12,1,2,3,3,2,0,5,1,1,0,5,2,0,5,5,4,5],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11352343,
              "datetime": "02/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[41,52,54,58,61,44,71,65,62,38,41,54,58,71,62,50,39,38,42,45,55,40,53,44],\"shotOnTarget\":[2,5,5,4,3,3,10,2,7,3,2,5,4,10,7,4,9,4,5,7,4,1,7,4],\"bigChances\":[1,0,4,4,4,3,6,1,4,2,1,4,4,6,4,1,3,4,1,4,0],\"goalR\":[0,2,1,0,3,4,0,1,3,4,0,1,0,0,3,0,1,1,2,2,1,1,2,3,0],\"goalKeeperSave\":[1,8,3,1,3,7,4,4,3,10,1,3,1,4,3,0,2,3,7,1,3,6,0,4],\"blockedShots\":[4,0,5,7,3,2,7,6,6,1,4,5,7,7,6,3,5,4,6,5,8,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[22,36,20,33,51,38,39,28,38,39,22,36,33,38,38,50,61,62,58,55,45,60,47,56],\"shotOnTarget\":[1,4,4,4,6,3,6,5,6,8,1,4,4,3,6,0,3,4,9,3,4,7,2,7],\"bigChances\":[0,1,3,3,1,3,2,5,3,0,1,3,1,5,0,1,1,3,1,2],\"goalR\":[2,0,1,2,3,0,0,0,0,2,2,0,2,0,0,1,3,2,1,3,0,0,3,0,0],\"goalKeeperSave\":[4,2,2,3,1,2,3,4,2,4,4,2,3,2,2,3,7,2,4,4,4,1,3,5],\"blockedShots\":[1,2,2,1,6,4,7,2,0,4,1,2,1,4,0,0,5,5,6,2,0,8],\"winH2H\":[4]}}"
          },
          {
              "datetime": "04/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[68,51,69,62,61,51,57,71,63,61,68,51,62,51,71,62,75],\"shotOnTarget\":[8,3,10,15,7,13,1,10,8,13,8,3,15,13,10,5,7],\"bigChances\":[1,1,2,9,2,4,0,5,6,6,1,1,9,4,5,5,4],\"goalR\":[0,1,0,2,0,1,3,1,1,1,0,1,2,1,1,1,0,0,2],\"goalKeeperSave\":[2,1,0,1,1,2,4,3,5,2,2,1,1,2,3,1,2],\"blockedShots\":[13,4,3,12,4,9,2,9,2,8,13,4,12,9,9,5,7],\"winH2H\":[3]},\"away\":{\"ballPossession\":[42,48,40,38,33,38,39,46,36,24,48,40,33,39,36,38,25],\"shotOnTarget\":[1,2,2,6,0,2,1,2,3,1,2,2,0,1,3,2,2],\"bigChances\":[0,0,1,2,0,1,2,1,1,3,0,1,0,2,1,2,1],\"goalR\":[8,2,3,2,5,1,1,3,5,2,2,3,5,1,5,2,2,4,1],\"goalKeeperSave\":[7,6,4,3,2,2,5,8,2,6,6,4,2,5,2,3,5],\"blockedShots\":[4,8,1,3,1,3,2,4,0,2,8,1,1,2,0,4,0],\"winH2H\":[1]}}",
              "gameId": 11352347
          },
          {
              "datetime": "03/04/2024 14:15",
              "gameId": 11352352,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,65,74,72,81,73,74,72,73,70,74,81,74,73,70,67,72,69,63,71,44,74],\"shotOnTarget\":[2,6,9,9,4,11,4,15,3,5,9,4,4,3,5,9,5,9,10,9,4,8],\"bigChances\":[2,3,5,4,2,2,2,3,2,3,5,2,2,2,3,6,3,4,5],\"goalR\":[1,1,2,1,0,2,1,1,0,1,2,0,1,0,1,1,2,0,0,0,0,2,0,0,0],\"goalKeeperSave\":[6,1,0,1,2,3,2,1,1,5,0,2,2,1,5,1,0,4,5,1,2,1],\"blockedShots\":[0,5,5,6,6,9,6,7,11,14,5,6,6,11,14,5,9,12,8,3,5],\"winH2H\":[8]},\"away\":{\"ballPossession\":[63,46,39,67,78,54,66,69,61,64,63,67,54,69,64,33,28,31,37,29,56,26],\"shotOnTarget\":[3,7,3,5,4,4,7,5,6,10,3,5,4,5,10,2,2,4,5,1,4,2],\"bigChances\":[1,2,0,4,3,2,3,1,3,6,1,4,2,1,6,0,2,0,2],\"goalR\":[2,0,0,1,1,3,2,0,3,0,2,1,3,0,0,3,3,2,0,3,4,3,4,0,5],\"goalKeeperSave\":[5,2,3,2,1,4,3,2,3,4,5,2,4,2,4,5,2,7,6,5,1,6],\"blockedShots\":[5,9,5,4,4,4,5,4,2,0,5,4,4,4,0,2,1,3,4,1,3],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11367157,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,2,2,6,2,1,1,0,0,1,2,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,1,1,0,0,0,1,1,0,3,3,1,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 5:40"
          },
          {
              "gameId": 11367158,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,2,2,4,2,3,0,0,2,1,2,4,3,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,3,1,2,0,0,0,2,0,0,1,2,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 10:30"
          },
          {
              "datetime": "01/04/2024 8:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,0,1,0,3,2,4,2,2,2,1,3,4,2,1,1,0,0,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[4]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,5,1,0,1,2,2,0,3,1,5,1,1,2,3,2,0,3,1,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "gameId": 11367162
          },
          {
              "datetime": "02/04/2024 13:45",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[58,50,20,49,30,54,57,44,48,46,58,50,30,57,44],\"shotOnTarget\":[3,2,4,5,6,2,5,1,1,3,3,2,6,5,1],\"bigChances\":[3,0,2,1,1,2,3,2,2,2,3,0,1,3,2],\"goalR\":[5,3,3,1,0,3,1,5,1,1,5,3,0,1,5],\"goalKeeperSave\":[4,2,7,5,3,1,0,3,4,1,4,2,3,0,3],\"blockedShots\":[3,4,3,4,8,8,6,1,3,0,3,4,8,6,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[35,56,44,51,59,47,45,50,63,47,44,51,45,50,47],\"shotOnTarget\":[5,5,4,5,10,5,1,1,5,4,4,5,1,1,4],\"bigChances\":[1,2,3,3,3,3,0,2,2,2,3,3,0,2,2],\"goalR\":[1,0,0,3,1,0,1,3,1,1,0,3,1,3,1],\"goalKeeperSave\":[3,2,2,5,2,1,4,6,7,6,2,5,4,6,6],\"blockedShots\":[3,3,5,5,3,0,0,2,3,5,5,5,0,2,5],\"winH2H\":[0]}}",
              "gameId": 11368277
          },
          {
              "gameId": 11368278,
              "datetime": "01/04/2024 7:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[38,51,55,57,49,55,66,43,55,57,51,57,66,43,42,55,52],\"shotOnTarget\":[6,3,4,1,4,5,3,9,2,3,3,1,3,9,3,2,4],\"bigChances\":[2,2,1,3,0,3,3,5,1,2,3,3,5,2,1,0],\"goalR\":[1,1,1,2,2,0,6,5,2,0,1,2,6,5,0,2,0,1,3,3],\"goalKeeperSave\":[2,1,2,3,15,2,1,4,1,3,1,3,1,4,2,1,3],\"blockedShots\":[8,6,9,7,3,7,4,8,6,6,6,7,4,8,5,3,7],\"winH2H\":[0]},\"away\":{\"ballPossession\":[57,41,57,52,42,49,52,58,37,28,57,42,52,37,58,45,48],\"shotOnTarget\":[6,1,4,8,3,4,3,3,8,7,4,3,3,8,2,3,4],\"bigChances\":[6,1,3,3,1,1,4,4,1,1,4,1,1,2],\"goalR\":[2,0,1,4,1,1,2,0,0,0,1,1,2,0,0,1,0,1,0,1],\"goalKeeperSave\":[0,1,0,2,2,2,0,1,5,3,0,2,0,5,4,1,3],\"blockedShots\":[2,2,5,6,12,1,7,3,1,1,5,12,7,1,4,1,4],\"winH2H\":[3]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[46,34,43,54,59,51,46,66,50,45,46,34,54,59,51],\"shotOnTarget\":[4,5,5,5,3,5,4,5,3,2,4,5,5,3,9],\"bigChances\":[1,1,3,3,3,3,2,3,3,1,1,1,3,3,2],\"goalR\":[2,1,1,1,2,2,1,0,1,1,2,1,1,2,0],\"goalKeeperSave\":[5,2,0,5,2,4,1,1,2,5,5,2,5,2,2],\"blockedShots\":[2,2,5,4,4,3,1,9,3,0,2,2,4,4,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[51,56,60,64,72,49,49,56,32,49,60,49,49,32,49],\"shotOnTarget\":[4,1,3,6,4,7,3,3,6,3,3,7,3,6,2],\"bigChances\":[3,0,1,5,4,2,1,6,2,2,1,2,1,2,0],\"goalR\":[0,2,0,2,0,0,1,1,2,1,0,0,1,2,0],\"goalKeeperSave\":[2,0,4,1,2,4,5,3,5,1,4,4,5,5,8],\"blockedShots\":[1,1,3,3,9,4,4,2,4,6,3,4,4,4,4],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11368279
          },
          {
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[45,44,64,50,49,43,55,47,39,41,45,64,50,55,47,52,51,59,54,51],\"shotOnTarget\":[3,2,7,3,2,1,5,4,5,4,3,7,3,5,4,7,5,6,8,4],\"bigChances\":[3,2,3,2,1,1,1,2,2,1,3,3,2,1,2,2,1,1,3],\"goalR\":[1,2,2,1,1,0,0,1,0,3,1,2,1,0,1,2,2,0,1,0,1,0,3],\"goalKeeperSave\":[3,3,3,4,3,5,1,1,2,5,3,3,4,1,1,4,2,1,0,4],\"blockedShots\":[5,4,5,9,3,1,2,5,1,5,5,5,9,2,5,6,5,5,2,1],\"winH2H\":[3]},\"away\":{\"ballPossession\":[62,58,59,36,58,44,48,54,72,48,62,36,58,48,48,48,49,41,46,49],\"shotOnTarget\":[5,2,7,3,3,3,6,1,5,2,5,3,3,6,2,6,4,1,1,4],\"bigChances\":[2,1,0,5,2,3,2,1,0,2,0,5,3,0,6,1,0,1],\"goalR\":[1,3,2,0,2,1,2,1,2,1,1,0,2,2,1,2,1,2,1,0,2,1,0],\"goalKeeperSave\":[1,3,0,6,6,7,7,11,5,0,1,6,6,7,0,5,4,4,6,3],\"blockedShots\":[3,3,6,1,3,4,4,4,3,1,3,1,3,4,1,5,2,0,6,2],\"winH2H\":[2]}}",
              "gameId": 11368281
          },
          {
              "gameId": 11368282,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[42,40,43,24,49,50,29,52,28,43,40,49,50,52,43,51],\"shotOnTarget\":[9,4,2,2,6,3,3,1,7,4,4,6,3,1,4,7],\"bigChances\":[4,3,2,0,2,1,3,3,3,2,3,2,1,3,2,3],\"goalR\":[0,3,2,5,2,1,1,1,1,0,3,2,1,1,0,0],\"goalKeeperSave\":[3,4,4,2,2,2,5,2,4,3,4,2,2,2,3,2],\"blockedShots\":[4,2,2,0,9,0,1,2,0,4,2,9,0,2,4,7],\"winH2H\":[1]},\"away\":{\"ballPossession\":[57,35,39,35,43,41,50,43,50,21,35,39,41,43,21,49],\"shotOnTarget\":[4,3,6,6,5,3,2,5,5,3,3,6,3,5,3,2],\"bigChances\":[3,2,1,5,1,2,1,2,4,2,1,2,1,4,1],\"goalR\":[0,0,1,2,1,4,0,2,3,1,0,1,4,2,1,1],\"goalKeeperSave\":[4,1,2,3,2,6,1,2,3,0,1,2,6,2,0,6],\"blockedShots\":[3,3,0,2,8,1,7,5,5,4,3,0,1,5,4,2],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,45,71,44,46,61,51,36,49,50,45,46,61,49,52],\"shotOnTarget\":[3,2,3,4,4,3,3,6,5,5,2,4,3,5,5],\"bigChances\":[0,0,5,1,1,2,1,2,0,1,1],\"goalR\":[1,3,1,3,0,0,0,0,0,1,3,0,0,0,2,1,2,1,3],\"goalKeeperSave\":[2,6,2,4,4,0,2,4,1,3,6,4,0,1,0],\"blockedShots\":[6,5,6,9,1,3,1,6,2,2,5,1,3,2],\"winH2H\":[2]},\"away\":{\"ballPossession\":[61,57,66,56,74,71,64,52,68,63,66,56,71,52,48],\"shotOnTarget\":[5,5,6,6,8,3,8,6,3,4,6,6,3,6,1],\"bigChances\":[2,3,1,1,4,1,2,3,1,1,1,1,1,3],\"goalR\":[2,2,2,1,1,0,3,2,5,2,2,1,0,2,1,2,1,2,2],\"goalKeeperSave\":[0,3,3,4,3,3,2,2,4,6,3,4,3,2,3],\"blockedShots\":[8,6,8,3,6,1,9,4,6,6,8,3,1,4],\"winH2H\":[3]}}",
              "gameId": 11368284,
              "datetime": "01/04/2024 9:0"
          },
          {
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,42,59,34,58,56,59,58,35,41,58,59,34,59,35,62,59,68],\"shotOnTarget\":[14,3,9,6,4,7,4,5,4,4,14,9,6,4,4,6,4,2],\"bigChances\":[3,3,2,1,2,2,2,0,1,1,3,2,1,2,1,3,2],\"goalR\":[0,2,1,3,1,1,1,3,2,1,0,1,3,1,2,1,0,1,0,2,1,0,3,0],\"goalKeeperSave\":[1,3,1,2,2,7,1,3,3,1,1,1,2,1,3,5,3,1],\"blockedShots\":[9,3,3,1,5,1,3,4,6,4,9,3,1,3,6,6,5,9],\"winH2H\":[2]},\"away\":{\"ballPossession\":[50,28,36,55,41,58,41,44,38,39,50,36,58,44,39,38,41,32],\"shotOnTarget\":[4,3,4,2,1,6,1,8,3,5,4,4,6,8,5,6,4,1],\"bigChances\":[1,2,1,3,0,1,1,1,1,2,1,1,1,1,2,1,0],\"goalR\":[3,1,1,1,2,3,3,2,1,2,3,1,3,2,2,1,0,3,1,0,0,0,1,0],\"goalKeeperSave\":[6,6,0,2,3,3,3,1,2,6,6,0,3,1,6,5,1,1],\"blockedShots\":[4,6,5,6,2,2,8,3,3,0,4,5,2,3,0,2,0,3],\"winH2H\":[3]}}",
              "gameId": 11368285
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[48,39,43,50,49,58,46,51,53,35,48,43,50],\"shotOnTarget\":[5,4,3,9,4,5,0,4,3,2,5,3,9],\"bigChances\":[1,2,0,2,3,0,1,2,2,1,0,2],\"goalR\":[1,1,3,0,2,1,3,1,0,1,1,3,0,1],\"goalKeeperSave\":[4,4,2,5,1,2,3,3,3,1,4,2,5],\"blockedShots\":[2,2,1,7,2,2,1,2,2,1,2,1,7],\"winH2H\":[1]},\"away\":{\"ballPossession\":[55,58,59,39,50,45,35,60,45,59,58,50,60],\"shotOnTarget\":[8,3,7,8,4,12,0,1,3,5,3,4,1],\"bigChances\":[3,3,3,2,1,2,0,1,0,2,3,1,1],\"goalR\":[2,1,3,0,2,3,1,3,1,2,1,2,3,2],\"goalKeeperSave\":[0,5,4,3,3,2,5,5,7,2,5,3,5],\"blockedShots\":[8,4,8,3,1,10,2,5,0,4,4,1,5],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11368286
          },
          {
              "gameId": 11368287,
              "datetime": "02/04/2024 14:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,46,74,64,63,63,56,67,64,62,46,64,56,64,53],\"shotOnTarget\":[5,6,6,2,6,2,6,6,4,3,6,2,6,4,1],\"bigChances\":[4,1,3,3,4,2,3,2,3,2,1,3,3,3,0],\"goalR\":[0,0,0,1,2,0,1,2,3,0,0,1,1,3,0],\"goalKeeperSave\":[3,2,5,3,1,2,4,1,3,3,2,3,4,3,2],\"blockedShots\":[7,4,3,6,2,2,3,4,5,4,4,6,3,5,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[55,62,55,46,66,34,59,66,66,58,55,46,66,59,47],\"shotOnTarget\":[9,2,5,4,5,5,6,7,3,0,9,4,5,6,3],\"bigChances\":[3,2,4,3,7,2,2,4,1,3,3,7,2,2],\"goalR\":[0,1,1,0,2,3,1,2,0,1,0,0,2,1,0],\"goalKeeperSave\":[2,0,1,0,4,3,0,0,3,1,2,0,4,0,1],\"blockedShots\":[0,2,0,2,8,6,4,5,6,3,0,2,8,4,6],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11368288,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[73,60,34,65,59,62,65,56,55,51,60,34,62,65,55,53],\"shotOnTarget\":[5,11,5,5,1,4,7,7,6,4,11,5,4,7,6,9],\"bigChances\":[1,8,1,1,1,3,5,3,2,8,1,1,3,3,1],\"goalR\":[1,0,1,2,0,2,1,1,1,1,0,1,2,1,1,0],\"goalKeeperSave\":[2,1,1,3,1,1,3,0,1,1,1,1,1,3,1,2],\"blockedShots\":[2,2,2,6,3,2,3,3,9,2,2,2,2,3,9,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[41,55,48,54,53,32,59,42,43,47,41,54,53,32,42,47],\"shotOnTarget\":[2,5,13,4,1,2,5,1,3,4,2,4,1,2,1,2],\"bigChances\":[0,7,1,0,3,1,0,2,2,1,0,3,0,0],\"goalR\":[1,1,3,1,2,3,1,0,0,2,1,1,2,3,0,1],\"goalKeeperSave\":[6,5,1,2,3,10,0,3,3,0,6,2,3,10,3,8],\"blockedShots\":[4,4,3,2,6,2,8,2,5,3,4,2,6,2,2,1],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11368289,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[45,67,66,66,80,76,67,57,79,64,45,67,80],\"shotOnTarget\":[5,2,3,3,9,7,7,9,1,1,5,2,9],\"bigChances\":[4,0,1,1,7,6,3,6,3,1,4,0,7],\"goalR\":[1,2,3,2,0,0,4,5,1,1,1,2,0,2],\"goalKeeperSave\":[5,2,2,2,4,2,3,4,2,6,5,2,4],\"blockedShots\":[5,2,4,3,7,1,2,4,3,5,5,2,7],\"winH2H\":[0]},\"away\":{\"ballPossession\":[62,62,60,65,42,70,64,71,68,68,60,70,64],\"shotOnTarget\":[3,5,7,4,8,3,6,6,12,7,7,3,6],\"bigChances\":[1,2,5,3,0,4,2,3,1,2,0,4],\"goalR\":[1,0,1,0,0,3,1,2,1,1,1,3,1,1],\"goalKeeperSave\":[5,0,3,3,3,3,1,0,1,5,3,3,1],\"blockedShots\":[5,3,11,13,1,7,6,11,3,2,11,7,6],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[50,34,25,59,47,52,49,41,33,50,34,59,52,33,46,61,54],\"shotOnTarget\":[3,3,7,3,6,8,9,4,7,1,3,3,8,7,4,4,0],\"bigChances\":[1,3,1,2,3,2,1,4,1,1,3,4,2,2,1],\"goalR\":[1,1,2,1,1,1,1,0,2,1,1,1,1,2,0,0,0,1],\"goalKeeperSave\":[1,4,3,2,3,4,4,3,5,1,4,2,4,5,0,1,3],\"blockedShots\":[4,2,5,5,5,6,6,4,4,1,2,5,6,4,3,7,2],\"winH2H\":[2]},\"away\":{\"ballPossession\":[45,38,65,42,35,52,51,64,58,48,45,65,35,52,54,39,46],\"shotOnTarget\":[7,2,4,5,3,4,4,6,3,3,7,4,3,4,1,1,3],\"bigChances\":[3,3,4,2,0,4,1,0,2,3,4,0,4,0,2,0],\"goalR\":[3,2,2,1,1,5,1,2,1,0,3,2,1,5,2,2,0,1],\"goalKeeperSave\":[3,3,3,3,3,7,6,2,2,1,3,3,3,7,2,3,0],\"blockedShots\":[4,2,6,4,2,3,5,8,0,9,4,6,2,3,1,5,0],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11368291
          },
          {
              "gameId": 11368292,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[52,58,36,24,68,67,51,22,41,38,58,68,67,41],\"shotOnTarget\":[3,8,5,2,9,8,6,1,11,3,8,9,8,11],\"bigChances\":[0,3,2,1,4,2,2,0,2,1,3,4,2,2],\"goalR\":[1,1,4,0,2,3,2,2,3,1,1,2,3,3,2,2],\"goalKeeperSave\":[2,3,3,10,2,4,3,7,3,3,3,2,4,3],\"blockedShots\":[4,3,5,1,8,2,4,3,12,3,3,8,2,12],\"winH2H\":[1]},\"away\":{\"ballPossession\":[61,60,46,33,49,41,64,54,51,47,60,46,41,64],\"shotOnTarget\":[6,4,5,3,5,5,0,1,6,6,4,5,5,0],\"bigChances\":[0,2,1,1,1,3,1,0,2,2,1,3,1],\"goalR\":[2,1,2,0,2,1,0,1,2,1,1,2,1,0,3,0],\"goalKeeperSave\":[2,1,1,9,1,4,1,1,5,1,1,1,4,1],\"blockedShots\":[5,5,2,2,8,4,3,0,3,5,5,2,4,3],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[53,49,41,49,34,35,50,51,61,31,53,41,49,50,61,60,44,53,63],\"shotOnTarget\":[4,1,5,8,7,3,4,2,2,4,4,5,8,4,2,3,6,2,6],\"bigChances\":[1,1,3,4,3,2,2,5,2,1,1,3,4,2,2,2,1,0],\"goalR\":[0,2,0,1,1,3,1,1,3,1,0,0,1,1,3,3,1,2,3],\"goalKeeperSave\":[6,4,4,4,2,4,1,2,2,2,6,4,4,1,2,2,1,2,3],\"blockedShots\":[5,4,5,7,6,3,4,11,9,4,5,5,7,4,9,2,4,0,2],\"winH2H\":[1]},\"away\":{\"ballPossession\":[55,37,36,45,58,51,45,56,54,46,55,36,58,56,46,40,56,47,37],\"shotOnTarget\":[4,4,2,3,4,5,2,5,5,12,4,2,4,5,12,6,2,4,6],\"bigChances\":[5,3,1,0,3,0,2,1,1,5,5,1,3,1,5,2,1,5],\"goalR\":[3,4,0,0,2,5,3,3,2,0,3,0,2,3,0,2,2,0,0],\"goalKeeperSave\":[1,1,4,2,3,4,2,3,1,1,1,4,3,3,1,1,4,2,6],\"blockedShots\":[2,3,1,3,2,2,2,4,5,6,2,1,2,4,6,2,3,2,2],\"winH2H\":[3]}}",
              "gameId": 11368293,
              "datetime": "01/04/2024 9:0"
          },
          {
              "gameId": 11368294,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[60,65,50,42,49,44,35,53,49,54,65,49,44,53,56,56,32,52],\"shotOnTarget\":[2,1,4,4,4,4,1,4,5,4,1,4,4,4,6,2,7,0],\"bigChances\":[1,2,1,3,3,3,0,0,5,1,2,3,3,0,3,0,0,2],\"goalR\":[0,3,4,2,1,1,1,0,1,1,3,1,1,0,0,1,3,1],\"goalKeeperSave\":[2,1,6,5,1,0,5,1,4,4,1,1,0,1,1,3,1],\"blockedShots\":[1,2,5,2,5,5,4,9,2,3,2,5,5,9,3,1,3,7],\"winH2H\":[3]},\"away\":{\"ballPossession\":[24,22,24,23,40,47,55,39,32,43,24,24,23,55,44,44,68,48],\"shotOnTarget\":[1,2,4,3,2,3,2,1,6,5,1,4,3,2,1,4,4,1],\"bigChances\":[0,1,2,0,1,0,1,0,2,2,0,2,0,1,0,2,2,2],\"goalR\":[1,1,1,1,1,3,1,2,0,2,1,1,1,1,3,2,4,0],\"goalKeeperSave\":[4,3,8,4,4,0,6,6,1,2,4,8,4,6,3,1,4],\"blockedShots\":[0,5,1,1,1,2,6,2,4,0,0,1,1,6,1,5,4,1],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11368295,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,26,27,38,30,44,32,24,29,30,26,30,44,29,30,48,51,39],\"shotOnTarget\":[2,3,3,4,3,2,4,0,1,4,3,3,2,1,4,1,1,3],\"bigChances\":[1,1,0,2,0,2,0,0,1,1,0,0,1,1,0,1],\"goalR\":[5,1,4,0,2,2,3,3,0,1,1,2,2,0,1,1,1,0,1,0],\"goalKeeperSave\":[3,4,8,3,8,0,6,7,5,2,4,8,0,5,2,3,1,5],\"blockedShots\":[2,0,1,2,1,0,3,1,1,3,0,1,0,1,3,4,8,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[41,62,40,40,53,22,45,47,37,35,41,40,53,22,47,52,49,61],\"shotOnTarget\":[6,5,3,4,3,6,7,1,3,5,6,3,3,6,1,4,2,5],\"bigChances\":[6,1,2,1,3,2,1,2,3,6,1,3,1,4,1,0],\"goalR\":[0,3,3,1,1,3,1,0,0,0,0,3,1,3,0,1,0,1,2,0],\"goalKeeperSave\":[2,6,4,2,2,3,1,4,1,3,2,4,2,3,4,0,1,2],\"blockedShots\":[3,5,1,1,2,3,1,0,5,2,3,1,2,3,0,2,2,2],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11368296,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[59,67,76,60,52,39,67,60,43,70,59,76,52,39,60,50],\"shotOnTarget\":[6,5,9,3,5,3,9,6,3,3,6,9,5,3,6,5],\"bigChances\":[3,2,3,2,3,2,1,3,1,0,3,3,3,2,3,2],\"goalR\":[1,2,2,1,1,0,1,3,0,1,1,2,1,0,3,1],\"goalKeeperSave\":[5,0,2,3,0,3,2,2,6,3,5,2,0,3,2,2],\"blockedShots\":[11,10,13,2,2,4,7,9,9,1,11,13,2,4,9,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[62,63,54,54,27,56,68,62,62,58,63,27,68,62,58,50],\"shotOnTarget\":[7,5,2,3,4,5,1,4,9,3,5,4,1,4,3,2],\"bigChances\":[2,3,0,1,1,0,0,1,5,1,3,1,0,1,1,1],\"goalR\":[2,3,2,1,4,2,3,3,2,4,3,4,3,3,4,2],\"goalKeeperSave\":[5,5,2,5,7,4,3,6,1,5,5,7,3,6,5,3],\"blockedShots\":[3,7,3,6,0,1,5,5,8,5,7,0,5,5,5,1],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[55,50,77,70,56,58,62,40,51,42,55,77,58,62,42,46,60,41,54,63],\"shotOnTarget\":[4,2,5,9,2,3,4,1,4,4,4,5,3,4,4,4,6,2,0,4],\"bigChances\":[1,1,4,2,1,3,1,2,1,1,1,4,3,1,1,6,3,1,0,2],\"goalR\":[2,3,1,1,1,2,1,5,2,0,2,1,2,1,0,0,1,1,0,0,0,0,0],\"goalKeeperSave\":[7,5,3,2,3,2,3,4,3,5,7,3,2,3,5,3,2,6,4,1],\"blockedShots\":[5,6,7,3,3,2,2,1,2,2,5,7,2,2,2,3,3,6,4,2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[50,62,50,46,35,76,51,39,63,46,62,46,51,63,46,54,40,59,46,37],\"shotOnTarget\":[0,3,9,5,4,10,2,2,1,2,3,5,2,1,2,3,3,7,4,1],\"bigChances\":[0,3,2,1,1,0,0,1,1,0,2,0,1,1,0,1,2,3,0],\"goalR\":[0,1,2,0,0,0,2,1,2,0,1,0,2,2,0,1,0,0,0,3,0,2,0],\"goalKeeperSave\":[2,0,2,1,6,2,1,3,1,5,0,1,1,1,5,3,6,2,0,1],\"blockedShots\":[3,1,6,0,4,9,1,4,3,4,1,0,1,3,4,2,2,7,5,6],\"winH2H\":[2]}}",
              "gameId": 11368297,
              "datetime": "01/04/2024 9:0"
          },
          {
              "datetime": "01/04/2024 9:0",
              "gameId": 11368298,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[49,56,41,48,56,65,53,74,38,58,49,41,65,74,38,70],\"shotOnTarget\":[4,1,3,1,2,7,2,3,3,5,4,3,7,3,3,7],\"bigChances\":[2,2,2,1,1,1,1,3,2,2,2,1,1,3,4],\"goalR\":[0,0,2,2,0,1,1,0,0,1,0,2,1,0,0,1,0,1],\"goalKeeperSave\":[0,1,1,3,2,0,3,3,1,3,0,1,0,3,1,2],\"blockedShots\":[3,3,3,7,1,4,3,6,7,3,3,3,4,6,7,4],\"winH2H\":[2]},\"away\":{\"ballPossession\":[66,39,40,47,48,44,59,46,59,44,39,48,44,59,44,30],\"shotOnTarget\":[9,4,4,3,7,6,5,6,5,2,4,7,6,5,2,3],\"bigChances\":[3,1,2,2,6,3,2,2,3,1,1,6,3,3,1,1],\"goalR\":[2,1,1,2,1,1,4,1,3,1,1,1,1,3,1,1,5,3],\"goalKeeperSave\":[3,6,2,4,9,5,2,3,7,6,6,9,5,7,6,6],\"blockedShots\":[6,2,4,4,2,6,1,2,7,4,2,2,6,7,4,0],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11368299,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[48,65,61,48,58,45,60,52,54,57,48,48,58,60,54,53],\"shotOnTarget\":[6,0,3,5,5,5,3,3,6,1,6,5,5,3,6,1],\"bigChances\":[1,0,1,2,1,0,3,1,2,1,1,2,1,3,2,1],\"goalR\":[1,1,3,0,0,1,1,1,1,2,1,0,0,1,1,1,2],\"goalKeeperSave\":[6,1,5,1,4,3,3,3,0,6,6,1,4,3,0,1],\"blockedShots\":[1,4,3,2,7,0,7,3,7,2,1,2,7,7,7,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[46,39,34,53,50,37,61,43,32,44,46,39,53,37,43,47],\"shotOnTarget\":[4,0,8,4,2,2,9,4,7,5,4,0,4,2,4,2],\"bigChances\":[1,4,0,1,2,6,2,2,3,1,0,2,2,1],\"goalR\":[1,0,2,1,1,1,2,1,2,2,1,0,1,1,1,1,2],\"goalKeeperSave\":[4,3,5,0,2,2,1,2,1,2,4,3,0,2,2,0],\"blockedShots\":[2,3,6,6,2,2,10,2,6,1,2,3,6,2,2,4],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[42,44,35,52,35,47,34,28,29,61,35,52,47,28,40],\"shotOnTarget\":[1,3,2,4,1,2,2,4,3,4,2,4,2,4,2],\"bigChances\":[0,3,1,2,1,1,1,1,0,0,1,2,1,1,1],\"goalR\":[3,1,0,0,1,1,3,0,0,1,0,0,1,0,1,1,0],\"goalKeeperSave\":[10,1,0,1,3,3,4,4,4,3,0,1,3,4,7],\"blockedShots\":[1,3,3,1,2,5,2,3,0,5,3,1,5,3,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[41,43,54,41,43,51,52,62,41,56,41,41,43,41,60],\"shotOnTarget\":[4,5,5,7,5,4,5,3,1,6,4,7,5,1,9],\"bigChances\":[2,1,1,1,3,2,2,1,0,4,2,1,3,0,4],\"goalR\":[1,1,1,2,2,1,1,1,0,2,1,2,2,0,2,3,0],\"goalKeeperSave\":[6,2,3,5,3,0,6,1,4,4,6,5,3,4,0],\"blockedShots\":[7,7,2,1,7,2,4,1,1,2,7,1,7,1,6],\"winH2H\":[0]}}",
              "gameId": 11368301,
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[41,52,37,51,66,48,55,61,42,41,37,51,55,61,42,56,46,48,51],\"shotOnTarget\":[8,7,3,5,7,7,4,0,6,2,3,5,4,0,6,2,6,1,9],\"bigChances\":[3,1,1,2,2,3,1,1,3,1,1,2,1,1,3,1,2],\"goalR\":[1,3,1,1,3,2,2,1,1,0,1,1,2,1,1,1,1,1,1,2,0],\"goalKeeperSave\":[2,2,5,3,5,3,3,3,4,6,5,3,3,3,4,3,1,4,2],\"blockedShots\":[3,1,3,5,4,2,4,6,2,1,3,5,4,6,2,2,2,1,3],\"winH2H\":[2]},\"away\":{\"ballPossession\":[36,35,26,32,51,48,42,47,26,41,36,35,51,48,26,44,54,52,49],\"shotOnTarget\":[4,1,5,1,0,1,6,3,4,9,4,1,0,1,4,5,2,5,2],\"bigChances\":[0,0,0,1,0,1,2,0,0,2,0,0,0,1,0,1,1],\"goalR\":[0,0,3,2,2,1,1,1,2,0,0,0,2,1,2,1,2,0,1,1,2],\"goalKeeperSave\":[5,4,3,2,2,3,4,1,5,2,5,4,2,3,5,1,4,1,7],\"blockedShots\":[2,1,0,2,9,4,4,1,5,4,2,1,9,4,5,7,1,4,2],\"winH2H\":[2]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11368302
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[60,53,39,38,41,49,56,37,71,39,60,39,38,49,36],\"shotOnTarget\":[2,6,4,4,2,2,3,0,4,3,2,4,4,2,3],\"bigChances\":[0,2,5,5,2,3,0,1,2,0,5,5,0],\"goalR\":[3,2,1,0,2,2,1,0,0,1,3,1,0,2,2],\"goalKeeperSave\":[4,1,4,5,7,1,7,4,3,2,4,4,5,1,3],\"blockedShots\":[1,7,0,8,5,1,2,2,12,3,1,0,8,1,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[59,45,58,54,57,49,65,44,63,62,45,58,49,65,64],\"shotOnTarget\":[0,2,2,0,3,1,2,7,3,2,2,2,1,2,5],\"bigChances\":[0,2,1,0,4,3,2,1,0,2,2,1,3,2,2],\"goalR\":[0,3,2,1,0,1,1,0,0,3,3,2,1,1,2],\"goalKeeperSave\":[1,5,1,3,2,3,1,5,2,5,1,3,1,1],\"blockedShots\":[1,1,7,6,7,3,7,0,3,7,1,7,3,7,1],\"winH2H\":[0]}}",
              "gameId": 11368303,
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[47,57,56,54,49,42,55,44,72,56,47,56,42,44,56],\"shotOnTarget\":[3,3,2,5,4,3,4,5,4,5,3,2,3,5,5],\"bigChances\":[2,1,2,0,1,3,2,4,0,1,2,2,3,4,1],\"goalR\":[2,1,1,1,2,2,3,2,2,3,2,1,2,2,3,0],\"goalKeeperSave\":[4,4,2,3,3,2,9,4,2,2,4,2,2,4,2],\"blockedShots\":[4,7,8,3,2,3,1,3,3,8,4,8,3,3,8],\"winH2H\":[1]},\"away\":{\"ballPossession\":[49,62,46,54,44,53,38,48,56,41,49,46,44,53,38],\"shotOnTarget\":[4,4,4,7,5,2,3,4,2,4,4,4,5,2,3],\"bigChances\":[3,5,1,4,1,1,2,2,2,4,3,1,1,1,2],\"goalR\":[1,1,2,0,2,0,2,0,1,2,1,2,2,0,2,2],\"goalKeeperSave\":[4,2,3,0,4,3,3,0,4,4,3,4,3],\"blockedShots\":[6,1,3,0,2,6,5,5,8,1,6,3,2,6,5],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11372509
          },
          {
              "gameId": 11372679,
              "datetime": "01/04/2024 11:30",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[60,55,47,53,65,45,45,54,64,49,60,55,65,45,54],\"shotOnTarget\":[7,3,3,6,5,0,3,5,2,5,7,3,5,3,5],\"bigChances\":[2,1,2,2,0,1,1,3,2,2,1,1],\"goalR\":[1,1,0,1,2,4,1,0,0,1,1,1,2,1,0,5,3],\"goalKeeperSave\":[2,1,2,2,1,1,1,2,4,2,2,1,1,1,2],\"blockedShots\":[7,3,2,2,1,0,8,0,3,8,7,3,1,8,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[64,60,72,73,61,60,78,75,70,66,60,72,61,75,66],\"shotOnTarget\":[6,3,3,11,4,9,7,4,7,10,3,3,4,4,10],\"bigChances\":[4,1,0,7,2,3,4,2,4,4,1,0,2,2,4],\"goalR\":[0,1,1,0,0,0,1,1,0,1,1,1,0,1,1,2,0],\"goalKeeperSave\":[2,3,3,4,2,1,0,1,4,4,3,3,2,1,4],\"blockedShots\":[5,1,3,5,8,2,10,7,3,8,1,3,8,7,8],\"winH2H\":[2]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[64,78,59,76,55,64,74,39,56,54,64,78,76,74,56,54],\"shotOnTarget\":[7,6,5,11,2,4,6,4,7,7,7,6,11,6,7,7],\"bigChances\":[6,3,3,6,0,3,5,2,1,6,6,3,6,5,1,6],\"goalR\":[0,2,2,0,1,0,1,3,1,1,0,2,0,1,1,1,3],\"goalKeeperSave\":[5,3,3,0,2,2,3,3,2,2,5,3,0,3,2,2],\"blockedShots\":[2,4,2,6,0,1,6,2,5,1,2,4,6,6,5,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[34,39,64,48,35,61,26,65,25,33,34,39,35,26,65,33],\"shotOnTarget\":[5,3,4,5,3,8,3,3,2,4,5,3,3,3,3,4],\"bigChances\":[1,1,1,5,1,3,1,0,1,2,1,1,1,1,0,2],\"goalR\":[3,1,0,1,2,0,1,1,1,1,3,1,2,1,1,1,2],\"goalKeeperSave\":[7,5,1,3,2,1,2,4,3,4,7,5,2,2,4,4],\"blockedShots\":[3,4,8,3,4,4,0,2,2,0,3,4,4,0,2,0],\"winH2H\":[1]}}",
              "gameId": 11372680,
              "datetime": "01/04/2024 6:30"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[46,61,64,55,56,62,64,61,57,52,64,55,64,57,52,62,71,44,40],\"shotOnTarget\":[4,4,6,4,2,1,11,7,6,4,6,4,11,6,4,2,7,3,6],\"bigChances\":[2,0,2,4,1,1,5,6,3,1,2,4,5,3,1,0],\"goalR\":[0,1,1,0,2,1,0,0,1,0,1,0,0,1,0,0,2,0,1,3,1,2],\"goalKeeperSave\":[2,3,2,0,2,1,1,1,1,1,2,0,1,1,1,2,1,4,4],\"blockedShots\":[2,4,5,3,5,11,5,1,6,3,5,3,5,6,3,3,4,1,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[45,73,61,65,46,57,51,57,38,37,45,65,46,51,37,38,29,56,60],\"shotOnTarget\":[9,12,7,6,4,7,3,6,9,1,9,6,4,3,1,2,3,4,5],\"bigChances\":[1,6,3,0,2,2,1,2,3,1,1,0,2,1,1,2],\"goalR\":[2,1,2,2,1,0,3,1,2,3,2,2,1,3,3,2,0,1,2,2,4,2],\"goalKeeperSave\":[2,2,2,2,7,1,2,2,2,3,2,2,7,2,3,1,5,2,4],\"blockedShots\":[5,4,7,3,3,2,7,3,4,3,5,3,3,7,3,5,2,5,1],\"winH2H\":[2]}}",
              "datetime": "01/04/2024 14:0",
              "gameId": 11372681
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[53,54,42,47,71,57,45,41,70,53,53,54,47,45,70,46,53,55,49,46],\"shotOnTarget\":[1,8,4,4,5,5,7,6,3,2,1,8,4,7,3,5,2,3,7,2],\"bigChances\":[0,2,2,4,1,4,3,3,2,2,0,2,4,3,2,1,2,3],\"goalR\":[2,2,1,0,1,1,3,1,1,1,2,2,0,3,1,1,4,1,0,0,0,3,1,1,0],\"goalKeeperSave\":[1,2,2,2,0,4,4,4,1,3,1,2,2,4,1,5,3,1,1,1],\"blockedShots\":[3,2,3,6,6,1,7,3,4,6,3,2,6,7,4,2,1,2,6,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[32,37,51,52,49,58,43,31,63,30,51,52,43,31,30,54,47,45,51,54],\"shotOnTarget\":[7,8,2,4,3,5,2,3,6,4,2,4,2,3,4,6,6,2,1,1],\"bigChances\":[4,2,1,3,1,0,3,1,0,1,1,3,3,1,1,3,5,0],\"goalR\":[1,1,0,3,1,2,2,0,1,4,0,3,2,0,4,3,1,0,0,0,1,2,1,3,1],\"goalKeeperSave\":[2,4,4,2,2,2,0,2,0,1,4,2,0,2,1,2,1,3,7,1],\"blockedShots\":[3,2,0,8,2,6,3,0,10,0,0,8,3,0,0,5,2,1,3,3],\"winH2H\":[3]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11372682
          },
          {
              "datetime": "01/04/2024 9:0",
              "gameId": 11372683,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[28,36,40,57,49,55,61,54,58,40,28,57,49,61,58,51,49],\"shotOnTarget\":[4,3,5,2,5,7,6,6,4,5,4,2,5,6,4,4,5],\"bigChances\":[1,1,4,0,2,3,2,1,1,3,1,0,2,2,1,1,1],\"goalR\":[1,1,0,0,2,1,1,1,2,2,1,0,2,1,0,0,0,1,2,2,2,3],\"goalKeeperSave\":[2,5,6,2,2,5,3,4,4,3,2,2,2,3,2,1,3],\"blockedShots\":[2,3,3,3,9,7,5,7,2,1,2,3,9,5,5,4,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[46,65,44,36,47,56,43,42,49,36,65,36,43,42,42,49,51],\"shotOnTarget\":[6,8,1,3,3,4,1,3,4,2,8,3,1,3,2,1,3],\"bigChances\":[3,1,1,2,1,1,1,1,1,0,1,2,1,1,0,1,0],\"goalR\":[3,1,1,2,0,1,3,1,2,2,1,2,3,1,0,1,1,1,1,1,0,1],\"goalKeeperSave\":[6,1,0,4,3,4,3,4,4,1,1,4,3,4,4,3,4],\"blockedShots\":[3,7,6,5,1,0,1,2,5,5,7,5,1,2,2,4,0],\"winH2H\":[4]}}"
          },
          {
              "datetime": "01/04/2024 9:0",
              "gameId": 11372685,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[38,62,44,44,41,33,51,36,36,47,62,41,51,36,47,66,50,56,48,45,51,57,45],\"shotOnTarget\":[7,3,6,5,5,7,5,1,1,6,3,5,5,1,6,4,2,2,2,7,2,4,4],\"bigChances\":[1,0,1,3,0,4,2,0,1,1,0,0,2,0,1,1,1,0,0,2,1],\"goalR\":[4,0,2,0,3,3,3,0,3,2,0,3,3,0,2,2,0,1,1,0,3,2,2,2],\"goalKeeperSave\":[3,3,7,4,2,5,1,0,7,1,3,2,1,0,1,3,2,1,2,4,4,3,4],\"blockedShots\":[7,3,2,3,2,2,4,0,2,2,3,2,4,0,2,3,2,3,2,0,0,4,1],\"winH2H\":[1]},\"away\":{\"ballPossession\":[40,35,42,54,36,53,52,38,44,69,40,42,36,53,38,34,50,44,52,55,49,43,55],\"shotOnTarget\":[4,2,0,1,1,3,10,4,4,2,4,0,1,3,4,4,2,2,3,4,6,5,6],\"bigChances\":[1,1,0,0,1,1,2,0,0,1,1,0,1,1,0,0,1,1,1,0,1],\"goalR\":[1,2,4,2,0,1,5,2,1,1,1,4,0,1,2,1,0,0,0,3,1,2,2,1],\"goalKeeperSave\":[4,6,4,3,2,2,1,2,1,2,4,4,2,2,2,3,2,2,2,4,1,2,2],\"blockedShots\":[1,1,3,5,2,5,7,1,4,1,1,3,2,5,1,2,3,0,0,2,3,1,2],\"winH2H\":[5]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[57,50,39,43,62,45,51,36,58,37,57,43,45,36,51,41,37,36,49,63,55],\"shotOnTarget\":[1,5,2,6,8,3,3,1,3,6,1,6,3,1,7,1,14,1,1,7,2],\"bigChances\":[2,3,1,4,3,3,1,1,1,5,2,4,3,1,2,0,1,1],\"goalR\":[3,2,1,1,4,2,3,6,4,0,3,1,2,6,2,2,0,2,3,2,6,3,0,2],\"goalKeeperSave\":[5,4,5,4,9,3,6,5,4,3,5,4,3,5,2,5,6,4,5,3,4],\"blockedShots\":[3,5,0,1,3,7,2,4,1,4,3,1,7,4,3,0,6,4,5,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[59,51,46,61,53,74,62,36,39,63,51,46,53,39,49,59,63,64,51,37,45],\"shotOnTarget\":[3,4,1,8,9,4,7,0,2,8,4,1,9,2,4,7,8,8,7,8,7],\"bigChances\":[3,1,0,9,5,4,3,0,3,4,1,0,5,3,0,4,2,4],\"goalR\":[0,1,1,0,1,2,1,4,2,2,1,1,1,2,3,1,0,3,0,1,3,0,2,1],\"goalKeeperSave\":[3,4,4,1,3,0,5,4,6,3,4,4,3,6,4,0,11,1,0,4,1],\"blockedShots\":[4,3,3,3,2,9,3,0,1,3,3,3,2,1,4,0,7,7,8,4],\"winH2H\":[6]}}",
              "datetime": "03/04/2024 11:45",
              "gameId": 11388437
          },
          {
              "datetime": "03/04/2024 14:0",
              "gameId": 11388439,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[51,38,59,34,49,41,49,43,60,56,59,49,49,56,42,39,48,50,48,59,53,55,45,48],\"shotOnTarget\":[3,2,2,1,7,3,9,3,7,5,2,7,9,5,2,6,3,4,3,6,7,2,4,7],\"bigChances\":[2,1,1,1,3,0,3,3,4,5,1,3,3,5,0,2,2,0,4],\"goalR\":[3,6,3,1,1,3,0,4,1,0,3,1,0,0,2,1,3,0,0,0,1,3,3,2],\"goalKeeperSave\":[3,3,0,2,2,0,3,4,4,4,0,2,3,4,6,5,3,0,1,2,3,2,6,2],\"blockedShots\":[6,2,2,0,5,2,1,5,5,6,2,5,1,6,3,3,3,3,4,5,0,1,0],\"winH2H\":[3]},\"away\":{\"ballPossession\":[47,53,66,66,58,55,40,54,68,36,53,58,55,54,58,61,52,50,52,41,47,45,55,52],\"shotOnTarget\":[9,2,5,3,6,5,5,1,7,1,2,6,5,1,9,6,5,1,1,2,4,5,9,5],\"bigChances\":[5,1,1,0,4,2,4,1,4,0,1,4,2,1,3,2,3,2,1],\"goalR\":[1,1,0,0,0,2,1,1,3,3,1,0,2,1,1,2,2,0,0,1,3,1,1,0],\"goalKeeperSave\":[2,2,3,1,1,1,4,5,5,4,2,1,1,5,1,4,2,4,3,6,4,1,2,7],\"blockedShots\":[11,3,5,3,2,7,2,5,18,7,3,2,7,5,7,8,3,2,6,3,1,3,0],\"winH2H\":[5]}}"
          },
          {
              "datetime": "02/04/2024 14:0",
              "gameId": 11388441,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,42,27,52,43,42,44,48,47,42,42,52,42,48,47,48,60],\"shotOnTarget\":[1,1,1,5,2,8,4,11,3,4,1,5,8,11,3,4,3],\"bigChances\":[0,0,1,3,1,4,1,4,2,0,0,3,4,4,2,1,1],\"goalR\":[4,3,3,0,2,1,3,1,0,4,3,0,1,1,0,1,3],\"goalKeeperSave\":[4,3,3,2,4,2,2,2,2,3,3,2,2,2,2,5,1],\"blockedShots\":[2,0,0,1,3,5,0,1,1,2,0,1,5,1,1,8,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[51,56,39,32,58,44,26,46,58,31,51,32,26,46,31,52,40],\"shotOnTarget\":[3,4,7,8,5,3,2,9,7,2,3,8,2,9,2,7,4],\"bigChances\":[2,2,3,2,2,0,2,2,4,1,2,2,2,2,1,4,3],\"goalR\":[1,1,3,3,1,2,1,1,1,2,1,3,1,1,2,1,1],\"goalKeeperSave\":[6,1,4,4,3,7,3,2,3,3,6,4,3,2,3,2,2],\"blockedShots\":[4,4,4,1,6,2,1,4,7,0,4,1,1,4,0,3,1],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,52,33,41,48,46,59,39,47,39,52,41,46,59,47,44,62,51,49,50,59,52,52,55],\"shotOnTarget\":[3,2,3,8,3,6,6,2,4,3,2,8,6,6,4,11,8,9,2,7,4,6,8,4],\"bigChances\":[3,2,3,4,1,2,1,3,5,2,2,4,2,1,5,6,4,5,2,4],\"goalR\":[3,2,1,3,0,1,2,1,1,0,2,3,1,2,1,1,3,1,0,1,1,2,0,2,2],\"goalKeeperSave\":[2,3,4,3,6,0,4,5,8,7,3,3,0,4,8,2,1,3,3,2,3,1,2,5],\"blockedShots\":[7,2,1,5,1,3,3,3,4,5,2,5,3,3,4,2,4,4,1,3,6,2,2],\"winH2H\":[7]},\"away\":{\"ballPossession\":[54,62,47,57,42,46,36,57,34,53,54,57,46,36,34,56,38,49,51,50,41,48,48,45],\"shotOnTarget\":[6,11,3,5,1,6,2,6,2,6,6,5,6,2,2,3,4,4,3,3,4,4,2,7],\"bigChances\":[2,1,1,4,1,4,0,2,0,1,2,4,4,0,0,0,2,0,1,2],\"goalR\":[1,1,3,2,2,2,1,0,4,2,1,2,2,1,4,5,3,3,2,2,3,5,1,0,1],\"goalKeeperSave\":[2,1,2,4,3,4,10,2,2,2,2,4,4,10,2,6,5,6,0,5,2,1,7,4],\"blockedShots\":[3,9,2,2,2,2,2,4,0,4,3,2,2,2,0,2,4,2,7,2,1,2,7],\"winH2H\":[2]}}",
              "datetime": "03/04/2024 13:0",
              "gameId": 11388442
          },
          {
              "gameId": 11388443,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[34,36,42,43,64,60,40,36,52,49,42,64,40,49,51,72,70,73,60],\"shotOnTarget\":[3,5,7,1,12,3,5,4,4,2,7,12,5,2,5,8,9,8,8],\"bigChances\":[1,2,2,0,4,0,1,1,2,2,2,4,1,2,1,5,4,3],\"goalR\":[1,1,0,4,1,5,3,5,3,0,0,1,3,0,4,1,0,0,0,0,0,1],\"goalKeeperSave\":[4,4,5,4,1,9,4,9,8,3,5,1,4,3,2,2,1,1,2],\"blockedShots\":[0,3,5,1,7,4,2,1,2,3,5,7,2,3,4,0,7,6,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[59,47,49,45,32,56,52,62,39,61,47,45,56,39,49,28,30,27,40],\"shotOnTarget\":[6,3,5,5,3,6,4,7,7,6,3,5,6,7,6,3,1,1,2],\"bigChances\":[3,1,2,1,0,6,4,2,3,1,1,2,2,2,1,0],\"goalR\":[1,2,0,2,4,0,0,2,1,2,2,2,0,1,0,0,2,0,7,5,2,1],\"goalKeeperSave\":[5,5,0,5,7,6,3,2,0,1,5,5,6,0,4,8,6,1,3],\"blockedShots\":[2,2,7,2,3,7,4,9,3,4,2,2,7,3,2,0,0,1,2],\"winH2H\":[2]}}",
              "datetime": "02/04/2024 11:45"
          },
          {
              "gameId": 11388454,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[41,55,58,54,49,49,41,38,48,19,55,54,49,38,44,43,44,35,45,46,37],\"shotOnTarget\":[4,7,5,5,2,5,6,3,6,4,7,5,5,3,4,1,4,1,1,4,4],\"bigChances\":[3,2,2,4,2,4,3,2,1,3,2,4,4,2,2,0,1,1],\"goalR\":[0,1,0,4,3,1,2,4,2,4,1,4,1,4,6,2,2,3,3,3,3,3],\"goalKeeperSave\":[2,4,7,2,8,3,4,5,6,4,4,2,3,5,7,4,7,8,3,6,3],\"blockedShots\":[1,4,4,4,4,4,3,2,3,0,4,4,4,2,2,3,3,1,2,5,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[64,66,64,40,61,64,69,57,70,51,64,64,40,64,56,57,56,65,55,54,63],\"shotOnTarget\":[11,6,7,7,8,8,15,5,8,5,11,7,7,8,13,6,8,11,6,9,6],\"bigChances\":[9,2,5,3,7,5,9,1,6,2,9,5,3,5,9,3,1,4],\"goalR\":[0,0,0,1,0,0,1,1,0,1,0,0,1,0,1,0,1,1,1,2,1,2],\"goalKeeperSave\":[1,2,1,2,1,0,2,3,2,3,1,1,2,0,3,1,4,0,1,3,2],\"blockedShots\":[8,3,2,3,6,8,7,4,5,6,8,2,3,8,4,7,3,2,3,1,2],\"winH2H\":[8]}}",
              "datetime": "02/04/2024 13:0"
          },
          {
              "datetime": "01/04/2024 13:0",
              "gameId": 11388882,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[59,54,55,50,50,65,64,41,52,62,54,50,64,52,50,51,45],\"shotOnTarget\":[2,3,10,2,2,4,6,2,7,6,3,2,6,7,8,7,6],\"bigChances\":[0,1,5,1,1,0,1,1,1,2,1,1,1,1,5,1],\"goalR\":[3,2,1,2,1,0,5,2,2,2,2,2,5,2,1,3,1,1,2,1],\"goalKeeperSave\":[4,8,4,7,4,5,4,9,4,7,8,7,4,4,3,5,3],\"blockedShots\":[3,2,6,4,3,5,2,3,5,2,2,4,2,5,1,2,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[55,56,63,51,58,39,52,66,48,59,56,58,52,48,50,49,55],\"shotOnTarget\":[11,7,3,11,4,2,5,5,3,11,7,4,5,3,4,8,4],\"bigChances\":[1,3,2,4,0,2,1,1,3,0,2,1,0,1],\"goalR\":[1,1,1,1,1,0,1,0,2,1,1,1,1,2,5,3,1,1,2,1],\"goalKeeperSave\":[3,2,1,3,3,6,5,6,7,1,2,3,5,7,3,4,5],\"blockedShots\":[2,4,4,9,4,3,5,6,6,7,4,4,5,6,4,3,3],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[53,49,64,46,51,51,41,37,63,48,53,64,51,37,48,37,53,61,57,60,48],\"shotOnTarget\":[4,2,3,2,3,5,3,3,2,5,4,3,3,3,5,4,3,5,5,3,3],\"bigChances\":[2,0,2,1,2,1,2,2,0,2,2,2,2,2,2,3,1,2,4],\"goalR\":[1,0,0,0,0,2,0,0,2,1,1,0,0,0,1,0,0,0,3,1,1,0,0,1],\"goalKeeperSave\":[1,0,2,1,1,0,5,1,4,1,1,2,1,1,1,0,1,2,2,1,2],\"blockedShots\":[1,1,5,1,4,6,1,1,1,1,1,5,4,1,1,1,3,4,3,0],\"winH2H\":[4]},\"away\":{\"ballPossession\":[62,65,55,62,64,62,45,50,69,49,55,62,62,50,49,63,47,39,43,40,52],\"shotOnTarget\":[5,4,4,6,6,4,7,7,3,7,4,6,4,7,7,0,1,5,3,2,3],\"bigChances\":[4,4,2,4,5,1,3,2,2,4,2,4,1,2,4,0,2,2,1],\"goalR\":[0,1,0,1,1,2,2,0,2,1,0,1,2,0,1,0,0,0,2,2,3,2,1,1],\"goalKeeperSave\":[1,0,3,0,1,0,6,1,4,3,3,0,0,1,3,4,3,4,3,2,1],\"blockedShots\":[2,3,0,5,3,5,3,7,7,2,0,5,5,7,2,3,4,1,2,3],\"winH2H\":[1]}}",
              "gameId": 11391626,
              "datetime": "01/04/2024 13:45"
          },
          {
              "gameId": 11400757,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[37,43,41,46,54,52,47,50,54,45,37,41,54,47,45],\"shotOnTarget\":[1,2,4,2,2,3,0,3,4,1,1,4,2,0,1],\"bigChances\":[],\"goalR\":[4,0,2,1,2,0,2,4,1,2,4,2,2,2,2,2,1,3,0,2],\"goalKeeperSave\":[2,3,1,6,1,2,1,2,4,2,2,1,1,1,2],\"blockedShots\":[2,2,1,0,1,1,2,2,4,1,2,1,1,2,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[57,43,44,46,56,62,46,40,42,50,43,46,56,46,42],\"shotOnTarget\":[2,4,5,5,2,5,1,7,0,7,4,5,2,1,0],\"bigChances\":[],\"goalR\":[1,5,3,1,2,3,1,3,7,2,5,1,2,1,7,0,0,1,4,4],\"goalKeeperSave\":[1,5,5,3,6,6,8,4,4,3,5,3,6,8,4],\"blockedShots\":[4,1,0,2,3,6,2,0,3,0,1,2,3,2,3],\"winH2H\":[3]}}",
              "datetime": "01/04/2024 6:0"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[48,47,52,52,44,53,48,39,55,50,47,52,44,48,55],\"shotOnTarget\":[5,5,6,3,3,3,4,8,5,5,5,6,3,4,5],\"bigChances\":[],\"goalR\":[1,2,0,0,0,0,0,1,1,1,2,0,0,0,1,0,0,1,1,3,0,0,7],\"goalKeeperSave\":[3,4,1,1,2,0,3,6,2,6,4,1,2,3,2],\"blockedShots\":[3,3,1,3,0,6,4,0,6,2,3,1,0,4,6],\"winH2H\":[3]},\"away\":{\"ballPossession\":[52,48,33,38,48,38,41,44,42,48,33,38,44],\"shotOnTarget\":[4,2,4,4,2,9,6,5,4,2,4,9,5],\"bigChances\":[],\"goalR\":[1,3,2,5,3,0,2,2,2,0,1,2,5,2,2,0,3,5,2,2,0,0,0],\"goalKeeperSave\":[2,5,7,1,3,4,1,3,1,5,7,4,3],\"blockedShots\":[4,0,2,3,2,3,7,4,3,0,2,3,4],\"winH2H\":[2]}}",
              "gameId": 11400758,
              "datetime": "01/04/2024 7:0"
          },
          {
              "gameId": 11400759,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[48,37,43,54,56,56,48,54,45,45,48,54,56,54,45],\"shotOnTarget\":[2,4,2,7,6,8,7,7,7,2,2,7,8,7,7],\"bigChances\":[],\"goalR\":[0,3,1,1,0,0,0,1,3,0,0,1,0,1,3,6],\"goalKeeperSave\":[4,6,3,1,2,4,1,1,4,5,4,1,4,1,4],\"blockedShots\":[2,2,3,1,3,1,4,1,4,1,2,1,1,1,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[60,63,59,75,62,70,62,59,60,61,60,59,62,62,60],\"shotOnTarget\":[3,9,3,3,4,4,2,4,7,7,3,3,4,2,7],\"bigChances\":[],\"goalR\":[1,1,1,1,1,1,0,0,3,1,1,1,1,0,3,4],\"goalKeeperSave\":[2,3,3,0,3,0,2,5,5,3,2,3,3,2,5],\"blockedShots\":[0,2,2,4,4,4,2,2,4,3,0,2,4,2,4],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 7:0"
          },
          {
              "datetime": "01/04/2024 7:0",
              "gameId": 11400760,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[41,44,25,54,56,52,39,46,38,44,54,52,46],\"shotOnTarget\":[3,1,1,4,2,1,2,5,3,1,4,1,5],\"bigChances\":[],\"goalR\":[2,1,1,1,2,1,1,3,0,2,2,1,2,1,0,3],\"goalKeeperSave\":[5,4,2,4,2,6,6,4,2,4,4,6,4],\"blockedShots\":[2,4,3,4,2,4,3,1,3,4,4,4,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[63,53,67,44,68,70,61,55,58,39,53,44,70,55,39],\"shotOnTarget\":[8,6,12,5,6,6,9,7,11,4,6,5,6,7,4],\"bigChances\":[],\"goalR\":[1,1,1,0,4,1,0,3,0,2,1,0,1,3,2,1],\"goalKeeperSave\":[1,4,4,6,3,1,2,4,0,2,4,6,1,4,2],\"blockedShots\":[4,3,6,2,3,4,3,6,0,3,3,2,4,6,3],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11406799,
              "datetime": "01/04/2024 5:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,37,55,59,50,61,49,58,65,56,57,55,50,49,56,47,73],\"shotOnTarget\":[9,3,6,5,2,4,2,4,5,2,9,6,2,2,2,4,7],\"bigChances\":[6,1,3,2,1,4,1,3,3,1,6,3,1,1,1,3,2],\"goalR\":[0,2,1,1,0,2,0,1,1,0,0,1,0,0,0,1,2,1],\"goalKeeperSave\":[4,3,4,5,1,2,3,3,1,3,4,4,1,3,3,3,2],\"blockedShots\":[2,2,1,3,2,3,5,2,2,4,2,1,2,5,4,2,8],\"winH2H\":[1]},\"away\":{\"ballPossession\":[60,36,42,48,48,41,37,38,42,35,60,42,48,37,42,53,27],\"shotOnTarget\":[2,3,5,2,2,3,2,6,1,2,2,5,2,2,1,4,4],\"bigChances\":[0,0,1,1,1,0,2,2,1,1,0,1,1,2,1,2,2],\"goalR\":[1,4,3,2,1,2,2,1,3,2,1,3,1,2,3,1,3,1],\"goalKeeperSave\":[5,2,4,4,3,6,7,1,7,3,5,4,3,7,7,3,4],\"blockedShots\":[7,5,1,2,2,5,2,2,6,4,7,1,2,2,6,8,2],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11406801,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[40,58,52,47,40,51,39,39,60,33,58,47,40,39,60,58,48,43,64,69,57],\"shotOnTarget\":[4,2,6,6,4,2,6,2,6,3,2,6,4,6,6,5,5,4,9,5,5],\"bigChances\":[1,2,2,9,2,0,3,1,1,3,2,9,2,3,1,1,4,4],\"goalR\":[3,4,2,3,1,2,1,1,1,2,4,3,1,1,1,2,2,1,1,2,0,1],\"goalKeeperSave\":[0,1,0,2,1,3,3,4,3,3,1,2,1,3,3,2,3,3,1,1,1],\"blockedShots\":[0,4,3,9,2,3,4,2,2,1,4,9,2,4,2,5,5,2,5],\"winH2H\":[2]},\"away\":{\"ballPossession\":[46,51,45,47,40,45,46,51,49,61,46,45,47,45,51,42,52,57,36,31,43],\"shotOnTarget\":[2,2,2,4,9,1,5,1,11,4,2,2,4,1,1,4,5,5,2,3,1],\"bigChances\":[2,0,0,2,0,0,2,0,3,2,2,0,2,0,0,2,2,5],\"goalR\":[1,1,0,2,3,1,3,1,2,3,1,0,2,1,1,1,0,1,2,1,1,0],\"goalKeeperSave\":[3,1,2,0,7,4,3,4,3,3,3,2,0,4,4,4,5,3,7,4,3],\"blockedShots\":[3,3,2,3,0,0,5,3,1,4,3,2,3,0,3,4,2,0,4],\"winH2H\":[4]}}",
              "datetime": "01/04/2024 8:0"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[47,65,43,74,52,68,52,62,43,42,47,74,68,62,49,64,52,52,43,58],\"shotOnTarget\":[6,4,4,8,4,8,6,6,7,4,6,8,8,6,1,10,14,8,5,10],\"bigChances\":[5,1,3,4,4,5,1,4,6,0,5,4,5,4,1,5,4],\"goalR\":[0,1,0,0,0,0,1,1,1,0,0,0,0,1,1,2,1,0,1,3,0,1,1,1],\"goalKeeperSave\":[3,0,4,1,4,5,3,2,1,4,3,1,5,2,7,0,3,3,1,2],\"blockedShots\":[6,0,2,3,3,3,4,1,3,2,6,3,3,1,7,8,4,6,4,0],\"winH2H\":[8]},\"away\":{\"ballPossession\":[47,39,40,43,59,28,44,36,34,49,47,43,28,36,51,36,48,48,57,42],\"shotOnTarget\":[2,1,6,4,3,3,2,2,3,8,2,4,3,2,8,2,4,3,2,5],\"bigChances\":[0,0,4,2,2,4,1,3,0,3,0,2,4,3,1,2,4],\"goalR\":[7,1,0,3,0,0,3,2,0,4,7,3,0,2,0,4,2,2,2,4,1,3,4,0],\"goalKeeperSave\":[3,4,2,6,1,7,3,3,7,5,3,6,7,3,1,7,12,6,3,6],\"blockedShots\":[2,3,2,4,7,2,2,4,3,1,2,4,2,4,2,2,3,1,5,0],\"winH2H\":[2]}}",
              "datetime": "01/04/2024 13:45",
              "gameId": 11406805
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[56,49,44,43,56,49,39,45,51,42,56,44,43,49,45,42,40],\"shotOnTarget\":[3,0,4,4,1,6,4,5,5,4,3,4,4,6,5,3,6],\"bigChances\":[1,0,1,3,1,0,1,4,2,2,1,1,3,0,4,0,0],\"goalR\":[0,1,4,1,1,1,2,2,2,1,0,4,1,1,2,1,1,2,2,3,2,1,3,1,4],\"goalKeeperSave\":[1,4,2,0,4,1,3,5,9,3,1,2,0,1,5,4,4],\"blockedShots\":[5,0,1,1,3,3,7,1,4,6,5,1,1,3,1,2,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[66,34,44,41,57,55,52,62,61,53,66,44,57,52,61,58,60],\"shotOnTarget\":[7,1,3,4,2,6,5,2,2,6,7,3,2,5,2,5,5],\"bigChances\":[3,1,1,3,0,5,1,3,2,4,3,1,0,1,2,3,5],\"goalR\":[1,1,2,0,1,1,3,1,1,0,1,2,1,3,1,1,0,4,1,0,2,1,0,1,0],\"goalKeeperSave\":[1,7,1,2,2,1,2,2,6,3,1,1,2,2,6,3,6],\"blockedShots\":[5,1,2,3,4,4,4,5,2,7,5,2,4,4,2,1,4],\"winH2H\":[5]}}",
              "datetime": "01/04/2024 11:0",
              "gameId": 11406808
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[43,57,51,41,46,63,51,34,40,67,43,51,41,63,34,32,59,62,69,65,66,56,61,59,42],\"shotOnTarget\":[7,1,2,5,2,9,9,2,4,7,7,2,5,9,2,2,6,1,10,3,4,5,1,4,7],\"bigChances\":[2,2,0,0,1,3,5,1,2,4,2,0,0,3,1,0,2,1,2],\"goalR\":[1,1,2,1,2,2,3,2,2,2,1,2,1,2,2,3,1,0,1,0,1,0,1,1,2],\"goalKeeperSave\":[3,3,5,3,7,0,5,5,3,5,3,5,3,0,5,1,5,0,1,1,1,3,2,2,2],\"blockedShots\":[6,5,3,3,0,8,5,2,2,8,6,3,3,8,2,2,4,1,8,8,5,2,8],\"winH2H\":[1]},\"away\":{\"ballPossession\":[34,59,41,44,40,28,34,36,39,26,34,41,40,28,36,68,41,38,31,35,34,44,39,41,58],\"shotOnTarget\":[3,2,1,6,3,2,4,2,6,1,3,1,3,2,2,4,6,0,2,1,2,3,3,3,4],\"bigChances\":[1,3,2,4,2,3,1,1,4,1,1,2,2,3,1,3,5,3,0],\"goalR\":[4,2,0,1,1,0,1,3,3,4,4,0,1,0,3,1,1,0,0,0,0,1,1,1,1],\"goalKeeperSave\":[6,2,3,0,3,4,1,2,1,4,6,3,3,4,2,1,4,1,10,3,4,4,0,2,6],\"blockedShots\":[3,4,3,3,4,4,5,0,1,6,3,3,4,4,0,4,2,2,3,0,0,4,1],\"winH2H\":[4]}}",
              "datetime": "01/04/2024 8:0",
              "gameId": 11406816
          },
          {
              "gameId": 11408420,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[61,54,57,59],\"shotOnTarget\":[4,4,8,6],\"bigChances\":[],\"goalR\":[3,1,1,2,1,3,1,0,1,1,1,2,3,0,1,1,2,1,0,0,5,1,0,0,1],\"goalKeeperSave\":[4,2,2,3],\"blockedShots\":[1],\"winH2H\":[5]},\"away\":{\"ballPossession\":[39,46,43,41],\"shotOnTarget\":[5,2,2,8],\"bigChances\":[],\"goalR\":[2,2,2,1,1,0,3,1,1,0,2,2,1,3,1,1,1,0,0,4,1,2,2,4,5],\"goalKeeperSave\":[3,4,4,5],\"blockedShots\":[3],\"winH2H\":[3]}}",
              "datetime": "01/04/2024 12:0"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[54],\"shotOnTarget\":[6],\"bigChances\":[],\"goalR\":[2,1,0,1,1,0,0,3,1,1,0,1,0,1,0],\"goalKeeperSave\":[1],\"blockedShots\":[1],\"winH2H\":[1]},\"away\":{\"ballPossession\":[46],\"shotOnTarget\":[1],\"bigChances\":[],\"goalR\":[1,0,2,2,2,1,0,1,0,1,0,2,1,1,1],\"goalKeeperSave\":[5],\"blockedShots\":[0],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 12:0",
              "gameId": 11408431
          },
          {
              "datetime": "01/04/2024 14:15",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,45,34,38,30,38,38,46,61,50,54,34,38,46,50,48,55,36,50,43,60],\"shotOnTarget\":[5,4,4,4,2,6,3,6,2,4,5,4,6,6,4,5,7,2,5,5,4],\"bigChances\":[1,2,2,2,1,1,2,2,3,1,1,2,1,2,1,3,1,1,4],\"goalR\":[1,1,3,2,1,0,6,1,1,1,1,3,0,1,1,2,2,0,1,1,2,3],\"goalKeeperSave\":[0,2,4,2,7,5,4,7,3,7,0,4,5,7,7,8,4,2,2,5,2],\"blockedShots\":[1,0,1,3,2,4,0,0,1,2,1,1,4,0,2,4,4,5,1,1,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[61,61,62,63,55,54,55,60,70,55,61,62,55,55,60,52,45,64,50,57,40],\"shotOnTarget\":[6,8,4,6,4,4,5,7,9,6,6,4,4,5,7,10,6,2,3,6,4],\"bigChances\":[1,5,4,1,3,3,1,3,6,2,1,4,3,1,3,6,5,1,2],\"goalR\":[0,1,1,1,1,1,2,1,1,0,0,1,1,2,1,1,1,0,0,1,1,0],\"goalKeeperSave\":[7,0,4,5,1,3,2,5,1,1,7,4,1,2,5,4,7,2,5,4,4],\"blockedShots\":[4,2,6,5,2,1,3,2,8,3,4,6,2,3,2,8,3,3,1,2,1],\"winH2H\":[5]}}",
              "gameId": 11408434
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[70,61,55,51,72,47,53,48,30,44,70,55,51,72],\"shotOnTarget\":[3,12,9,3,5,8,1,2,4,4,3,9,3,5],\"bigChances\":[],\"goalR\":[0,2,0,2,2,2,2,0,1,1,0,0,2,2],\"goalKeeperSave\":[2,2,3,4,2,1,4,3,1,4,2,3,4,2],\"blockedShots\":[7,9,2,2,7,10,3,5,2,2,7,2,2,7],\"winH2H\":[0]},\"away\":{\"ballPossession\":[48,31,41,44,36,38,39,45,51,48,31,36,39,48],\"shotOnTarget\":[5,2,3,7,2,2,2,6,3,7,2,2,2,7],\"bigChances\":[],\"goalR\":[1,0,1,4,0,0,3,0,1,1,0,0,3,1],\"goalKeeperSave\":[4,5,1,6,3,3,5,1,1,2,5,3,5,2],\"blockedShots\":[4,2,2,1,2,2,4,4,5,10,2,2,4,10],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 10:0",
              "gameId": 11408573
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[46,48,42,46,54,67,56,40,43,46,46,48,46,54],\"shotOnTarget\":[6,7,4,0,6,3,2,5,4,4,6,7,0,6],\"bigChances\":[],\"goalR\":[0,3,1,1,4,1,3,2,1,1,0,3,1,4,0,0,0,4],\"goalKeeperSave\":[2,5,5,5,3,4,6,4,3,7,2,5,5,3],\"blockedShots\":[2,4,2,2,3,2,4,3,3,2,2,4,2,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[50,51,42,47,53,56,58,59,33,50,42,47,53],\"shotOnTarget\":[5,0,5,6,5,5,2,6,5,5,5,6,5],\"bigChances\":[],\"goalR\":[2,1,1,1,1,2,1,1,3,2,2,1,1,2,3,0,0,1],\"goalKeeperSave\":[5,3,3,2,1,5,4,1,7,5,3,2,1],\"blockedShots\":[6,4,2,6,5,5,5,6,2,6,2,6,5],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11410107
          },
          {
              "datetime": "01/04/2024 9:0",
              "gameId": 11410109,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[72,47,59,47,50,66,51,52,66,58,72,59,50,52],\"shotOnTarget\":[5,3,5,4,4,7,2,3,3,6,5,5,4,3],\"bigChances\":[],\"goalR\":[2,1,2,1,0,0,3,1,1,0,2,2,0,1,1,0,0],\"goalKeeperSave\":[3,4,1,2,2,0,1,4,3,2,3,1,2,4],\"blockedShots\":[5,5,7,1,5,2,6,5,3,10,5,7,5,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[38,35,47,45,58,51,45,39,32,45,35,45,51,32],\"shotOnTarget\":[6,1,4,2,4,4,5,3,2,1,1,2,4,2],\"bigChances\":[],\"goalR\":[1,6,1,1,1,2,4,3,0,0,6,1,2,0,0,1,0],\"goalKeeperSave\":[3,3,1,8,4,1,5,2,4,6,3,8,1,4],\"blockedShots\":[3,3,1,0,6,5,6,1,1,3,3,0,5,1],\"winH2H\":[1]}}"
          },
          {
              "datetime": "01/04/2024 9:0",
              "gameId": 11410111,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[72,59,57,54,53,54,69,52,53,37,72,57,53,69],\"shotOnTarget\":[4,2,2,1,2,2,2,5,3,6,4,2,2,2],\"bigChances\":[],\"goalR\":[0,2,0,2,0,3,2,3,2,2,0,0,0,2],\"goalKeeperSave\":[4,5,7,7,1,5,3,8,4,3,4,7,1,3],\"blockedShots\":[5,5,3,1,2,1,2,5,3,0,5,3,2,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[68,50,44,67,57,51,54,40,57,60,44,57,54,40],\"shotOnTarget\":[4,0,8,3,5,5,3,3,4,3,8,5,3,3],\"bigChances\":[],\"goalR\":[4,1,4,3,2,1,2,0,1,1,4,2,2,0],\"goalKeeperSave\":[1,2,9,0,2,0,7,3,3,5,9,2,7,3],\"blockedShots\":[3,2,1,2,2,12,2,2,4,3,1,2,2,2],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11410113,
              "datetime": "02/04/2024 13:45",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[27,41,50,53,50,49,54,48,58,41,41,53,48,58],\"shotOnTarget\":[3,7,3,5,2,3,2,4,4,8,7,5,4,4],\"bigChances\":[],\"goalR\":[0,2,1,0,3,2,2,2,3,1,2,0,2,3,0],\"goalKeeperSave\":[2,0,3,5,1,8,4,3,2,1,0,5,3,2],\"blockedShots\":[1,1,3,4,6,2,6,5,4,5,1,4,5,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[39,28,44,22,24,42,49,40,58,34,39,28,22,42],\"shotOnTarget\":[1,4,2,2,7,5,5,6,5,4,1,4,2,5],\"bigChances\":[],\"goalR\":[1,0,1,1,0,1,1,2,1,0,1,0,1,1,1],\"goalKeeperSave\":[5,4,6,4,5,3,1,1,3,3,5,4,4,3],\"blockedShots\":[0,1,1,2,1,3,5,6,1,2,0,1,2,3],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[40,50,56,53,34,55,36,25,23,53,50,53,34,25],\"shotOnTarget\":[7,5,3,7,0,9,3,4,1,4,5,7,0,4],\"bigChances\":[],\"goalR\":[3,1,4,4,1,4,4,3,1,2,1,4,1,3,0,0,1,1,0,2],\"goalKeeperSave\":[5,1,4,5,6,2,8,1,6,2,1,5,6,1],\"blockedShots\":[1,2,0,3,2,1,3,1,2,2,2,3,2,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[38,61,34,43,39,48,49,43,50,42,38,34,39,42],\"shotOnTarget\":[4,5,6,6,2,2,4,2,6,4,4,6,2,4],\"bigChances\":[],\"goalR\":[1,1,1,0,0,2,0,1,1,1,1,1,0,1,1,1,0,2,2,2],\"goalKeeperSave\":[0,1,5,4,2,4,3,6,5,4,0,5,2,4],\"blockedShots\":[4,6,5,3,1,2,4,3,4,0,4,5,1,0],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11410115
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[36,46,39,44,47,35,45,59,31,36,46,44,45,59],\"shotOnTarget\":[3,3,4,8,8,3,7,2,5,3,3,8,7,2],\"bigChances\":[],\"goalR\":[2,2,1,1,4,2,1,1,1,1,2,2,1,1,1,1,3,3],\"goalKeeperSave\":[2,2,3,3,3,1,3,4,1,2,2,3,3,4],\"blockedShots\":[2,5,3,0,4,6,7,5,2,2,5,0,7,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[62,45,62,33,56,58,54,29,60,57,45,62,56,54,57],\"shotOnTarget\":[1,8,6,0,7,7,2,5,2,6,8,6,7,2,6],\"bigChances\":[],\"goalR\":[1,1,2,1,1,3,5,0,1,0,1,2,1,5,0,1,1,3],\"goalKeeperSave\":[3,2,3,2,1,4,5,6,2,2,2,3,1,5,2],\"blockedShots\":[2,5,2,4,4,4,9,2,3,3,5,2,4,9,3],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0",
              "gameId": 11410117
          },
          {
              "gameId": 11410119,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[61,67,72,50,57,74,49,75,65,64,61,72,50,57,74],\"shotOnTarget\":[8,6,9,8,3,4,4,4,4,8,8,9,8,3,4],\"bigChances\":[],\"goalR\":[1,2,2,0,1,0,0,1,1,2,1,2,0,1,0],\"goalKeeperSave\":[1,0,3,0,3,1,2,3,3,3,1,3,0,3,1],\"blockedShots\":[2,3,7,1,3,2,6,2,3,5,2,7,1,3,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[38,57,53,53,55,47,55,43,41,53,57,53,47,43,41],\"shotOnTarget\":[5,4,5,2,9,1,7,4,5,4,4,2,1,4,5],\"bigChances\":[],\"goalR\":[0,1,2,1,2,2,2,0,0,2,1,1,2,0,0],\"goalKeeperSave\":[6,5,1,3,0,1,0,5,2,0,5,3,1,5,2],\"blockedShots\":[6,3,6,1,6,4,5,3,3,1,3,1,4,3,3],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11410121,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[48,42,46,51,49,44,58,52,37,42,48,46,49,52,37],\"shotOnTarget\":[5,7,10,3,3,2,4,6,9,4,5,10,3,6,9],\"bigChances\":[],\"goalR\":[2,2,1,0,2,1,1,2,3,2,2,1,2,2,3,2,0,0,2,0,0,1,1],\"goalKeeperSave\":[0,5,1,5,2,6,5,2,1,6,0,1,2,2,1],\"blockedShots\":[6,3,1,8,5,3,7,4,1,1,6,1,5,4,1],\"winH2H\":[5]},\"away\":{\"ballPossession\":[48,61,39,52,46,26,52,68,47,59,48,39,46,26,52],\"shotOnTarget\":[4,1,6,5,3,1,3,4,5,6,4,6,3,1,3],\"bigChances\":[],\"goalR\":[1,0,0,3,3,1,0,1,0,1,1,0,3,1,0,0,0,1,2,1,2,2,2],\"goalKeeperSave\":[3,2,5,2,6,3,1,1,2,1,3,5,6,3,1],\"blockedShots\":[4,1,1,2,5,1,2,4,4,2,4,1,5,1,2],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11410124,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,50,47,43,64,49,48,60,63,50,64,48],\"shotOnTarget\":[2,3,5,3,10,4,11,2,6,3,10,11],\"bigChances\":[],\"goalR\":[2,2,0,2,2,0,0,0,2,3,2,2,0,0,0],\"goalKeeperSave\":[3,1,5,1,3,0,6,7,0,1,3,6],\"blockedShots\":[0,0,1,3,11,5,3,2,8,0,11,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[33,33,43,38,52,48,42,56,53,44,33,38,42,53],\"shotOnTarget\":[3,3,3,3,4,1,1,4,5,1,3,3,1,5],\"bigChances\":[],\"goalR\":[2,1,0,0,0,1,0,1,1,1,1,0,0,1,1],\"goalKeeperSave\":[3,2,5,7,3,2,4,1,3,1,2,7,4,3],\"blockedShots\":[0,1,1,3,1,4,2,3,3,0,1,3,2,3],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "gameId": 11410127,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[60,39,61,48,45,56,46,52,52,39,60,61,56,46],\"shotOnTarget\":[8,2,5,5,2,7,8,8,5,1,8,5,7,8],\"bigChances\":[],\"goalR\":[5,4,0,2,4,1,1,3,1,5,5,0,1,1,0],\"goalKeeperSave\":[2,4,6,3,3,3,1,5,3,6,2,6,3,1],\"blockedShots\":[6,6,0,5,1,5,4,6,2,2,6,0,5,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[52,50,59,53,62,59,55,61,59,59,50,53,55,61],\"shotOnTarget\":[4,1,1,4,6,6,3,5,7,2,1,4,3,5],\"bigChances\":[],\"goalR\":[2,2,2,1,1,0,2,0,3,3,2,1,2,0,2],\"goalKeeperSave\":[4,3,1,6,2,3,5,3,2,5,3,6,5,3],\"blockedShots\":[1,3,4,2,4,2,4,3,7,3,3,2,4,3],\"winH2H\":[0]}}"
          },
          {
              "datetime": "01/04/2024 8:0",
              "gameId": 11425485,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,38,46,39,56,51,47,57,51,56,54,46,56,57,51,43,43,45,51,48],\"shotOnTarget\":[5,4,4,5,8,5,4,3,6,4,5,4,8,3,6,5,3,4,3,4],\"bigChances\":[0,0,3,1,4,1,1,0,3,2,0,3,4,0,3,1,2,2,1],\"goalR\":[2,0,2,1,0,2,0,1,0,2,2,2,0,1,0,0,1,0,2,3],\"goalKeeperSave\":[0,6,2,5,6,2,5,3,3,1,0,2,6,3,3,2,6,5,2,1],\"blockedShots\":[4,6,2,4,3,3,5,2,4,2,4,2,3,2,4,3,2,2,5,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[57,65,61,48,55,52,56,38,36,57,61,52,56,36,57,57,57,55,49,52],\"shotOnTarget\":[3,4,5,0,3,4,4,1,7,3,5,4,4,7,3,2,7,5,4,4],\"bigChances\":[2,4,1,1,2,2,0,0,3,1,1,2,0,3,1,0,1,2,2],\"goalR\":[1,1,0,1,2,3,1,3,1,1,0,3,1,1,1,1,1,0,1,2],\"goalKeeperSave\":[2,5,0,3,4,2,2,2,2,3,0,2,2,2,3,4,2,3,2,2],\"blockedShots\":[13,8,5,1,5,4,7,7,2,2,5,4,7,2,2,6,6,4,3,0],\"winH2H\":[2]}}"
          },
          {
              "gameId": 11425591,
              "datetime": "01/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[45,58,49,52,49,63,44,45,50,63,49,49,44,50],\"shotOnTarget\":[3,5,6,4,5,4,7,5,4,3,6,5,7,4],\"bigChances\":[0,0,2,2,2,1,2,2,0,2,2,2,2],\"goalR\":[1,1,2,1,0,3,0,0,1,3,2,0,0,1],\"goalKeeperSave\":[6,3,1,3,5,0,4,2,1,1,1,5,4,1],\"blockedShots\":[4,4,1,4,4,5,2,5,6,2,1,4,2,6],\"winH2H\":[0]},\"away\":{\"ballPossession\":[48,47,56,43,52,39,49,41,44,45,48,56,52,49],\"shotOnTarget\":[4,3,3,7,5,0,3,2,5,4,4,3,5,3],\"bigChances\":[2,2,2,1,1,1,2,1,3,2,2,2,1,2],\"goalR\":[2,1,2,0,2,1,2,1,2,1,2,2,2,2],\"goalKeeperSave\":[3,2,3,3,2,4,3,1,8,3,3,3,2,3],\"blockedShots\":[1,4,6,2,6,3,8,1,2,3,1,6,6,8],\"winH2H\":[0]}}"
          },
          {
              "datetime": "01/04/2024 8:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[42,42,50,48,40,51,53,62,53,70,42,48,51,62,70],\"shotOnTarget\":[5,3,4,4,5,3,7,7,6,7,5,4,3,7,7],\"bigChances\":[2,1,2,2,3,2,2,4,0,4,2,2,2,4,4],\"goalR\":[1,1,3,1,1,0,3,1,0,3,1,1,0,1,3],\"goalKeeperSave\":[3,2,2,5,3,2,4,0,0,0,3,5,2,0,0],\"blockedShots\":[2,3,6,4,1,3,1,4,3,5,2,4,3,4,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[64,55,66,51,57,65,62,61,72,44,55,51,57,62,72],\"shotOnTarget\":[2,5,8,3,6,5,4,5,2,3,5,3,6,4,2],\"bigChances\":[2,2,2,1,1,0,2,0,2,2,1,0,0],\"goalR\":[5,2,1,1,0,0,1,2,2,0,2,1,0,1,2],\"goalKeeperSave\":[2,3,2,5,3,2,1,2,3,4,3,5,3,1,3],\"blockedShots\":[6,3,3,0,4,4,3,2,2,1,3,0,4,3,2],\"winH2H\":[0]}}",
              "gameId": 11425592
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[57,59,50,53,45,59,28,54,60,54,57,50,45,28,60,57],\"shotOnTarget\":[1,4,5,5,3,2,5,6,6,4,1,5,3,5,6,6],\"bigChances\":[4,0,5,1,1,0,3,2,5,1,4,5,1,3,5,4],\"goalR\":[3,1,2,1,1,0,1,0,1,1,3,2,1,1,1,1],\"goalKeeperSave\":[1,3,2,2,2,3,1,4,3,1,1,2,2,1,3,3],\"blockedShots\":[5,2,1,5,5,1,4,3,7,1,5,1,5,4,7,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[53,55,57,56,47,50,50,49,69,52,53,57,50,49,52,43],\"shotOnTarget\":[4,2,3,3,3,8,6,3,3,3,4,3,8,3,3,4],\"bigChances\":[3,1,0,1,1,4,0,0,2,1,3,0,4,0,1,1],\"goalR\":[1,0,3,1,0,0,1,2,1,2,1,3,0,2,2,2],\"goalKeeperSave\":[2,5,5,1,5,4,3,4,2,5,2,5,4,4,5,4],\"blockedShots\":[2,4,4,4,4,0,3,4,4,1,2,4,0,4,1,3],\"winH2H\":[0]}}",
              "gameId": 11425593,
              "datetime": "01/04/2024 8:0"
          },
          {
              "datetime": "01/04/2024 8:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,51,53,45,53,53,49,56,50,47,57,53,53,56,47,71,54,52,50,56],\"shotOnTarget\":[5,5,6,5,5,5,4,9,2,0,5,6,5,9,0,6,7,2,7,2],\"bigChances\":[2,2,1,1,0,1,1,2,1,2,1,1,1,5],\"goalR\":[0,1,0,2,0,3,0,2,2,1,0,0,0,2,1,1,2,1,1,0,3],\"goalKeeperSave\":[3,2,3,5,4,9,5,3,2,6,3,3,4,3,6,2,1,3,4,1],\"blockedShots\":[5,3,8,3,5,6,5,8,2,2,5,8,5,8,2,6,0],\"winH2H\":[4]},\"away\":{\"ballPossession\":[51,38,44,43,48,39,60,43,47,31,38,43,39,43,31,29,46,48,50,44],\"shotOnTarget\":[0,8,5,3,4,5,4,3,5,3,8,3,5,3,3,3,3,4,5,1],\"bigChances\":[0,3,3,0,5,3,1,0,2,3,0,3,2,1],\"goalR\":[1,2,0,1,1,0,3,1,1,1,2,1,0,1,1,3,3,1,2,0,4],\"goalKeeperSave\":[3,2,3,1,3,4,1,3,2,2,2,1,4,3,2,3,4,1,5,2],\"blockedShots\":[4,3,5,1,2,2,4,0,1,1,3,1,2,0,1,3,0],\"winH2H\":[0]}}",
              "gameId": 11425594
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[66,64,41,52,53,52,51,39,55,38,66,41,52,51,55,46,46,53,48],\"shotOnTarget\":[5,2,4,2,4,4,5,4,2,1,5,4,2,5,2,5,10,2,9],\"bigChances\":[2,1,2,0,3,4,0,1,2,1,2,4,1],\"goalR\":[0,0,3,2,1,0,1,1,2,1,0,3,2,1,2,1,1,1,0,0,1,2],\"goalKeeperSave\":[4,1,1,2,2,1,2,4,3,6,4,1,2,2,3,2,6,5,4],\"blockedShots\":[3,8,6,3,1,4,6,1,2,3,3,6,3,6,2,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[54,45,58,67,44,47,45,57,63,46,54,58,44,45,63,54,54,47,52],\"shotOnTarget\":[3,5,4,4,2,3,6,3,5,4,3,4,2,6,5,3,8,5,5],\"bigChances\":[1,3,3,2,1,2,0,2,0,1,3,1,0,2,1],\"goalR\":[1,2,2,1,1,1,1,0,3,3,1,2,1,1,3,1,2,0,4,0,0,0],\"goalKeeperSave\":[0,3,3,5,2,3,2,3,3,3,0,3,2,2,3,4,7,3,5],\"blockedShots\":[3,2,4,3,4,2,9,9,6,8,3,4,4,9,6,3],\"winH2H\":[3]}}",
              "gameId": 11425595,
              "datetime": "01/04/2024 5:30"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[54,58,48,63,61,47,62,55,47,46,58,63,47,55,46],\"shotOnTarget\":[4,3,3,6,4,12,5,2,2,2,3,6,12,2,2],\"bigChances\":[2,0,2,0,3,4,3,1,1,3,0,0,4,1,3],\"goalR\":[1,2,1,1,1,0,0,0,0,0,2,1,0,0,0],\"goalKeeperSave\":[3,1,3,2,2,5,1,2,4,4,1,2,5,2,4],\"blockedShots\":[4,4,4,11,4,2,10,4,3,0,4,11,2,4,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[41,39,51,49,35,35,50,43,37,40,51,35,35,43,40],\"shotOnTarget\":[1,3,4,3,6,2,4,5,5,3,4,6,2,5,3],\"bigChances\":[0,1,0,0,2,0,1,4,1,1,0,2,0,4,1],\"goalR\":[1,1,1,2,1,3,3,1,3,2,1,1,3,1,2],\"goalKeeperSave\":[6,3,2,3,3,2,4,2,2,5,2,3,2,2,5],\"blockedShots\":[3,4,0,0,4,2,6,6,2,3,0,4,2,6,3],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 11:0",
              "gameId": 11425596
          },
          {
              "datetime": "01/04/2024 8:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[61,45,61,47,58,74,55,57,57,64,61,58,74,57,52],\"shotOnTarget\":[4,5,6,3,6,4,3,4,5,2,6,6,4,4,3],\"bigChances\":[2,1,2,0,2,3,1,0,3,3,2,2,3,0,0],\"goalR\":[0,0,2,0,1,2,2,1,1,1,2,1,2,1,1],\"goalKeeperSave\":[3,2,3,6,3,1,1,1,1,6,3,3,1,1,4],\"blockedShots\":[6,0,2,1,2,8,1,5,0,4,2,2,8,5,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,60,48,55,64,56,44,57,55,48,43,48,56,55,48],\"shotOnTarget\":[4,6,4,7,9,4,3,0,5,8,4,4,4,5,5],\"bigChances\":[3,3,3,3,3,0,1,1,2,2,3,3,0,2,3],\"goalR\":[1,1,0,2,2,1,0,1,1,2,1,0,1,1,1],\"goalKeeperSave\":[0,1,2,3,5,6,4,2,3,5,0,2,6,3,2],\"blockedShots\":[1,9,1,6,5,4,2,6,4,1,1,1,4,4,1],\"winH2H\":[0]}}",
              "gameId": 11425597
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[33,44,61,26,36,50,51,47,45,43,61,51,47],\"shotOnTarget\":[6,6,4,3,7,4,4,8,2,4,4,4,8],\"bigChances\":[3,0,1,2,3,1,4,6,3,1,1,4,6],\"goalR\":[2,3,2,1,1,1,0,2,1,0,2,0,2],\"goalKeeperSave\":[3,5,3,3,7,4,4,5,1,3,3,4,5],\"blockedShots\":[2,3,2,4,2,5,8,3,4,3,2,8,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[55,34,67,48,42,39,48,43,48,50,34,42,43],\"shotOnTarget\":[3,3,5,4,4,3,5,3,6,5,3,4,3],\"bigChances\":[2,0,3,2,2,0,1,2,1,4,0,2,2],\"goalR\":[3,1,1,2,2,2,2,0,1,0,1,2,0],\"goalKeeperSave\":[1,7,5,3,4,2,3,0,1,4,7,4,0],\"blockedShots\":[4,3,3,1,6,2,6,6,2,0,3,6,6],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 8:0",
              "gameId": 11425598
          },
          {
              "datetime": "01/04/2024 8:0",
              "gameId": 11425599,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[33,52,52,38,43,53,60,57,58,46,52,38,60,57,46,67],\"shotOnTarget\":[6,5,6,2,2,3,7,3,4,4,5,2,7,3,4,4],\"bigChances\":[1,2,2,2,1,1,3,0,2,2,2,3,0,2,1],\"goalR\":[1,3,2,0,1,0,1,0,0,1,3,0,1,0,1,2],\"goalKeeperSave\":[3,0,2,5,3,3,2,4,0,5,0,5,2,4,5,3],\"blockedShots\":[1,6,4,5,4,4,3,5,8,2,6,5,3,5,2,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[53,36,40,43,37,37,49,43,52,43,53,40,37,49,52,33],\"shotOnTarget\":[3,1,2,3,2,3,2,2,2,4,3,2,2,2,2,5],\"bigChances\":[1,2,1,2,0,1,0,0,1,2,1,0,0,4],\"goalR\":[1,0,2,1,0,1,2,2,2,1,1,2,0,2,2,0],\"goalKeeperSave\":[2,2,4,5,6,3,1,2,4,2,2,4,6,1,4,3],\"blockedShots\":[1,2,1,1,1,2,1,5,1,3,1,1,1,1,1,3],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11425892,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,54,47,45,49,65,64,52,36,54,54,45,65,52,54],\"shotOnTarget\":[4,5,9,9,2,8,5,7,1,7,5,9,8,7,7],\"bigChances\":[1,3,3,5,0,2,7,2,1,1,3,5,2,2,1],\"goalR\":[1,1,2,1,1,1,1,1,3,2,1,1,1,1,2,1,2,1,2,2,2,1,0,0,0],\"goalKeeperSave\":[2,2,2,3,5,4,2,5,1,4,2,3,4,5,4],\"blockedShots\":[7,4,2,2,2,2,8,2,0,0,4,2,2,2,0],\"winH2H\":[4]},\"away\":{\"ballPossession\":[44,65,63,60,59,80,58,50,67,68,44,63,59,58,67],\"shotOnTarget\":[5,4,5,7,5,6,1,0,6,2,5,5,5,1,6],\"bigChances\":[2,1,2,3,3,2,0,0,3,1,2,2,3,0,3],\"goalR\":[0,1,0,0,1,1,0,1,1,0,0,0,1,0,1,0,1,2,2,1,1,0,4,3,1],\"goalKeeperSave\":[2,2,2,2,2,0,5,3,2,2,2,2,2,5,2],\"blockedShots\":[2,5,1,6,5,9,1,2,7,6,2,1,5,1,7],\"winH2H\":[5]}}",
              "datetime": "04/04/2024 6:0"
          },
          {
              "gameId": 11425893,
              "datetime": "04/04/2024 7:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[37,46,47,43,47,48,48,61,39,37,43,47,48,39],\"shotOnTarget\":[3,3,6,4,2,7,7,4,3,3,4,2,7,3],\"bigChances\":[2,1,2,0,1,2,4,0,2,2,0,1,4,2],\"goalR\":[2,0,2,1,3,2,4,2,2,2,2,1,2,2,2],\"goalKeeperSave\":[5,5,2,4,3,3,3,2,3,5,4,3,3,3],\"blockedShots\":[1,0,1,2,10,1,3,3,4,1,2,10,3,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[41,45,51,45,36,41,39,47,27,51,45,45,41,47,51],\"shotOnTarget\":[2,5,4,5,3,3,4,5,6,6,5,5,3,5,6],\"bigChances\":[0,1,3,0,0,3,1,1,2,1,1,0,3,1,1],\"goalR\":[2,1,1,3,1,3,0,2,1,2,1,3,3,2,2],\"goalKeeperSave\":[1,2,3,1,4,8,4,3,4,3,2,1,8,3,3],\"blockedShots\":[5,4,2,2,1,2,3,3,6,6,4,2,2,3,6],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11425894,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,55,30,55,65,55,46,59,63,56,55,55,55,59,56],\"shotOnTarget\":[7,7,7,7,7,10,8,10,3,2,7,7,10,10,2,0],\"bigChances\":[1,6,7,2,3,5,3,4,1,0,6,2,5,4,0],\"goalR\":[2,1,1,1,2,1,1,2,2,2,1,1,1,2,2,0,0,0,0,2,3],\"goalKeeperSave\":[1,2,3,4,2,5,2,1,2,3,2,4,5,1,3],\"blockedShots\":[7,5,3,4,2,6,3,4,3,6,5,4,6,4,6,0],\"winH2H\":[3]},\"away\":{\"ballPossession\":[46,42,56,35,48,37,61,44,37,38,42,56,48,61,38],\"shotOnTarget\":[1,3,4,8,7,4,5,5,4,3,3,4,7,5,3,0],\"bigChances\":[0,0,1,3,2,1,2,2,3,0,0,1,2,2,0],\"goalR\":[1,3,2,2,3,1,2,2,0,0,3,2,3,2,0,2,1,0,1,2,3],\"goalKeeperSave\":[1,3,2,5,4,2,1,2,4,5,3,2,4,1,5],\"blockedShots\":[3,4,4,7,1,4,4,4,2,1,4,4,1,4,1,0],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 7:0"
          },
          {
              "gameId": 11425895,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[40,53,45,35,51,45,58,54,63,37,53,51,58,37],\"shotOnTarget\":[3,4,5,6,6,2,5,6,4,2,4,6,5,2],\"bigChances\":[1,3,0,4,2,1,2,0,1,0,3,2,2,0],\"goalR\":[0,2,3,4,3,1,2,1,2,1,2,3,2,1,2,3],\"goalKeeperSave\":[2,5,4,4,2,1,3,1,2,4,5,2,3,4],\"blockedShots\":[4,7,4,3,3,4,5,3,2,3,7,3,5,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[42,45,44,43,35,35,52,44,46,44,45,43,35,44],\"shotOnTarget\":[4,4,3,2,4,5,7,0,2,6,4,2,5,0],\"bigChances\":[2,0,1,0,0,3,1,1,2,0,1,0,1],\"goalR\":[2,0,0,4,1,3,3,3,0,1,0,4,3,3,4,2],\"goalKeeperSave\":[5,7,2,2,5,6,4,4,5,3,7,2,6,4],\"blockedShots\":[2,2,2,2,4,1,5,3,4,6,2,2,1,3],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 6:0"
          },
          {
              "datetime": "03/04/2024 7:0",
              "gameId": 11425896,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[65,56,41,47,60,54,60,67,68,68,56,54,67,68],\"shotOnTarget\":[7,7,1,5,9,3,7,4,5,1,7,3,4,5,0],\"bigChances\":[5,3,0,4,2,2,4,1,4,1,3,2,1,4],\"goalR\":[1,0,0,1,2,1,1,2,2,1,0,1,2,2,2,0,0,2,1,2,3,0,0,2],\"goalKeeperSave\":[7,0,4,5,4,3,3,1,1,0,3,1,1],\"blockedShots\":[6,5,4,0,4,2,7,1,15,6,5,2,1,15,0],\"winH2H\":[4]},\"away\":{\"ballPossession\":[54,60,59,64,49,58,54,54,62,50,60,49,54,62],\"shotOnTarget\":[7,1,4,4,5,11,2,7,4,4,1,5,2,4,0],\"bigChances\":[2,3,2,4,2,5,0,3,3,1,3,2,0,3],\"goalR\":[2,0,0,0,2,1,4,0,1,0,0,2,4,1,0,1,1,1,2,0,1,3,0,1],\"goalKeeperSave\":[2,2,1,0,3,0,5,2,3,0,2,3,5,3],\"blockedShots\":[1,7,6,6,3,3,7,1,3,0,7,3,7,3,0],\"winH2H\":[5]}}"
          },
          {
              "gameId": 11425897,
              "datetime": "03/04/2024 6:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,70,46,54,46,56,53,58,50,69,55,70,53,50],\"shotOnTarget\":[3,3,4,5,6,4,6,1,4,4,3,3,6,4,0],\"bigChances\":[0,4,1,1,2,2,1,2,1,0,0,4,1,1],\"goalR\":[0,2,2,1,1,3,3,1,2,3,0,2,3,2,3,3,3,2,1,1,1,0,1,0],\"goalKeeperSave\":[5,2,5,4,6,2,2,5,7,2,5,2,2,7],\"blockedShots\":[4,8,2,2,8,1,5,4,5,6,4,8,5,5,0],\"winH2H\":[5]},\"away\":{\"ballPossession\":[25,46,56,40,49,44,32,40,46,31,25,56,49,32],\"shotOnTarget\":[1,7,2,3,4,4,1,2,5,2,1,2,4,1,0],\"bigChances\":[1,3,2,0,2,1,0,2,0,1,0,1],\"goalR\":[0,0,0,0,2,1,2,0,1,1,0,0,2,2,1,2,0,0,1,3,2,4,2,3],\"goalKeeperSave\":[2,1,3,4,2,3,3,1,4,4,2,3,2,3],\"blockedShots\":[0,1,5,4,1,1,0,2,1,2,0,5,1,0,0],\"winH2H\":[4]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[70,49,49,64,41,56,73,51,70,51],\"shotOnTarget\":[4,5,5,4,4,4,5,5,4,5],\"bigChances\":[2,6,2,2,1,3,0,3,2,3],\"goalR\":[3,5,1,1,0,2,4,0,3,2,3,1,2],\"goalKeeperSave\":[4,5,5,6,8,6,3,3,4,3],\"blockedShots\":[5,0,5,6,2,0,3,5,5,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[48,54,53,57,51,30,54,42,59,53,54,57,30],\"shotOnTarget\":[9,1,3,6,6,4,3,4,13,4,1,6,4],\"bigChances\":[0,1,0,2,2,5,2,1,3,3,1,2,5],\"goalR\":[4,3,2,2,0,0,5,1,2,2,3,2,0],\"goalKeeperSave\":[2,4,1,2,2,3,2,4,2,5,4,2,3],\"blockedShots\":[2,4,9,6,3,1,3,1,9,4,4,6,1],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 7:0",
              "gameId": 11425898
          },
          {
              "datetime": "03/04/2024 6:30",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[30,38,53,60,65,45,51,52,28,47,30,53,65],\"shotOnTarget\":[1,1,4,4,8,6,5,5,6,7,1,4,8,0],\"bigChances\":[1,1,1,0,2,0,1,3,2,1,1,1,2],\"goalR\":[0,0,2,2,3,6,1,4,1,2,0,2,3,0,1],\"goalKeeperSave\":[2,10,7,2,3,3,4,3,5,2,2,7,3],\"blockedShots\":[1,2,3,4,3,2,6,8,1,2,1,3,3,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[50,45,42,55,45,53,55,36,72,53,45,55,53],\"shotOnTarget\":[6,3,4,4,2,5,2,8,6,5,3,4,5,0],\"bigChances\":[4,1,0,1,1,2,0,3,5,4,1,1,2],\"goalR\":[2,3,2,6,2,1,1,1,3,2,3,6,1,0,1],\"goalKeeperSave\":[1,4,3,3,4,1,1,3,2,3,4,3,1],\"blockedShots\":[5,3,1,6,6,2,3,2,5,2,3,6,2,0],\"winH2H\":[0]}}",
              "gameId": 11425899
          },
          {
              "gameId": 11438120,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,4,1,2,2,1,2,0,0,0,1,2,2,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,43],\"shotOnTarget\":[3,3],\"bigChances\":[],\"goalR\":[0,2,4,0,2,2,0,2,1,1,0,4,0,2,2,0,0],\"goalKeeperSave\":[2,2],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:30"
          },
          {
              "gameId": 11450035,
              "datetime": "04/04/2024 12:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[40,42,55,53,49,38,35,37,49,45,40,55,53,38,37],\"shotOnTarget\":[2,2,7,4,7,5,6,6,3,3,2,7,4,5,6],\"bigChances\":[0,0,2,2,5,3,2,5,1,1,0,2,2,3,5],\"goalR\":[0,2,1,1,1,2,2,2,1,3,0,1,1,2,2],\"goalKeeperSave\":[0,3,3,1,3,3,2,2,1,4,0,3,1,3,2],\"blockedShots\":[7,1,5,5,2,5,2,1,1,3,7,5,5,5,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[46,63,68,39,38,46,51,44,29,44,63,39,46,44,44],\"shotOnTarget\":[4,4,5,3,0,11,4,3,0,4,4,3,11,3,4],\"bigChances\":[2,3,1,3,1,5,1,3,1,1,3,3,5,3,1],\"goalR\":[1,2,3,1,5,2,0,4,2,2,2,1,2,4,2],\"goalKeeperSave\":[3,1,4,6,6,1,7,1,3,2,1,6,1,1,2],\"blockedShots\":[2,1,4,1,0,6,2,4,2,2,1,1,6,4,2],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,71,61,54,54,66,63,59,51,67,63,61,54,66,59,66,62,61],\"shotOnTarget\":[11,5,7,6,1,10,8,8,9,4,11,7,6,10,8,10,4,4],\"bigChances\":[4,2,7,4,5,1,4,3,2,4,7,4,5,4,4,3,4],\"goalR\":[0,0,1,0,0,0,1,1,1,1,0,1,0,0,1,0,1,0],\"goalKeeperSave\":[1,0,3,2,0,5,3,3,1,1,1,3,2,5,3,4,3,1],\"blockedShots\":[6,6,3,5,0,6,10,11,2,6,6,3,5,6,11,5,3,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[39,45,56,45,29,54,51,60,53,54,45,45,54,60,53,34,38,39],\"shotOnTarget\":[4,5,10,3,5,3,8,3,4,2,5,3,3,3,4,4,4,1],\"bigChances\":[1,1,5,2,3,2,4,4,0,0,1,2,2,4,0,1,2,1],\"goalR\":[1,4,2,2,1,2,3,1,0,2,4,2,2,1,0,4,2,3],\"goalKeeperSave\":[3,2,1,5,5,9,4,2,3,5,2,5,9,2,3,6,2,2],\"blockedShots\":[2,1,4,3,0,3,1,5,3,5,1,3,3,5,3,1,1,3],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 12:30",
              "gameId": 11450036
          },
          {
              "gameId": 11450047,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[61,37,54,39,52,41,65,51,39,34,61,54,52,65,39,63,61,42,46,37,34,46,59,55,52],\"shotOnTarget\":[7,3,3,3,7,5,1,2,4,5,7,3,7,1,4,4,9,6,3,4,5,7,3,3,1],\"bigChances\":[2,0,4,2,3,1,1,1,2,1,2,4,3,1,2,2,2,1,2,0,1],\"goalR\":[2,3,1,2,0,2,1,1,0,3,2,1,0,1,0,1,2,1,1,2,2,1,0,0,0],\"goalKeeperSave\":[1,1,1,2,1,4,2,2,4,7,1,1,1,2,4,1,4,4,1,4,3,1,3,1,2],\"blockedShots\":[2,2,3,2,3,4,6,5,4,0,2,3,3,6,4,8,5,8,4,1,2,2,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[55,44,55,35,61,59,56,56,56,44,55,55,35,59,56,37,39,58,54,63,66,54,41,45,48],\"shotOnTarget\":[3,3,0,4,5,5,1,11,4,2,3,0,4,5,11,2,5,5,2,6,5,2,3,1,2],\"bigChances\":[0,1,1,2,2,3,6,1,1,0,1,2,3,6,3,1,1,0,5,6],\"goalR\":[1,0,0,2,1,3,1,1,1,0,1,0,2,3,1,2,2,1,0,2,2,1,2,1,0],\"goalKeeperSave\":[7,3,4,2,2,6,3,3,0,2,7,4,2,6,3,1,7,5,3,2,3,6,1,2,1],\"blockedShots\":[7,3,1,2,4,2,2,2,7,5,7,1,2,2,2,1,4,1,3,4,3,4,2],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 12:30"
          },
          {
              "gameId": 11450048,
              "datetime": "03/04/2024 12:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[53,60,44,57,46,53,44,56,51,61,60,57,53,56,51,53,48,34,58,67,65,53,57,54],\"shotOnTarget\":[2,8,2,4,6,4,4,4,6,4,8,4,4,4,6,3,4,2,10,6,5,3,4,6],\"bigChances\":[0,5,3,3,3,3,2,4,2,5,3,3,2,4,0,3,0,3,3,3],\"goalR\":[3,1,1,0,3,1,0,0,4,2,1,0,1,0,4,0,0,0,1,1,2,2,0,0,0],\"goalKeeperSave\":[5,2,9,5,6,2,1,4,2,2,2,5,2,4,2,2,1,3,2,4,0,2,1,0],\"blockedShots\":[0,6,2,3,1,0,2,3,4,4,6,3,0,3,4,1,2,0,3,6,5,3,2],\"winH2H\":[5]},\"away\":{\"ballPossession\":[27,38,40,38,37,54,48,48,63,48,27,40,37,48,63,47,52,66,42,33,35,47,43,46],\"shotOnTarget\":[2,1,6,11,5,9,1,7,4,3,2,6,5,1,4,2,1,3,3,6,2,4,1,0],\"bigChances\":[0,0,2,3,2,4,0,3,1,0,2,2,0,1,1,0,4,2,4,3],\"goalR\":[2,2,0,1,2,4,2,2,4,0,2,0,2,2,4,0,2,1,1,2,3,2,0,0,1],\"goalKeeperSave\":[3,5,8,4,1,2,5,2,2,1,3,8,1,5,2,3,2,1,9,4,2,1,4,6],\"blockedShots\":[0,2,4,5,2,2,4,9,3,1,0,4,2,4,3,4,1,4,2,1,2,0,1],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[48,39,36,41,27,54,52,44,33,32,39,41,54,44,33,47,24,63],\"shotOnTarget\":[1,2,6,5,5,2,5,4,5,3,2,5,2,4,5,5,4,3],\"bigChances\":[4,3,4,2,0,4,1,3,3,4,0,1,3,1,2,1],\"goalR\":[2,0,2,0,0,1,4,3,2,0,0,0,1,3,2,1,1,1],\"goalKeeperSave\":[2,2,4,2,3,4,3,8,5,3,2,2,4,8,5,4,5,1],\"blockedShots\":[3,4,1,2,1,0,2,0,6,2,4,2,0,0,6,6,1,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[51,43,56,51,43,73,37,59,51,41,51,56,43,37,51,53,76,37],\"shotOnTarget\":[1,3,3,5,5,2,1,6,6,3,1,3,5,1,6,5,6,2],\"bigChances\":[0,3,1,6,1,1,0,2,4,3,0,1,1,0,4,1,0,1],\"goalR\":[4,1,1,2,1,3,4,2,0,1,4,1,1,4,0,2,3,1],\"goalKeeperSave\":[3,3,3,2,3,2,7,3,2,2,3,3,3,7,2,3,1,2],\"blockedShots\":[0,1,6,3,2,5,2,3,0,3,0,6,2,2,0,2,4,1],\"winH2H\":[0]}}",
              "gameId": 11450049,
              "datetime": "02/04/2024 12:30"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[49,38,52,41,51,35,36,48,35,37,38,41,48,37,47,36,37],\"shotOnTarget\":[4,10,3,9,4,3,4,6,5,4,10,9,6,4,3,9,4],\"bigChances\":[0,4,3,2,2,4,0,2,3,1,4,2,2,1,2,3,2],\"goalR\":[1,1,3,3,4,0,3,0,1,1,1,3,0,1,0,0,0],\"goalKeeperSave\":[4,3,5,2,2,1,2,0,3,6,3,2,0,6,3,4,3],\"blockedShots\":[2,2,2,2,1,3,0,2,1,3,2,2,2,3,2,2,2],\"winH2H\":[2]},\"away\":{\"ballPossession\":[44,45,46,48,46,53,67,46,68,55,46,46,67,46,53,64,63],\"shotOnTarget\":[6,4,2,7,2,5,2,2,3,1,2,2,2,2,3,4,3],\"bigChances\":[2,1,1,2,1,2,1,1,3,0,1,1,1,1,1,1,1],\"goalR\":[0,0,1,0,2,0,2,1,3,1,1,2,2,1,0,4,1],\"goalKeeperSave\":[7,0,2,3,1,0,0,5,0,3,2,1,0,5,3,5,3],\"blockedShots\":[1,1,2,4,2,3,6,3,0,2,2,2,6,3,4,5,4],\"winH2H\":[0]}}",
              "gameId": 11450050,
              "datetime": "03/04/2024 9:0"
          },
          {
              "datetime": "04/04/2024 12:30",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,48,51,56,51,40,64,44,56,44,48,56,40,64,56,49,45,32,34,41,35,39,37,47],\"shotOnTarget\":[3,4,2,6,9,3,6,1,1,5,4,6,3,6,1,2,8,3,3,3,3,4,4,3],\"bigChances\":[1,2,0,5,1,1,5,2,2,4,2,5,1,5,2,1,5,3,1,0,2],\"goalR\":[1,3,1,1,3,0,1,1,0,0,3,1,0,1,0,2,2,3,0,0,0,1,2,2,1],\"goalKeeperSave\":[0,2,3,2,4,3,3,3,5,0,2,2,3,3,5,2,7,2,7,4,2,5,4,6],\"blockedShots\":[3,3,1,2,3,1,4,3,1,2,3,2,1,4,1,4,0,3,1,2,1,0,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[62,60,46,59,46,56,46,44,65,57,62,60,46,46,65,51,55,68,66,59,65,61,63,53],\"shotOnTarget\":[6,4,1,3,7,3,7,6,4,6,6,4,7,7,4,4,8,5,7,4,2,6,6,7],\"bigChances\":[4,2,1,2,1,2,3,3,2,4,2,2,3,2,2,1,0,2,0],\"goalR\":[1,1,3,3,1,3,0,0,4,0,1,1,1,0,4,0,3,2,1,1,1,3,2,1,1],\"goalKeeperSave\":[3,3,6,0,1,4,7,3,1,0,3,3,1,7,1,2,5,1,2,2,1,1,2,4],\"blockedShots\":[5,2,2,10,7,3,6,2,3,6,5,2,7,6,3,0,6,4,5,4,3,4,1],\"winH2H\":[3]}}",
              "gameId": 11450051
          },
          {
              "gameId": 11450052,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[49,54,49,46,53,67,64,66,66,62,49,46,67,66,49,62],\"shotOnTarget\":[5,9,6,0,14,11,6,7,7,10,5,0,11,7,7,4],\"bigChances\":[2,5,5,5,8,6,4,6,5,2,8,4,4,2],\"goalR\":[1,1,3,0,1,1,0,1,0,1,1,0,1,1,2,2],\"goalKeeperSave\":[3,0,3,1,7,0,4,3,1,1,3,1,0,3,6,7],\"blockedShots\":[2,2,3,0,4,0,5,5,8,4,2,0,0,5,1,6],\"winH2H\":[1]},\"away\":{\"ballPossession\":[55,39,49,45,56,63,62,57,47,65,55,56,62,47,51,38],\"shotOnTarget\":[5,7,7,8,6,4,4,0,3,4,5,6,4,3,9,9],\"bigChances\":[2,3,6,1,2,5,0,0,0,1,2,2,0,0,3,1],\"goalR\":[2,2,0,0,2,0,1,0,1,3,2,2,1,1,4,1],\"goalKeeperSave\":[4,3,1,3,4,3,9,9,3,3,4,4,9,3,3,3],\"blockedShots\":[5,1,3,1,1,5,6,0,5,4,5,1,6,5,2,2],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 12:30"
          },
          {
              "datetime": "02/04/2024 12:30",
              "gameId": 11450053,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[61,42,36,45,52,49,33,52,61,46,42,36,52,33,54,46,55,56],\"shotOnTarget\":[2,1,3,10,10,7,2,1,3,2,1,3,10,2,2,4,3,5],\"bigChances\":[0,1,4,3,3,0,3,1,0,1,3,0,0,1,3,2],\"goalR\":[1,0,2,0,0,3,1,0,2,0,0,2,0,1,2,0,2,4,0,2,0,0],\"goalKeeperSave\":[1,3,2,2,3,5,1,3,0,4,3,2,3,1,0,3,1,0],\"blockedShots\":[3,5,0,6,3,4,0,3,9,2,5,0,3,0,2,3,2,4],\"winH2H\":[4]},\"away\":{\"ballPossession\":[58,52,55,46,40,32,47,46,56,38,52,46,46,38,46,54,45,44],\"shotOnTarget\":[5,5,2,5,4,7,3,1,2,3,5,5,1,3,2,3,3,4],\"bigChances\":[3,0,0,2,1,1,0,0,0,0,2,0,0,2,0,2,4],\"goalR\":[0,3,4,0,1,1,0,1,1,1,3,0,1,1,0,1,2,2,1,2,1,1],\"goalKeeperSave\":[2,1,7,2,3,2,4,2,1,1,1,2,2,1,2,3,1,3],\"blockedShots\":[1,2,5,2,3,3,5,3,3,3,2,2,3,3,1,2,1,3],\"winH2H\":[2]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[35,47,62,54,47,59,46,47,49,52,47,54,46,49],\"shotOnTarget\":[2,3,6,5,3,0,11,0,2,0,3,5,11,2],\"bigChances\":[0,2,2,6,1,1,6,0,0,0,2,6,6,0],\"goalR\":[2,1,3,4,2,1,1,3,1,1,1,4,1,1],\"goalKeeperSave\":[3,0,8,7,2,2,2,3,5,5,0,7,2,5],\"blockedShots\":[1,3,4,2,0,2,2,3,6,2,3,2,2,6],\"winH2H\":[0]},\"away\":{\"ballPossession\":[34,31,40,38,59,41,48,49,68,55,34,40,59,48],\"shotOnTarget\":[1,4,3,3,2,3,3,8,3,5,1,3,2,3],\"bigChances\":[0,1,2,0,2,0,3,6,1,6,0,2,2,3],\"goalR\":[5,0,3,1,2,0,0,2,1,1,5,3,2,0],\"goalKeeperSave\":[6,3,4,4,3,0,10,7,6,4,6,4,3,10],\"blockedShots\":[1,1,5,3,5,2,2,5,5,1,1,5,5,2],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 9:0",
              "gameId": 11450054
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[33,68,39,48,55,37,59,58,46,58,33,39,48,37,58,49,47,61],\"shotOnTarget\":[1,5,2,4,1,2,3,3,4,8,1,2,4,2,3,4,5,1],\"bigChances\":[0,1,1,4,1,3,2,2,3,2,0,1,4,3,2,0],\"goalR\":[3,1,4,0,0,3,2,2,0,4,3,4,0,3,2,0,2,2,2],\"goalKeeperSave\":[6,1,6,4,3,4,3,3,1,2,6,6,4,4,3,3,2,2],\"blockedShots\":[0,5,2,1,2,1,1,8,4,6,0,2,1,1,8,2,2,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[41,55,33,49,49,53,54,34,43,29,41,33,49,34,29,51,53,39],\"shotOnTarget\":[1,3,3,6,6,7,1,1,2,2,1,3,6,1,2,3,4,5],\"bigChances\":[1,2,2,2,3,1,0,1,0,3,1,2,3,1,3,2],\"goalR\":[1,1,1,3,0,2,0,1,1,2,1,1,0,1,2,0,3,0,1],\"goalKeeperSave\":[6,4,2,4,4,2,4,4,3,2,6,2,4,4,2,4,2,1],\"blockedShots\":[0,3,1,4,1,5,3,3,4,1,0,1,1,3,1,0,3,0],\"winH2H\":[2]}}",
              "gameId": 11453090,
              "datetime": "01/04/2024 14:0"
          },
          {
              "gameId": 11453092,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[51,44,57,40,58,55,44,42,50,55,51,57,58,42,45,46],\"shotOnTarget\":[2,2,3,1,2,6,6,6,4,1,2,3,2,6,4,8],\"bigChances\":[1,0,0,0,2,1,2,1,2,3,1,0,2,1,1,5],\"goalR\":[0,2,3,2,0,1,4,2,3,2,0,3,0,2,3,1],\"goalKeeperSave\":[2,6,2,7,3,3,2,4,4,5,2,2,3,4,9,4],\"blockedShots\":[2,2,1,0,6,1,1,3,2,1,2,1,6,3,3,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[64,63,61,55,41,62,50,49,57,64,63,55,62,57,55,54],\"shotOnTarget\":[6,6,6,4,5,11,7,3,4,4,6,4,11,4,12,4],\"bigChances\":[1,3,3,3,6,7,2,0,2,0,3,3,7,2,3,1],\"goalR\":[0,0,1,3,1,1,0,1,1,0,0,3,1,1,1,1],\"goalKeeperSave\":[1,2,0,4,2,2,5,8,1,2,2,4,2,1,3,7],\"blockedShots\":[2,2,7,2,2,5,1,3,1,3,2,2,5,1,2,8],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 14:0"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[32,36,38,42,38,35,59,40,43,43,32,38,38,59,43,36,41,30,35],\"shotOnTarget\":[2,1,3,3,4,4,2,6,5,4,2,3,4,2,4,0,3,4,0],\"bigChances\":[3,0,5,2,3,1,1,4,0,1,3,5,3,1,1,1,2,4,1],\"goalR\":[0,1,1,1,3,4,1,2,4,1,0,1,3,1,1,3,3,1,2],\"goalKeeperSave\":[6,5,1,1,0,8,1,4,4,6,6,1,0,1,6,4,5,10,7],\"blockedShots\":[3,5,2,4,3,3,6,0,5,2,3,2,3,6,2,0,3,3,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[59,70,64,67,66,60,55,66,46,69,59,64,60,55,46,64,59,70,65],\"shotOnTarget\":[9,7,3,8,11,8,6,8,6,9,9,3,8,6,6,7,8,11,10],\"bigChances\":[3,2,1,3,7,6,4,3,2,7,3,1,6,4,2,3,2,7,2],\"goalR\":[1,0,3,1,1,2,1,1,2,4,1,3,2,1,2,0,1,2,0],\"goalKeeperSave\":[4,5,3,0,1,3,7,5,5,1,4,3,3,7,5,0,2,2,0],\"blockedShots\":[2,4,5,6,3,0,3,5,5,9,2,5,0,3,5,1,5,5,4],\"winH2H\":[3]}}",
              "datetime": "02/04/2024 14:0",
              "gameId": 11453094
          },
          {
              "gameId": 11453096,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[64,69,36,66,70,66,73,74,61,66,64,36,70,66],\"shotOnTarget\":[5,15,6,5,7,9,6,6,5,9,5,6,7,9],\"bigChances\":[4,7,6,3,6,10,3,3,3,5,4,6,6,10],\"goalR\":[0,0,0,1,0,0,0,1,0,1,0,0,0,0],\"goalKeeperSave\":[4,2,3,3,2,3,0,0,3,1,4,3,2,3],\"blockedShots\":[5,3,2,3,3,3,2,5,0,5,5,2,3,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[33,43,33,37,49,38,51,42,27,56,33,33,49,42],\"shotOnTarget\":[3,2,1,2,3,4,4,5,1,6,3,1,3,5],\"bigChances\":[1,0,0,0,2,3,1,3,1,2,1,0,2,3],\"goalR\":[1,3,1,1,0,1,1,1,3,2,1,1,0,1],\"goalKeeperSave\":[3,4,0,5,1,4,4,2,7,1,3,0,1,2],\"blockedShots\":[1,4,0,0,3,1,2,1,1,4,1,0,3,1],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 14:0"
          },
          {
              "gameId": 11453098,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,56,51,71,45,57,32,68,48,48,56,45,32,48],\"shotOnTarget\":[2,7,1,5,7,7,1,6,2,2,7,7,1,2],\"bigChances\":[1,3,0,4,4,3,2,1,1,3,4,2,1],\"goalR\":[0,1,2,2,0,2,0,2,0,2,1,0,0,2],\"goalKeeperSave\":[2,4,6,3,1,2,4,1,7,2,4,1,4,2],\"blockedShots\":[2,2,1,7,7,1,2,1,2,5,2,7,2,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,42,52,51,33,53,41,29,32,42,43,52,33,41],\"shotOnTarget\":[3,4,4,1,2,2,2,4,3,4,3,4,2,2],\"bigChances\":[2,2,2,0,2,2,1,3,1,4,2,2,2,1],\"goalR\":[2,4,3,1,6,3,0,2,0,3,2,3,6,0],\"goalKeeperSave\":[3,4,1,2,6,5,2,3,4,1,3,1,6,2],\"blockedShots\":[0,2,3,1,2,2,1,2,0,1,0,3,2,1],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 14:0"
          },
          {
              "datetime": "03/04/2024 14:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,32,70,38,51,42,53,46,57,48,32,38,53,57,57,54,49,27,45,54,44],\"shotOnTarget\":[7,1,9,6,7,3,7,2,8,2,1,6,7,8,5,7,6,2,3,1,1],\"bigChances\":[2,2,5,5,2,0,1,0,2,2,2,5,1,2,3,1,2,1,0],\"goalR\":[1,5,2,1,1,2,1,1,1,1,5,1,1,1,0,0,3,1,0,1,0,0,0,1],\"goalKeeperSave\":[1,2,2,8,5,2,4,5,4,5,2,8,4,4,2,2,4,2,3,2,5],\"blockedShots\":[3,3,6,0,1,4,3,3,9,2,3,0,3,9,2,1,7,1,4,1,5],\"winH2H\":[5]},\"away\":{\"ballPossession\":[36,61,62,56,51,54,68,46,65,48,36,62,51,68,43,46,51,73,55,46,56],\"shotOnTarget\":[2,7,3,6,2,6,4,8,5,4,2,3,2,4,2,2,7,4,3,3,6],\"bigChances\":[0,4,0,3,3,2,0,2,1,0,0,0,3,0,1,0,3,1,2],\"goalR\":[2,1,1,3,0,2,1,2,0,0,2,1,0,1,2,4,2,1,0,1,0,2,1,2],\"goalKeeperSave\":[4,1,2,3,3,0,2,3,0,5,4,2,3,2,3,3,4,1,3,0,1],\"blockedShots\":[1,4,2,3,0,2,5,0,8,2,1,2,0,5,4,2,4,2,3,1,4],\"winH2H\":[1]}}",
              "gameId": 11453101
          },
          {
              "gameId": 11453105,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[42,44,37,49,52,37,57,37,43,38,37,52,57,43,56,50,45],\"shotOnTarget\":[4,3,5,7,5,5,2,3,2,2,5,5,2,2,5,3,8],\"bigChances\":[2,2,3,3,3,4,2,2,2,0,3,3,2,2,1,0,3],\"goalR\":[1,1,3,3,0,0,4,4,4,3,3,0,4,4,0,1,2],\"goalKeeperSave\":[3,6,4,2,4,4,2,5,3,6,4,4,2,3,1,4,4],\"blockedShots\":[1,5,4,6,3,1,4,2,1,0,4,3,4,1,1,3,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[51,65,47,58,43,54,58,41,45,56,47,43,58,56,44,50,55],\"shotOnTarget\":[7,12,5,3,1,5,4,5,5,1,5,1,4,1,1,5,6],\"bigChances\":[4,7,1,1,0,1,4,1,3,0,1,0,4,0,0,1,1],\"goalR\":[2,0,5,1,3,3,1,3,1,2,5,3,1,2,2,0,5],\"goalKeeperSave\":[4,4,2,3,4,5,0,6,6,1,2,4,0,1,3,2,2],\"blockedShots\":[0,4,1,1,1,4,3,4,2,4,1,1,3,4,1,5,2],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 14:0"
          },
          {
              "datetime": "02/04/2024 9:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[49,53,53,62,55,44,60,44,33,44,49,53,55,60,33,48,55,44],\"shotOnTarget\":[6,0,2,4,4,3,5,5,4,3,6,2,4,5,4,3,8,2],\"bigChances\":[3,0,1,2,1,1,3,2,1,3,3,1,1,3,1,3,3],\"goalR\":[1,1,2,0,1,0,3,1,3,0,1,2,1,3,3,2,1,1],\"goalKeeperSave\":[0,1,2,0,2,2,2,4,8,2,0,2,2,2,8,6,0,1],\"blockedShots\":[1,1,2,2,1,3,4,5,3,0,1,2,1,4,3,1,1],\"winH2H\":[1]},\"away\":{\"ballPossession\":[53,43,56,39,57,50,42,47,49,33,43,39,50,47,33,52,45,56],\"shotOnTarget\":[9,3,2,3,7,3,1,8,6,7,3,3,3,8,7,8,1,2],\"bigChances\":[7,1,1,4,2,2,1,2,1,4,1,4,2,2,4,7,1],\"goalR\":[0,2,0,0,1,1,3,0,2,1,2,0,1,0,1,1,3,0],\"goalKeeperSave\":[1,4,3,7,1,3,3,0,3,4,4,7,3,0,4,1,5,2],\"blockedShots\":[1,3,0,1,0,3,6,3,7,4,3,1,3,3,4,1,0],\"winH2H\":[2]}}",
              "gameId": 11457257
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[50,34,54,48,57,35,47,34,58,35,50,54,57,47,58],\"shotOnTarget\":[0,2,4,3,4,3,0,1,4,0,0,4,4,0,4],\"bigChances\":[1,1,2,0,3,1,0,0,1,0,1,2,3,0,1],\"goalR\":[2,1,2,3,0,1,1,0,2,4,2,2,0,1,2],\"goalKeeperSave\":[1,4,5,2,6,1,2,4,4,1,1,5,6,2,4],\"blockedShots\":[1,1,2,2,2,2,2,2,0,3,1,2,2,2,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[35,43,54,56,65,40,64,55,55,46,43,56,40,55,46],\"shotOnTarget\":[4,3,5,6,2,5,3,8,6,4,3,6,5,8,4],\"bigChances\":[2,0,3,6,1,1,2,3,4,4,0,6,1,3,4],\"goalR\":[0,1,0,1,1,2,2,1,1,1,1,1,2,1,1],\"goalKeeperSave\":[4,6,0,3,2,3,7,3,8,1,6,3,3,3,1],\"blockedShots\":[1,0,1,5,3,0,5,2,4,1,0,5,0,2,1],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 6:0",
              "gameId": 11457259
          },
          {
              "gameId": 11457261,
              "datetime": "03/04/2024 6:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[56,51,66,43,47,63,44,61,47,49,56,66,47,63,61,34,52],\"shotOnTarget\":[3,1,5,3,2,3,2,7,5,1,3,5,2,3,7,1,2],\"bigChances\":[2,0,3,2,0,2,0,2,0,3,2,3,0,2,2,0],\"goalR\":[1,2,1,0,2,1,1,1,2,1,1,1,2,1,1,1,0,1],\"goalKeeperSave\":[0,4,1,1,1,2,2,1,4,2,0,1,1,2,1,2,4],\"blockedShots\":[3,1,8,1,1,4,0,1,0,0,3,8,1,4,1,0,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[37,61,59,42,55,56,53,56,41,58,37,59,55,53,41,66,48],\"shotOnTarget\":[5,3,8,4,5,3,3,5,6,10,5,8,5,3,6,4,5],\"bigChances\":[2,1,3,4,2,2,2,1,4,4,2,3,2,2,4,1],\"goalR\":[2,1,0,2,1,1,0,2,0,0,2,0,1,0,0,1,0,0],\"goalKeeperSave\":[7,5,5,2,2,1,0,3,4,5,7,5,2,0,4,0,2],\"blockedShots\":[2,4,2,1,2,2,4,3,0,5,2,2,2,4,0,1,2],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11457263,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[62,57,58,64,67,68,39,50,67,67,57,64,68,50,67,63],\"shotOnTarget\":[3,7,4,4,3,2,6,7,5,2,7,4,2,7,5,5],\"bigChances\":[1,2,4,2,1,1,3,5,3,2,2,2,1,5,3,2],\"goalR\":[1,1,3,2,2,2,2,0,1,1,1,2,2,0,1,1],\"goalKeeperSave\":[1,2,1,2,3,3,5,0,2,0,2,2,3,0,2,2],\"blockedShots\":[0,3,0,2,2,5,2,2,4,7,3,2,5,2,4,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[33,47,39,41,51,45,33,42,62,42,33,47,41,45,42,37],\"shotOnTarget\":[2,2,4,2,3,3,5,3,10,6,2,2,2,3,3,3],\"bigChances\":[3,3,2,0,3,2,4,2,2,3,3,3,0,2,2,1],\"goalR\":[0,1,1,3,1,2,0,1,1,0,0,1,3,2,1,2],\"goalKeeperSave\":[0,1,5,2,2,2,3,4,2,3,0,1,2,2,4,3],\"blockedShots\":[1,3,0,1,8,0,0,0,6,2,1,3,1,0,0,1],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 12:30"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,46,57,47,56,42,58,43,45,42,52,57,56,58,45],\"shotOnTarget\":[8,2,1,2,1,4,5,2,4,5,8,1,1,5,4],\"bigChances\":[3,0,0,2,1,3,2,1,3,1,3,0,1,2,3],\"goalR\":[1,3,2,0,1,2,1,1,3,2,1,2,1,1,3],\"goalKeeperSave\":[0,1,1,7,4,7,2,6,5,8,0,1,4,2,5],\"blockedShots\":[2,7,3,4,1,3,3,1,2,3,2,3,1,3,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[48,44,61,53,50,36,58,44,65,53,48,61,53,36,44],\"shotOnTarget\":[6,3,6,3,7,4,9,4,4,7,6,6,3,4,4],\"bigChances\":[2,1,1,2,1,1,6,1,5,6,2,1,2,1,1],\"goalR\":[0,0,2,0,3,2,3,2,0,0,0,2,0,2,2],\"goalKeeperSave\":[0,3,2,6,1,2,1,6,0,8,0,2,6,2,6],\"blockedShots\":[1,1,1,2,5,1,1,0,3,0,1,1,2,1,0],\"winH2H\":[0]}}",
              "gameId": 11457265,
              "datetime": "02/04/2024 6:0"
          },
          {
              "gameId": 11457267,
              "datetime": "03/04/2024 12:30",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,53,45,41,47,44,54,32,35,50,53,41,44,32,46,48],\"shotOnTarget\":[0,2,4,5,1,4,2,2,0,0,2,5,4,2,2,11],\"bigChances\":[1,2,2,0,2,1,1,0,0,2,2,2,1,1,10],\"goalR\":[4,1,0,3,3,2,2,7,3,3,1,3,2,7,1,3],\"goalKeeperSave\":[1,1,1,5,6,3,6,4,1,4,1,5,3,4,1,3],\"blockedShots\":[1,0,1,2,1,2,3,0,1,3,0,2,2,0,2,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[39,47,49,33,46,32,66,51,60,57,39,49,33,32,54,52],\"shotOnTarget\":[6,6,3,2,10,5,5,3,5,7,6,3,2,5,2,6],\"bigChances\":[1,2,2,3,4,3,1,2,1,2,1,2,3,3,1,0],\"goalR\":[0,0,2,0,0,0,1,1,3,1,0,2,0,0,1,4],\"goalKeeperSave\":[3,2,0,3,2,2,0,0,2,4,3,0,3,2,1,7],\"blockedShots\":[2,4,1,0,2,1,6,3,8,1,2,1,0,1,2,4],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11457269,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[52,43,39,46,45,27,61,38,47,33,52,39,45,61,40],\"shotOnTarget\":[1,1,8,1,3,3,7,3,8,3,1,8,3,7,3],\"bigChances\":[1,4,1,0,1,3,2,1,4,1,4,0,3,0],\"goalR\":[0,1,2,2,0,4,2,0,3,1,0,2,0,2,1,0],\"goalKeeperSave\":[2,2,1,2,5,5,1,10,4,4,2,1,5,1,3],\"blockedShots\":[3,1,1,0,6,3,0,3,6,4,3,1,6,0,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[63,65,59,50,67,73,68,61,67,65,65,50,68,67,60],\"shotOnTarget\":[9,4,5,4,3,9,11,7,11,5,4,4,11,11,5],\"bigChances\":[4,0,2,0,0,6,5,2,4,4,0,0,5,4,5],\"goalR\":[0,2,0,2,1,1,1,1,0,0,2,2,1,0,1,0],\"goalKeeperSave\":[5,2,2,5,1,2,1,5,4,0,2,5,1,4,2],\"blockedShots\":[3,5,1,3,5,10,2,6,2,3,5,3,2,2,5],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 9:0"
          },
          {
              "datetime": "03/04/2024 12:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,65,53,57,42,56,56,59,40,61,54,65,57,56],\"shotOnTarget\":[5,5,3,6,3,8,5,4,5,5,5,5,6,8],\"bigChances\":[3,2,1,2,1,3,3,4,3,3,3,2,2,3],\"goalR\":[0,1,1,2,0,1,1,3,0,1,0,1,2,1],\"goalKeeperSave\":[2,4,1,1,4,3,1,4,5,4,2,4,1,3],\"blockedShots\":[4,0,3,3,0,3,5,7,1,2,4,0,3,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[53,38,57,47,52,44,58,56,53,56,38,47,44,56],\"shotOnTarget\":[10,3,3,4,5,5,4,2,6,8,3,4,5,2],\"bigChances\":[3,5,1,2,1,2,1,2,3,5,1,1,1],\"goalR\":[1,0,0,0,0,0,1,3,0,0,0,0,0,3],\"goalKeeperSave\":[6,1,1,2,3,1,2,1,5,2,1,2,1,1],\"blockedShots\":[3,3,5,1,2,2,2,2,5,6,3,1,2,2],\"winH2H\":[0]}}",
              "gameId": 11457271
          },
          {
              "datetime": "04/04/2024 12:30",
              "gameId": 11457273,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[48,47,35,53,37,61,44,36,50,44,47,53,44,50,57,48],\"shotOnTarget\":[2,2,5,7,3,1,4,10,5,3,2,7,4,5,5,7],\"bigChances\":[2,1,0,2,1,1,5,5,4,2,1,2,5,4,3],\"goalR\":[1,0,1,1,0,1,2,2,1,4,0,1,2,1,0,4],\"goalKeeperSave\":[0,0,4,1,3,4,0,1,2,0,0,1,0,2,3,2],\"blockedShots\":[1,5,1,5,6,1,1,1,4,3,5,5,1,4,4],\"winH2H\":[2]},\"away\":{\"ballPossession\":[31,44,48,55,61,38,43,39,39,44,31,48,61,43,43,52],\"shotOnTarget\":[1,3,1,1,3,0,6,6,2,2,1,1,3,6,3,6],\"bigChances\":[1,0,2,4,0,1,3,1,0,1,2,4,1,0],\"goalR\":[3,0,3,0,4,1,0,0,3,4,3,3,4,0,2,5],\"goalKeeperSave\":[7,4,5,4,4,3,3,1,4,2,7,5,4,3,3,2],\"blockedShots\":[1,3,6,1,2,1,5,1,0,1,1,6,2,5,3],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,72,65,50,44,62,50,60,41,52,58,65,50,62,60,40,58,49,51,50,50],\"shotOnTarget\":[7,3,4,4,4,8,7,7,5,3,7,4,4,8,7,4,1,5,5,2,1],\"bigChances\":[2,4,3,2,2,4,2,4,0,1,2,3,2,4,4,1,0,2,0],\"goalR\":[1,2,1,1,3,0,2,2,1,2,1,1,1,0,2,2,3,2,2,1,0,0,0,4,2],\"goalKeeperSave\":[1,0,3,3,1,0,3,2,4,3,1,3,3,0,2,0,0,4,3,3,4],\"blockedShots\":[4,8,7,8,0,0,1,1,2,7,4,7,8,0,1,3,5,4,4],\"winH2H\":[3]},\"away\":{\"ballPossession\":[68,50,50,51,42,60,57,53,53,65,50,42,60,57,53,60,42,51,49,50,50],\"shotOnTarget\":[5,4,5,2,2,7,8,4,7,1,4,2,7,8,7,2,3,6,4,4,4],\"bigChances\":[1,4,0,1,2,0,3,4,4,1,4,2,0,3,4,1,3,2,2],\"goalR\":[1,1,2,2,2,1,0,1,1,1,1,2,1,0,1,3,0,1,2,1,0,1,2,0,1],\"goalKeeperSave\":[1,3,5,2,2,1,1,2,3,0,3,2,1,1,3,1,1,3,3,1,1],\"blockedShots\":[4,3,4,2,2,7,7,0,7,8,3,2,7,7,7,2,0,3,2],\"winH2H\":[4]}}",
              "gameId": 11471907,
              "datetime": "02/04/2024 11:30"
          },
          {
              "datetime": "02/04/2024 9:30",
              "gameId": 11471909,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[52,50,56,50,38,63,55,44,73,63,56,50,63,73,26,54,30,34],\"shotOnTarget\":[3,2,4,4,8,6,6,4,6,1,4,4,6,6,2,8,8,3],\"bigChances\":[0,4,2,2,2,5,2,5,2,3,2,2,5,2,1,2,3],\"goalR\":[2,1,1,0,1,1,0,0,0,1,1,0,1,0,4,1,1,1],\"goalKeeperSave\":[2,3,3,4,5,5,4,1,1,4,3,4,5,1,1,1,2,2],\"blockedShots\":[4,3,2,0,6,3,3,0,4,4,2,0,3,4,2,2,2,4],\"winH2H\":[2]},\"away\":{\"ballPossession\":[35,49,59,42,68,50,72,69,56,72,35,49,42,50,74,46,70,66],\"shotOnTarget\":[4,4,4,6,5,5,5,4,6,4,4,4,6,5,5,2,3,3],\"bigChances\":[2,0,2,2,6,2,4,0,5,3,2,0,2,2,3,1,1],\"goalR\":[1,0,2,0,0,0,0,1,0,0,1,0,0,0,1,2,1,2],\"goalKeeperSave\":[7,2,3,3,5,0,2,1,4,0,7,2,3,0,1,6,6,1],\"blockedShots\":[0,1,5,2,4,6,4,6,5,7,0,1,2,6,3,5,6,3],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11471911,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[27,59,58,54,50,32,41,45,61,41,27,58,50,41,61,43,54,51,54],\"shotOnTarget\":[6,3,11,1,4,2,3,2,7,4,6,11,4,3,7,3,4,2,2,2,6],\"bigChances\":[1,2,7,0,3,2,2,0,2,1,1,7,3,2,2,1,1,1,2],\"goalR\":[2,2,1,1,0,1,1,3,1,3,2,1,0,1,1,4,3,3,2,2,0,1,1,3,2],\"goalKeeperSave\":[4,1,1,1,2,4,4,3,1,0,4,1,2,4,1,4,4,3,2,5,7],\"blockedShots\":[1,1,7,3,5,4,4,4,5,1,1,7,5,4,5,3,1,3,2],\"winH2H\":[2]},\"away\":{\"ballPossession\":[61,54,67,63,52,55,37,58,58,54,61,54,63,52,37,57,46,49,46],\"shotOnTarget\":[5,10,6,6,5,6,6,4,5,11,5,10,6,5,6,8,7,6,4,7,7],\"bigChances\":[4,7,4,3,6,5,1,1,1,4,4,7,3,6,1,6,4,3,1],\"goalR\":[1,0,0,0,0,0,0,1,4,1,1,0,0,0,0,0,0,0,0,1,1,2,0,0,1],\"goalKeeperSave\":[1,1,1,2,2,2,6,1,3,2,1,1,2,2,6,3,4,2,2,1,5],\"blockedShots\":[4,7,7,5,0,7,3,7,1,4,4,7,5,0,3,2,6,4,0],\"winH2H\":[8]}}",
              "datetime": "03/04/2024 9:55"
          },
          {
              "datetime": "03/04/2024 11:30",
              "gameId": 11471913,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,28,46,50,29,37,36,44,62,39,28,46,50,37,44,62,54,44],\"shotOnTarget\":[4,2,2,1,4,2,5,6,3,3,2,2,1,2,6,2,6,5,3,5],\"bigChances\":[3,0,2,1,2,1,1,2,3,0,2,1,1,2,1,3,1],\"goalR\":[2,0,0,3,0,3,1,3,2,0,0,0,3,3,3,1,1,0,1,4,0,2,1,2,0],\"goalKeeperSave\":[3,3,1,3,9,3,2,6,7,5,3,1,3,3,6,1,2,2,1,3],\"blockedShots\":[2,2,4,5,0,0,2,2,5,1,2,4,5,0,2,3,3,8],\"winH2H\":[5]},\"away\":{\"ballPossession\":[55,53,55,36,54,53,64,39,45,43,55,55,36,53,39,38,46,56],\"shotOnTarget\":[2,9,2,2,8,6,3,3,4,1,2,2,2,6,3,3,3,2,2,7],\"bigChances\":[2,3,1,0,2,4,0,1,2,2,2,1,0,4,1,1,1,2],\"goalR\":[1,0,0,1,1,1,1,1,1,2,1,0,1,1,1,0,1,2,1,1,2,4,3,1,1],\"goalKeeperSave\":[2,2,2,8,1,3,4,6,5,6,2,2,8,3,6,2,5,3,2,4],\"blockedShots\":[6,5,4,4,6,8,7,0,6,4,6,4,4,8,0,2,8,3],\"winH2H\":[3]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[42,37,43,42,48,40,33,47,48,55,37,43,48,40,45,69,65],\"shotOnTarget\":[2,5,5,2,4,5,1,4,2,5,5,5,4,5,2,3,10],\"bigChances\":[1,4,1,1,5,1,0,3,3,3,4,1,5,1,1,1,3],\"goalR\":[0,2,1,3,0,1,2,2,4,0,2,1,0,1,0,0,1,0],\"goalKeeperSave\":[8,4,3,6,3,2,4,4,4,4,4,3,3,2,5,1,0],\"blockedShots\":[1,0,3,2,2,4,0,3,0,3,0,3,2,4,1,2,12],\"winH2H\":[0]},\"away\":{\"ballPossession\":[42,51,48,52,56,59,56,47,50,51,51,56,47,51,55,31,35],\"shotOnTarget\":[4,8,2,8,9,3,1,3,0,6,8,9,3,6,5,1,1],\"bigChances\":[1,5,1,1,2,2,0,1,0,4,5,2,1,4,0,1,0],\"goalR\":[3,1,4,1,1,0,4,4,0,3,1,1,4,3,0,0,0,0],\"goalKeeperSave\":[5,4,1,1,5,4,0,0,5,2,4,5,0,2,2,3,10],\"blockedShots\":[3,6,0,3,7,0,6,2,6,0,6,7,2,0,6,2,3],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 9:30",
              "gameId": 11471915
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[41,47,46,46,49,54,49,45,40,49,41,46,49,49,40,53,64,70,56],\"shotOnTarget\":[3,3,1,2,5,7,1,4,3,3,3,1,5,1,3,4,3,7,8],\"bigChances\":[2,2,1,0,4,2,0,1,1,1,2,1,4,0,1,0,3,5],\"goalR\":[1,2,2,1,3,0,4,1,0,2,1,2,3,4,0,1,4,1,1,1,0,1,0,1,0],\"goalKeeperSave\":[2,2,9,7,5,3,3,4,7,3,2,9,5,3,7,3,4,0,7],\"blockedShots\":[4,2,0,3,1,2,3,1,2,0,4,0,1,3,2,6,4,4],\"winH2H\":[3]},\"away\":{\"ballPossession\":[35,40,39,29,46,45,38,46,27,47,35,40,29,45,27,47,36,30,44],\"shotOnTarget\":[4,2,4,1,2,4,9,7,1,4,4,2,1,4,1,6,8,0,8],\"bigChances\":[2,1,2,1,1,0,3,3,1,0,2,1,1,0,1,4,4,0],\"goalR\":[2,0,1,3,2,1,0,0,2,2,2,0,3,1,2,0,2,1,1,2,3,3,0,1,0],\"goalKeeperSave\":[2,2,1,7,5,4,3,2,4,4,2,2,7,4,4,4,1,5,7],\"blockedShots\":[2,1,4,2,5,4,0,4,0,1,2,1,2,4,0,7,2,3],\"winH2H\":[2]}}",
              "datetime": "04/04/2024 10:30",
              "gameId": 11471917
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[62,65,70,70,48,62,61,56,64,51,65,70,61,51],\"shotOnTarget\":[11,8,8,6,5,7,12,7,8,5,8,8,12,5],\"bigChances\":[2,3,2,3,1,1,4,5,3,4,3,2,4,4],\"goalR\":[1,1,1,1,1,0,1,1,2,1,1,1,1,1],\"goalKeeperSave\":[0,3,0,3,2,0,1,3,2,3,3,0,1,3],\"blockedShots\":[5,7,5,7,3,4,5,3,2,1,7,5,5,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[51,50,52,59,38,51,63,61,53,37,50,59,51,53],\"shotOnTarget\":[4,4,3,5,1,7,6,5,2,5,4,5,7,2],\"bigChances\":[2,1,3,3,1,4,4,1,3,1,3,4,1],\"goalR\":[0,2,1,3,2,0,1,1,4,0,2,3,0,4],\"goalKeeperSave\":[1,2,1,4,8,1,1,2,0,1,2,4,1,0],\"blockedShots\":[1,4,4,3,3,4,6,6,6,1,4,3,4,6],\"winH2H\":[0]}}",
              "gameId": 11471919,
              "datetime": "03/04/2024 12:0"
          },
          {
              "gameId": 11471921,
              "datetime": "02/04/2024 12:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[37,51,39,62,61,41,33,40,30,51,37,39,62,41,30],\"shotOnTarget\":[6,8,2,2,2,8,1,4,4,5,6,2,2,8,4],\"bigChances\":[2,1,2,0,1,7,0,2,2,2,2,2,0,7,2],\"goalR\":[2,6,2,2,2,1,0,2,2,1,2,2,2,1,2],\"goalKeeperSave\":[2,4,2,4,3,4,6,5,4,2,2,2,4,4,4],\"blockedShots\":[1,0,2,0,3,0,0,6,2,4,1,2,0,0,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[35,50,32,39,36,45,60,48,38,67,35,32,36,48,38],\"shotOnTarget\":[1,2,1,4,3,2,2,2,0,6,1,1,3,2,0],\"bigChances\":[0,2,0,1,2,0,1,1,0,1,0,0,2,1,0],\"goalR\":[1,0,0,1,3,0,1,1,3,1,1,0,3,1,3],\"goalKeeperSave\":[6,4,1,3,8,2,1,2,5,0,6,1,8,2,5],\"blockedShots\":[1,1,1,4,1,1,0,0,1,2,1,1,1,0,1],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11475102,
              "datetime": "03/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,62,56,51,55,49,67,55,50,63,44,56,55,67,50,63,62,60,62,68,66,58],\"shotOnTarget\":[3,8,4,3,6,5,6,3,0,9,3,4,6,6,0,9,5,4,4,2,2,3],\"bigChances\":[],\"goalR\":[1,1,0,0,0,1,0,1,1,0,1,0,0,0,1,0,0,1,3,0,1,0,1,0,1,0],\"goalKeeperSave\":[2,2,4,7,2,4,1,4,3,1,2,4,2,1,3,1,1,1,3,1,3,1],\"blockedShots\":[3,7,5,1,5,4,13,2,6,9,3,5,5,13,6,9,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[39,54,72,68,56,69,53,67,54,52,39,72,56,53,54,52,38,40,38,32,34,42],\"shotOnTarget\":[5,7,4,5,3,1,4,5,5,0,5,4,3,4,5,0,1,2,6,1,4,1],\"bigChances\":[],\"goalR\":[3,0,0,0,1,2,2,2,1,0,3,0,1,2,1,0,1,1,1,0,1,2,4,0,0,2],\"goalKeeperSave\":[3,3,2,3,4,7,7,3,5,3,3,2,4,7,5,3,4,3,3,2,1,0],\"blockedShots\":[0,2,11,0,2,3,0,4,1,0,0,11,2,0,1,0,2],\"winH2H\":[2]}}"
          },
          {
              "datetime": "02/04/2024 11:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,68,32,52,42,47,48,48,32,58,68,52,47,48,32,58,53,63,65,52,54],\"shotOnTarget\":[6,5,3,5,2,2,5,2,0,5,5,5,2,2,0,5,3,3,5,9,3],\"bigChances\":[],\"goalR\":[3,2,2,2,1,0,1,0,2,0,2,2,0,0,2,0,0,0,1,1,1,0,1,2,0,1],\"goalKeeperSave\":[4,2,3,2,1,1,2,2,5,0,2,2,1,2,5,0,4,5,3,1,1],\"blockedShots\":[2,2,5,5,0,1,3,2,0,2,2,5,1,2,0,2,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[30,34,49,48,58,37,38,37,51,39,30,34,48,37,37,51,47,37,35,48,46],\"shotOnTarget\":[1,2,3,6,2,1,3,3,1,5,1,2,6,1,3,1,3,6,4,2,1],\"bigChances\":[],\"goalR\":[2,1,0,0,1,4,2,2,0,1,2,1,0,4,2,0,2,0,1,2,3,0,3,1,0,1],\"goalKeeperSave\":[9,5,2,6,2,9,7,5,7,3,9,5,6,9,5,7,2,2,3,5,3],\"blockedShots\":[2,1,1,1,2,0,2,5,2,1,2,1,1,0,5,2,3],\"winH2H\":[1]}}",
              "gameId": 11475104
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[38,28,24,52,44,44,38,43,49,42,28,52,44,43,38,38],\"shotOnTarget\":[7,2,0,6,1,3,0,6,7,0,2,6,3,6,0,4],\"bigChances\":[],\"goalR\":[1,1,1,2,3,5,0,1,1,1,1,2,5,1,0,1],\"goalKeeperSave\":[9,5,10,4,1,0,3,2,2,4,5,4,0,2,2,5],\"blockedShots\":[4,3,0,3,3,0,2,0,1,2,3,3,0,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[49,56,53,33,44,36,50,54,61,45,49,53,44,50,62,62],\"shotOnTarget\":[5,4,1,2,4,0,1,4,3,4,5,1,4,1,2,6],\"bigChances\":[],\"goalR\":[1,0,1,0,0,0,1,0,3,2,1,1,0,1,0,1],\"goalKeeperSave\":[3,1,1,2,4,2,5,1,2,3,3,1,4,5,0,4],\"blockedShots\":[3,1,1,0,3,1,5,5,5,1,3,1,3,5],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 9:0",
              "gameId": 11475112
          },
          {
              "gameId": 11475113,
              "datetime": "03/04/2024 6:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[37,34,46,42,51,51,42,47,62,52,34,42,51,47,62],\"shotOnTarget\":[10,8,3,5,2,4,2,2,3,2,8,5,4,2,3],\"bigChances\":[],\"goalR\":[1,0,4,2,1,3,0,0,0,2,0,2,3,0,0],\"goalKeeperSave\":[5,2,3,3,2,2,0,4,0,1,2,3,2,4,0],\"blockedShots\":[3,0,6,1,0,3,2,1,4,0,0,1,3,1,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[61,38,66,55,55,58,56,52,42,45,38,55,56,42,45],\"shotOnTarget\":[6,0,6,4,4,0,5,3,1,2,0,4,5,1,2],\"bigChances\":[],\"goalR\":[4,1,2,3,1,0,2,0,3,3,1,3,2,3,3],\"goalKeeperSave\":[1,2,0,4,1,2,1,5,13,3,2,4,1,13,3],\"blockedShots\":[2,2,3,5,8,7,3,2,1,0,2,5,3,1,0],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[56,45,47,68,47,30,63,50,45,58,56,47,47,63,50,58,62,55],\"shotOnTarget\":[6,3,5,1,9,1,7,5,7,4,6,5,9,7,5,4,6,3],\"bigChances\":[],\"goalR\":[0,1,0,0,2,1,1,0,1,2,0,0,2,1,0,2,1,1,1,2,0,0,0,2,0],\"goalKeeperSave\":[2,2,0,3,2,5,2,1,2,3,2,0,2,2,1,3,7,2],\"blockedShots\":[4,3,6,2,3,1,10,4,5,1,4,6,3,10,4,1,3],\"winH2H\":[6]},\"away\":{\"ballPossession\":[62,32,30,38,32,42,38,41,39,35,32,38,42,41,39,35,38,45],\"shotOnTarget\":[8,4,2,1,3,2,0,0,4,6,4,1,2,0,4,6,7,3],\"bigChances\":[],\"goalR\":[3,1,3,2,0,0,0,6,0,2,1,2,0,6,0,2,2,1,0,2,2,3,1,4,1],\"goalKeeperSave\":[4,4,6,5,1,4,3,8,2,2,4,5,4,8,2,2,4,2],\"blockedShots\":[3,2,1,0,0,2,2,2,3,1,2,0,2,2,3,1,3],\"winH2H\":[1]}}",
              "gameId": 11475116,
              "datetime": "04/04/2024 11:30"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[56,62,46,40,61,39,48,62,60,56,46,40,62,55,57],\"shotOnTarget\":[3,3,6,3,4,5,5,5,5,3,6,3,5,5,11,3],\"bigChances\":[],\"goalR\":[0,0,1,0,2,0,1,3,0,1,0,1,0,3,0,2,1,0],\"goalKeeperSave\":[1,0,4,8,3,4,2,7,0,1,4,8,7,2,4],\"blockedShots\":[3,2,6,0,4,5,1,4,1,3,6,0,4],\"winH2H\":[3]},\"away\":{\"ballPossession\":[32,42,47,45,37,53,45,57,61,47,42,45,53,57,47,45,43],\"shotOnTarget\":[4,1,1,2,3,4,7,3,2,1,1,2,4,3,1,2,1,4],\"bigChances\":[],\"goalR\":[1,4,0,1,2,1,5,2,2,1,4,1,1,2,1,5,5,1],\"goalKeeperSave\":[7,5,4,3,4,1,2,4,3,2,5,3,1,4,2,8,2],\"blockedShots\":[3,4,0,2,2,1,3,3,6,0,4,2,1,3,0],\"winH2H\":[0]}}",
              "datetime": "04/04/2024 6:30",
              "gameId": 11475129
          },
          {
              "gameId": 11475137,
              "datetime": "04/04/2024 9:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[47,44,53,44,37,58,33,25,49,46,47,44,58,25,49,53,48,60,71,60,62,52,61,52],\"shotOnTarget\":[4,2,4,6,1,4,4,2,7,1,4,6,4,2,7,2,5,4,8,4,4,3,7,7],\"bigChances\":[],\"goalR\":[3,2,1,0,4,1,1,1,0,1,3,0,1,1,0,1,1,0,0,1,1,2,1,1,4],\"goalKeeperSave\":[1,4,1,6,2,1,5,7,1,3,1,6,1,7,1,3,1,2,2,2,4,2,4,0],\"blockedShots\":[1,4,4,3,1,4,0,1,0,5,1,3,4,1,0,4],\"winH2H\":[5]},\"away\":{\"ballPossession\":[63,44,55,59,48,43,62,55,56,64,44,55,43,55,64,47,52,40,29,40,38,48,39,48],\"shotOnTarget\":[6,1,7,13,3,3,9,5,10,7,1,7,3,5,7,4,2,2,2,3,5,3,5,4],\"bigChances\":[],\"goalR\":[1,1,3,0,0,1,1,0,0,2,1,3,1,0,2,1,1,3,2,1,2,3,1,2,3],\"goalKeeperSave\":[0,2,4,0,0,2,4,4,2,2,2,4,2,4,2,1,4,1,6,3,3,1,6,4],\"blockedShots\":[9,3,4,1,4,3,5,3,0,4,3,4,3,3,4,3],\"winH2H\":[1]}}"
          },
          {
              "datetime": "01/04/2024 6:0",
              "gameId": 11481774,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,1,1,1,2,1,0,2,1,1,1,1,2,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,0,0,2,3,1,2,2,0,0,0,2,1,2,0,9],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11481775,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,0,2,1,1,2,2,1,1,0,0,1,1,1,0,0,0,0,2,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,2,4,1,0,2,0,1,1,1,2,1,2,1,3,0,0,0,0,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 6:0"
          },
          {
              "gameId": 11495575,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,0,0,1,1,1,2,1,1,2,0,0,1,1,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,2,1,0,1,2,5,0,2,2,1,1,5,2,3,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,1,1,2,0,2,3,0,0,5,1,2,2,0,5,1,0,2,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "gameId": 11507978,
              "datetime": "02/04/2024 12:0"
          },
          {
              "gameId": 11507980,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,6,2,0,0,2,0,4,2,1,1,0,0,0,2,1,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[4]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,1,5,2,0,1,2,1,0,2,3,5,0,2,0,2,3,3,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 12:0"
          },
          {
              "gameId": 11507982,
              "datetime": "02/04/2024 12:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,2,1,2,1,0,2,2,1,1,2,2,0,1,0,0,1,0,1,3,2,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[8]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[5,0,0,1,1,2,2,1,0,1,5,0,1,2,0,3,2,6,0,2,4,3,2,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "gameId": 11507984,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,3,2,0,0,1,1,1,3,2,3,0,1,1,2,0,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[3]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,4,2,1,2,5,3,4,3,3,2,2,3,3,3,4,5],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 12:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "gameId": 11887798,
              "datetime": "03/04/2024 13:0"
          },
          {
              "datetime": "01/04/2024 9:30",
              "gameId": 11912280,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[41,51,43,55,47,50,46,52,45,47,41,43,47,46,45,60,37,60,51,29,54,62,50,58],\"shotOnTarget\":[7,8,10,4,7,11,11,3,11,6,7,10,7,11,11,6,3,12,4,7,4,9,10,5],\"bigChances\":[2,1,6,1,2,5,4,0,4,2,2,6,2,4,4,2,2,2,1,1,0,3,4,0],\"goalR\":[1,1,0,2,1,2,2,2,0,3,1,0,1,2,0,1,2,1,2,2,0,1,0,1,2],\"goalKeeperSave\":[1,7,2,2,0,2,4,2,4,5,1,2,0,4,4,0,10,0,1,3,2,4,2,2],\"blockedShots\":[4,9,3,5,6,2,1,4,6,4,4,3,6,1,6,9,3,1,1,1,1,4,5,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[62,54,50,57,53,48,51,53,42,54,54,57,48,53,42,40,63,40,49,71,46,38,50,42],\"shotOnTarget\":[4,3,5,5,6,7,5,6,2,4,3,5,7,6,2,2,13,1,3,5,2,5,3,4],\"bigChances\":[0,2,3,3,4,3,2,2,1,0,2,3,3,2,1,2,1,0,4,3,1,2,1,2],\"goalR\":[4,3,1,4,0,6,1,1,1,1,3,4,6,1,1,2,2,4,3,2,0,2,0,4,0],\"goalKeeperSave\":[1,3,3,4,3,2,2,1,3,1,3,4,2,1,3,4,1,9,1,6,4,7,6,5],\"blockedShots\":[2,1,6,2,4,7,5,6,1,7,1,2,7,6,1,1,4,0,4,7,4,1,1,4],\"winH2H\":[1]}}"
          },
          {
              "datetime": "03/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,40,56,59,39,39,58,60,50,49,44,56,59,39,58,44,62,73],\"shotOnTarget\":[5,1,4,3,7,9,5,9,7,3,5,4,3,9,5,5,5,2],\"bigChances\":[2,1,3,0,2,2,1,1,0,2,2,3,0,2,1,2,1,1],\"goalR\":[2,0,1,2,0,1,1,2,1,0,2,1,2,1,1,2,1,0],\"goalKeeperSave\":[5,1,1,4,3,4,1,1,4,3,5,1,4,4,1,5,2,1],\"blockedShots\":[9,3,0,3,3,2,6,9,1,7,9,0,3,2,6,9,3,6],\"winH2H\":[1]},\"away\":{\"ballPossession\":[51,54,49,47,40,40,63,33,34,47,54,49,47,33,47,56,38,27],\"shotOnTarget\":[5,7,4,2,3,3,5,7,4,3,7,4,2,7,3,7,3,1],\"bigChances\":[3,4,4,2,4,1,2,3,0,1,4,4,2,3,1,4,2,0],\"goalR\":[4,4,3,2,1,0,3,1,3,0,4,3,2,1,0,3,1,0],\"goalKeeperSave\":[4,6,2,0,8,3,5,2,8,1,6,2,0,2,1,2,4,2],\"blockedShots\":[10,4,6,6,2,4,1,1,2,3,4,6,6,1,3,3,2,1],\"winH2H\":[0]}}",
              "gameId": 11912289
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[50,61,54,47,55,53,52,62,66,69,61,47,55,53,62,69,55,41,41,61,45,46,46,44,43],\"shotOnTarget\":[4,3,8,2,4,2,5,8,7,2,3,2,4,2,8,2,4,4,2,2,3,5,2,3,2],\"bigChances\":[2,0,3,0,1,4,3,5,2,0,0,1,4,5,1,4,1,0,1,1,1,4,0],\"goalR\":[3,3,0,0,3,1,2,3,1,0,3,0,3,1,3,0,3,0,2,0,2,1,1,0,1,3],\"goalKeeperSave\":[2,4,1,6,7,1,4,2,0,1,4,6,7,1,2,1,7,3,2,1,2,2,1,2,5],\"blockedShots\":[3,3,4,1,2,2,3,3,4,3,3,1,2,2,3,3,2,2,1,3,2,3,1,0,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[54,53,54,43,41,48,50,45,47,46,54,54,41,50,45,46,45,59,59,39,55,54,54,56,57],\"shotOnTarget\":[2,3,3,5,3,4,3,10,4,2,2,3,3,3,10,2,10,5,2,3,3,3,1,3,8],\"bigChances\":[1,1,0,2,1,3,0,7,1,1,1,0,1,0,7,1,7,1,1,1,1,1,1,1,3],\"goalR\":[3,1,3,1,4,0,1,1,1,0,3,3,4,1,1,0,1,0,2,1,0,1,2,1,2,0],\"goalKeeperSave\":[0,3,5,1,5,3,2,3,7,4,0,5,5,2,3,4,3,2,1,2,2,3,1,1,2],\"blockedShots\":[4,2,3,5,0,3,2,4,3,2,4,3,0,2,4,2,4,4,2,1,4,2,5,2,2],\"winH2H\":[3]}}",
              "datetime": "02/04/2024 13:30",
              "gameId": 11912291
          },
          {
              "datetime": "03/04/2024 13:30",
              "gameId": 11912295,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[59,59,34,41,48,37,61,40,48,42,59,59,48,61,48,48],\"shotOnTarget\":[9,6,6,2,6,8,7,3,4,3,9,6,6,7,4,4],\"bigChances\":[0,1,4,0,2,3,5,2,4,1,0,1,2,5,4,4],\"goalR\":[1,0,1,1,2,3,0,0,1,2,1,0,2,0,1,1],\"goalKeeperSave\":[2,2,8,4,3,2,3,4,3,2,2,2,3,3,3,3],\"blockedShots\":[7,6,5,1,2,3,10,2,2,2,7,6,2,10,2,2],\"winH2H\":[1]},\"away\":{\"ballPossession\":[57,58,48,57,62,44,50,59,47,50,57,48,57,44,59,52],\"shotOnTarget\":[3,3,6,2,3,2,4,4,3,3,3,6,2,2,4,4],\"bigChances\":[2,1,2,1,0,2,1,3,2,1,2,2,1,2,3,1],\"goalR\":[2,1,0,4,2,1,5,5,1,1,2,0,4,1,5,3],\"goalKeeperSave\":[2,0,2,5,3,3,7,2,5,2,2,2,5,3,2,1],\"blockedShots\":[4,6,0,6,4,0,3,4,4,3,4,0,6,0,4,2],\"winH2H\":[0]}}"
          },
          {
              "datetime": "01/04/2024 7:15",
              "gameId": 11912296,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[44,42,49,57,50,47,57,52,46,55,44,49,57,47,38],\"shotOnTarget\":[7,1,8,0,5,6,2,6,1,4,7,8,0,6,3],\"bigChances\":[4,1,3,0,2,2,3,4,1,0,4,3,0,2,2],\"goalR\":[2,1,0,3,2,1,2,2,5,4,2,0,3,1,2],\"goalKeeperSave\":[3,2,8,3,2,3,3,4,3,7,3,8,3,3,2],\"blockedShots\":[2,3,1,3,0,0,4,5,4,2,2,1,3,0,6],\"winH2H\":[0]},\"away\":{\"ballPossession\":[52,61,66,61,53,50,65,61,58,52,61,50,61,52,62],\"shotOnTarget\":[8,5,9,2,7,4,7,3,11,6,5,4,3,6,5],\"bigChances\":[5,2,2,2,1,2,2,1,3,4,2,2,1,4,2],\"goalR\":[1,3,1,0,0,1,0,1,1,0,3,1,1,0,2],\"goalKeeperSave\":[6,6,5,4,6,2,3,1,4,4,6,2,1,4,1],\"blockedShots\":[3,1,4,2,3,3,6,0,7,5,1,3,0,5,3],\"winH2H\":[0]}}"
          },
          {
              "datetime": "04/04/2024 13:30",
              "gameId": 11912297,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[45,40,46,38,46,50,48,53,57,59,46,46,50,53,59,52,41,53,43,55,59,45,55,52],\"shotOnTarget\":[1,5,6,5,8,5,6,6,4,5,6,8,5,6,5,3,1,3,7,3,4,4,4,4],\"bigChances\":[0,1,4,3,5,2,2,2,2,1,4,5,2,2,1,3,1,1,4],\"goalR\":[2,2,1,2,0,1,2,0,5,1,1,0,1,0,1,1,5,2,3,1,4,3,1,0,0],\"goalKeeperSave\":[3,4,2,3,3,3,4,3,1,1,2,3,3,3,1,1,7,5,3,0,1,4,3,3],\"blockedShots\":[5,3,3,3,5,4,5,3,2,3,3,5,4,3,3,2,1,3,0,7],\"winH2H\":[3]},\"away\":{\"ballPossession\":[41,51,43,55,47,50,46,52,45,47,51,55,50,52,47,48,59,47,57,45,41,55,45,48],\"shotOnTarget\":[7,8,10,4,7,11,11,3,11,6,8,4,11,3,6,2,12,7,5,1,5,7,4,3],\"bigChances\":[2,1,6,1,2,5,4,0,4,2,1,1,5,0,2,3,9,4,4],\"goalR\":[1,1,0,2,1,2,2,2,0,3,1,2,2,2,3,0,1,1,4,0,1,3,0,1,3],\"goalKeeperSave\":[1,7,2,2,0,2,4,2,4,5,7,2,2,2,5,2,0,2,3,3,3,1,4,3],\"blockedShots\":[4,9,3,5,6,2,1,4,6,4,9,5,2,4,4,1,5,5,3,6],\"winH2H\":[6]}}"
          },
          {
              "gameId": 11912298,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[62,54,50,57,53,48,51,53,42,54,62,50,53,51,50],\"shotOnTarget\":[4,3,5,5,6,7,5,6,2,4,4,5,6,5,5],\"bigChances\":[0,2,3,3,4,3,2,2,1,0,0,3,4,2,3],\"goalR\":[4,3,1,4,0,6,1,1,1,1,4,1,0,1,1],\"goalKeeperSave\":[1,3,3,4,3,2,2,1,3,1,1,3,3,2,3],\"blockedShots\":[2,1,6,2,4,7,5,6,1,7,2,6,4,5,6],\"winH2H\":[1]},\"away\":{\"ballPossession\":[44,42,49,57,50,47,57,52,46,55,42,50,57,55,50],\"shotOnTarget\":[7,1,8,0,5,6,2,6,1,4,1,5,2,4,5],\"bigChances\":[4,1,3,0,2,2,3,4,1,0,1,2,3,0,2],\"goalR\":[2,1,0,3,2,1,2,2,5,4,1,2,2,4,2],\"goalKeeperSave\":[3,2,8,3,2,3,3,4,3,7,2,2,3,7,2],\"blockedShots\":[2,3,1,3,0,0,4,5,4,2,3,0,4,2,0],\"winH2H\":[0]}}",
              "datetime": "04/04/2024 13:30"
          },
          {
              "gameId": 11912306,
              "datetime": "04/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,61,66,61,53,50,65,61,58,52,52,66,61,53,65,65,62,59,45,66,40,57,64,55,52],\"shotOnTarget\":[8,5,9,2,7,4,7,3,11,6,8,9,2,7,7,7,7,7,5,8,9,6,7,5,3],\"bigChances\":[5,2,2,2,1,2,2,1,3,4,5,2,2,1,2,2,3,4,4,2],\"goalR\":[1,3,1,0,0,1,0,1,1,0,1,1,0,0,0,0,0,1,0,0,1,0,1,1,1],\"goalKeeperSave\":[6,6,5,4,6,2,3,1,4,4,6,5,4,6,3,3,1,1,3,0,3,1,1,2,3],\"blockedShots\":[3,1,4,2,3,3,6,0,7,5,3,4,2,3,6,6,5,3,3,12,0,0],\"winH2H\":[6]},\"away\":{\"ballPossession\":[46,43,32,53,47,41,41,43,53,37,43,53,47,41,53,35,38,41,55,34,60,43,36,45,48],\"shotOnTarget\":[3,6,4,1,3,7,2,6,7,4,6,1,3,2,7,3,1,2,3,0,4,1,2,3,4],\"bigChances\":[1,3,1,1,0,1,1,3,1,1,3,1,0,1,1,3,0,1,2,0],\"goalR\":[1,0,1,3,2,2,2,0,0,1,0,3,2,2,0,1,2,1,3,0,6,2,3,1,0],\"goalKeeperSave\":[3,0,4,4,3,2,4,4,4,4,0,4,3,4,4,6,5,6,2,8,3,4,4,4,3],\"blockedShots\":[0,6,4,3,0,2,2,1,5,4,6,3,0,2,5,3,0,2,5,1,0,0],\"winH2H\":[1]}}"
          },
          {
              "datetime": "01/04/2024 7:15",
              "gameId": 11912514,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,3,1,1,2,2,2,3,2,1,1,2,2,2,1,3,4,2,3,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,1,1,0,1,5,0,3,1,1,2,1,0,5,1,0,2,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[5]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[48,38,38,54,49,61,48,38,38,54,44,44],\"shotOnTarget\":[6,2,2,2,2,5,4,2,2,2,3,1],\"bigChances\":[],\"goalR\":[2,1,0,1,1,0,0,1,0,1,0,1],\"goalKeeperSave\":[1,2,3,0,2,3,3,2,3,0,4,4],\"blockedShots\":[3,0,1,5,2,8,2,0,1,5,1,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[62,61,62,53,53,62,47,61,62,53,56,56],\"shotOnTarget\":[3,5,9,2,3,4,7,5,9,2,3,5],\"bigChances\":[],\"goalR\":[2,2,2,2,4,1,2,2,2,2,0,1],\"goalKeeperSave\":[0,1,0,2,1,0,1,1,0,2,3,0],\"blockedShots\":[5,1,3,4,1,8,4,1,3,4,4,4],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 11:0",
              "gameId": 11915743
          },
          {
              "gameId": 11915744,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[59,50,46,43,39,50,39,46,56],\"shotOnTarget\":[2,3,1,9,5,3,5,4,2],\"bigChances\":[],\"goalR\":[3,3,0,1,2,3,2,0,0,4,4,0,3,0,1,0,0],\"goalKeeperSave\":[3,3,2,3,3,3,3,4,5],\"blockedShots\":[6,3,2,6,4,3,4,9,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[52,44,45,38,46,45,38,54,44],\"shotOnTarget\":[3,1,3,1,4,3,1,4,5],\"bigChances\":[],\"goalR\":[3,2,3,1,2,3,1,2,1,2,2,2,1,0,0,2,2],\"goalKeeperSave\":[3,4,4,3,5,4,3,2,1],\"blockedShots\":[4,1,1,3,1,1,3,4,1],\"winH2H\":[4]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "gameId": 11915745,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[53,42,51,47,49,51],\"shotOnTarget\":[0,5,3,5,3,3],\"bigChances\":[],\"goalR\":[3,1,2,3,1,2,1,1,0,2,1,2],\"goalKeeperSave\":[3,1,0,1,1,0],\"blockedShots\":[6,1,5,2,8,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[61,62,42,56,47,62],\"shotOnTarget\":[3,3,2,6,4,3],\"bigChances\":[],\"goalR\":[0,1,0,0,0,1,0,0,0,1,2,0],\"goalKeeperSave\":[1,1,5,1,3,1],\"blockedShots\":[3,9,4,2,2,9],\"winH2H\":[4]}}",
              "datetime": "01/04/2024 9:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[38,61,58,58,53,61,54,61,58,53],\"shotOnTarget\":[2,0,4,2,5,5,7,0,2,5],\"bigChances\":[],\"goalR\":[1,1,1,2,0,1,1,1,2,0],\"goalKeeperSave\":[2,4,1,3,1,4,3,4,3,1],\"blockedShots\":[0,3,11,2,4,3,4,3,2,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[39,39,56,47,57,39,51,39,47,39],\"shotOnTarget\":[1,3,3,2,4,3,2,1,2,3],\"bigChances\":[],\"goalR\":[2,3,1,1,1,0,1,2,1,0],\"goalKeeperSave\":[2,2,8,2,8,5,2,2,2,5],\"blockedShots\":[2,1,6,1,2,6,4,2,1,6],\"winH2H\":[0]}}",
              "datetime": "01/04/2024 11:0",
              "gameId": 11915759
          },
          {
              "datetime": "01/04/2024 11:0",
              "gameId": 11915778,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[47,50,62,53,47,55,47,53,58,53],\"shotOnTarget\":[6,4,2,3,1,7,6,3,4,3],\"bigChances\":[],\"goalR\":[0,3,2,1,3,3,0,1,2,2,0,1,4],\"goalKeeperSave\":[0,0,0,1,2,1,0,1,0,2],\"blockedShots\":[2,3,5,2,2,2,2,2,2,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[41,39,44,38,37,52,39,44,42,47],\"shotOnTarget\":[5,5,8,2,2,3,5,8,2,4],\"bigChances\":[],\"goalR\":[1,0,0,2,0,1,0,0,0,2,2,1,1],\"goalKeeperSave\":[2,0,3,7,2,3,0,3,4,1],\"blockedShots\":[6,4,6,2,12,4,4,6,1,4],\"winH2H\":[2]}}"
          },
          {
              "datetime": "01/04/2024 16:0",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[37,33,29,26,36,40,31,54,37,51,33,36,31,54],\"shotOnTarget\":[3,3,3,2,0,1,3,3,1,2,3,0,3,3],\"bigChances\":[],\"goalR\":[1,2,0,2,1,1,1,1,0,4,2,1,1,1,1,0,2,3,1,0],\"goalKeeperSave\":[2,5,4,1,0,5,4,3,3,2,5,0,4,3],\"blockedShots\":[0,0,1,3,3,1,0,4,1,6,0,3,0,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[58,75,50,74,43,70,67,33,58,65,58,50,43,33],\"shotOnTarget\":[3,8,3,3,3,2,1,1,3,3,3,3,3,1],\"bigChances\":[],\"goalR\":[0,2,1,0,0,3,1,2,2,1,0,1,0,2,1,2,1,2,0,0],\"goalKeeperSave\":[1,2,2,2,5,3,1,4,1,2,1,2,5,4],\"blockedShots\":[3,2,3,3,0,3,5,1,3,2,3,3,0,1],\"winH2H\":[3]}}",
              "gameId": 11920146
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,0,2,1,0,1,2,0,2,0,2,2,0,2,2,1,0,3,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[4]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,2,2,0,0,1,0,1,1,0,2,0,1,1,3,1,4,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "gameId": 11927740,
              "datetime": "02/04/2024 18:5"
          },
          {
              "gameId": 11928549,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[28,70,61,57,51,54,70,61,51,67,62],\"shotOnTarget\":[2,4,4,1,6,3,4,4,6,3,6],\"bigChances\":[1,2,2,0,0,0,2,2,0,3,4],\"goalR\":[1,2,5,1,0,2,2,5,0,1,2,0,1,2,1,0,1,0,0],\"goalKeeperSave\":[0,3,4,5,2,3,3,4,2,2,2],\"blockedShots\":[2,4,1,5,3,2,4,1,3,2,4],\"winH2H\":[9]},\"away\":{\"ballPossession\":[34,55,45,46,52,37,34,46,52,33,38],\"shotOnTarget\":[4,4,4,2,6,4,4,2,6,3,4],\"bigChances\":[2,0,1,0,2,0,2,0,2,1,3],\"goalR\":[2,4,1,0,3,0,2,0,3,3,3,2,4,1,2,3,3,6,1],\"goalKeeperSave\":[2,0,1,1,1,1,2,1,1,0,3],\"blockedShots\":[2,4,2,2,6,6,2,2,6,0,0],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 7:0"
          },
          {
              "gameId": 11928556,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[61,30,51,44,54,28,61,51,54,52,52],\"shotOnTarget\":[1,5,7,1,2,1,1,7,2,2,6],\"bigChances\":[3,1,0,1,0,1,1],\"goalR\":[1,0,1,1,0,1,1,1,0,2,0,0,3,3,2,2,2,2,2],\"goalKeeperSave\":[2,5,2,1,2,4,2,2,2,2,3],\"blockedShots\":[5,1,3,8,4,0,5,3,4],\"winH2H\":[4]},\"away\":{\"ballPossession\":[57,47,55,44,38,35,47,55,35,48,48],\"shotOnTarget\":[4,4,2,2,3,2,4,2,2,4,3],\"bigChances\":[2,1,2,0,3,1,1,2,1],\"goalR\":[2,0,2,2,1,3,0,2,3,1,2,1,5,0,0,3,2,0,0],\"goalKeeperSave\":[1,5,3,3,5,5,5,3,5,0,4],\"blockedShots\":[2,2,2,1,3,1,2,2,1],\"winH2H\":[5]}}",
              "datetime": "01/04/2024 13:45"
          },
          {
              "gameId": 11928557,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[39,53,48,54,43,57,63,53,43,57,62,55],\"shotOnTarget\":[3,5,1,6,6,2,1,5,6,2,4,0],\"bigChances\":[0,0,0,2,1,0,2,0],\"goalR\":[0,1,2,3,0,2,0,1,0,2,0,0,1,0,2,3,0,0,1,1],\"goalKeeperSave\":[1,3,2,6,1,1,3,3,1,1,6],\"blockedShots\":[5,5,5,5,3,7,4,5,3,7,3,4],\"winH2H\":[5]},\"away\":{\"ballPossession\":[43,48,58,39,39,48,40,43,39,40,38,45],\"shotOnTarget\":[3,2,2,9,4,4,4,3,9,4,6,1],\"bigChances\":[2,0,0,2,1,2,1,2,2,1,1],\"goalR\":[2,0,1,0,0,1,0,2,0,0,1,0,0,1,1,0,2,0,2,2],\"goalKeeperSave\":[2,3,5,4,4,5,4,2,4,4,3],\"blockedShots\":[3,1,4,4,1,5,1,3,4,1,1,4],\"winH2H\":[3]}}",
              "datetime": "01/04/2024 11:0"
          },
          {
              "gameId": 11928559,
              "datetime": "01/04/2024 11:0",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[55,31,42,56,56,43,46,31,56,46,44,44],\"shotOnTarget\":[2,6,5,2,5,3,4,6,2,4,4,4],\"bigChances\":[1,3,1,1,1,2,3,1,2,1],\"goalR\":[1,1,0,0,0,1,1,1,0,1,1,1,1,0,1,1,1,2,0],\"goalKeeperSave\":[2,4,2,1,2,1,2,4,1,2,4,3],\"blockedShots\":[2,0,2,3,5,3,4,0,3,4,4,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[66,52,52,38,53,62,68,52,38,62,56,56],\"shotOnTarget\":[4,3,4,5,6,6,0,3,5,6,5,5],\"bigChances\":[1,3,1,5,3,1,0,3,5,1,2],\"goalR\":[1,0,1,2,0,2,1,0,2,2,1,0,0,0,0,1,0,0,1],\"goalKeeperSave\":[3,2,0,2,0,1,2,2,2,1,3,4],\"blockedShots\":[4,3,2,8,3,1,2,3,8,1,3,2],\"winH2H\":[5]}}"
          },
          {
              "datetime": "01/04/2024 11:0",
              "gameId": 11928560,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[45,45,49,46,47,49,45,46,40,42],\"shotOnTarget\":[3,5,3,9,0,2,3,9,1,4],\"bigChances\":[0,4,1,2,0,2,0,2],\"goalR\":[1,1,2,1,3,0,1,1,3,4,2,5,0,2,1,1],\"goalKeeperSave\":[1,3,4,5,3,6,1,5,6,3],\"blockedShots\":[2,1,2,4,3,2,2,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[72,69,62,61,72,65,69,61,60,58],\"shotOnTarget\":[1,6,4,4,6,8,6,4,7,8],\"bigChances\":[2,4,4,1,2,4,4,1],\"goalR\":[1,2,2,0,0,1,2,0,1,1,0,1,0,1,0,2],\"goalKeeperSave\":[1,4,3,3,1,1,4,3,1,3],\"blockedShots\":[4,5,4,9,2,3,5,9],\"winH2H\":[6]}}"
          },
          {
              "gameId": 11936664,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[45],\"shotOnTarget\":[4,1,4],\"bigChances\":[],\"goalR\":[1,2,2,3,1,3,1,2,0,1,3,3,2,3,2,0,0,3,0,1,1,2,0],\"goalKeeperSave\":[4,4,4],\"blockedShots\":[1,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[55,51,45,45,52,55,45,45],\"shotOnTarget\":[3,5,7,4,6,5,4],\"bigChances\":[],\"goalR\":[0,2,1,0,0,0,2,1,1,2,0,2,1,1,0,0,3,0,2,2,0,0,2],\"goalKeeperSave\":[5,1,1,1,2,1,1],\"blockedShots\":[],\"winH2H\":[5]}}",
              "datetime": "04/04/2024 15:30"
          },
          {
              "gameId": 11936667,
              "datetime": "03/04/2024 19:40",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[44,47,47],\"shotOnTarget\":[3,3,5,1,2,1,2],\"bigChances\":[],\"goalR\":[0,0,2,2,3,3,1,2,2,0,3,3,2,0,1,0,0,0,0,0,0,2],\"goalKeeperSave\":[3,5,4,8,2,8,2],\"blockedShots\":[5,2,1,2,2],\"winH2H\":[7]},\"away\":{\"ballPossession\":[45,52],\"shotOnTarget\":[2,5,2],\"bigChances\":[],\"goalR\":[1,0,3,0,1,1,2,0,2,0,0,1,0,4,3,1,2,0,2,1,1,0],\"goalKeeperSave\":[2,3,1],\"blockedShots\":[],\"winH2H\":[1]}}"
          },
          {
              "gameId": 11936669,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,55],\"shotOnTarget\":[3,3,5,5],\"bigChances\":[],\"goalR\":[1,2,3,1,1,1,0,0,3,2,1,0,0,1,2,2,0,3,1,2,1,2,3],\"goalKeeperSave\":[2,5,1,1],\"blockedShots\":[1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[45,55,50,46,55,45,55,46],\"shotOnTarget\":[2,6,2,2,3,2,6,2],\"bigChances\":[],\"goalR\":[0,1,1,3,2,1,2,2,3,0,1,1,2,1,1,0,1,1,2,1,1,1,1],\"goalKeeperSave\":[4,1,2,4,1],\"blockedShots\":[],\"winH2H\":[6]}}",
              "datetime": "04/04/2024 18:0"
          },
          {
              "gameId": 11936673,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[1,5,5],\"bigChances\":[],\"goalR\":[2,1,1,2,2,0,0,0,4,1,2,0,0,1,1,0,0,2,0,1,2,2],\"goalKeeperSave\":[3,4,4],\"blockedShots\":[2,2],\"winH2H\":[7]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,2,2,1,0,2,0,1,1,2,1,2,1,2,3,2,1,4,2,1,1,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 15:30"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55],\"shotOnTarget\":[8,3],\"bigChances\":[],\"goalR\":[0,1,2,0,0,0,1,1,3,0,2,0,1,0,1,1,2,0,0,1,0],\"goalKeeperSave\":[5,1],\"blockedShots\":[2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[49,48,54,48,49,48,48],\"shotOnTarget\":[5,8,2,2,2,4,5,2,2,4],\"bigChances\":[],\"goalR\":[0,1,1,0,0,1,2,0,1,0,1,0,2,1,1,1,1,0,1,1,1],\"goalKeeperSave\":[4,7,6,2,1,4,4,6,2,4],\"blockedShots\":[3,2,3],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 17:30",
              "gameId": 11936675
          },
          {
              "gameId": 11937554,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[65,27,46,48,35,34,33,47,37,48,46,48,34,33,37,55,37],\"shotOnTarget\":[1,1,7,9,0,5,4,3,6,8,7,9,5,4,6,3,2],\"bigChances\":[1,1,4,2,0,2,4,4,4,2,0,2,4,2],\"goalR\":[2,1,1,0,1,1,1,0,0,1,1,0,1,1,0,1,0,0],\"goalKeeperSave\":[4,3,3,3,1,3,4,2,0,1,3,3,3,4,0,4,5],\"blockedShots\":[3,2,0,5,1,1,6,3,2,3,0,5,1,6,2,1],\"winH2H\":[1]},\"away\":{\"ballPossession\":[63,41,57,69,51,60,55,57,35,45,41,57,51,60,55,45,63],\"shotOnTarget\":[6,4,4,2,2,3,4,8,4,7,4,4,2,3,4,5,5],\"bigChances\":[3,3,1,1,1,3,1,5,1,6,3,1,1,3,1,1],\"goalR\":[0,0,1,1,2,3,0,1,0,1,0,1,2,3,0,0,0,1],\"goalKeeperSave\":[0,3,4,1,4,3,2,1,5,3,3,4,4,3,2,3,1],\"blockedShots\":[4,0,2,2,0,5,2,9,0,2,0,2,0,5,2,2],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 12:30"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[69,69,76,61,78,70,77,77,78,76,78,70,77,63],\"shotOnTarget\":[2,6,8,1,2,1,6,6,3,8,2,1,6,3],\"bigChances\":[4,2,3,1,3,1,3,1,3,3,1,2],\"goalR\":[0,0,0,0,0,1,0,0,1,0,0,1,0,0,0],\"goalKeeperSave\":[4,1,0,1,1,4,1,2,0,0,1,4,1,4],\"blockedShots\":[1,3,3,2,3,1,3,2,2,3,3,1,3,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[51,53,38,31,54,40,47,40,43,51,38,40,40,37],\"shotOnTarget\":[0,4,2,3,7,5,2,4,6,0,2,5,4,6],\"bigChances\":[0,1,0,0,1,2,1,2,3,0,0,2,2,3],\"goalR\":[2,2,0,1,1,0,2,1,1,2,0,0,1,1,0],\"goalKeeperSave\":[1,3,2,2,1,0,4,0,1,1,2,0,0,2],\"blockedShots\":[0,5,3,3,1,2,1,1,6,0,3,2,1,1],\"winH2H\":[0]}}",
              "gameId": 11937556,
              "datetime": "02/04/2024 12:30"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[53,33,58,41,49,59,67,56,65,61,53,41,59,56,65,60,55,55,68],\"shotOnTarget\":[3,1,2,4,2,2,1,6,5,6,3,4,2,6,5,5,3,6,9],\"bigChances\":[1,1,0,1,1,2,0,2,2,1,1,1,2,2,2,2],\"goalR\":[0,2,1,0,0,0,1,1,1,2,0,0,0,1,1,1,1,2,1,0,3],\"goalKeeperSave\":[4,6,6,4,5,2,2,2,3,2,4,4,2,2,3,3,4,2,2],\"blockedShots\":[1,5,3,1,1,4,4,7,5,3,1,1,4,7,5,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[55,34,41,54,44,38,47,37,38,49,34,44,38,47,38,40,45,45,32],\"shotOnTarget\":[3,2,9,5,5,4,5,2,6,2,2,5,4,5,6,4,5,4,3],\"bigChances\":[0,2,6,1,2,4,4,3,3,1,2,2,4,4,3,2],\"goalR\":[2,3,0,1,0,0,0,0,2,1,3,0,0,0,2,2,0,2,1,0,2],\"goalKeeperSave\":[0,2,0,2,3,4,3,1,2,0,2,3,4,3,2,3,3,4,8],\"blockedShots\":[1,1,3,1,2,2,2,0,0,2,1,2,2,2,0,0],\"winH2H\":[2]}}",
              "datetime": "02/04/2024 12:30",
              "gameId": 11937557
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[46,37,33,42,65,53,58,47,49,51,46,37,33,65,58],\"shotOnTarget\":[2,2,3,3,5,6,7,3,3,2,2,2,3,5,7],\"bigChances\":[0,0,1,1,1,4,2,0,0,0,0,0,1,1,2],\"goalR\":[0,1,2,0,3,1,0,0,1,2,0,1,2,3,0,1,1,1,1,1],\"goalKeeperSave\":[3,3,2,5,4,1,1,1,0,2,3,3,2,4,1],\"blockedShots\":[3,1,1,0,6,1,3,2,3,2,3,1,1,6,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[60,68,58,52,61,43,44,28,44,58,68,52,43,44,58],\"shotOnTarget\":[1,5,6,2,2,2,3,5,4,2,5,2,2,4,2],\"bigChances\":[1,1,3,1,3,1,2,5,1,2,1,1,1,1,2],\"goalR\":[2,3,1,3,2,3,2,1,3,1,3,3,3,3,1,1,0,3,1,1],\"goalKeeperSave\":[2,1,1,1,2,4,3,3,3,3,1,1,4,3,3],\"blockedShots\":[1,5,3,1,2,1,2,0,2,3,5,1,1,2,3],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 12:30",
              "gameId": 11937558
          },
          {
              "datetime": "02/04/2024 12:30",
              "gameId": 11937559,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[49,40,52,36,37,51,67,62,31,65,49,40,37,62,31],\"shotOnTarget\":[5,6,5,2,1,1,5,4,1,2,5,6,1,4,1,3,4],\"bigChances\":[2,2,1,3,0,3,2,3,0,2,2,0,3,0],\"goalR\":[0,2,0,2,0,1,1,1,1,0,0,2,0,1,1,0,0,3,0,0,1],\"goalKeeperSave\":[2,1,1,3,1,3,3,3,5,0,2,1,1,3,5,6,0],\"blockedShots\":[4,6,4,3,0,3,7,4,3,1,4,6,0,4,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[37,38,32,53,31,48,44,57,45,30,37,53,57,45,30],\"shotOnTarget\":[0,4,5,6,1,1,4,2,1,3,0,6,2,1,3,5,1],\"bigChances\":[0,0,5,2,0,0,3,1,1,1,0,2,1,1,1],\"goalR\":[1,0,1,3,2,0,0,0,0,1,1,3,0,0,1,1,0,2,2,1,3],\"goalKeeperSave\":[4,4,4,1,4,5,1,6,2,4,4,1,6,2,4,3,2],\"blockedShots\":[1,3,1,7,1,3,2,0,1,0,1,7,0,1,0],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[37,47,30,47,42,45,39,33,39,52,37,47,45,39,41,52,50],\"shotOnTarget\":[8,5,2,4,5,4,4,2,4,2,8,4,4,4,5,3,3,2,4,3],\"bigChances\":[2,3,0,1,4,1,4,2,1,2,1,1,2,3],\"goalR\":[1,1,4,2,0,0,1,0,1,1,1,2,0,1,1,2,1,0,3,0,1,3,0,0],\"goalKeeperSave\":[3,3,5,4,3,2,1,3,5,2,3,4,2,5,1,1,4,6,4],\"blockedShots\":[5,2,1,1,1,0,7,1,4,1,5,1,0,4,2],\"winH2H\":[6]},\"away\":{\"ballPossession\":[50,52,61,54,31,64,38,54,51,57,52,61,54,64,59,48,50],\"shotOnTarget\":[6,4,5,5,4,3,5,9,1,3,4,5,5,3,2,3,1,4,9,4],\"bigChances\":[3,1,1,2,0,4,3,1,0,1,1,2,0],\"goalR\":[1,2,1,2,2,1,1,3,1,1,2,1,2,1,2,3,3,1,2,1,1,0,1,0],\"goalKeeperSave\":[2,5,2,5,0,2,0,4,1,3,5,2,5,2,3,0,2,2,2],\"blockedShots\":[3,0,7,3,3,3,0,0,3,3,0,7,3,3,6],\"winH2H\":[2]}}",
              "gameId": 11937560,
              "datetime": "03/04/2024 12:30"
          },
          {
              "gameId": 11937561,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[51,45,60,59,35,48,50,51,43,60,45,60,35,48,51,63],\"shotOnTarget\":[2,2,1,0,6,7,1,6,2,3,2,1,6,7,6,4,1,1],\"bigChances\":[2,0,1,0,2,4,1,2,2,0,1,2,4,1,3],\"goalR\":[1,0,1,3,0,3,1,0,1,0,0,1,0,3,0,1,4,0,0],\"goalKeeperSave\":[4,4,3,5,1,1,3,4,2,1,4,3,1,1,4,2],\"blockedShots\":[3,0,4,0,0,3,0,1,2,2,0,4,0,3,1,2],\"winH2H\":[1]},\"away\":{\"ballPossession\":[56,42,48,43,35,41,49,56,50,48,42,35,41,56,50,37],\"shotOnTarget\":[3,3,2,1,7,2,1,1,3,3,3,7,2,1,3,3,4,0],\"bigChances\":[1,1,0,1,0,1,2,2,1,1,1,0,2,2,1],\"goalR\":[0,4,3,0,2,0,1,1,3,0,4,2,0,1,3,1,1,1,0],\"goalKeeperSave\":[2,4,3,1,2,2,0,2,4,2,4,2,2,2,4,3],\"blockedShots\":[2,2,0,1,0,0,3,0,3,2,2,0,0,0,3,2],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 12:30"
          },
          {
              "datetime": "03/04/2024 12:30",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[53,48,60,45,64,53,50,50,47,40,48,60,64,53,50,47,39,50],\"shotOnTarget\":[3,4,0,2,5,1,4,4,1,0,4,0,5,1,4,1,1,6,1],\"bigChances\":[1,1,1,2,2,1,1,1,0,1,1,2,1,1,0],\"goalR\":[1,1,1,0,1,0,0,0,1,2,1,1,1,0,0,1,4,1,1,1,1,0,2,0,2,1],\"goalKeeperSave\":[0,1,4,3,1,2,1,2,2,1,1,4,1,2,1,2,0,3],\"blockedShots\":[3,2,3,3,5,1,0,2,4,3,2,3,5,1,0,4,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[63,70,72,73,42,70,60,57,62,62,63,72,42,70,62,62,61,50],\"shotOnTarget\":[1,5,4,4,4,1,2,3,4,2,1,4,4,1,4,2,4,1,4],\"bigChances\":[1,2,3,1,2,1,1,2,3,2,1,3,2,1,3,2,5],\"goalR\":[0,1,2,0,0,0,0,0,1,3,0,2,0,0,1,3,1,1,1,1,0,0,3,0,0,0],\"goalKeeperSave\":[1,2,3,1,1,4,0,2,0,2,1,3,1,4,0,2,5,1],\"blockedShots\":[3,6,4,4,3,5,3,1,3,2,3,4,3,5,3,2,2],\"winH2H\":[4]}}",
              "gameId": 11937564
          },
          {
              "gameId": 11940191,
              "datetime": "03/04/2024 5:45",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[47,52,38,44,34,45,48,42,52,52,47,38,48,52,57,64,42,58,58,47,57,49,42],\"shotOnTarget\":[10,3,3,3,6,2,5,2,7,3,10,3,5,7,4,7,4,6,2,4,4,6,2],\"bigChances\":[3,3,1,2,2,2,2,1,4,4,3,1,2,4,2,4,1,1,1,2,2,1],\"goalR\":[2,2,0,2,2,3,2,2,2,4,2,0,2,2,0,2,2,1,3,0,1,2,3,3],\"goalKeeperSave\":[1,2,4,4,5,11,8,1,2,6,1,4,8,2,5,3,8,1,0,1,3,3,3],\"blockedShots\":[4,4,2,2,0,3,2,1,7,1,4,2,2,7,4,1,3,7,2,1,1,2,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[58,59,43,44,59,55,59,68,61,62,59,43,44,68,43,36,58,42,42,53,43,51,58],\"shotOnTarget\":[7,7,0,10,6,13,4,3,11,12,7,0,10,3,5,5,9,4,0,2,5,6,5],\"bigChances\":[0,0,1,6,2,9,2,4,1,5,0,1,6,4,2,4,2,2,0,0,2,4],\"goalR\":[2,3,2,1,1,2,2,3,2,1,3,2,1,3,0,2,0,1,1,0,3,1,2,0],\"goalKeeperSave\":[5,5,3,5,4,0,4,3,4,3,5,3,5,3,2,6,3,5,2,1,3,4,2],\"blockedShots\":[5,2,5,8,6,11,7,7,7,11,2,5,8,7,2,4,5,1,0,3,1,5,0],\"winH2H\":[5]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[58,46,43,51,52],\"shotOnTarget\":[12,10,5,8,3],\"bigChances\":[6,5,2],\"goalR\":[1,0,0,0,1,1,2],\"goalKeeperSave\":[1,3,1,5,2],\"blockedShots\":[2,7,2,2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[55,43,57,49,48],\"shotOnTarget\":[5,5,1,5,4],\"bigChances\":[3,2,0],\"goalR\":[0,0,1,2,2,1,1],\"goalKeeperSave\":[2,2,4,6,1],\"blockedShots\":[0,3,5,3],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 14:0",
              "gameId": 11967754
          },
          {
              "gameId": 11967762,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[66,38,66,31,41,56],\"shotOnTarget\":[7,5,7,3,3,6],\"bigChances\":[4,3,4,2,3],\"goalR\":[2,0,2,1,3,1],\"goalKeeperSave\":[6,5,6,2,1,0],\"blockedShots\":[10,5,10,0,3],\"winH2H\":[2]},\"away\":{\"ballPossession\":[58,38,38,69,59,44],\"shotOnTarget\":[8,5,5,3,4,1],\"bigChances\":[4,1,1,4,2],\"goalR\":[1,1,1,2,3,3],\"goalKeeperSave\":[3,4,4,0,0,3],\"blockedShots\":[5,2,2,5,3],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 14:0"
          },
          {
              "datetime": "01/04/2024 13:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[47,46,44,50,50,48,55,50,47,44,50,53],\"shotOnTarget\":[3,3,3,1,0,2,3,1,3,3,0,5],\"bigChances\":[],\"goalR\":[0,0,0,0,0,2,2,0,0,0,0,2],\"goalKeeperSave\":[1,6,3,2,3,3,6,6,1,3,3,1],\"blockedShots\":[0,3,7,2,4,1,7,0,0,7,4,6],\"winH2H\":[0]},\"away\":{\"ballPossession\":[59,44,51,44,46,36,54,56,44,44,36,47],\"shotOnTarget\":[2,6,3,6,2,2,8,2,6,6,2,3],\"bigChances\":[],\"goalR\":[1,0,0,0,0,1,1,0,0,0,1,0],\"goalKeeperSave\":[3,6,5,2,1,5,1,3,6,2,5,5],\"blockedShots\":[3,0,4,3,3,4,1,1,0,3,4,1],\"winH2H\":[1]}}",
              "gameId": 11972550
          },
          {
              "gameId": 11985179,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[50,49,47,40,49,40,44,58,55,33,47,45],\"shotOnTarget\":[3,6,1,5,6,5,3,6,7,3,7,3],\"bigChances\":[1,3,0,1,3,1,0,4,3,2,3],\"goalR\":[1,1,2,2,1,2,0,2,2,0,2,1,1,0,1,1],\"goalKeeperSave\":[3,4,1,2,4,2,4,2,5,0,5,1],\"blockedShots\":[2,1,1,1,1,1,3,4,4,4,2,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[62,29,55,47,29,55,56,42,45,67,53,55],\"shotOnTarget\":[7,2,1,3,2,1,4,5,7,0,7,2],\"bigChances\":[1,3,5,3,2,3,1,2,0],\"goalR\":[1,1,1,2,1,1,0,0,1,2,2,1,0,0,0,0],\"goalKeeperSave\":[2,8,0,3,8,0,3,6,6,1,5,2],\"blockedShots\":[4,4,4,6,4,4,6,2,2,3,4,3],\"winH2H\":[5]}}",
              "datetime": "03/04/2024 5:30"
          },
          {
              "datetime": "03/04/2024 5:30",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[50,49,57,57,50,57,59,65,42,49,52,54],\"shotOnTarget\":[4,6,5,7,4,7,0,1,4,3,6,4],\"bigChances\":[0,4,1,1,0,1,0,1,3,1,3,0],\"goalR\":[1,4,1,1,1,1,1,0,0,0,0,0,4,2,1,3],\"goalKeeperSave\":[2,2,3,2,2,2,6,2,3,6,3,4],\"blockedShots\":[8,5,2,7,8,7,2,1,0,0,2,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[47,39,35,33,39,33,41,35,58,51,48,46],\"shotOnTarget\":[3,2,9,4,2,4,7,2,3,6,3,4],\"bigChances\":[2,0,2,1,0,1,3,1,0,1,1,1],\"goalR\":[1,3,1,1,3,1,1,1,2,2,3,0,2,0,0,1],\"goalKeeperSave\":[1,3,7,3,3,3,0,0,2,1,4,4],\"blockedShots\":[2,4,1,4,4,4,2,7,2,2,2,3],\"winH2H\":[4]}}",
              "gameId": 11985182
          },
          {
              "datetime": "03/04/2024 5:30",
              "gameId": 11985183,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[45,51,50,67,45,51,74,43,62,44,71,36],\"shotOnTarget\":[7,6,2,4,7,6,3,12,5,5,9,3],\"bigChances\":[2,2,0,2,2,2,2,4,3,2,2,1],\"goalR\":[0,2,1,2,0,2,2,0,1,0,1,1,0,1,2,0],\"goalKeeperSave\":[3,4,2,2,3,4,0,7,4,0,1,5],\"blockedShots\":[4,6,1,2,4,6,8,2,2,2,9,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[59,39,39,45,39,39,26,57,38,56,29,64],\"shotOnTarget\":[5,6,7,4,6,7,2,7,5,0,2,6],\"bigChances\":[0,2,7,3,2,7,2,1,0,1,1,1],\"goalR\":[1,0,3,0,0,3,0,5,1,1,2,2,0,1,2,0],\"goalKeeperSave\":[0,1,7,3,1,7,3,7,4,4,7,1],\"blockedShots\":[5,5,2,0,5,2,2,3,1,8,1,4],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,61,53,43,61,53,34,58,34],\"shotOnTarget\":[4,1,3,3,1,3,3,4,6],\"bigChances\":[1,1,2,1,1,2,1,2],\"goalR\":[2,0,0,1,0,0,1,2,1,2,0,2,0,2,2,0],\"goalKeeperSave\":[5,6,1,6,6,1,3,1,7],\"blockedShots\":[3,1,1,1,1,1,3,5,2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[53,59,45,37,53,37,66,42,66],\"shotOnTarget\":[2,5,1,6,2,6,4,3,8],\"bigChances\":[1,2,0,5,1,5,1,0],\"goalR\":[0,3,0,1,0,1,1,2,2,1,2,2,0,1,2,4],\"goalKeeperSave\":[3,3,1,3,3,3,2,2,4],\"blockedShots\":[0,4,1,1,0,1,3,3,3],\"winH2H\":[2]}}",
              "gameId": 11985187,
              "datetime": "03/04/2024 5:30"
          },
          {
              "datetime": "02/04/2024 5:30",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[43,61,50,60,61,50,49,49,55,45,55],\"shotOnTarget\":[2,6,3,4,6,3,8,8,9,5,7],\"bigChances\":[2,2,4,3,2,4,3,4,2,2,2],\"goalR\":[1,1,0,0,1,0,0,1,0,1,0,1,3,1],\"goalKeeperSave\":[5,1,2,5,1,2,0,5,0,4,4],\"blockedShots\":[1,2,3,6,2,3,3,1,5,1,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[41,71,65,63,41,65,51,51,45,55,45],\"shotOnTarget\":[1,10,8,4,1,8,0,6,0,5,4],\"bigChances\":[2,1,3,2,1,0,1,0,2,1],\"goalR\":[0,1,1,4,0,1,2,3,2,3,1,0,2,0],\"goalKeeperSave\":[5,1,8,2,5,8,6,5,7,2,6],\"blockedShots\":[0,7,7,2,0,7,2,3,2,4,1],\"winH2H\":[3]}}",
              "gameId": 11985189
          },
          {
              "gameId": 11985198,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[38,51,43,55,43,46],\"shotOnTarget\":[3,5,4,3,4,4],\"bigChances\":[2,1,0,3,0,0],\"goalR\":[1,3,1,2,1,1,0,1,3,0,0,5,0,2],\"goalKeeperSave\":[6,3,4,2,4,6],\"blockedShots\":[5,3,0,6,0,2],\"winH2H\":[3]},\"away\":{\"ballPossession\":[57,41,61,53,41,54],\"shotOnTarget\":[6,6,10,5,6,7],\"bigChances\":[1,2,4,2,2,2],\"goalR\":[0,2,3,2,2,2,0,1,0,0,1,1,1,2],\"goalKeeperSave\":[2,3,4,1,3,2],\"blockedShots\":[2,2,6,6,2,5],\"winH2H\":[2]}}",
              "datetime": "02/04/2024 5:30"
          },
          {
              "datetime": "02/04/2024 7:0",
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,48,67,60,36,54,67,34,58],\"shotOnTarget\":[1,8,7,5,2,1,7,3,6],\"bigChances\":[],\"goalR\":[0,2,2,0,3,0,2,0,3,4,0,1,0,0,1,0,0],\"goalKeeperSave\":[2,2,0,1,3,2,0,3,3],\"blockedShots\":[1,6,10,6,3,1,10,1,3],\"winH2H\":[1]},\"away\":{\"ballPossession\":[66,65,60,53,68,65,53,66,42],\"shotOnTarget\":[3,3,3,1,7,3,1,3,5],\"bigChances\":[],\"goalR\":[0,1,0,0,2,1,0,0,3,2,0,1,0,0,0,0,1],\"goalKeeperSave\":[2,3,2,1,5,3,1,3,3],\"blockedShots\":[1,4,3,2,5,4,2,3,1],\"winH2H\":[2]}}",
              "gameId": 11989246
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[68,52,53,47,32,68,52,47],\"shotOnTarget\":[8,3,4,8,7,8,3,8],\"bigChances\":[],\"goalR\":[1,3,1,0,3,1,3,0,0,0,0,1,0,0,1,1,0,1],\"goalKeeperSave\":[3,5,6,5,6,3,5,5],\"blockedShots\":[5,3,5,4,3,5,3,4],\"winH2H\":[7]},\"away\":{\"ballPossession\":[34,55,47,39,54,34,55,39],\"shotOnTarget\":[2,5,1,4,4,2,5,4],\"bigChances\":[],\"goalR\":[1,1,1,1,1,1,1,1,4,3,0,2,1,0,2,3,0,4],\"goalKeeperSave\":[2,2,1,3,0,2,2,3],\"blockedShots\":[1,2,1,3,2,1,2,3],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 12:0",
              "gameId": 11989247
          },
          {
              "gameId": 12004579,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[40,51,57,43,46,40,43],\"shotOnTarget\":[1,5,5,4,5,1,4],\"bigChances\":[0,3,4,4,2,0,4],\"goalR\":[2,1,1,4,2,2,4],\"goalKeeperSave\":[2,7,9,2,2,2,2],\"blockedShots\":[5,2,1,9,3,5,9],\"winH2H\":[0]},\"away\":{\"ballPossession\":[32,37,38,38,62,37,38],\"shotOnTarget\":[7,1,5,4,5,1,5],\"bigChances\":[2,0,2,2,2,0,2],\"goalR\":[2,1,2,2,2,1,2],\"goalKeeperSave\":[1,3,1,1,2,3,1],\"blockedShots\":[2,2,2,3,5,2,2],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 5:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[68,72,59,63,72,57,54,63,55,50,47,33,51,50],\"shotOnTarget\":[3,5,8,5,5,5,6,5,4,4,2,5,2,8],\"bigChances\":[1,1,3,2,1,3,3,1,0,1,1],\"goalR\":[1,1,2,2,1,1,2,1,3,2,1,0,2,3,0],\"goalKeeperSave\":[5,3,3,3,3,5,3,4,1,5,7,6,4,5],\"blockedShots\":[4,7,4,6,7,4,2,8,4,6,2,2,0],\"winH2H\":[3]},\"away\":{\"ballPossession\":[60,73,65,56,60,43,46,37,45,50,53,67,49,50],\"shotOnTarget\":[4,8,4,3,4,6,5,5,3,7,7,6,7,8],\"bigChances\":[3,4,1,2,3,3,2,2,4,3,7],\"goalR\":[1,5,1,2,1,0,4,1,1,2,1,2,0,1,2],\"goalKeeperSave\":[0,1,3,1,0,5,2,4,3,2,1,2,2,7],\"blockedShots\":[2,3,2,1,2,4,7,3,4,2,3,1,0],\"winH2H\":[4]}}",
              "gameId": 12004581,
              "datetime": "03/04/2024 5:0"
          },
          {
              "gameId": 12004583,
              "datetime": "03/04/2024 5:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[50,27,56,54,46,50,56,58,52],\"shotOnTarget\":[1,6,0,3,3,1,0,5,3],\"bigChances\":[0,3,0,1,2,0,0],\"goalR\":[2,4,1,2,1,2,1,2,2,1,0,0,1,2,0],\"goalKeeperSave\":[3,4,2,4,2,3,2,2,5],\"blockedShots\":[2,0,8,11,4,2,8,2,0],\"winH2H\":[3]},\"away\":{\"ballPossession\":[65,58,61,62,65,65,58,42,48],\"shotOnTarget\":[8,1,3,3,2,8,1,4,7],\"bigChances\":[4,1,1,1,1,4,1],\"goalR\":[1,1,0,2,1,1,1,2,1,2,0,1,1,0,1],\"goalKeeperSave\":[2,3,3,2,10,2,3,3,2],\"blockedShots\":[3,1,5,4,5,3,1,3,0],\"winH2H\":[2]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,52,44,62,35,52,62,24,35,44,62,48,48],\"shotOnTarget\":[3,2,3,2,11,3,2,4,1,3,9,1,4],\"bigChances\":[3,1,3,1,5,3,1,2,2,1,2,1],\"goalR\":[1,0,0,2,1,1,2,1,0,0,3,1,0,1,3,1,1],\"goalKeeperSave\":[4,2,0,3,1,4,3,0,3,0,1,4,3],\"blockedShots\":[4,5,1,7,4,4,7,1,3,5,3,5,2],\"winH2H\":[4]},\"away\":{\"ballPossession\":[60,52,62,37,54,52,37,76,65,56,38,52,52],\"shotOnTarget\":[5,6,3,3,4,6,3,1,3,0,3,6,3],\"bigChances\":[3,2,2,2,4,2,2,2,1,2,1,3],\"goalR\":[2,1,1,0,0,1,0,1,0,1,1,1,1,2,1,4,1],\"goalKeeperSave\":[0,2,4,3,5,2,3,3,1,2,8,0,3],\"blockedShots\":[7,3,6,1,3,3,1,4,5,4,0,3,1],\"winH2H\":[2]}}",
              "datetime": "03/04/2024 5:0",
              "gameId": 12004584
          },
          {
              "gameId": 12004587,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[65,51,60,66,62,60,66,71,46,68,53,60,34],\"shotOnTarget\":[3,3,1,3,0,1,3,5,5,4,2,8,10],\"bigChances\":[0,3,1,1,1,1,1,4,4,3,2,4,3],\"goalR\":[0,4,1,2,6,1,2,2,2,2,0,0,0,1,3],\"goalKeeperSave\":[2,3,1,0,7,1,0,0,2,2,3,0,3],\"blockedShots\":[3,2,7,1,1,7,1,5,2,3,4,2,7],\"winH2H\":[3]},\"away\":{\"ballPossession\":[56,56,39,38,37,39,38,29,54,32,47,40,66],\"shotOnTarget\":[0,1,3,5,5,3,5,2,4,5,2,0,3],\"bigChances\":[0,0,1,3,2,1,3,1,1,1,0,1,0],\"goalR\":[3,1,1,0,1,1,0,1,2,0,0,3,3,2,1],\"goalKeeperSave\":[2,0,2,2,4,2,2,4,3,3,1,5,8],\"blockedShots\":[2,2,2,3,3,2,3,1,5,0,3,1,3],\"winH2H\":[3]}}",
              "datetime": "03/04/2024 5:30"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[34,44,35,34,38,34,35],\"shotOnTarget\":[5,1,3,2,6,5,3],\"bigChances\":[2,3,2,1,7,2,2],\"goalR\":[1,0,0,1,1,1,0],\"goalKeeperSave\":[5,1,0,2,3,5,0],\"blockedShots\":[7,6,5,4,5,7,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,59,46,50,60,59,50],\"shotOnTarget\":[11,6,10,0,5,6,0],\"bigChances\":[4,2,1,0,2,2,0],\"goalR\":[0,1,0,0,1,1,0],\"goalKeeperSave\":[4,1,2,4,1,1,4],\"blockedShots\":[4,6,12,3,6,6,3],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 5:0",
              "gameId": 12004724
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[66,42,46,40,42,46,55,41],\"shotOnTarget\":[6,5,6,2,5,6,2,5],\"bigChances\":[3,3,3,3,3,3,2,2],\"goalR\":[1,0,1,1,0,1,0,1,1,1,0],\"goalKeeperSave\":[3,1,2,5,1,2,0,3],\"blockedShots\":[3,6,2,3,6,2,3,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[48,49,35,41,48,35,45,59],\"shotOnTarget\":[5,8,4,5,5,4,0,4],\"bigChances\":[3,0,1,6,3,1,0,1],\"goalR\":[1,2,0,3,1,0,1,1,1,4,1],\"goalKeeperSave\":[2,3,4,4,2,4,1,4],\"blockedShots\":[3,3,2,4,3,2,6,2],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 5:0",
              "gameId": 12004727
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[35,28,43,44,28,35,43,43,50,49,46],\"shotOnTarget\":[2,4,10,5,4,2,10,1,5,3,0],\"bigChances\":[3,1,2,1,0,3,2,1,1,0],\"goalR\":[0,0,1,3,2,0,1,0,1,0,2,2],\"goalKeeperSave\":[3,5,4,0,5,3,4,2,5,3],\"blockedShots\":[3,3,4,5,0,3,4,3,1,2,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[44,48,65,44,54,44,65,57,50,51,54],\"shotOnTarget\":[5,2,0,3,3,5,0,0,3,5,5],\"bigChances\":[3,2,1,5,3,3,1,3,1,1],\"goalR\":[0,1,1,1,0,0,1,0,0,1,1,1],\"goalKeeperSave\":[0,5,2,2,3,0,2,5,2,0],\"blockedShots\":[3,6,1,3,3,3,1,7,3,3,0],\"winH2H\":[3]}}",
              "datetime": "03/04/2024 5:0",
              "gameId": 12004729
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[35,49,54,63,62,35,49,59,53,43,29,36,33,38,49,44],\"shotOnTarget\":[3,7,2,3,4,3,7,0,3,2,2,2,2,3,4,2],\"bigChances\":[2,4,0,0,1,2,4,0,1,2,1,3,1],\"goalR\":[2,0,4,2,3,2,0,1,2,2,1,6,1,0,0,0,1],\"goalKeeperSave\":[6,3,6,1,3,6,3,3,4,4,4,2,5,4,2,2],\"blockedShots\":[3,3,2,4,2,3,3,1,0,7,3,7,3,3,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[50,48,55,50,38,50,55,41,47,57,71,64,67,62,51,56],\"shotOnTarget\":[6,2,3,5,12,6,3,4,6,6,5,8,6,3,3,4],\"bigChances\":[3,2,4,1,8,3,4,2,3,4,2,5,1],\"goalR\":[0,1,1,0,1,0,1,0,0,0,0,1,1,1,0,0,1],\"goalKeeperSave\":[1,0,2,0,0,1,2,0,3,2,2,1,1,2,4,1],\"blockedShots\":[3,2,3,0,2,3,3,3,0,3,1,2,4,7,0],\"winH2H\":[5]}}",
              "gameId": 12004730,
              "datetime": "03/04/2024 5:0"
          },
          {
              "datetime": "03/04/2024 5:30",
              "gameId": 12004731,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[40,41,45,56,50,41,45,52,40,35,44,39,35,52,48,51],\"shotOnTarget\":[2,3,3,3,1,3,3,3,3,5,3,6,2,1,3,9],\"bigChances\":[2,2,0,4,0,2,0,1,1,2,3,3],\"goalR\":[2,1,2,1,3,1,2,0,0,2,0,1,1,1,3,4,4],\"goalKeeperSave\":[3,5,1,4,3,5,1,1,3,3,1,1,4,4,2,1],\"blockedShots\":[3,2,7,1,1,2,7,3,2,1,3,4,1,1,0],\"winH2H\":[2]},\"away\":{\"ballPossession\":[57,63,40,57,72,57,40,48,60,65,56,61,65,48,52,49],\"shotOnTarget\":[4,4,3,7,7,4,3,1,4,5,1,2,5,5,4,5],\"bigChances\":[3,2,1,5,6,3,1,0,0,1,1,1],\"goalR\":[2,1,0,4,1,2,0,2,0,1,2,1,1,0,1,3,4],\"goalKeeperSave\":[9,0,1,0,3,9,1,2,3,4,1,5,1,1,2,6],\"blockedShots\":[6,1,2,2,2,6,2,2,4,4,3,4,5,5,0],\"winH2H\":[4]}}"
          },
          {
              "gameId": 12007518,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[43,54,43,33,54,50,56,41,42,42,43,54,50,56,42,51,53,57,42,49,67],\"shotOnTarget\":[2,1,1,2,7,1,5,4,4,6,1,7,1,5,6,5,4,2,6,3,2],\"bigChances\":[1,1,0,2,1,2,5,2,2,2,0,1,2,5,2,3,1,2,4,1],\"goalR\":[2,3,3,0,1,3,1,3,2,0,3,1,3,1,0,1,4,1,1,0,1],\"goalKeeperSave\":[3,1,6,3,1,5,2,2,2,3,6,1,5,2,3,0,6,1,2,5,1],\"blockedShots\":[2,4,6,3,5,1,5,0,5,3,6,5,1,5,3,2,5,4,0,2,4],\"winH2H\":[2]},\"away\":{\"ballPossession\":[44,44,37,52,38,47,55,41,58,51,44,44,38,55,51,49,47,43,58,51,33],\"shotOnTarget\":[1,3,2,5,2,3,0,3,5,3,1,3,2,0,3,1,10,1,3,5,2],\"bigChances\":[0,0,1,3,2,1,1,2,2,0,0,0,2,1,0,0,4,0,2,0],\"goalR\":[0,1,0,0,2,3,1,1,2,1,0,1,2,1,1,3,1,0,4,0,1],\"goalKeeperSave\":[3,2,6,2,1,6,5,2,2,1,3,2,1,5,1,2,3,2,2,3,0],\"blockedShots\":[1,2,5,5,0,5,4,3,6,3,1,2,0,4,3,5,8,3,5,1,4],\"winH2H\":[2]}}",
              "datetime": "04/04/2024 9:0"
          },
          {
              "datetime": "02/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[57,56,59,47,53,66,65,64,61,60,57,56,53,64,61],\"shotOnTarget\":[6,3,3,4,4,1,2,8,6,6,6,3,4,8,6],\"bigChances\":[2,1,4,3,3,1,1,4,1,2,2,1,3,4,1],\"goalR\":[2,0,0,2,0,0,0,1,1,0,2,0,0,1,1],\"goalKeeperSave\":[2,3,3,1,4,3,1,5,5,3,2,3,4,5,5],\"blockedShots\":[4,5,3,4,4,3,0,1,0,4,4,5,4,1,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[42,48,56,48,46,31,42,40,42,41,42,48,48,46,31],\"shotOnTarget\":[1,1,4,2,2,4,3,3,2,0,1,1,2,2,4],\"bigChances\":[1,0,2,1,0,1,1,1,1,1,0,1,0,1],\"goalR\":[3,1,1,0,5,2,1,3,0,1,3,1,0,5,2],\"goalKeeperSave\":[4,5,1,5,2,3,3,3,1,2,4,5,5,2,3],\"blockedShots\":[3,1,5,2,0,3,0,4,1,2,3,1,2,0,3],\"winH2H\":[0]}}",
              "gameId": 12007522
          },
          {
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[50,63,67,58,36,58,52,50,58,47,67,36,50,52,39,47,50],\"shotOnTarget\":[3,6,3,1,3,4,3,1,2,4,3,3,1,8,7,0,4],\"bigChances\":[2,0,0,0,1,3,2,0,0,2,0,1,0,2,2,1,1],\"goalR\":[2,1,1,1,1,1,1,2,1,3,1,1,2,3,1,2,1],\"goalKeeperSave\":[1,1,1,2,3,2,1,5,5,0,1,3,5,4,1,1,3],\"blockedShots\":[1,4,2,4,2,1,4,5,4,3,2,2,5,3,2,3,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[52,58,42,69,48,59,69,52,65,58,52,48,59,48,61,53,50],\"shotOnTarget\":[5,5,2,5,8,1,2,3,1,6,5,8,1,6,2,3,4],\"bigChances\":[2,5,1,2,3,1,1,0,0,4,2,3,1,3,0,1,2],\"goalR\":[2,1,1,1,0,0,0,1,2,0,2,0,0,3,2,0,1],\"goalKeeperSave\":[2,3,1,3,4,2,1,2,1,2,2,4,2,5,5,0,3],\"blockedShots\":[4,0,1,5,0,1,2,3,6,5,4,0,1,4,5,1,1],\"winH2H\":[1]}}",
              "gameId": 12007523
          },
          {
              "gameId": 12007526,
              "datetime": "03/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[41,56,42,59,53,40,48,59,51,59,41,56,59,53,49,64],\"shotOnTarget\":[5,3,4,1,4,3,2,5,3,3,5,3,1,10,5,3],\"bigChances\":[1,3,1,2,3,1,1,3,1,2,1,3,2,5,0,0],\"goalR\":[1,0,2,1,1,1,0,3,1,0,1,0,1,1,0,1],\"goalKeeperSave\":[4,3,2,4,3,3,1,3,0,4,3,1,1,7],\"blockedShots\":[1,3,4,5,5,4,3,4,1,3,1,3,5,5,2,3],\"winH2H\":[2]},\"away\":{\"ballPossession\":[56,50,52,50,60,44,50,58,31,34,52,44,31,47,51,36],\"shotOnTarget\":[3,3,4,1,4,2,10,1,1,3,4,2,1,2,1,7],\"bigChances\":[1,1,2,0,3,0,3,0,0,2,0,0,0,1,3],\"goalR\":[0,1,2,2,2,1,0,0,0,0,2,1,0,3,1,1],\"goalKeeperSave\":[1,2,0,5,1,4,1,2,2,1,0,4,2,7,4,2],\"blockedShots\":[0,4,4,2,4,1,1,7,2,4,4,1,2,2,5,4],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,1,0,0,1,0,0,0,1,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,2,1,2,1,0,0,1,1,1,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "gameId": 12009753,
              "datetime": "03/04/2024 5:0"
          },
          {
              "datetime": "03/04/2024 13:45",
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[65,56,65,56],\"shotOnTarget\":[8,6,8,6],\"bigChances\":[3,4,3,4],\"goalR\":[1,2,1,2],\"goalKeeperSave\":[2,3,2,3],\"blockedShots\":[2,5,2,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[54,60,54,60],\"shotOnTarget\":[9,6,9,6],\"bigChances\":[7,1,7,1],\"goalR\":[3,1,3,1],\"goalKeeperSave\":[7,1,7,1],\"blockedShots\":[8,7,8,7],\"winH2H\":[0]}}",
              "gameId": 12059360
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[32,57,55,55,66,55],\"shotOnTarget\":[5,6,1,1,4,11],\"bigChances\":[2,3,0,0,2,2],\"goalR\":[3,1,1,1,0,4],\"goalKeeperSave\":[5,3,2,2,3,0],\"blockedShots\":[3,0,1,1,4,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[27,29,39,39,34,45],\"shotOnTarget\":[3,4,6,6,3,4],\"bigChances\":[3,2,4,4,0,1],\"goalR\":[2,0,2,2,2,3],\"goalKeeperSave\":[2,1,1,1,2,8],\"blockedShots\":[6,3,5,5,2,1],\"winH2H\":[1]}}",
              "datetime": "04/04/2024 6:35",
              "gameId": 12060246
          },
          {
              "gameId": 12062463,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[49],\"shotOnTarget\":[4],\"bigChances\":[],\"goalR\":[1,1,1,1,1,4],\"goalKeeperSave\":[1],\"blockedShots\":[1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[63],\"shotOnTarget\":[5],\"bigChances\":[],\"goalR\":[2,1,1,2,1,1],\"goalKeeperSave\":[0],\"blockedShots\":[2],\"winH2H\":[1]}}",
              "datetime": "03/04/2024 13:45"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[24,67,53,51,33,61,61,39,40,16,67,51,61,40,40],\"shotOnTarget\":[1,5,3,12,3,7,1,1,3,2,5,12,1,3,2],\"bigChances\":[1,5,1,8,2,3,0,0,2,1,5,8,0,2,1],\"goalR\":[3,0,2,1,2,3,4,3,5,3,0,1,4,5,0,2,1],\"goalKeeperSave\":[13,3,2,1,8,3,3,5,5,7,3,1,3,5,9],\"blockedShots\":[0,1,0,1,1,2,6,4,1,0,1,1,6,1,2],\"winH2H\":[1]},\"away\":{\"ballPossession\":[54,62,60,84,67,69,61,62,43,69,62,67,61,43,60],\"shotOnTarget\":[5,9,3,10,12,5,5,6,2,4,9,12,5,2,12],\"bigChances\":[1,9,3,4,5,2,4,4,1,3,9,5,4,1,4],\"goalR\":[0,2,2,1,0,2,1,0,0,0,2,0,1,0,0,0,2],\"goalKeeperSave\":[1,2,5,1,0,0,1,0,4,0,2,0,1,4,2],\"blockedShots\":[2,7,3,7,3,10,2,6,2,10,7,3,2,2,3],\"winH2H\":[1]}}",
              "datetime": "04/04/2024 11:45",
              "gameId": 12064206
          },
          {
              "datetime": "04/04/2024 13:0",
              "gameId": 12064207,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[59,40,43,47,57,47,48,25,65,47,40,47,47,25,65],\"shotOnTarget\":[1,3,9,3,4,4,3,1,5,4,3,3,4,1,5],\"bigChances\":[1,3,2,1,0,1,2,1,2,1,3,1,1,1,2],\"goalR\":[3,4,0,0,1,1,2,2,1,1,4,0,1,2,1,0],\"goalKeeperSave\":[6,5,2,2,5,2,2,10,1,5,5,2,2,10,1],\"blockedShots\":[4,5,3,1,3,2,3,2,3,6,5,1,2,2,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[43,44,51,38,37,63,47,38,44,38,44,38,47,38,38],\"shotOnTarget\":[6,2,1,4,5,3,2,6,7,2,2,4,2,6,2],\"bigChances\":[3,1,2,2,3,1,2,1,3,3,1,2,2,1,3],\"goalR\":[0,1,0,1,2,5,3,4,0,0,1,1,3,4,0,0],\"goalKeeperSave\":[4,3,3,6,11,1,4,3,4,2,3,6,4,3,2],\"blockedShots\":[8,6,4,4,2,7,4,3,2,4,6,4,4,3,4],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[63,64,56,61,56,65,61,68,73,49,64,56,65,68,49,75,76,69,60,65],\"shotOnTarget\":[13,12,10,6,7,5,6,7,12,4,12,7,5,7,4,7,5,12,9,11],\"bigChances\":[5,4,4,1,4,4,3,3,5,4,4,4,4,3,4,3,5],\"goalR\":[2,0,1,2,1,2,2,1,2,1,0,1,2,1,1,0,1,0,0,1,0],\"goalKeeperSave\":[3,4,2,0,2,1,10,4,5,4,4,2,1,4,4,0,2,2,4,4],\"blockedShots\":[2,4,4,7,9,6,7,4,12,2,4,9,6,4,2,6,11,7],\"winH2H\":[3]},\"away\":{\"ballPossession\":[35,38,38,44,49,40,35,42,60,61,35,38,49,35,42,25,24,31,40,35],\"shotOnTarget\":[2,3,13,6,3,14,2,4,9,7,2,3,3,2,4,1,2,2,6,4],\"bigChances\":[0,0,3,2,5,1,1,2,1,0,0,2,1,1,1,0],\"goalR\":[3,3,0,0,0,1,0,1,3,2,3,3,0,0,1,0,1,0,4,3,6],\"goalKeeperSave\":[8,6,8,6,1,2,5,4,2,1,8,6,1,5,4,6,4,8,4,7],\"blockedShots\":[2,1,4,3,3,3,5,6,2,8,2,1,3,5,6,2,0,3],\"winH2H\":[0]}}",
              "gameId": 12064208,
              "datetime": "04/04/2024 14:0"
          },
          {
              "datetime": "04/04/2024 13:45",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[37,52],\"shotOnTarget\":[5,6],\"bigChances\":[],\"goalR\":[1,2,1],\"goalKeeperSave\":[4,2],\"blockedShots\":[1,6],\"winH2H\":[0]},\"away\":{\"ballPossession\":[52,48],\"shotOnTarget\":[3,3],\"bigChances\":[],\"goalR\":[0,2,1],\"goalKeeperSave\":[1,5],\"blockedShots\":[6],\"winH2H\":[0]}}",
              "gameId": 12071570
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[42,51,51],\"shotOnTarget\":[6,3,5],\"bigChances\":[],\"goalR\":[0,2,3],\"goalKeeperSave\":[3,2,4],\"blockedShots\":[5,1,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[36,59,47],\"shotOnTarget\":[3,6,6],\"bigChances\":[],\"goalR\":[1,2,1],\"goalKeeperSave\":[6,1,8],\"blockedShots\":[1,4,0],\"winH2H\":[0]}}",
              "gameId": 12085630,
              "datetime": "04/04/2024 9:0"
          },
          {
              "gameId": 12111611,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,1,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,2,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 11:0"
          },
          {
              "gameId": 12112794,
              "datetime": "02/04/2024 13:30",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[52],\"shotOnTarget\":[5,3,4,2,5],\"bigChances\":[],\"goalR\":[3,0,1,0,2,3,0],\"goalKeeperSave\":[2,3,4,2],\"blockedShots\":[5,5,2,5,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[53,48,53],\"shotOnTarget\":[12,3,3,5,6,3,3],\"bigChances\":[],\"goalR\":[0,0,1,0,1,0,1],\"goalKeeperSave\":[1,2,2,5,1,2,2],\"blockedShots\":[3,4,4,2,3,4],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[55,43,43,44,39,48,40,48,45,41,55,43,43,48,45,50,51,51,47,36,37,52,53,52],\"shotOnTarget\":[8,5,8,9,4,10,6,3,5,3,8,5,8,10,5,7,2,5,3,4,3,2,3,4],\"bigChances\":[4,4,4,7,3,3,3,2,4,4,4,4,4,3,4,4,1,4,2,3,1,1],\"goalR\":[1,2,0,3,0,2,0,1,1,0,1,2,0,2,1,1,3,2,4,1,1,2,3,2],\"goalKeeperSave\":[2,0,1,3,5,2,5,2,5,3,2,0,1,2,5,7,4,0,5,3,4,4,5,1],\"blockedShots\":[1,4,8,1,1,7,2,3,3,0,1,4,8,7,3,2,1,6,1,1,0,2,6,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[62,60,57,61,55,55,52,56,56,57,60,55,55,52,55,50,49,49,53,64,63,48,47,48],\"shotOnTarget\":[7,2,5,7,6,7,10,6,2,14,2,6,7,10,6,9,7,2,9,4,5,6,8,3],\"bigChances\":[3,2,2,3,4,3,3,3,0,10,2,4,3,3,4,3,5,2,5,1,2,2],\"goalR\":[2,6,0,3,1,0,1,3,0,1,6,1,0,1,2,1,1,3,2,2,1,2,2,2],\"goalKeeperSave\":[2,2,0,3,4,1,4,4,4,5,2,4,1,4,3,6,1,2,1,2,2,0,2,2],\"blockedShots\":[4,5,4,10,7,3,2,6,0,2,5,7,3,2,7,5,7,2,9,9,6,3,3,2],\"winH2H\":[3]}}",
              "gameId": 12113193,
              "datetime": "02/04/2024 3:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[38,42,42,39,51,19,43,46,49,58,42,39,19,46,58,46,53,54,48,54,44,50],\"shotOnTarget\":[5,5,1,7,4,2,3,8,1,6,5,7,2,8,6,2,5,4,5,4,3,4],\"bigChances\":[1,3,0,2,3,2,1,6,0,3,3,2,2,6,3,1,3,3],\"goalR\":[5,4,1,1,3,2,2,3,1,1,4,1,2,3,1,1,1,2,3,2,1,1,2,1],\"goalKeeperSave\":[2,3,2,2,2,8,4,2,1,5,3,2,8,2,5,5,5,1,5,4,0,5],\"blockedShots\":[2,2,0,4,5,1,5,1,3,2,2,4,1,1,2,1,11,2,5,4],\"winH2H\":[1]},\"away\":{\"ballPossession\":[37,44,51,62,43,51,44,55,24,32,44,51,43,44,24,54,47,46,52,46,56,50],\"shotOnTarget\":[2,2,3,4,5,2,4,0,3,0,2,3,5,4,3,7,6,3,8,6,2,5],\"bigChances\":[0,0,0,3,5,1,4,2,0,0,0,5,4,2,2,1,2],\"goalR\":[1,3,1,0,2,0,5,0,2,1,3,1,2,5,2,1,2,2,1,1,1,0,1,0],\"goalKeeperSave\":[0,4,4,4,5,1,2,1,3,5,4,4,5,2,3,1,3,2,4,4,2,4],\"blockedShots\":[3,0,5,3,3,3,3,2,3,0,0,5,3,3,3,1,6,0,1,3],\"winH2H\":[5]}}",
              "datetime": "04/04/2024 13:0",
              "gameId": 12114515
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,4],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "04/04/2024 13:45",
              "gameId": 12118515
          },
          {
              "gameId": 12126025,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,0,2,2,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,1,0,1,1,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]}}",
              "datetime": "03/04/2024 12:0"
          },
          {
              "datetime": "03/04/2024 9:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,0,2,3,1,4],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[58],\"shotOnTarget\":[5],\"bigChances\":[],\"goalR\":[1,0,1,1,1,0,0],\"goalKeeperSave\":[2],\"blockedShots\":[],\"winH2H\":[4]}}",
              "gameId": 12126026
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[65,66,72,64,57,64,52,67,46,70,57,67,70,54,51,56,54,59,57,59,64],\"shotOnTarget\":[3,5,3,2,3,9,3,8,4,10,3,8,10,6,7,5,8,3,1,4,6,8,6],\"bigChances\":[1,1,1,4,2,4,2,5,0,6,2,5,6,4,2,2],\"goalR\":[2,0,0,1,2,2,1,1,2,0,2,1,0,1,2,0,1,0,0,1,1,2,1],\"goalKeeperSave\":[2,2,1,5,1,1,0,1,5,0,1,1,0,0,0,2,3,0,5,3,2,3,0],\"blockedShots\":[3,5,7,2,1,4,4,2,3,5,1,2,5,8,14,2,2,2],\"winH2H\":[8]},\"away\":{\"ballPossession\":[58,55,37,40,48,63,52,41,58,48,55,40,52,46,49,44,46,41,43,41,36],\"shotOnTarget\":[5,3,5,1,6,12,3,4,9,0,3,1,3,1,2,2,4,1,6,4,4,6,1],\"bigChances\":[4,2,3,1,1,3,3,1,1,1,2,1,3,1,1,0],\"goalR\":[3,0,0,1,0,2,3,1,0,2,0,1,3,4,2,1,2,1,1,1,3,4,4],\"goalKeeperSave\":[2,4,8,4,0,1,0,1,2,1,4,4,0,3,5,4,4,1,0,2,3,4,1],\"blockedShots\":[9,3,2,2,4,4,1,9,6,4,3,2,1,5,1,1,1,3],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 11:0",
              "gameId": 12129728
          },
          {
              "gameId": 12129736,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,70,48,59,73,63,39,69,43,52,48,73,39,43,64,62,64,75,64,66],\"shotOnTarget\":[7,12,9,2,10,7,5,2,5,4,9,10,5,5,9,3,7,10,7,5,6],\"bigChances\":[4,4,7,1,8,2,3,5,1,1,7,8,3,1,5,1],\"goalR\":[1,0,0,2,0,1,1,0,1,4,0,0,1,1,0,0,1,0,0,0,0],\"goalKeeperSave\":[2,2,7,2,0,1,4,4,3,7,0,4,4,6,3,1,0,0,0,1],\"blockedShots\":[2,1,3,3,6,2,2,6,1,5,3,6,2,1,1,2,0],\"winH2H\":[7]},\"away\":{\"ballPossession\":[39,46,35,33,36,40,36,67,37,36,35,36,36,37,36,38,36,25,36,34],\"shotOnTarget\":[3,6,3,5,0,7,6,3,3,6,3,0,6,3,6,3,2,0,0,0,1],\"bigChances\":[2,2,2,3,1,3,2,1,0,1,2,1,2,0,2,0],\"goalR\":[0,1,0,2,3,3,3,2,2,0,0,3,3,2,3,1,2,4,3,3,4],\"goalKeeperSave\":[1,1,3,4,2,3,5,1,10,2,3,2,5,10,5,2,4,5,3,2,1],\"blockedShots\":[0,4,2,3,1,1,3,5,4,1,2,1,3,4,3,1,1],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 11:30"
          },
          {
              "datetime": "03/04/2024 13:0",
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,71,74,74,70,41,60,61,54,55,63,74,70,60,54,52,52,54,55,55,60,38,49,63],\"shotOnTarget\":[7,5,5,6,4,2,6,5,7,7,7,6,4,6,7,4,1,5,2,8,2,3,6,6,5],\"bigChances\":[6,3,2,1,3,2,1,2,3,6,6,1,3,1,3,0,1,5],\"goalR\":[0,3,0,0,1,2,0,2,0,0,0,0,1,0,0,2,0,1,1,0,1,1,3,2,0],\"goalKeeperSave\":[3,1,0,3,4,2,1,3,4,2,3,3,4,1,4,3,3,2,1,2,0,3,4,4,2],\"blockedShots\":[5,2,2,3,1,1,5,1,3,1,5,3,1,5,3,0,1,3,0,4],\"winH2H\":[3]},\"away\":{\"ballPossession\":[75,64,72,60,69,66,72,43,59,59,75,72,69,66,43,48,48,46,45,45,40,62,51,37],\"shotOnTarget\":[6,5,7,6,7,5,5,3,4,10,6,7,7,5,3,5,3,3,2,3,1,4,7,8,2],\"bigChances\":[3,2,3,3,1,2,5,1,3,7,3,3,1,2,1,5,1,2],\"goalR\":[1,0,0,0,2,2,0,1,2,0,1,0,2,2,1,1,0,2,1,3,0,1,1,3,0],\"goalKeeperSave\":[1,1,3,1,0,3,3,2,0,3,1,3,0,3,2,3,1,3,1,3,2,2,3,3,4],\"blockedShots\":[5,1,3,6,2,2,3,5,7,1,5,3,2,2,5,3,7,3,6,4],\"winH2H\":[3]}}",
              "gameId": 12129738
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[74,67,42,63,42,63],\"shotOnTarget\":[8,5,7,1,7,1],\"bigChances\":[3,3,5,5],\"goalR\":[0,1,1,0,1,0],\"goalKeeperSave\":[1,2,4,1,4,1],\"blockedShots\":[4,6,1,8,1,8],\"winH2H\":[0]},\"away\":{\"ballPossession\":[61,31],\"shotOnTarget\":[3,4],\"bigChances\":[1,2],\"goalR\":[0,1,0,1,0,1],\"goalKeeperSave\":[3,2],\"blockedShots\":[6,0],\"winH2H\":[0]}}",
              "datetime": "02/04/2024 13:45",
              "gameId": 12145933
          },
          {
              "datetime": "04/04/2024 6:0",
              "gameId": 12149851,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[4,4,1,3,1,4,3,3,4,4,3,1,4,3,4,0,1,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,2,2,0,1,0,2,0,0,2,0,1,0,2,0,0,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[39,56,42,52,35,42,37,52,44,51,56,35,42,52,51,52,39],\"shotOnTarget\":[2,6,4,5,4,5,3,4,2,4,6,4,5,4,4,4,3,3],\"bigChances\":[1,3,2,5,4,5,2,1,2,2,3,4,5,1,2,1,4],\"goalR\":[0,2,1,3,2,0,1,1,1,1,2,2,0,1,1,1,0,1],\"goalKeeperSave\":[6,4,1,5,3,1,3,3,1,1,4,3,1,3,1,3,4,1],\"blockedShots\":[1,4,1,2,0,3,0,4,3,1,4,0,3,4,1,4,2,0],\"winH2H\":[2]},\"away\":{\"ballPossession\":[61,55,55,64,45,53,55,61,54,48,61,55,45,53,61,48,61],\"shotOnTarget\":[9,6,5,5,7,2,6,1,5,4,9,5,7,2,1,4,5,2],\"bigChances\":[1,1,0,2,1,1,6,1,2,1,1,0,1,1,1,1,3],\"goalR\":[2,1,1,3,1,2,1,2,0,0,2,1,1,2,2,0,1,2],\"goalKeeperSave\":[3,6,2,3,1,2,1,5,4,4,3,2,1,2,5,4,2,1],\"blockedShots\":[2,1,5,6,8,3,5,2,1,5,2,5,8,3,2,5,4,3],\"winH2H\":[1]}}",
              "gameId": 12153200,
              "datetime": "23/04/2024 9:0"
          },
          {
              "datetime": "02/04/2024 13:45",
              "gameId": 12155506,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[39,27],\"shotOnTarget\":[3,2],\"bigChances\":[2,1],\"goalR\":[0,1],\"goalKeeperSave\":[2,3],\"blockedShots\":[1,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[27,54],\"shotOnTarget\":[4,8],\"bigChances\":[2,6],\"goalR\":[2,0],\"goalKeeperSave\":[1,2],\"blockedShots\":[1,4],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[77,75,58,65,58,65],\"shotOnTarget\":[18,8,8,6,8,6],\"bigChances\":[10,2,4,5,4,5],\"goalR\":[0,1,1,1,1,1],\"goalKeeperSave\":[5,3,2,3,2,3],\"blockedShots\":[3,3,3,5,3,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[59,47,58,50,59,58],\"shotOnTarget\":[3,7,13,6,3,13],\"bigChances\":[5,3,5,7,5,5],\"goalR\":[0,1,1,1,0,1],\"goalKeeperSave\":[3,1,0,2,3,0],\"blockedShots\":[3,3,3,3,3,3],\"winH2H\":[0]}}",
              "gameId": 12158614,
              "datetime": "03/04/2024 14:10"
          },
          {
              "datetime": "02/04/2024 20:0",
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[66,65,55,51,65,55],\"shotOnTarget\":[6,4,8,4,4,8],\"bigChances\":[1,3,5,0,3,5],\"goalR\":[0,0,0,1,0,0],\"goalKeeperSave\":[2,1,3,7,1,3],\"blockedShots\":[6,2,4,0,2,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[68,62,62,60,68,62],\"shotOnTarget\":[5,5,7,3,5,7],\"bigChances\":[1,3,2,3,1,2],\"goalR\":[2,0,0,3,2,0],\"goalKeeperSave\":[1,2,2,2,1,2],\"blockedShots\":[6,3,2,2,6,2],\"winH2H\":[0]}}",
              "gameId": 12163999
          },
          {
              "datetime": "03/04/2024 21:0",
              "gameId": 12164000,
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[32,37,32],\"shotOnTarget\":[5,5,5],\"bigChances\":[0,1,0],\"goalR\":[2,2,2],\"goalKeeperSave\":[5,4,5],\"blockedShots\":[1,2,1],\"winH2H\":[0]},\"away\":{\"ballPossession\":[49,58,49],\"shotOnTarget\":[3,11,3],\"bigChances\":[0,8,0],\"goalR\":[0,0,0],\"goalKeeperSave\":[3,2,3],\"blockedShots\":[7,3,7],\"winH2H\":[0]}}"
          },
          {
              "datetime": "02/04/2024 19:45",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[52,52,52],\"shotOnTarget\":[6,3,3],\"bigChances\":[2,1,1],\"goalR\":[0,1,1],\"goalKeeperSave\":[4,0,0],\"blockedShots\":[5,0,0],\"winH2H\":[0]},\"away\":{\"ballPossession\":[67,55,67],\"shotOnTarget\":[6,6,6],\"bigChances\":[0,3,0],\"goalR\":[1,0,1],\"goalKeeperSave\":[0,2,0],\"blockedShots\":[3,2,3],\"winH2H\":[0]}}",
              "gameId": 12164001
          },
          {
              "gameId": 12164002,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[70,45,45],\"shotOnTarget\":[6,5,5],\"bigChances\":[2,3,3],\"goalR\":[2,1,1],\"goalKeeperSave\":[1,2,2],\"blockedShots\":[4,3,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[69,72,69],\"shotOnTarget\":[8,11,8],\"bigChances\":[3,2,3],\"goalR\":[1,0,1],\"goalKeeperSave\":[6,3,6],\"blockedShots\":[3,4,3],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 19:0"
          },
          {
              "datetime": "04/04/2024 11:0",
              "gameId": 12164162,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[32,59,45,38,62,35,46,29,38,43,59,62,46,43,59],\"shotOnTarget\":[6,5,1,1,8,0,3,4,1,1,5,8,3,1,5],\"bigChances\":[],\"goalR\":[2,2,1,3,4,1,1,2,4,0,2,4,1,0,2,5],\"goalKeeperSave\":[4,5,3,4,1,8,4,8,5,3,5,1,4,3,5],\"blockedShots\":[3,2,1,4,5,2,3,6,0,7,2,5,3,7,2],\"winH2H\":[0]},\"away\":{\"ballPossession\":[53,41,36,50,32,48,35,54,31,38,41,32,35,38,41],\"shotOnTarget\":[7,7,2,2,3,1,6,3,2,5,7,3,6,5,7],\"bigChances\":[],\"goalR\":[0,2,2,2,0,3,2,2,1,0,2,0,2,0,2,1],\"goalKeeperSave\":[3,4,6,3,5,1,3,4,5,0,4,5,3,0,4],\"blockedShots\":[4,1,2,1,1,0,3,5,0,3,1,1,3,3,1],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[48],\"shotOnTarget\":[4,3,1,3],\"bigChances\":[],\"goalR\":[0,1,0,0,2,2,1,1,0,1,0,0,2,1,1,0,0],\"goalKeeperSave\":[4,2],\"blockedShots\":[2,1,2,1],\"winH2H\":[1]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,0,0,4,2,1,0,4,3,2,3,4,2,4,2,0,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "03/04/2024 13:30",
              "gameId": 12166170
          },
          {
              "gameId": 12171328,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[52,64,60,44,54,69,56,51,56,38,64,44,69,51,56,52,50,70,65],\"shotOnTarget\":[8,6,2,6,10,9,7,8,3,3,6,6,9,8,3,3,2,6,5],\"bigChances\":[4,8,2,6,7,5,4,2,1,3,8,6,5,2,1,0,1,1,3],\"goalR\":[3,1,1,2,1,0,2,0,1,1,1,2,0,0,1,1,1,2,1],\"goalKeeperSave\":[2,2,2,4,3,1,1,1,5,3,2,4,1,1,5,3,3,3,6],\"blockedShots\":[5,2,4,1,3,5,5,3,5,2,2,1,5,3,5,4,4,4,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[60,48,51,65,40,53,41,52,48,56,48,65,40,41,48,48,50,30,35],\"shotOnTarget\":[6,9,8,8,3,4,8,5,4,3,9,8,3,8,4,4,4,5,7],\"bigChances\":[4,1,1,2,2,2,3,4,2,3,1,2,2,3,2,2,5,2,4],\"goalR\":[1,1,0,1,1,1,2,2,0,2,1,1,1,2,0,0,1,1,1],\"goalKeeperSave\":[5,2,7,0,1,0,0,0,3,2,2,0,1,0,3,3,1,5,4],\"blockedShots\":[1,3,6,7,2,5,3,3,2,6,3,7,2,3,2,2,1,2,6],\"winH2H\":[2]}}",
              "datetime": "01/04/2024 11:30"
          },
          {
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[46,48,54,50,41,44,47,35,44,40,48,50,41,47,28,41,37,37,46],\"shotOnTarget\":[4,1,2,7,5,4,2,3,10,6,1,7,5,2,4,10,5,5,5],\"bigChances\":[3,1,3,3,3,2,1,3,5,0,1,3,3,1,0,3,3,1],\"goalR\":[2,2,2,2,0,0,3,0,0,2,2,2,0,3,1,2,0,2,2,2,3,0,3,0],\"goalKeeperSave\":[3,5,2,3,5,7,2,7,4,4,5,3,5,2,2,3,6,1,8],\"blockedShots\":[3,3,5,3,7,7,10,3,10,2,3,3,7,10,1,5,3,1],\"winH2H\":[2]},\"away\":{\"ballPossession\":[53,56,53,63,60,62,48,71,49,55,56,63,48,49,72,59,63,63,54],\"shotOnTarget\":[5,2,4,10,2,9,7,10,4,6,2,10,7,4,3,5,6,3,12],\"bigChances\":[3,2,4,6,3,8,5,4,2,3,2,6,5,2,3,2,1,2],\"goalR\":[0,0,0,1,1,0,1,3,2,0,0,1,1,2,1,2,2,1,0,2,0,2,0,0],\"goalKeeperSave\":[2,4,1,2,2,0,4,2,1,1,4,2,4,1,3,8,2,4,5],\"blockedShots\":[4,6,6,2,4,3,4,10,3,4,6,2,4,3,3,2,1,3],\"winH2H\":[4]}}",
              "datetime": "01/04/2024 6:30",
              "gameId": 12171331
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[53,62,50,48,46,60,63,52,55,49,53,50,60,52,49,62],\"shotOnTarget\":[4,3,3,5,5,5,6,3,4,7,4,3,5,3,7,11],\"bigChances\":[],\"goalR\":[0,1,1,2,0,0,1,0,0,2,0,1,0,0,2,0,0,1,1,0,1,1,0,1,1],\"goalKeeperSave\":[0,7,2,4,5,1,0,2,5,4,0,2,1,2,4,7],\"blockedShots\":[1,3,4,6,5,7,4,4,4,4,1,4,7,4,4,4],\"winH2H\":[5]},\"away\":{\"ballPossession\":[29,38,40,54,37,40,46,42,42,53,29,40,54,46,42,38],\"shotOnTarget\":[2,7,4,4,5,3,4,2,3,4,2,4,4,4,2,7],\"bigChances\":[],\"goalR\":[1,0,0,2,1,0,2,3,2,0,1,0,2,2,3,0,7,2,1,0,2,1,3,2,0],\"goalKeeperSave\":[5,3,4,2,4,2,1,5,1,3,5,4,2,1,5,5],\"blockedShots\":[6,2,1,2,5,4,2,0,5,2,6,1,2,2,0,1],\"winH2H\":[1]}}",
              "gameId": 12172538,
              "datetime": "03/04/2024 21:0"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[43],\"shotOnTarget\":[5],\"bigChances\":[],\"goalR\":[1,1,0,1,1,0,1,2,6],\"goalKeeperSave\":[4],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[57],\"shotOnTarget\":[4],\"bigChances\":[],\"goalR\":[0,0,1,0,0,1,0,0,2],\"goalKeeperSave\":[8],\"blockedShots\":[],\"winH2H\":[2]}}",
              "datetime": "03/04/2024 14:15",
              "gameId": 12174470
          },
          {
              "gameId": 12174745,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[67,57,43,50,67,70,52,43,61,60,67,57,50,70,43,50,70,48,54,43,57,47,50,58,54],\"shotOnTarget\":[9,12,8,7,7,5,5,7,5,6,9,12,7,5,7,7,6,10,9,8,2,8,2,8,6],\"bigChances\":[4,8,4,2,2,5,4,6,2,2,4,8,2,5,6,2,2,4,1,4,2,3,1,4],\"goalR\":[1,0,2,0,2,2,0,0,2,0,1,0,0,2,0,0,1,1,0,2,1,2,0,1,2],\"goalKeeperSave\":[1,0,4,4,1,1,3,5,5,1,1,0,4,1,5,4,3,3,3,1,1,1,4,4,2],\"blockedShots\":[7,2,4,2,7,4,0,5,2,4,7,2,2,4,5,2,5,3,4,2,6,1,2,1,0],\"winH2H\":[6]},\"away\":{\"ballPossession\":[51,76,53,52,55,59,49,64,50,39,76,52,59,50,39,50,30,52,46,57,43,53,50,42,46],\"shotOnTarget\":[4,6,5,7,1,4,6,4,4,6,6,7,4,4,6,4,4,4,3,4,2,3,4,5,3],\"bigChances\":[4,4,1,5,3,3,3,2,0,3,4,5,3,0,3,0,3,0,0,2,1,2,1,5],\"goalR\":[1,0,3,0,0,2,1,1,0,3,0,0,2,0,3,0,0,4,2,4,2,1,0,2,3],\"goalKeeperSave\":[1,2,5,2,3,3,2,7,5,2,2,3,7,5,7,6,6,7,4,1,7,2,6,3],\"blockedShots\":[1,1,5,5,4,5,5,0,2,2,1,5,5,2,2,2,4,1,3,3,2,0,5,3,0],\"winH2H\":[2]}}",
              "datetime": "01/04/2024 7:0"
          },
          {
              "datetime": "01/04/2024 11:0",
              "gameId": 12174746,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[54,55,51,53,48,50,61,60,45,64,55,53,50,60,45,57,46,67,51,51,49,48,48],\"shotOnTarget\":[4,6,2,7,3,3,6,6,2,2,6,7,3,6,2,2,5,1,7,5,4,7,3],\"bigChances\":[1,3,2,4,3,4,3,4,3,1,3,4,4,4,3,1,2,1],\"goalR\":[0,0,1,0,1,0,2,1,4,2,0,0,0,1,4,1,0,2,1,5,1,2,0,1,4],\"goalKeeperSave\":[1,2,3,3,1,2,4,3,8,3,2,3,2,3,8,1,4,2,4,2,2,3,2],\"blockedShots\":[5,1,3,5,2,2,5,3,1,4,1,5,2,3,1,4,2,4,3],\"winH2H\":[5]},\"away\":{\"ballPossession\":[58,24,55,50,35,45,45,36,50,38,58,55,50,45,36,43,54,33,49,49,51,52,52],\"shotOnTarget\":[3,2,6,2,1,5,2,3,6,2,3,6,2,5,3,1,4,4,6,6,3,7,2],\"bigChances\":[1,0,1,2,1,4,2,2,2,1,1,1,2,4,2,0,1,4],\"goalR\":[1,3,1,2,1,0,1,1,0,0,1,1,2,0,1,0,1,0,2,2,2,0,1,3,0],\"goalKeeperSave\":[1,3,0,2,3,3,4,2,6,1,0,2,3,4,2,4,1,5,4,2,7,2],\"blockedShots\":[5,1,1,5,6,2,2,0,1,3,5,1,5,2,0,3,3,1,6],\"winH2H\":[5]}}"
          },
          {
              "gameId": 12174747,
              "datetime": "01/04/2024 9:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[42,47,59,40,54,46,55,62,43,55,42,59,54,55,43,43,47,41,49,49,68,39,52,56,43],\"shotOnTarget\":[2,5,3,5,9,6,5,8,3,12,2,3,9,5,3,3,1,7,8,10,10,8,4,8,4],\"bigChances\":[1,3,2,2,1,6,4,4,1,5,1,2,1,4,1,1,1,7,6,7,3],\"goalR\":[1,2,1,0,2,1,1,1,0,1,1,1,2,1,0,0,0,0,0,1,3,1,0,3,1],\"goalKeeperSave\":[2,4,0,2,4,2,4,1,2,2,2,0,4,4,2,2,2,5,1,0,0,0,4,2,5],\"blockedShots\":[5,5,5,5,1,8,7,4,3,1,5,5,1,7,3,3,3,1,5,3,5,1,0,0],\"winH2H\":[7]},\"away\":{\"ballPossession\":[68,62,51,62,57,62,46,56,57,53,62,51,57,46,57,57,53,59,51,51,32,61,48,44,57],\"shotOnTarget\":[5,5,4,7,2,6,4,9,5,10,5,4,2,4,5,2,2,5,1,1,3,1,4,5,6],\"bigChances\":[3,2,2,7,0,0,3,2,0,6,2,2,0,3,0,0,3,0,0,3,1],\"goalR\":[1,0,2,0,2,0,1,2,2,1,0,2,2,1,2,2,0,2,3,3,6,2,0,3,2],\"goalKeeperSave\":[2,5,4,3,1,1,0,3,4,0,5,4,1,0,4,1,1,5,5,6,4,6,4,5,2],\"blockedShots\":[9,6,4,7,1,3,5,11,7,2,6,4,1,5,7,1,4,2,1,2,2,1,0,0],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[46,41,66,32,54,50,56,60,43,54,46,66,54,60,54,57,56,48,55,40,46],\"shotOnTarget\":[1,1,7,3,3,2,2,6,3,1,1,7,3,6,1,3,5,11,0,1,7],\"bigChances\":[0,0,5,1,0,1,0,2,1,4,0,5,0,2,4,3,5,2,0,1],\"goalR\":[3,2,2,0,2,0,2,2,0,1,3,2,2,2,1,2,1,0,1,1,0,0],\"goalKeeperSave\":[1,1,0,4,4,3,4,4,3,3,1,0,4,4,3,2,2,2,4,4,1],\"blockedShots\":[1,1,5,2,3,6,3,3,5,2,1,5,3,3,2,3,0,4,5,2,6],\"winH2H\":[4]},\"away\":{\"ballPossession\":[47,40,43,44,45,35,37,33,41,38,47,43,45,33,38,43,44,52,45,60,54],\"shotOnTarget\":[1,3,4,4,5,1,8,2,2,3,1,4,5,2,3,4,3,2,5,5,1],\"bigChances\":[2,4,2,1,2,1,3,2,1,1,2,2,2,2,1,2,0,1,2,0],\"goalR\":[2,1,1,2,1,0,1,2,1,1,2,1,1,2,1,1,2,6,0,0,1,2],\"goalKeeperSave\":[5,2,2,1,2,3,2,7,9,4,5,2,2,7,4,2,3,4,0,1,6],\"blockedShots\":[3,2,2,0,2,2,1,0,5,0,3,2,2,0,0,2,4,3,1,6,5],\"winH2H\":[3]}}",
              "datetime": "02/04/2024 12:0",
              "gameId": 12174751
          },
          {
              "gameId": 12174912,
              "datetime": "04/04/2024 7:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,3,0,1,0,0,0,0,3,0,1,0,0,0,0,0,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,0,0,1,1,1,0,0,2,0,0,1,1,0,0,0,0,2,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "gameId": 12174915,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,1,1,1,1,1,1,1,0,0,1,1,1,0,0,2,1,4],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,2,2,1,4,1,1,2,1,1,0,2,4,1,2,1,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]}}",
              "datetime": "04/04/2024 7:0"
          },
          {
              "gameId": 12176194,
              "datetime": "03/04/2024 18:15",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,2,0,1,0,0,1,0,0,2,0,1,1,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,0,2,1,0,0,1,1,1,1,0,2,2,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "datetime": "02/04/2024 14:30",
              "gameId": 12176195,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,2,1,0,1,1,0,0,0,2,1,1,1,1,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,1,0,0,2,0,0,2,3,0,0,2,0,3,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}"
          },
          {
              "gameId": 12179728,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[7,1,2,2,0,0,1,1,2,0,2,0,1,2,0,0,0,3,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,1,1,0,1,1,1,2,1,0,1,1,1,1,0,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]}}",
              "datetime": "02/04/2024 13:45"
          },
          {
              "datetime": "03/04/2024 13:45",
              "gameId": 12179730,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[3,1,3,0,1,1,2,1,2,2,1,0,1,1,0,0,0,0,1,0,1,0,3,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,2,1,0,0,0,0,3,2,2,0,0,3,0,0,0,0,1,3,1,0,3,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,4,1,0,1,5,1,1,2,2,2,4,0,5,0,0,2,1,3,2,2,1,0,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[3]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,3,0,2,1,1,2,2,3,1,3,1,2,3,0,0,2,2,2,2,2,1,3,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "datetime": "02/04/2024 13:45",
              "gameId": 12179731
          },
          {
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,3,1,2,2,0,3,0,2,1,3,2,3,2,0,0,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,3,1,0,1,3,1,0,1,3,3,0,3,1,0,0,2,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "gameId": 12179732,
              "datetime": "02/04/2024 13:45"
          },
          {
              "datetime": "03/04/2024 13:45",
              "gameId": 12179733,
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,0,0,3,1,3,2,0,1,1,0,3,2,1,0,0,0,0,2,0,1,1,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[4]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,1,1,2,3,0,1,1,2,1,1,2,0,1,0,0,1,1,1,0,2,1,1,3],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}"
          },
          {
              "datetime": "03/04/2024 13:45",
              "gameId": 12179734,
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,2,0,1,1,1,2,1,3,2,2,1,1,1,0,0,3,1,3,1,3,7,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[3]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,5,2,2,0,2,0,1,3,1,2,0,1,0,0,2,4,4,2,2,0,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[3]}}"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,3,0,0,0,2],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,0,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}",
              "gameId": 12180575,
              "datetime": "03/04/2024 11:0"
          },
          {
              "gameId": 12180576,
              "datetime": "03/04/2024 8:30",
              "data": "{\"bet\":\"Empate\",\"betStatus\":false,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,2,1,2,1,2,1,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,0,0,0,0,2,2,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[1]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[43,47,58,47,51,55,50,41,37,49,43,47,51,50,37,51],\"shotOnTarget\":[7,7,2,7,8,3,5,6,5,8,7,7,8,5,5,8],\"bigChances\":[],\"goalR\":[0,1,3,2,1,2,0,0,1,1,0,2,1,0,1,1],\"goalKeeperSave\":[1,3,0,3,3,7,2,4,1,1,1,3,3,2,1,3],\"blockedShots\":[2,0,2,2,5,0,2,2,3,0,2,2,5,2,3,5],\"winH2H\":[0]},\"away\":{\"ballPossession\":[49,49,51,45,59,49,41,54,45,33,49,49,45,49,54,49],\"shotOnTarget\":[4,3,3,3,4,4,1,1,7,7,4,3,3,4,1,3],\"bigChances\":[],\"goalR\":[0,0,2,4,3,2,4,1,0,0,0,0,4,2,1,0],\"goalKeeperSave\":[3,8,4,3,3,2,2,6,7,3,3,8,3,2,6,8],\"blockedShots\":[4,3,3,3,6,5,4,3,1,2,4,3,3,5,3,3],\"winH2H\":[1]}}",
              "datetime": "01/04/2024 7:0",
              "gameId": 12180811
          },
          {
              "gameId": 12180828,
              "datetime": "01/04/2024 8:0",
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[49,50,51,41,44,58,61,52,62,56,50,51,44,61,62],\"shotOnTarget\":[4,1,5,1,1,6,1,5,6,2,1,5,1,1,8],\"bigChances\":[],\"goalR\":[2,7,2,4,0,3,1,2,0,3,7,2,0,1,4,0,1,1,2,1,0],\"goalKeeperSave\":[6,5,2,3,9,3,3,2,2,4,5,2,9,3,3],\"blockedShots\":[4,2,5,3,4,2,1,1,2,1,2,5,4,1,3],\"winH2H\":[3]},\"away\":{\"ballPossession\":[51,51,37,54,44,51,47,37,50,55,51,54,47,50,38],\"shotOnTarget\":[7,5,9,8,7,3,4,12,2,4,5,8,4,2,7],\"bigChances\":[],\"goalR\":[0,5,1,2,0,0,0,1,0,4,5,2,0,0,1,2,0,1,0,2,1],\"goalKeeperSave\":[4,1,4,4,5,4,2,3,5,3,1,4,2,5,7],\"blockedShots\":[3,2,3,6,3,3,3,6,3,3,2,6,3,3,2],\"winH2H\":[3]}}"
          },
          {
              "gameId": 12180829,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[59,58,54,45,63,61,52,67,67,70,59,54,45,52,67,70],\"shotOnTarget\":[3,4,6,7,2,6,2,3,3,11,3,6,7,2,3,11],\"bigChances\":[],\"goalR\":[0,1,1,2,1,3,0,1,4,0,0,1,2,0,4,0,0,0,1,4,2,3,3,0,2],\"goalKeeperSave\":[2,5,6,4,4,3,4,1,3,2,2,6,4,4,3,2],\"blockedShots\":[3,4,6,4,3,2,1,3,4,4,3,6,4,1,4,4],\"winH2H\":[2]},\"away\":{\"ballPossession\":[43,57,55,62,55,53,63,51,49,46,43,57,62,63,49,30],\"shotOnTarget\":[1,1,3,9,3,1,4,2,6,5,1,1,9,4,6,2],\"bigChances\":[],\"goalR\":[0,2,1,0,2,1,3,3,0,1,0,2,0,3,0,0,2,4,1,2,1,1,3,0,1],\"goalKeeperSave\":[5,5,0,0,3,4,9,6,0,2,5,5,0,9,0,11],\"blockedShots\":[2,1,0,13,9,4,0,2,2,2,2,1,13,0,2,3],\"winH2H\":[4]}}",
              "datetime": "02/04/2024 11:30"
          },
          {
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[56,41,57,49,41,44,51,53,49,59,56,57,41,44,53,59,59],\"shotOnTarget\":[6,3,5,4,4,1,4,2,6,3,6,5,4,1,2,3,3],\"bigChances\":[],\"goalR\":[2,0,1,0,2,2,1,1,1,3,2,1,2,2,1,3,3,2,1],\"goalKeeperSave\":[0,6,0,5,6,5,2,3,2,2,0,0,6,5,3,2,2],\"blockedShots\":[6,2,5,1,3,1,2,1,4,3,6,5,3,1,1,3,3],\"winH2H\":[0]},\"away\":{\"ballPossession\":[45,35,46,39,42,41,44,45,58,48,45,46,39,41,45,48,41],\"shotOnTarget\":[1,6,2,4,5,5,7,3,2,3,1,2,4,5,3,3,5],\"bigChances\":[],\"goalR\":[2,0,3,0,2,2,1,4,1,1,2,3,0,2,4,1,2,1,1],\"goalKeeperSave\":[1,5,2,1,2,1,1,2,2,1,1,2,1,1,2,1,1],\"blockedShots\":[3,1,1,3,4,4,4,2,2,7,3,1,3,4,2,7,4],\"winH2H\":[2]}}",
              "gameId": 12180833,
              "datetime": "01/04/2024 8:0"
          },
          {
              "datetime": "01/04/2024 8:0",
              "gameId": 12189898,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,0,3,7,3,9,2,1,5,1,0,3,3,1,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[5,2,2,3,2,1,0,2,1,3,2,3,1,0,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}"
          },
          {
              "datetime": "23/04/2024 14:0",
              "gameId": 12190322,
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[64,64,57,67,61,49,49,74,60,61,64,57,49,74,61,55,35,39,42,35,58,50,46,57,62],\"shotOnTarget\":[6,4,6,9,5,9,2,8,3,7,6,6,9,8,7,10,3,7,2,5,6,5,1,1,1],\"bigChances\":[3,3,4,3,4,5,1,5,2,6,3,4,5,5,6,4,1,3,1,3,1,2],\"goalR\":[1,0,1,3,1,0,1,2,2,0,1,1,0,2,0,1,2,1,2,0,2,0,1,0,0],\"goalKeeperSave\":[4,1,2,1,2,1,2,1,2,5,4,2,1,1,5,3,3,2,2,1,4,2,4,2,4],\"blockedShots\":[4,4,5,9,3,9,5,10,3,7,4,5,9,10,7,3,1,4,0,1,5,4,1,4],\"winH2H\":[4]},\"away\":{\"ballPossession\":[46,32,55,72,78,68,64,49,55,49,46,55,72,68,49,45,65,61,58,65,42,50,54,43,38],\"shotOnTarget\":[4,5,3,4,6,5,4,8,3,3,4,3,4,5,8,4,5,3,4,1,6,2,6,3,5],\"bigChances\":[2,3,5,0,5,2,6,1,2,1,2,5,0,2,1,2,5,3,2,1,5,1],\"goalR\":[4,2,2,2,0,2,1,2,0,4,4,2,2,2,2,3,0,3,1,2,2,3,0,0,0],\"goalKeeperSave\":[1,7,7,3,1,4,4,4,4,9,1,7,3,4,4,6,3,4,1,3,4,2,1,1,1],\"blockedShots\":[0,0,3,6,5,7,2,2,5,0,0,3,6,7,2,2,11,5,3,3,3,2,3,2],\"winH2H\":[3]}}"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,1,0,0,0,1,0,2,0,1,1,0,1,0,0,0,0,0,1,0,0,0,0,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[5]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[0,1,2,0,2,1,0,0,3,1,2,0,1,0,1,0,1,0,1,1,1,0,1,0],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[0]}}",
              "datetime": "04/04/2024 16:0",
              "gameId": 12191065
          },
          {
              "datetime": "01/04/2024 12:0",
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,59,37,42,49,60,52,41,48,47,55,37,49,52,49],\"shotOnTarget\":[2,2,2,4,9,6,8,4,3,2,2,2,9,8,9],\"bigChances\":[0,1,3,0,3,7,3,1,1,1,0,3,3,3,3],\"goalR\":[1,2,0,1,1,1,1,2,0,2,1,0,1,1,1,0,0,1],\"goalKeeperSave\":[1,4,1,4,2,2,1,1,3,3,1,1,2,1,2],\"blockedShots\":[3,4,2,2,5,2,0,3,3,4,3,2,5,0,5],\"winH2H\":[1]},\"away\":{\"ballPossession\":[69,48,36,39,57,51,33,54,51,37,69,36,51,51,51],\"shotOnTarget\":[6,5,2,7,4,3,2,6,2,1,6,2,3,2,3],\"bigChances\":[2,2,2,2,3,1,2,3,2,1,2,2,1,2,1],\"goalR\":[2,1,1,0,1,2,0,3,2,4,2,1,2,2,2,0,0,0],\"goalKeeperSave\":[1,8,5,3,4,7,3,2,4,6,1,5,7,4,7],\"blockedShots\":[7,2,1,2,1,4,10,3,4,0,7,1,4,4,4],\"winH2H\":[1]}}",
              "gameId": 12193955
          },
          {
              "datetime": "01/04/2024 7:30",
              "data": "{\"bet\":\"Away Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[47,50,49,58,53,68,34,50,49,56,47,50,58,68],\"shotOnTarget\":[3,4,1,11,6,6,5,5,1,4,3,4,11,6],\"bigChances\":[3,2,1,1,1,2,2,1,1,3,2,1,1],\"goalR\":[0,3,0,1,1,0,1,0,3,3,0,3,1,0],\"goalKeeperSave\":[7,0,1,4,2,2,3,2,2,3,7,0,4,2],\"blockedShots\":[1,3,4,5,2,4,7,1,2,1,1,3,5,4],\"winH2H\":[0]},\"away\":{\"ballPossession\":[48,61,61,57,58,55,60,61,32,51,61,57,60,32],\"shotOnTarget\":[8,6,9,7,7,7,5,5,4,5,6,7,5,4],\"bigChances\":[1,1,6,3,4,2,1,4,6,2],\"goalR\":[2,0,1,0,1,0,1,1,0,1,0,0,1,0],\"goalKeeperSave\":[2,2,1,1,0,1,2,2,2,1,2,1,2,2],\"blockedShots\":[6,5,8,9,4,2,5,3,1,4,5,9,5,1],\"winH2H\":[0]}}",
              "gameId": 12198860
          },
          {
              "gameId": 12206691,
              "datetime": "03/04/2024 15:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":true,\"home\":{\"ballPossession\":[55,54,56,64,75,66,30,42,68,43,54,64,66,42,64],\"shotOnTarget\":[2,4,6,3,3,7,5,4,3,7,4,3,7,4,2],\"bigChances\":[1,4,3,1,3,1,2,3,0,3,4,1,1,3,0],\"goalR\":[1,1,3,1,0,3,2,1,1,1,1,1,3,1,1,0,2,1,3,0,2,2,1,2],\"goalKeeperSave\":[6,4,3,0,2,2,3,3,0,1,4,0,2,3,0],\"blockedShots\":[0,1,8,4,4,3,2,1,4,1,1,4,3,1,3],\"winH2H\":[4]},\"away\":{\"ballPossession\":[49,38,51,39,36,55,33,53,58,45,38,39,36,33,36],\"shotOnTarget\":[4,3,5,6,1,4,2,4,2,6,3,6,1,2,1],\"bigChances\":[2,2,1,2,1,4,0,1,1,2,2,1,0,2],\"goalR\":[1,0,1,5,1,1,0,1,0,0,0,5,1,0,0,1,0,1,1,1,2,2,3,3],\"goalKeeperSave\":[4,2,4,1,7,2,2,2,1,6,2,1,7,2,2],\"blockedShots\":[2,0,7,1,2,2,2,9,3,1,0,1,2,2,2],\"winH2H\":[3]}}"
          },
          {
              "gameId": 12206696,
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":false,\"home\":{\"ballPossession\":[51,36,33,32,54,34,40,34,51,28,36,54,34,51,28],\"shotOnTarget\":[5,3,7,4,8,2,1,7,7,4,3,8,2,7,4],\"bigChances\":[2,0,4,1,2,1,1,4,1,0,0,2,1,1,0],\"goalR\":[1,1,2,0,0,1,1,2,2,2,1,0,1,2,2,2,3,0,0,4,1,0,1],\"goalKeeperSave\":[3,5,6,5,3,2,1,3,3,1,5,3,2,3,1],\"blockedShots\":[6,2,0,4,3,4,2,3,0,1,2,3,4,0,1],\"winH2H\":[4]},\"away\":{\"ballPossession\":[39,37,49,37,43,36,48,65,41,38,37,49,43,48,41],\"shotOnTarget\":[3,3,5,4,4,2,3,2,4,1,3,5,4,3,4],\"bigChances\":[4,0,3,2,0,1,3,0,1,1,0,3,0,3,1],\"goalR\":[2,1,2,3,3,2,1,0,1,3,1,2,3,1,1,1,2,0,2,1,2,1,2],\"goalKeeperSave\":[4,4,3,2,2,1,4,1,5,2,4,3,2,4,5],\"blockedShots\":[1,3,5,1,2,1,2,3,0,1,3,5,2,2,0],\"winH2H\":[3]}}",
              "datetime": "03/04/2024 15:0"
          },
          {
              "data": "{\"bet\":\"Home Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[63,63,62,68,34,34,50,58,58,56,62,34,34,58],\"shotOnTarget\":[4,12,9,5,4,6,6,6,2,4,9,4,6,6],\"bigChances\":[3,3,0,0,2,6,5,1,1,3,0,2,5],\"goalR\":[1,2,0,1,0,2,0,2,1,2,0,0,2,2],\"goalKeeperSave\":[3,3,2,6,4,6,2,3,0,3,2,4,6,3],\"blockedShots\":[3,3,4,6,2,5,3,7,8,2,4,2,5,7],\"winH2H\":[0]},\"away\":{\"ballPossession\":[45,64,60,63,64,29,62,55,61,42,64,63,29,62],\"shotOnTarget\":[7,6,7,5,8,4,0,7,12,2,6,5,4,0],\"bigChances\":[3,1,3,4,1,0,1,4,0,1,1,4,0,1],\"goalR\":[0,0,3,3,1,1,0,1,1,1,0,3,1,0],\"goalKeeperSave\":[2,3,2,1,0,4,2,4,0,5,3,1,4,2],\"blockedShots\":[2,3,1,5,2,3,0,3,1,1,3,5,3,0],\"winH2H\":[0]}}",
              "gameId": 12206697,
              "datetime": "03/04/2024 15:0"
          },
          {
              "datetime": "04/04/2024 15:0",
              "gameId": 12206701,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[60,36,35,42,26,39,36,42,32,49,36,42,26,36,32,39],\"shotOnTarget\":[6,0,3,7,5,8,3,5,1,5,0,7,5,3,1,3],\"bigChances\":[1,0,1,3,2,3,0,2,1,1,0,3,2,0,1,1],\"goalR\":[1,0,1,1,1,0,2,4,0,1,0,1,1,2,0,1,1],\"goalKeeperSave\":[1,2,2,2,4,2,1,3,3,6,2,2,4,1,3,3],\"blockedShots\":[1,3,2,1,2,4,4,0,2,2,3,1,2,4,2,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[47,37,37,36,68,48,52,47,45,56,37,36,48,47,56,61],\"shotOnTarget\":[3,2,5,1,5,7,5,3,5,5,2,1,7,3,5,4],\"bigChances\":[0,0,1,0,0,2,2,2,1,2,0,0,2,2,2,0],\"goalR\":[2,1,2,0,1,3,1,2,2,3,1,0,3,2,3,2,0],\"goalKeeperSave\":[1,2,9,4,3,1,2,3,5,1,2,4,1,3,1,1],\"blockedShots\":[2,3,1,0,2,2,1,2,2,3,3,0,2,2,3,4],\"winH2H\":[1]}}"
          },
          {
              "gameId": 12206702,
              "datetime": "04/04/2024 15:0",
              "data": "{\"bet\":\"Home Gana\",\"betStatus\":false,\"home\":{\"ballPossession\":[47,63,56,60,41,66,64,44,57,72,63,60,66,44,44],\"shotOnTarget\":[1,4,5,1,4,4,3,6,2,3,4,1,4,6,6],\"bigChances\":[0,1,3,1,1,2,4,1,2,1,1,4,2],\"goalR\":[4,0,1,0,3,4,1,0,1,2,0,0,4,0,1,1,0,1,2,2,0,0,0,0],\"goalKeeperSave\":[3,2,2,4,3,2,3,6,2,2,3,3,0],\"blockedShots\":[3,4,4,4,1,5,2,2,2,4,4,4,5,2,1],\"winH2H\":[6]},\"away\":{\"ballPossession\":[37,46,67,58,57,45,38,44,52,50,46,58,45,52,56],\"shotOnTarget\":[4,5,8,4,5,3,2,4,2,5,5,4,3,2,1],\"bigChances\":[1,2,2,3,1,1,1,0,1,2,1,0],\"goalR\":[0,3,2,2,2,0,0,1,0,2,3,2,0,0,2,0,1,0,2,0,1,2,1,2],\"goalKeeperSave\":[4,1,5,2,3,4,0,4,2,0,1,2,4,2,4],\"blockedShots\":[3,0,4,4,2,1,3,0,3,3,0,4,1,3,2],\"winH2H\":[3]}}"
          },
          {
              "gameId": 12206758,
              "datetime": "04/04/2024 15:0",
              "data": "{\"bet\":\"Empate\",\"betStatus\":true,\"home\":{\"ballPossession\":[64,37,48,58,50,34,50,58,66,47,37,48,50,50,66,57],\"shotOnTarget\":[1,0,6,2,3,7,2,4,5,6,0,6,3,2,5,5],\"bigChances\":[0,1,0,1,1,1,2,2,2,0,1,1,1,2,0],\"goalR\":[2,1,2,3,2,0,1,2,3,0,1,2,2,1,3,1],\"goalKeeperSave\":[1,3,6,4,1,4,5,2,4,4,3,6,1,5,4,4],\"blockedShots\":[4,0,3,1,2,3,2,4,5,0,0,3,2,2,5,0],\"winH2H\":[1]},\"away\":{\"ballPossession\":[40,44,44,38,25,46,27,35,42,39,44,38,46,35,39,43],\"shotOnTarget\":[2,4,3,2,2,2,1,1,1,1,4,2,2,1,1,5],\"bigChances\":[0,1,0,0,2,0,1,2,1,0,0,1,2,3],\"goalR\":[0,2,3,4,0,2,0,0,0,3,2,4,2,0,3,3],\"goalKeeperSave\":[6,4,2,4,3,8,3,2,2,9,4,4,8,2,9,1],\"blockedShots\":[3,2,0,3,3,3,4,2,2,3,2,3,3,2,3,5],\"winH2H\":[0]}}"
          },
          {
              "datetime": "03/04/2024 18:15",
              "gameId": 12211109,
              "data": "{\"bet\":\"Away Gana/Empata\",\"betStatus\":true,\"home\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[2,2,2,2,0,0,0,2,0,2,2,0,0,0,0,0,4,1,1,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[2]},\"away\":{\"ballPossession\":[],\"shotOnTarget\":[],\"bigChances\":[],\"goalR\":[1,0,1,0,2,1,0,1,0,0,2,1,0,2,2,0,1,0,0,1],\"goalKeeperSave\":[],\"blockedShots\":[],\"winH2H\":[3]}}"
          }
      ])
  }, []);

  useEffect(() => {
    if(data.home.load && data.away.load && data.homeInd.load && data.awayInd.load && dataSt.statistics.home.load) {
      let winningStats = [];
      gameStatistcs.map(game => {
        winningStats.push(JSON.parse(game.data))
      })
      // if(winningStats.length > 0) {
      //   const patterns = advancedAnalysis(winningStats);
      //   // console.log(patterns); 
      // }

      const analyzeStatistics = () => {
        let pointH = 0, pointA = 0;

        let BPH = (dataSt.statistics.home.ballPossession + dataSt.statistics.homeInd.ballPossession + dataSt.statistics.homeH2H.ballPossession)
        let BPA = (dataSt.statistics.away.ballPossession + dataSt.statistics.awayInd.ballPossession + dataSt.statistics.awayH2H.ballPossession)

        if(BPH > BPA){
          pointH += 1;
        } else {
          if(BPH < BPA) {
            pointA += 1;
          }
          // pointA += 1;
        }

        let SHTH = (dataSt.statistics.home.shotOnTarget + dataSt.statistics.homeInd.shotOnTarget + dataSt.statistics.homeH2H.shotOnTarget)
        let SHTA = (dataSt.statistics.away.shotOnTarget + dataSt.statistics.awayInd.shotOnTarget + dataSt.statistics.awayH2H.shotOnTarget)

        if(SHTH > SHTA){
          pointH += 1;
        } else {
          if(SHTH < SHTA) {
            pointA += 1;
          }
          // pointA += 1;
        }

        let BGCH = (dataSt.statistics.home.BigChances + dataSt.statistics.homeInd.BigChances + dataSt.statistics.homeH2H.BigChances)
        let BGCA = (dataSt.statistics.away.BigChances + dataSt.statistics.awayInd.BigChances + dataSt.statistics.awayH2H.BigChances)

        if(BGCH > BGCA){
          pointH += 1;
        } else {
          if(BGCH < BGCA) {
            pointA += 1;
          }
          // pointA += 1;
        }

        // let GLH = roundToTwo((sumArray(data.home.totalGoals) + sumArray(data.homeInd.totalGoals) + dataSt.statistics.homeH2H.goal) / (data.home.matches + data.homeInd.matches + data.homeH2H.matches))
        // let GLA = roundToTwo((sumArray(data.away.totalGoals) + sumArray(data.awayInd.totalGoals) + dataSt.statistics.awayH2H.goal) / (data.away.matches + data.awayInd.matches + data.awayH2H.matches))
        // console.log(GLH, GLA)

        // if(GLH > GLA){
        //   pointH += 1;
        // } else {
        //   // if(GLH < GLA) {
        //   //   pointA += 1;
        //   // }
        //   pointA += 1;
        // }

        let GLRH = roundToTwo((sumArray(data.home.totalGoalsR) + sumArray(data.homeInd.totalGoalsR) + dataSt.statistics.homeH2H.goalR) / (data.home.matches + data.homeInd.matches + data.homeH2H.matches))
        let GLRA = roundToTwo((sumArray(data.away.totalGoalsR) + sumArray(data.awayInd.totalGoalsR) + dataSt.statistics.awayH2H.goalR) / (data.away.matches + data.awayInd.matches + data.awayH2H.matches))
  

        if(GLRH < GLRA){
          pointH += 1;
        } else {
          if(GLRH > GLRA) {
            pointA += 1;
          }
          // pointA += 1;
        }
 
        let GLKSH = (dataSt.statistics.home.GoalkeeperSaves + dataSt.statistics.homeInd.GoalkeeperSaves + dataSt.statistics.homeH2H.GoalkeeperSaves)
        let GLKSA = (dataSt.statistics.away.GoalkeeperSaves + dataSt.statistics.awayInd.GoalkeeperSaves + dataSt.statistics.awayH2H.GoalkeeperSaves)

        if(GLKSH < GLKSA){
          pointH += 1;
        } else {
          if(GLKSH > GLKSA) {
            pointA += 1;
          }
          // pointA += 1;
        }

        let BLCKH = (dataSt.statistics.home.BlockedShots + dataSt.statistics.homeInd.BlockedShots + dataSt.statistics.homeH2H.BlockedShots)
        let BLCKA = (dataSt.statistics.away.BlockedShots + dataSt.statistics.awayInd.BlockedShots + dataSt.statistics.awayH2H.BlockedShots)

        if(BLCKH > BLCKA){
          pointH += 1;
        } else {
          if(BLCKH < BLCKA) {
            pointA += 1;
          }
          // pointA += 1;
        }

        if(data.homeH2H.win > data.awayH2H.win){
          pointH += 1;
        } else {
          // pointA += 1;
          if(data.homeH2H.win < data.awayH2H.win) {
            pointA += 1;
          }
        }

        console.log("HOME: BallPosession: " + BPH + " ShotOnTarget: " + SHTH + " BigChances: " + BGCH + " | GoalR: " + GLRH + " GoalKS: " + GLKSH + " BlockShot: " + BLCKH);
        console.log("AWAY: BallPosession: " + BPA + " ShotOnTarget: " + SHTA + " BigChances: " + BGCA + " | GoalR: " + GLRA + " GoalKS: " + GLKSA + " BlockShot: " + BLCKA);
        console.log("HOME Points: " + pointH);
        console.log("AWAY Points: " + pointA);
        const dateGame_ = new Date(data_.timestamp * 1000);
        // Calcular tasa de victoria y rendimiento general para ambos equipos en todos los contextos
        const homeWinRate = calculateWinRate(data.home, dataSt.statistics.home) + calculateWinRate(data.homeInd, dataSt.statistics.homeInd) + calculateWinRate(dataH2H.home, dataSt.statistics.home);
        const awayWinRate = calculateWinRate(data.away, dataSt.statistics.away) + calculateWinRate(data.awayInd, dataSt.statistics.awayInd) + calculateWinRate(dataH2H.away, dataSt.statistics.away);
        
        // console.log(homeWinRate, awayWinRate)
        // Calcular promedios de goles, corners, fouls y tarjetas
        // const averageGoalsRHome = calculateAverage([data.statistics.home.goalRPerGame, data.statistics.homeInd.goalRPerGame, data.statistics.homeH2H.goalRPerGame]);
        // const averageGoalsRAway = calculateAverage([data.statistics.away.goalRPerGame, data.statistics.awayInd.goalRPerGame, data.statistics.awayH2H.goalRPerGame]);
        const averageGoalsRHome = calculateAverage([dataSt.statistics.home.goalR, dataSt.statistics.homeInd.goalR, dataSt.statistics.homeH2H.goalR]);
        const averageGoalsRAway = calculateAverage([dataSt.statistics.away.goalR, dataSt.statistics.awayInd.goalR, dataSt.statistics.awayH2H.goalR]);
        // const averageGoalsHome = calculateAverage([data.statistics.home.goalPerGame, data.statistics.homeInd.goalPerGame, data.statistics.homeH2H.goalPerGame]);
        // const averageGoalsAway = calculateAverage([data.statistics.away.goalPerGame, data.statistics.awayInd.goalPerGame, data.statistics.awayH2H.goalPerGame]);
        const averageGoalsHome = calculateAverage([dataSt.statistics.home.goal, dataSt.statistics.homeInd.goal, dataSt.statistics.homeH2H.goal]);
        const averageGoalsAway = calculateAverage([dataSt.statistics.away.goal, dataSt.statistics.awayInd.goal, dataSt.statistics.awayH2H.goal]);
        /* 
        homeWinRate > awayWinRate && 
        */
        const averageShotOnTargetHome = calculateAverage([dataSt.statistics.home.shotOnTarget, dataSt.statistics.homeInd.shotOnTarget, dataSt.statistics.homeH2H.shotOnTarget]);
        const averageShotOnTargetAway = calculateAverage([dataSt.statistics.away.shotOnTarget, dataSt.statistics.awayInd.shotOnTarget, dataSt.statistics.awayH2H.shotOnTarget]);

        const averageShotOffTargetHome = calculateAverage([dataSt.statistics.home.shotOffTarget, dataSt.statistics.homeInd.shotOffTarget, dataSt.statistics.homeH2H.shotOffTarget]);
        const averageShotOffTargetAway = calculateAverage([dataSt.statistics.away.shotOffTarget, dataSt.statistics.awayInd.shotOffTarget, dataSt.statistics.awayH2H.shotOffTarget]);

        const averageBlockedShotsHome = calculateAverage([dataSt.statistics.home.BlockedShots, dataSt.statistics.homeInd.BlockedShots, dataSt.statistics.homeH2H.BlockedShots]);
        const averageBlockedShotsAway = calculateAverage([dataSt.statistics.away.BlockedShots, dataSt.statistics.awayInd.BlockedShots, dataSt.statistics.awayH2H.BlockedShots]);

        const averageShotsInsideBoxHome = calculateAverage([dataSt.statistics.home.ShotsInsideBox, dataSt.statistics.homeInd.ShotsInsideBox, dataSt.statistics.homeH2H.ShotsInsideBox]);
        const averageShotsInsideBoxAway = calculateAverage([dataSt.statistics.away.ShotsInsideBox, dataSt.statistics.awayInd.ShotsInsideBox, dataSt.statistics.awayH2H.ShotsInsideBox]);

        const averageShotsOutsideBoxHome = calculateAverage([dataSt.statistics.home.ShotsOutsideBox, dataSt.statistics.homeInd.ShotsOutsideBox, dataSt.statistics.homeH2H.ShotsOutsideBox]);
        const averageShotsOutsideBoxAway = calculateAverage([dataSt.statistics.away.ShotsOutsideBox, dataSt.statistics.awayInd.ShotsOutsideBox, dataSt.statistics.awayH2H.ShotsOutsideBox]);

        const averageBigChancesHome = calculateAverage([dataSt.statistics.home.BigChances, dataSt.statistics.homeInd.BigChances, dataSt.statistics.homeH2H.BigChances]);
        const averageBigChancesAway = calculateAverage([dataSt.statistics.away.BigChances, dataSt.statistics.awayInd.BigChances, dataSt.statistics.awayH2H.BigChances]);

        const averageBigChancesMissedHome = calculateAverage([dataSt.statistics.home.BigChancesMissed, dataSt.statistics.homeInd.BigChancesMissed, dataSt.statistics.homeH2H.BigChancesMissed]);
        const averageBigChancesMissedAway = calculateAverage([dataSt.statistics.away.BigChancesMissed, dataSt.statistics.awayInd.BigChancesMissed, dataSt.statistics.awayH2H.BigChancesMissed]);

        const averageGoalkeeperSavesHome = calculateAverage([dataSt.statistics.home.GoalkeeperSaves, dataSt.statistics.homeInd.GoalkeeperSaves, dataSt.statistics.homeH2H.GoalkeeperSaves]);
        const averageGoalkeeperSavesAway = calculateAverage([dataSt.statistics.away.GoalkeeperSaves, dataSt.statistics.awayInd.GoalkeeperSaves, dataSt.statistics.awayH2H.GoalkeeperSaves]);

        const averageTotalShotHome = calculateAverage([dataSt.statistics.home.totalShot, dataSt.statistics.homeInd.totalShot, dataSt.statistics.homeH2H.totalShot]);
        const averageTotalShotAway = calculateAverage([dataSt.statistics.away.totalShot, dataSt.statistics.awayInd.totalShot, dataSt.statistics.awayH2H.totalShot]);

        const averageTotalGoalHome = calculateAverage([dataSt.statistics.home.goal, dataSt.statistics.homeInd.goal, dataSt.statistics.homeH2H.goal]);
        const averageTotalGoalAway = calculateAverage([dataSt.statistics.away.goal, dataSt.statistics.awayInd.goal, dataSt.statistics.awayH2H.goal]);

        const averageBallPossessionHome = calculateAverage([sumArray(dataSt.statistics.home.totalBallPossession), sumArray(dataSt.statistics.homeInd.totalBallPossession), sumArray(dataSt.statistics.homeH2H.totalBallPossession)]);
        const averageBallPossessionAway = calculateAverage([sumArray(dataSt.statistics.away.totalBallPossession), sumArray(dataSt.statistics.awayInd.totalBallPossession), sumArray(dataSt.statistics.awayH2H.totalBallPossession)]);

        const averageCorners = calculateAverage([dataSt.statistics.home.corners, dataSt.statistics.homeInd.corners, dataSt.statistics.homeH2H.corners, dataSt.statistics.away.corners, dataSt.statistics.awayInd.corners, dataSt.statistics.awayH2H.corners]);
  
        const averageFouls = calculateAverage([dataSt.statistics.home.fouls, dataSt.statistics.homeInd.fouls, dataSt.statistics.homeH2H.fouls, dataSt.statistics.away.fouls, dataSt.statistics.awayInd.fouls, dataSt.statistics.awayH2H.fouls]);
  
        const averageCards = calculateAverage([
          dataSt.statistics.home.yCard + dataSt.statistics.home.rCard,
          dataSt.statistics.homeInd.yCard + dataSt.statistics.homeInd.rCard,
          dataSt.statistics.homeH2H.yCard + dataSt.statistics.homeH2H.rCard,
          dataSt.statistics.away.yCard + dataSt.statistics.away.rCard,
          dataSt.statistics.awayInd.yCard + dataSt.statistics.awayInd.rCard,
          dataSt.statistics.awayH2H.yCard + dataSt.statistics.awayH2H.rCard,
        ]); 

        
        let arrGoalRHome = [], arrGoalRAway = [];
        const averageGoalsTotalR = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.home.totalGoals),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.away.totalGoals),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(data.home.totalGoalsR),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(data.away.totalGoalsR),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(data.homeInd.totalGoalsR),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(data.awayInd.totalGoalsR),
              weight: 0.2
            }
          } 
        }, { home: arrGoalRHome.concat(data.homeInd.totalGoalsR, data.home.totalGoalsR, dataH2H.home.totalGoals), away: arrGoalRAway.concat(data.awayInd.totalGoalsR, data.away.totalGoalsR, dataH2H.away.totalGoals) })
        
  
        const isDrawLikely = Math.abs(homeWinRate - awayWinRate) <= 10 && Math.abs(averageGoalsTotalR.home.rec - averageGoalsTotalR.away.rec) <= 0.5;

        const winRateDifference = (homeWinRate - awayWinRate);
        

        const homeHasClearAdvantage = winRateDifference > 10 && averageBallPossessionHome > averageBallPossessionAway + 5 && averageShotOnTargetHome > averageShotOnTargetAway;

        const awayHasClearAdvantage = winRateDifference < -10 && averageBallPossessionAway > averageBallPossessionHome+ 5 && averageShotOnTargetAway > averageShotOnTargetHome;
        let recommendedTeam = "";
        let recommendedTeam_ = "";
        if(pointH == pointA){
          recommendedTeam += "El encuentro es un posible Empate pero no es recomendable apostar";
          const dataJS = JSON.stringify({
            bet: 'Empate',
            betStatus: data_.homeScore > data_.awayScore ? true : data_.homeScore == data_.awayScore ? false : false,
            home: {
              ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
              shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
              bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
              goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
              goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
              blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
              winH2H: Array.from(String(data.homeH2H.win), Number),
            },
            away: {
              ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
              shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
              bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
              goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
              goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
              blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
              winH2H: Array.from(String(data.awayH2H.win), Number),
            }
          });
          const setDataG = {
            gameId: data.idGame,
            datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
            data: dataJS
          };
          if(winningStats.length > 0) {
            const patterns = advancedAnalysis(winningStats);
            const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
            console.log("Prediction: ", prediction);
            recommendedTeam_ += prediction;
          }
          setCollectionGames(setDataG);
        } else {
          if(pointH >= 5 && pointA <= 1) {
            recommendedTeam += "El Equipo local tiene alta probabilidad en Ganar";
            const dataJS = JSON.stringify({
              bet: 'Home Gana',
              betStatus: data_.homeScore > data_.awayScore ? true : data_.homeScore == data_.awayScore ? false : false,
              home: {
                ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                winH2H: Array.from(String(data.homeH2H.win), Number),
              },
              away: {
                ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                winH2H: Array.from(String(data.awayH2H.win), Number),
              }
            });
            const setDataG = {
              gameId: data.idGame,
              datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
              data: dataJS
            };
            if(winningStats.length > 0) {
              const patterns = advancedAnalysis(winningStats);
              const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
              console.log("Prediction: ", prediction);
              recommendedTeam_ += prediction;
            }

            setCollectionGames(setDataG);
          } else if(pointH >= 5 && pointA == 2){
            recommendedTeam += "El Equipo local Gana/Empata";
            const dataJS = JSON.stringify({
              bet: 'Home Gana/Empata',
              betStatus: data_.homeScore > data_.awayScore ? true : data_.homeScore == data_.awayScore ? true : false,
              home: {
                ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                winH2H: Array.from(String(data.homeH2H.win), Number),
              },
              away: {
                ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                winH2H: Array.from(String(data.awayH2H.win), Number),
              }
            });
            const setDataG = {
              gameId: data.idGame,
              datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
              data: dataJS
            };
            if(winningStats.length > 0) {
              const patterns = advancedAnalysis(winningStats);
              const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
              console.log("Prediction: ", prediction);
              recommendedTeam_ += prediction;
            } 

            setCollectionGames(setDataG);
          } else if(pointA >= 5 && pointH <= 1) {
            recommendedTeam += "El Equipo Visitante tiene alta probabilidad en Ganar";
            const dataJS = JSON.stringify({
              bet: 'Away Gana',
              betStatus: data_.homeScore < data_.awayScore ? true : data_.homeScore == data_.awayScore ? false : false,
              home: {
                ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                winH2H: Array.from(String(data.homeH2H.win), Number),
              },
              away: {
                ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                winH2H: Array.from(String(data.awayH2H.win), Number),
              }
            });
            const setDataG = {
              gameId: data.idGame,
              datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
              data: dataJS
            };
            if(winningStats.length > 0) {
              const patterns = advancedAnalysis(winningStats);
              const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
              console.log("Prediction: ", prediction);
              recommendedTeam_ += prediction;
            }

            setCollectionGames(setDataG);
          } else if(pointA >= 5 && pointH <= 2) {
            recommendedTeam += "El Equipo Visitante Gana/Empata";
            const dataJS = JSON.stringify({
              bet: 'Away Gana/Empata',
              betStatus: data_.homeScore < data_.awayScore ? true : data_.homeScore == data_.awayScore ? true : false,
              home: {
                ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                winH2H: Array.from(String(data.homeH2H.win), Number),
              },
              away: {
                ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                winH2H: Array.from(String(data.awayH2H.win), Number),
              }
            });
            const setDataG = {
              gameId: data.idGame,
              datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
              data: dataJS
            };
            if(winningStats.length > 0) {
              const patterns = advancedAnalysis(winningStats);
              const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
              console.log("Prediction: ", prediction);
              recommendedTeam_ += prediction;
            }

            setCollectionGames(setDataG);
          } else {
            if(pointH > pointA){
              recommendedTeam += "El encuentro es un posible Gana/Empata para el Equipo Local pero no es recomendable apostar";
              const dataJS = JSON.stringify({
                bet: 'Home Gana/Empata',
                betStatus: data_.homeScore > data_.awayScore ? true : data_.homeScore == data_.awayScore ? true : false,
                home: {
                  ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                  shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                  bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                  goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                  goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                  blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                  winH2H: Array.from(String(data.homeH2H.win), Number),
                },
                away: {
                  ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                  shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                  bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                  goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                  goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                  blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                  winH2H: Array.from(String(data.awayH2H.win), Number),
                }
              });
              const setDataG = {
                gameId: data.idGame,
                datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
                data: dataJS
              };
              if(winningStats.length > 0) {
                const patterns = advancedAnalysis(winningStats);
                const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
                console.log("Prediction: ", prediction);
                recommendedTeam_ += prediction;
              }

              setCollectionGames(setDataG);
            } else {
              recommendedTeam += "El encuentro es un posible Gana/Empata para el Equipo Visitante pero no es recomendable apostar";
              const dataJS = JSON.stringify({
                bet: 'Away Gana/Empata',
                betStatus: data_.homeScore < data_.awayScore ? true : data_.homeScore == data_.awayScore ? true : false,
                home: {
                  ballPossession: dataSt.statistics.home.totalBallPossession.concat(dataSt.statistics.homeInd.totalBallPossession, dataSt.statistics.homeH2H.totalBallPossession),
                  shotOnTarget: dataSt.statistics.home.totalShotOnTarget.concat(dataSt.statistics.homeInd.totalShotOnTarget, dataSt.statistics.homeH2H.totalShotOnTarget),
                  bigChances: dataSt.statistics.home.totalBigChances.concat(dataSt.statistics.homeInd.totalBigChances, dataSt.statistics.homeH2H.totalBigChances),
                  goalR: data.home.totalGoalsR.concat(data.homeInd.totalGoalsR, dataSt.statistics.homeH2H.totalGoalsR),
                  goalKeeperSave: dataSt.statistics.home.totalGoalkeeperSaves.concat(dataSt.statistics.homeInd.totalGoalkeeperSaves, dataSt.statistics.homeH2H.totalGoalkeeperSaves),
                  blockedShots: dataSt.statistics.home.totalBlockedShots.concat(dataSt.statistics.homeInd.totalBlockedShots, dataSt.statistics.homeH2H.totalBlockedShots),
                  winH2H: Array.from(String(data.homeH2H.win), Number),
                },
                away: {
                  ballPossession: dataSt.statistics.away.totalBallPossession.concat(dataSt.statistics.awayInd.totalBallPossession, dataSt.statistics.awayH2H.totalBallPossession),
                  shotOnTarget: dataSt.statistics.away.totalShotOnTarget.concat(dataSt.statistics.awayInd.totalShotOnTarget, dataSt.statistics.awayH2H.totalShotOnTarget),
                  bigChances: dataSt.statistics.away.totalBigChances.concat(dataSt.statistics.awayInd.totalBigChances, dataSt.statistics.awayH2H.totalBigChances),
                  goalR: data.away.totalGoalsR.concat(data.awayInd.totalGoalsR, dataSt.statistics.awayH2H.totalGoalsR),
                  goalKeeperSave: dataSt.statistics.away.totalGoalkeeperSaves.concat(dataSt.statistics.awayInd.totalGoalkeeperSaves, dataSt.statistics.awayH2H.totalGoalkeeperSaves),
                  blockedShots: dataSt.statistics.away.totalBlockedShots.concat(dataSt.statistics.awayInd.totalBlockedShots, dataSt.statistics.awayH2H.totalBlockedShots),
                  winH2H: Array.from(String(data.awayH2H.win), Number),
                }
              });
              const setDataG = {
                gameId: data.idGame,
                datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
                data: dataJS
              };
              if(winningStats.length > 0) {
                const patterns = advancedAnalysis(winningStats);
                const prediction = predictMatchOutcome(patterns, JSON.parse(dataJS));
                console.log("Prediction: ", prediction);
                recommendedTeam_ += prediction;
              }

              setCollectionGames(setDataG);
            }
          }
          
          
        }
        /*if((data.home.win + data.home.tie + data.home.loss + data.away.win + data.away.tie + data.away.loss) >= 10 && (data.homeInd.win + data.homeInd.tie + data.homeInd.loss + data.awayInd.win + data.awayInd.tie + data.awayInd.loss) >= 5) {// && (dataH2H.home.win + dataH2H.home.tie + dataH2H.home.loss + dataH2H.away.win + dataH2H.away.tie + dataH2H.away.loss) >= 5){
          if (homeHasClearAdvantage) {
            recommendedTeam = "El equipo local tiene una alta probabilidad de ganar.";
            if(data_.homeScore <= data_.awayScore){
              const setDataG = {
                gameId: data.idGame,
                datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
                data: JSON.stringify({recommendedTeam,idGame: data.idGame,estimatedGoals: averageGoalsHome + averageGoalsAway,estimatedCorners: averageCorners,estimatedFouls: averageFouls,estimatedCards: averageCards,estimatedBallPossessionHome: averageBallPossessionHome,estimatedBallPossessionAway: averageBallPossessionAway,estimatedGoalsRHome: averageGoalsRHome,estimatedGoalsRAway: averageGoalsRAway,estimatedShotOnTargetHome: averageShotOnTargetHome,estimatedShotOnTargetAway: averageShotOnTargetAway,})
              };
              setCollectionGames(setDataG);
            }
          } else if (awayHasClearAdvantage) {
            recommendedTeam = "El equipo visitante tiene una alta probabilidad de ganar.";
            if(data_.homeScore >= data_.awayScore){
              const setDataG = {
                gameId: data.idGame,
                datetime: String(dateGame_.getDate()).padStart(2, '0') + '/' + String(dateGame_.getMonth() + 1).padStart(2, '0') + '/' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + dateGame_.getMinutes(), 
                data: JSON.stringify({recommendedTeam,idGame: data.idGame,estimatedGoals: averageGoalsHome + averageGoalsAway,estimatedCorners: averageCorners,estimatedFouls: averageFouls,estimatedCards: averageCards,estimatedBallPossessionHome: averageBallPossessionHome,estimatedBallPossessionAway: averageBallPossessionAway,estimatedGoalsRHome: averageGoalsRHome,estimatedGoalsRAway: averageGoalsRAway,estimatedShotOnTargetHome: averageShotOnTargetHome,estimatedShotOnTargetAway: averageShotOnTargetAway,})
              };
              setCollectionGames(setDataG);
            }
          } else {
            // Aquí se puede ajustar para reflejar una ventaja ligera o condiciones de empate basadas en la diferencia de la tasa de victoria.
            if (winRateDifference > 0) {
              recommendedTeam = "El equipo local tiene una ligera ventaja, pero un empate también es posible.";
              if(data_.homeScore < data_.awayScore){
                const setDataG = {
                  gameId: data.idGame,
                  datetime: String(String(dateGame_.getMonth() + 1).padStart(2, '0') + '-' + dateGame_.getDate()).padStart(2, '0') + '-' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + String(dateGame_.getMinutes()).padStart(2, '0'), 
                  data: JSON.stringify({recommendedTeam,idGame: data.idGame,estimatedGoals: averageGoalsHome + averageGoalsAway,estimatedCorners: averageCorners,estimatedFouls: averageFouls,estimatedCards: averageCards,estimatedBallPossessionHome: averageBallPossessionHome,estimatedBallPossessionAway: averageBallPossessionAway,estimatedGoalsRHome: averageGoalsRHome,estimatedGoalsRAway: averageGoalsRAway,estimatedShotOnTargetHome: averageShotOnTargetHome,estimatedShotOnTargetAway: averageShotOnTargetAway,})
                };
                setCollectionGames(setDataG);
              }
            } else if (winRateDifference < 0) {
              recommendedTeam = "El equipo visitante tiene una ligera ventaja, pero un empate también es posible.";
              if(data_.homeScore > data_.awayScore){
                const setDataG = {
                  gameId: data.idGame,
                  datetime: String(String(dateGame_.getMonth() + 1).padStart(2, '0') + '-' + dateGame_.getDate()).padStart(2, '0') + '-' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + String(dateGame_.getMinutes()).padStart(2, '0'), 
                  data: JSON.stringify({recommendedTeam,idGame: data.idGame,estimatedGoals: averageGoalsHome + averageGoalsAway,estimatedCorners: averageCorners,estimatedFouls: averageFouls,estimatedCards: averageCards,estimatedBallPossessionHome: averageBallPossessionHome,estimatedBallPossessionAway: averageBallPossessionAway,estimatedGoalsRHome: averageGoalsRHome,estimatedGoalsRAway: averageGoalsRAway,estimatedShotOnTargetHome: averageShotOnTargetHome,estimatedShotOnTargetAway: averageShotOnTargetAway,})
                };
                setCollectionGames(setDataG);
              }
            } else {
              if (isDrawLikely) {
                recommendedTeam = "El partido tiene una alta probabilidad de terminar en empate.";
                if(data_.homeScore > data_.awayScore || data_.homeScore < data_.awayScore){
                  const setDataG = {
                    gameId: data.idGame,
                    datetime: String(String(dateGame_.getMonth() + 1).padStart(2, '0') + '-' + dateGame_.getDate()).padStart(2, '0') + '-' + dateGame_.getFullYear() + ' ' + dateGame_.getHours() + ':' + String(dateGame_.getMinutes()).padStart(2, '0'), 
                    data: JSON.stringify({recommendedTeam,idGame: data.idGame,estimatedGoals: averageGoalsHome + averageGoalsAway,estimatedCorners: averageCorners,estimatedFouls: averageFouls,estimatedCards: averageCards,estimatedBallPossessionHome: averageBallPossessionHome,estimatedBallPossessionAway: averageBallPossessionAway,estimatedGoalsRHome: averageGoalsRHome,estimatedGoalsRAway: averageGoalsRAway,estimatedShotOnTargetHome: averageShotOnTargetHome,estimatedShotOnTargetAway: averageShotOnTargetAway,})
                  };
                  setCollectionGames(setDataG);
                }
              } else {
                recommendedTeam = "Los equipos están muy igualados, el resultado es difícil de predecir pero puede resultar un empate.";
              }
            }
          }
        } else {
          recommendedTeam += "No es recomendable apostar en este encuentro por que no hay suficientes datos";
        }*/
        /*if(gameStatistcs.length > 0) {

          console.log(findCommonParameters({
            recommendedTeam,
            idGame: data.idGame,
            estimatedGoals: averageGoalsHome + averageGoalsAway,
            estimatedGoalsHome: averageGoalsHome,
            estimatedGoalsAway: averageGoalsAway,
            estimatedCorners: averageCorners,
            estimatedDefenceHome: averageBlockedShotsHome,
            estimatedDefenceAway: averageBlockedShotsAway,
            estimatedBigChancesHome: averageBigChancesHome,
            estimatedBigChancesAway: averageBigChancesAway,
            estimatedKSavesHome: averageGoalkeeperSavesHome,
            estimatedKSavesAway: averageGoalkeeperSavesAway,
            estimatedFouls: averageFouls,
            estimatedCards: averageCards,
            estimatedBallPossessionHome: averageBallPossessionHome,
            estimatedBallPossessionAway: averageBallPossessionAway,
            estimatedGoalsRHome: averageGoalsRHome,
            estimatedGoalsRAway: averageGoalsRAway,
            estimatedShotOnTargetHome: averageShotOnTargetHome,
            estimatedShotOnTargetAway: averageShotOnTargetAway,
          }, gameStatistcs));
        }*/
        
        let arrCornerHome = [], arrCornerAway = [];
        const averageCornersTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalCorner),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalCorner),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalCorner),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalCorner),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalCorner),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalCorner),
              weight: 0.2
            }
          } 
        }, { home: arrCornerHome.concat(dataH2H.statistics.home.totalCorner, dataSt.statistics.home.totalCorner, dataSt.statistics.homeInd.totalCorner), away: arrCornerAway.concat(dataH2H.statistics.away.totalCorner, dataSt.statistics.away.totalCorner, dataSt.statistics.awayInd.totalCorner) })
        
        let arrGoalHome = [], arrGoalAway = [];
        const averageGoalsTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.home.totalGoals),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.away.totalGoals),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(data.home.totalGoals),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(data.away.totalGoals),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(data.homeInd.totalGoals),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(data.awayInd.totalGoals),
              weight: 0.2
            }
          } 
        }, { home: arrGoalHome.concat(dataH2H.home.totalGoals, data.home.totalGoals, data.homeInd.totalGoals), away: arrGoalAway.concat(dataH2H.away.totalGoals, data.away.totalGoals, data.awayInd.totalGoals) })
        
        let arrPBallHome = [], arrPBallAway = [];
        const averagePBallTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalBallPossession),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalBallPossession),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalBallPossession),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalBallPossession),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalBallPossession),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalBallPossession),
              weight: 0.2
            }
          } 
        }, { home: arrPBallHome.concat(dataH2H.statistics.home.totalBallPossession, dataSt.statistics.home.totalBallPossession, dataSt.statistics.homeInd.totalBallPossession), away: arrPBallAway.concat(dataH2H.statistics.away.totalBallPossession, dataSt.statistics.away.totalBallPossession, dataSt.statistics.awayInd.totalBallPossession) })
        
        let arrBCHHome = [], arrBCHAway = [];
        const averageBCHTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalBigChances),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalBigChances),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalBigChances),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalBigChances),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalBigChances),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalBigChances),
              weight: 0.2
            }
          } 
        }, { home: arrBCHHome.concat(dataH2H.statistics.home.totalBigChances, dataSt.statistics.home.totalBigChances, dataSt.statistics.homeInd.totalBigChances), away: arrBCHAway.concat(dataH2H.statistics.away.totalBigChances, dataSt.statistics.away.totalBigChances, dataSt.statistics.awayInd.totalBigChances) })
        
        let arrBCHMHome = [], arrBCHMAway = [];
        const averageBCHMTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalBigChancesMissed),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalBigChancesMissed),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalBigChancesMissed),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalBigChancesMissed),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalBigChancesMissed),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalBigChancesMissed),
              weight: 0.2
            }
          } 
        }, { home: arrBCHMHome.concat(dataH2H.statistics.home.totalBigChancesMissed, dataSt.statistics.home.totalBigChancesMissed, dataSt.statistics.homeInd.totalBigChancesMissed), away: arrBCHMAway.concat(dataH2H.statistics.away.totalBigChancesMissed, dataSt.statistics.away.totalBigChancesMissed, dataSt.statistics.awayInd.totalBigChancesMissed) })
        
        let arrBlockShHome = [], arrBlockShAway = [];
        const averageBlockShTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalBlockedShots),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalBlockedShots),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalBlockedShots),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalBlockedShots),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalBlockedShots),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalBlockedShots),
              weight: 0.2
            }
          } 
        }, { home: arrBlockShHome.concat(dataH2H.statistics.home.totalBlockedShots, dataSt.statistics.home.totalBlockedShots, dataSt.statistics.homeInd.totalBlockedShots), away: arrBlockShAway.concat(dataH2H.statistics.away.totalBlockedShots, dataSt.statistics.away.totalBlockedShots, dataSt.statistics.awayInd.totalBlockedShots) })
        
        let arrFoulsHome = [], arrFoulsAway = [];
        const averageFoulsTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalFouls),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalFouls),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalFouls),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalFouls),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalFouls),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalFouls),
              weight: 0.2
            }
          } 
        }, { home: arrFoulsHome.concat(dataH2H.statistics.home.totalFouls, dataSt.statistics.home.totalFouls, dataSt.statistics.homeInd.totalFouls), away: arrFoulsAway.concat(dataH2H.statistics.away.totalFouls, dataSt.statistics.away.totalFouls, dataSt.statistics.awayInd.totalFouls) })
        
        let arrGKSHome = [], arrGKSAway = [];
        const averageGKSTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalGoalkeeperSaves),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalGoalkeeperSaves),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalGoalkeeperSaves),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalGoalkeeperSaves),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalGoalkeeperSaves),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalGoalkeeperSaves),
              weight: 0.2
            }
          } 
        }, { home: arrGKSHome.concat(dataH2H.statistics.home.totalGoalkeeperSaves, dataSt.statistics.home.totalGoalkeeperSaves, dataSt.statistics.homeInd.totalGoalkeeperSaves), away: arrGKSAway.concat(dataH2H.statistics.away.totalGoalkeeperSaves, dataSt.statistics.away.totalGoalkeeperSaves, dataSt.statistics.awayInd.totalGoalkeeperSaves) })
        
        let arrCardsHome = [], arrCardsAway = [];
        const averageCardsTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalRCard.concat(dataH2H.statistics.home.totalYCard)),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalRCard.concat(dataH2H.statistics.away.totalYCard)),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalRCard.concat(dataSt.statistics.home.totalYCard)),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalRCard.concat(dataSt.statistics.away.totalYCard)),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalRCard.concat(dataSt.statistics.homeInd.totalYCard)),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalRCard.concat(dataSt.statistics.awayInd.totalYCard)),
              weight: 0.2
            }
          } 
        }, { home: arrCardsHome.concat(dataH2H.statistics.home.totalRCard.concat(dataH2H.statistics.home.totalYCard), dataSt.statistics.home.totalRCard.concat(dataSt.statistics.home.totalYCard), dataSt.statistics.homeInd.totalRCard.concat(dataSt.statistics.homeInd.totalYCard)), away: arrCardsAway.concat(dataH2H.statistics.away.totalRCard.concat(dataH2H.statistics.away.totalYCard), dataSt.statistics.away.totalRCard.concat(dataSt.statistics.away.totalYCard), dataSt.statistics.awayInd.totalRCard.concat(dataSt.statistics.awayInd.totalYCard)) })
        
        let arrShOffHome = [], arrShOffAway = [];
        const averageShOffTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalShotOffTarget),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalShotOffTarget),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalShotOffTarget),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalShotOffTarget),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalShotOffTarget),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalShotOffTarget),
              weight: 0.2
            }
          } 
        }, { home: arrShOffHome.concat(dataH2H.statistics.home.totalShotOffTarget, dataSt.statistics.home.totalShotOffTarget, dataSt.statistics.homeInd.totalShotOffTarget), away: arrShOffAway.concat(dataH2H.statistics.away.totalShotOffTarget, dataSt.statistics.away.totalShotOffTarget, dataSt.statistics.awayInd.totalShotOffTarget) })
        
        let arrShOnHome = [], arrShOnAway = [];
        const averageShOnTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalShotOffTarget),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalShotOffTarget),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalShotOffTarget),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalShotOffTarget),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalShotOffTarget),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalShotOffTarget),
              weight: 0.2
            }
          } 
        }, { home: arrShOnHome.concat(dataH2H.statistics.home.totalShotOffTarget, dataSt.statistics.home.totalShotOffTarget, dataSt.statistics.homeInd.totalShotOffTarget), away: arrShOnAway.concat(dataH2H.statistics.away.totalShotOffTarget, dataSt.statistics.away.totalShotOffTarget, dataSt.statistics.awayInd.totalShotOffTarget) })
        
        let arrShHome = [], arrShAway = [];
        const averageShTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalShots),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalShots),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalShots),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalShots),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalShots),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalShots),
              weight: 0.2
            }
          } 
        }, { home: arrShHome.concat(dataH2H.statistics.home.totalShots, dataSt.statistics.home.totalShots, dataSt.statistics.homeInd.totalShots), away: arrShAway.concat(dataH2H.statistics.away.totalShots, dataSt.statistics.away.totalShots, dataSt.statistics.awayInd.totalShots) })
        
        let arrShInHome = [], arrShInAway = [];
        const averageShInTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalShotsInsideBox),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalShotsInsideBox),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalShotsInsideBox),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalShotsInsideBox),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalShotsInsideBox),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalShotsInsideBox),
              weight: 0.2
            }
          } 
        }, { home: arrShInHome.concat(dataH2H.statistics.home.totalShotsInsideBox, dataSt.statistics.home.totalShotsInsideBox, dataSt.statistics.homeInd.totalShotsInsideBox), away: arrShInAway.concat(dataH2H.statistics.away.totalShotsInsideBox, dataSt.statistics.away.totalShotsInsideBox, dataSt.statistics.awayInd.totalShotsInsideBox) })
        
        let arrShOutHome = [], arrShOutAway = [];
        const averageShOutTotal = calculateWeightedAverage({ 
          h2h: {
            home: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.home.totalShotsOutsideBox),
              weight: 0.5
            },
            away: {
              mean: calculateEnhancedPrediction(dataH2H.statistics.away.totalShotsOutsideBox),
              weight: 0.5
            }
          },
          homeAway: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.home.totalShotsOutsideBox),
              weight: 0.3
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.away.totalShotsOutsideBox),
              weight: 0.3
            }
          },
          individual: {
            home: {
              mean: calculateEnhancedPrediction(dataSt.statistics.homeInd.totalShotsOutsideBox),
              weight: 0.2
            },
            away: {
              mean: calculateEnhancedPrediction(dataSt.statistics.awayInd.totalShotsOutsideBox),
              weight: 0.2
            }
          } 
        }, { home: arrShOutHome.concat(dataH2H.statistics.home.totalShotsOutsideBox, dataSt.statistics.home.totalShotsOutsideBox, dataSt.statistics.homeInd.totalShotsOutsideBox), away: arrShOutAway.concat(dataH2H.statistics.away.totalShotsOutsideBox, dataSt.statistics.away.totalShotsOutsideBox, dataSt.statistics.awayInd.totalShotsOutsideBox) })

        setAnalysisResult({
          recommendedTeam,
          recommendedTeam_,
          idGame: data.idGame,
          estimatedGoals: {min: Math.round((averageGoalsTotal.home.min < 0 ? 0 : averageGoalsTotal.home.min) + averageGoalsTotal.away.min), rec: Math.round(averageGoalsTotal.home.rec + averageGoalsTotal.away.rec), max: Math.round(averageGoalsTotal.home.max + averageGoalsTotal.away.max)},
          estimatedGoalsHome: {min: Math.round((averageGoalsTotal.home.min < 0 ? 0 : averageGoalsTotal.home.min)), rec: Math.round(averageGoalsTotal.home.rec), max: Math.round(averageGoalsTotal.home.max)},
          estimatedGoalsAway: {min: Math.round(averageGoalsTotal.away.min < 0 ? 0 : averageGoalsTotal.away.min), rec: Math.round(averageGoalsTotal.away.rec), max: Math.round(averageGoalsTotal.away.max)},
          estimatedCorners: {min: Math.round(averageCornersTotal.home.min + averageCornersTotal.away.min), rec: Math.round(averageCornersTotal.home.rec + averageCornersTotal.away.rec), max: Math.round(averageCornersTotal.home.max + averageCornersTotal.away.max)},
          estimatedDefenceHome: {min: Math.round(averageBlockShTotal.home.min), rec: Math.round(averageBlockShTotal.home.rec), max: Math.round(averageBlockShTotal.home.max)},
          estimatedDefenceAway: {min: Math.round(averageBlockShTotal.away.min), rec: Math.round(averageBlockShTotal.away.rec), max: Math.round(averageBlockShTotal.away.max)},
          estimatedBigChancesHome: {min: Math.round(averageBCHTotal.home.min), rec: Math.round(averageBCHTotal.home.rec), max: Math.round(averageBCHTotal.home.max)},
          estimatedBigChancesAway: {min: Math.round(averageBCHTotal.away.min), rec: Math.round(averageBCHTotal.away.rec), max: Math.round(averageBCHTotal.away.max)},
          estimatedKSavesHome: {min: Math.round(averageGKSTotal.home.min), rec: Math.round(averageGKSTotal.home.rec), max: Math.round(averageGKSTotal.home.max)},
          estimatedKSavesAway: {min: Math.round(averageGKSTotal.away.min), rec: Math.round(averageGKSTotal.away.rec), max: Math.round(averageGKSTotal.away.max)},
          estimatedFouls: {min: Math.round(averageFoulsTotal.home.min + averageFoulsTotal.away.min), rec: Math.round(averageFoulsTotal.home.rec + averageFoulsTotal.away.rec), max: Math.round(averageFoulsTotal.home.max + averageFoulsTotal.away.max)},
          estimatedCards: {min: Math.round(averageCardsTotal.home.min + averageCardsTotal.away.min), rec: Math.round(averageCardsTotal.home.rec + averageCardsTotal.away.rec), max: Math.round(averageCardsTotal.home.max + averageCardsTotal.away.max)},
          estimatedBallPossessionHome: {min: Math.round((averagePBallTotal.home.min < 0 ? 0 : averagePBallTotal.home.min)), rec: Math.round(averagePBallTotal.home.rec), max: Math.round(averagePBallTotal.home.max)},
          estimatedBallPossessionAway: {min: Math.round((averagePBallTotal.away.min < 0 ? 0 : averagePBallTotal.away.min)), rec: Math.round(averagePBallTotal.away.rec), max: Math.round(averagePBallTotal.away.max)},
          estimatedGoalsRHome: {min: Math.round((averageGoalsTotalR.home.min < 0 ? 0 : averageGoalsTotalR.home.min)), rec: Math.round(averageGoalsTotalR.home.rec), max: Math.round(averageGoalsTotalR.home.max)},
          estimatedGoalsRAway: {min: Math.round(averageGoalsTotalR.away.min < 0 ? 0 : averageGoalsTotalR.away.min), rec: Math.round(averageGoalsTotalR.away.rec), max: Math.round(averageGoalsTotalR.away.max)},
          estimatedShotOnTargetHome: {min: Math.round(averageShOnTotal.home.min), rec: Math.round(averageShOnTotal.home.rec), max: Math.round(averageShOnTotal.home.max)},
          estimatedShotOnTargetAway: {min: Math.round(averageShOnTotal.away.min), rec: Math.round(averageShOnTotal.away.rec), max: Math.round(averageShOnTotal.away.max)},
        });
        // console.log(JSON.stringify({
        //   recommendedTeam,
        //   idGame: data.idGame,
        //   estimatedGoals: averageGoalsHome + averageGoalsAway,
        //   estimatedCorners: averageCorners,
        //   estimatedFouls: averageFouls,
        //   estimatedCards: averageCards,
        //   estimatedBallPossessionHome: averageBallPossessionHome,
        //   estimatedBallPossessionAway: averageBallPossessionAway,
        //   estimatedGoalsRHome: averageGoalsRHome,
        //   estimatedGoalsRAway: averageGoalsRAway,
        //   estimatedShotOnTargetHome: averageShotOnTargetHome,
        //   estimatedShotOnTargetAway: averageShotOnTargetAway,
        // }))   
      };
  
      analyzeStatistics();
    }
  }, [data, dataSt,gameStatistcs]);

  return (
    <div>
      <h2>Match Analysis Result</h2> 
      <p>{analysisResult.recommendedTeam}</p>
      <p>New Formula: {analysisResult.recommendedTeam_}</p>
      <p>Estimated Total Goals: Min: {analysisResult.estimatedGoals.min} | Max: {analysisResult.estimatedGoals.max} | Recommended: Under/Over({analysisResult.estimatedGoals.rec})</p>
      <p>Estimated Total Goals Home: Min: {analysisResult.estimatedGoalsHome.min} | Max: {analysisResult.estimatedGoalsHome.max} | Recommended: Under/Over({analysisResult.estimatedGoalsHome.rec})</p>
      <p>Estimated Total Goals Away: Min: {analysisResult.estimatedGoalsAway.min} | Max: {analysisResult.estimatedGoalsAway.max} | Recommended: Under/Over({analysisResult.estimatedGoalsAway.rec})</p>
      <p>Estimated Home Total Goal Received: Min: {analysisResult.estimatedGoalsRHome.min} | Max: {analysisResult.estimatedGoalsRHome.max} | Recommended: Under/Over({analysisResult.estimatedGoalsRHome.rec})</p>
      <p>Estimated Away Total Goal Received: Min: {analysisResult.estimatedGoalsRAway.min} | Max: {analysisResult.estimatedGoalsRAway.max} | Recommended: Under/Over({analysisResult.estimatedGoalsRAway.rec})</p>
      <p>Estimated Total Corners: Min: {analysisResult.estimatedCorners.min} | Max: {analysisResult.estimatedCorners.max} | Recommended: Under/Over({analysisResult.estimatedCorners.rec})</p>
      <p>Estimated Total Fouls: Min: {analysisResult.estimatedFouls.min} | Max: {analysisResult.estimatedFouls.max} | Recommended: Under/Over({analysisResult.estimatedFouls.rec})</p>
      <p>Estimated Total Cards: Min: {analysisResult.estimatedCards.min} | Max: {analysisResult.estimatedCards.max} | Recommended: Under/Over({analysisResult.estimatedCards.rec})</p>
      <p>Estimated Home Total BigChances: Min: {analysisResult.estimatedBigChancesHome.min} | Max: {analysisResult.estimatedBigChancesHome.max} | Recommended: Under/Over({analysisResult.estimatedBigChancesHome.rec})</p>
      <p>Estimated Away Total BigChances: Min: {analysisResult.estimatedBigChancesAway.min} | Max: {analysisResult.estimatedBigChancesAway.max} | Recommended: Under/Over({analysisResult.estimatedBigChancesAway.rec})</p>
      <p>Estimated Home Total Defense: Min: {analysisResult.estimatedDefenceHome.min} | Max: {analysisResult.estimatedDefenceHome.max} | Recommended: Under/Over({analysisResult.estimatedDefenceHome.rec})</p>
      <p>Estimated Away Total Defense: Min: {analysisResult.estimatedDefenceAway.min} | Max: {analysisResult.estimatedDefenceAway.max} | Recommended: Under/Over({analysisResult.estimatedDefenceAway.rec})</p>
      <p>Estimated Home Total Keeper Saves: Min: {analysisResult.estimatedKSavesHome.min} | Max: {analysisResult.estimatedKSavesHome.max} | Recommended: Under/Over({analysisResult.estimatedKSavesHome.rec})</p>
      <p>Estimated Away Total Keeper Saves: Min: {analysisResult.estimatedKSavesAway.min} | Max: {analysisResult.estimatedKSavesAway.max} | Recommended: Under/Over({analysisResult.estimatedKSavesAway.rec})</p>
      <p>Estimated Home Total BallPossession: {analysisResult.estimatedBallPossessionHome.rec}</p>
      <p>Estimated Away Total BallPossession: {analysisResult.estimatedBallPossessionAway.rec}</p>
      <p>Estimated Home Total Shot On Target: Min: {analysisResult.estimatedShotOnTargetHome.min} | Max: {analysisResult.estimatedShotOnTargetHome.max} | Recommended: Under/Over({analysisResult.estimatedShotOnTargetHome.rec})</p>
      <p>Estimated Away Total Shot On Target: Min: {analysisResult.estimatedShotOnTargetAway.min} | Max: {analysisResult.estimatedShotOnTargetAway.max} | Recommended: Under/Over({analysisResult.estimatedShotOnTargetAway.rec})</p>

      <TensorFlowComponent matchData={matchData} />
    </div>
  );
};

export default DetailedAnalysis;
