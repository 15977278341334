import React, { useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';

const TensorFlowComponent = (matchData) => {
  const [model, setModel] = useState(null);
  const [isTrained, setIsTrained] = useState(false);
  const [prediction, setPrediction] = useState('');
  // Preparar los datos para el modelo 
  const prepareData = () => {
    const inputs = matchData.matchData.map(match => [match.homeScore, match.awayScore]);
    const labels = matchData.matchData.map(match => {
      switch (match.result) {
        case "tie": return [1, 0, 0];
        case "home winner": return [0, 1, 0];
        case "away winner": return [0, 0, 1];
        default: return [0, 0, 0];
      }
    });

    const xs = tf.tensor2d(inputs);
    const ys = tf.tensor2d(labels);

    return { xs, ys };
  };

  // Crear el modelo
  const createModel = () => {
    const model = tf.sequential();
    model.add(tf.layers.dense({ inputShape: [2], units: 16, activation: 'relu' }));
    model.add(tf.layers.dense({ units: 32, activation: 'relu' }));
    model.add(tf.layers.dense({ units: 3, activation: 'softmax' }));

    model.compile({
      optimizer: 'adam',
      loss: 'categoricalCrossentropy',
      metrics: ['accuracy']
    });

    return model;
  };

  // Entrenar el modelo
  const trainModel = async (xs, ys) => {
    const model = createModel();
    await model.fit(xs, ys, {
      epochs: 50,
      callbacks: {
        onEpochEnd: (epoch, log) => console.log(`Epoch ${epoch}: loss = ${log.loss}, accuracy = ${log.acc}`)
      }
    });
    setModel(model);
    setIsTrained(true);
    alert('Ready');
  };

  // Hacer una predicción
  const predictResult = (homeScore, awayScore) => {
    if (!model || !isTrained) {
      alert('Please train the model first!');
      return;
    }
    const inputTensor = tf.tensor2d([[homeScore, awayScore]]);
    const prediction = model.predict(inputTensor);
    const predictedIndex = prediction.argMax(-1).dataSync()[0];
    setPrediction(["Tie", "Home Winner", "Away Winner"][predictedIndex]);
  };

  // Iniciar el entrenamiento del modelo con datos
  const handleTrain = () => {
    const { xs, ys } = prepareData(matchData);
    trainModel(xs, ys);
  };
 
  return (
    <div>
      <h1>Match Predictor</h1>
      <button onClick={handleTrain}>Train Model</button>
      <div>
        <button onClick={() => predictResult(50, 50)}>Predict Result for Home: 50, Away: 50</button>
        <p>Prediction: {prediction}</p>
      </div>
    </div>
  );
};

export default TensorFlowComponent;
