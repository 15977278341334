// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB9NMiFLZTibBB1vZENbZ8zlUpKlF6qEHs",
    authDomain: "checkgame-85100.firebaseapp.com",
    projectId: "checkgame-85100",
    storageBucket: "checkgame-85100.appspot.com",
    messagingSenderId: "484690031298",
    appId: "1:484690031298:web:308ab744ea0b0a8533815b",
    measurementId: "G-4BY33156WL"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app); 
 
export { db, storage };