// Modal.js
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import H2HMatch from './H2HMatch';
import StatisticsTeamV from './StatisticsTeamV';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '90%',
    height: '90%',
    maxWidth: '800px', // Tamaño máximo si el contenido es muy grande
    maxHeight: '800px', // Tamaño máximo si el contenido es muy grande
    overflow: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
}));

const Modal = ({ isOpen, handleClose, data }) => {
  const [h2hData, setH2hData] = useState(null);
    const classes = useStyles();
    
    useEffect(() => {
      if (!isOpen) {
        setH2hData(null); // Reinicia los datos al cerrar el modal
      }
    }, [isOpen]); // Depende de 'isOpen' para activarse
  
    if (!isOpen) {
      return null;
    }

    const updateH2HData = (data) => {
      setH2hData(data);
    };
  
    return (
      <div className={classes.modal}>
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <Typography variant="h5">
              {data.homeTeam} vs {data.awayTeam} | ({data.homeScore}) - ({data.awayScore})
            </Typography>
            <CloseIcon className={classes.closeButton} onClick={handleClose} />
          </div>
          <div variant="body1">
            <H2HMatch data_={data} updateH2HData={updateH2HData} />
            {h2hData && (
            <StatisticsTeamV data_={data} dataH2H={h2hData} close={handleClose} />
            )}
          </div>
        </div>
      </div>
    );
  };

export default Modal;
