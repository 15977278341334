// StatisticsTeamV.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography  } from '@material-ui/core';
import '../../index.css';
import DetailedAnalysis from './DetailedAnalysis'
import { db, storage } from '../../Firebase';
import { collection, where, getDocs, getDoc, addDoc, onSnapshot, query, doc, setDoc } from 'firebase/firestore';




const sumArray = (array) => array.reduce((sum, value) => sum + value, 0);

function initializeStatistics() {
    return {
      load: true,
      fouls: 0,
      corners: 0,
      yCard: 0,
      rCard: 0,
      ballPossession: 0,
      shotOnTarget: 0,
      shotOffTarget: 0,
      BlockedShots: 0,
      ShotsInsideBox: 0,
      ShotsOutsideBox: 0,
      BigChances: 0,
      BigChancesMissed: 0,
      GoalkeeperSaves: 0,
      goal: 0,
      goalR: 0,
      totalShot: 0,
      totalCorner: [],
      totalShots: [],
      totalShotOnTarget: [],
      totalShotOffTarget: [],
      totalBigChances: [],
      totalBigChancesMissed: [],
      totalGoalkeeperSaves: [],
      totalBlockedShots: [],
      totalFouls: [],
      totalYCard: [],
      totalRCard: [],
      totalBallPossession: [],
      totalShotsInsideBox: [],
      totalShotsOutsideBox: []
    };
}

// Función para calcular estadísticas por juego
function calculatePerGameStats(team, data, matches) {
    if (typeof matches === 'undefined' || matches === 0) {
        // console.error("No matches provided or matches are zero, cannot calculate stats.");
        return; // Early return to handle undefined matches or zero matches
    }
    // console.log(  data)
    const statsKeys = [
        'corners', 'fouls', 'cardY', 'cardR', 'ballPossession', 'shotOnTarget',
        'shotOffTarget', 'BlockedShots', 'ShotsInsideBox', 'ShotsOutsideBox',
        'BigChances', 'BigChancesMissed', 'GoalkeeperSaves', 'goal', 'goalR', 'totalShot'
    ];
 
    statsKeys.forEach(key => {
        if (key === "ballPossession") {
            // console.log("")
            // console.log(team)
            // console.log(team[key], roundToTwo(sumArray(data.totalBallPossession) / matches), sumArray(data.totalBallPossession))
            let totalBallPossession = sumArray(data.totalBallPossession);
            team[key] = roundToTwo(totalBallPossession / matches);
            // console.log(team[key])
        } else if (data[key] !== undefined) { // Ensure the key exists in data
            team[key] = roundToTwo(data[key] / matches);
        } else {
            console.warn(`Key ${key} not found in data`);
        }
    });

    // console.log(team); // Logging the updated team object
}

// Función para sumar estadísticas
function addStatistics(team, stats, isH2H = false) {
    if (stats && typeof stats === 'object') {
      Object.keys(stats).forEach(key => {
        if (Array.isArray(team[key])) {
          team[key] = stats[key];
        } else if (typeof team[key] === 'number' && isH2H) {
          team[key] = stats[key];
        } 
      }); 
  }
}

function getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '-' + day + '-' + year;
}

function compareDates(dateStr1, dateStr2) {
    // Crear objetos Date a partir de las cadenas de fecha
    // Asumiendo que el formato de fecha es DD-MM-AAAA
    const parts1 = dateStr1.split('-');
    const parts2 = dateStr2.split('-');
    
    // Ten en cuenta que los meses en JavaScript son base-0 (0 para enero, 11 para diciembre)
    const date1 = new Date(parts1[2], parts1[0] - 1, parts1[1]);
    const date2 = new Date(parts2[2], parts2[0] - 1, parts2[1]);
    
    // Compara las fechas
    return date1 < date2;
}

function roundToTwo(num) {
    if(num == 0) { return 0; }
    const parteEntera = Math.floor(num);
    const parteDecimal = num - parteEntera;
  
    if (parteDecimal < 0.25) {
      return parteEntera;
    } else if (parteDecimal < 0.75) {
      return parteEntera + 0.5;
    } else {
      return parteEntera + 1;
    }
} 

const setCollectionNoFound = async (idGame, table) => {
    try {
      // const docRef = doc(db, "gameStatistics", data.gameId.toString());
      // const docSnap = await getDoc(docRef);
    
      // if(!docSnap.exists()){
        // const gameStatistcsCollection = await setDoc(doc(db, table, idGame), {idGame: idGame});
        // console.log(gameStatistcsCollection);
      // }    
    } catch (error) { 
    //   console.error(error)
    }
}

async function fetchAndProcessGameShotmap(gameId) {
    let stats = { goal: 0, goalR: 0 };
    try {
        // const docRef = doc(db, "shotmapNotFound", gameId.game.toString());
        // const docSnap = await getDoc(docRef);
        // if(!docSnap.exists()) {
            const response = await axios.get(`https://api.sofascore.com/api/v1/event/${gameId.game}/shotmap`);
            if (response.status === 200) {
                const dataStatist = response.data.shotmap;
                dataStatist.forEach(statis => {
                    if (statis.shotType === 'goal'/* && statis.situation !== 'penalty'*/) {
                      if(gameId.isHome){
                        if(statis.isHome){
                            stats.goal += 1;
                        } else {
                            stats.goalR += 1;
                        }
                      }
                      if(!gameId.isHome){
                        if(!statis.isHome) {
                            stats.goal += 1;
                        } else {
                            stats.goalR += 1;
                        }
                      }
                    }
                }); 
            }
        // }
    } catch (error) {
        setCollectionNoFound(gameId.game.toString(), "shotmapNotFound");
        // console.error(`Error fetching data for game ID ${gameId}:`, error);
    }
    return stats;
  }
  

async function fetchAndProcessGameStatistics(gameId) {
    let stats = { 
        load: true,
        corners: 0, 
        fouls: 0, 
        cardY: 0, 
        cardR: 0, 
        ballPossession: 0, 
        totalShot: 0, 
        shotOnTarget: 0, 
        shotOffTarget: 0, 
        BlockedShots: 0, 
        ShotsInsideBox: 0, 
        ShotsOutsideBox: 0, 
        BigChances: 0, 
        BigChancesMissed: 0, 
        GoalkeeperSaves: 0, 
        totalCorner: [],
        totalShots: [],
        totalShotOnTarget: [],
        totalShotOffTarget: [],
        totalBigChances: [],
        totalBigChancesMissed: [],
        totalGoalkeeperSaves: [],
        totalBlockedShots: [],
        totalFouls: [],
        totalYCard: [],
        totalRCard: [],
        totalBallPossession: [],
        totalShotsInsideBox: [],
        totalShotsOutsideBox: [],
    };
    try {
        // const docRef = doc(db, "stastisticsNotFound", gameId.game.toString());
        // const docSnap = await getDoc(docRef);
        // if(!docSnap.exists()) {
            const response = await axios.get(`https://api.sofascore.com/api/v1/event/${gameId.game}/statistics`);
            if (response.status === 200) {
                const dataStatist = response.data.statistics[0].groups;
                dataStatist.forEach(statis => {
                    if (statis.groupName === 'TVData') {
                        statis.statisticsItems.forEach(item => {
                            switch (item.name) {
                                case "Corner kicks":
                                    stats.totalCorner.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.corners += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Fouls":
                                    stats.totalFouls.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.fouls += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Yellow cards":
                                    stats.totalYCard.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.cardY += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Red cards":
                                    stats.totalRCard.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.cardR += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                    if (statis.groupName === 'Possession') {
                        statis.statisticsItems.forEach(item => {
                            switch (item.name) {
                                case "Ball possession":
                                    stats.totalBallPossession.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.ballPossession += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                default:
                                    break;
                            }
                        }); 
                    }
                    if (statis.groupName === 'Shots') {
                        statis.statisticsItems.forEach(item => {
                            switch (item.name) {
                                case "Total shots":
                                    stats.totalShots.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.totalShot += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Shots on target":
                                    stats.totalShotOnTarget.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.shotOnTarget += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Shots off target":
                                    stats.totalShotOffTarget.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.shotOffTarget += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Blocked shots":
                                    stats.totalBlockedShots.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.BlockedShots += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                    if (statis.groupName === 'Shots extra') {
                        statis.statisticsItems.forEach(item => {
                            switch (item.name) {
                                case "Big chances":
                                    stats.totalBigChances.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.BigChances += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Big chances missed":
                                    stats.totalBigChancesMissed.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.BigChancesMissed += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Shots inside box":
                                    stats.totalShotsInsideBox.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.ShotsInsideBox += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Shots outside box":
                                    stats.totalShotsOutsideBox.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.ShotsOutsideBox += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                case "Goalkeeper saves":
                                    stats.totalGoalkeeperSaves.push(gameId.isHome ? item.homeValue : item.awayValue);
                                    stats.GoalkeeperSaves += gameId.isHome ? item.homeValue : item.awayValue;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                });
            }
        // }
    } catch (error) {
        setCollectionNoFound(gameId.game.toString(), "stastisticsNotFound");
        // console.error(`Error fetching data for game ID ${gameId}:`, error);
    }
    return stats;
}

// Función para procesar estadísticas para un conjunto de juegos, ya sea en casa o fuera
async function processGameStatistics(arrGames, length) {
    // Asegurarse de que el array no exceda el límite de longitud deseado
    const limitedGames = arrGames.slice(0, length);
    const promises = limitedGames.map(gameId => Promise.all([
        fetchAndProcessGameStatistics(gameId),
        fetchAndProcessGameShotmap(gameId)
    ]));
 
    const results = await Promise.all(promises);
    const combinedResults = results.map(([stats, shotmap]) => ({
        stats,
        shotmap
    }));

    // Reducir los resultados para sumar estadísticas
    return combinedResults.reduce((acc, { stats, shotmap }) => {
        ['corners', 'fouls', 'cardY', 'cardR', 'ballPossession', 'totalShot', 'shotOnTarget', 'shotOffTarget',
         'BlockedShots', 'ShotsInsideBox', 'ShotsOutsideBox', 'BigChances', 'BigChancesMissed', 
         'GoalkeeperSaves', 'goal', 'goalR'].forEach(key => {
            acc[key] += stats[key] || 0;  // Suma directa de valores numéricos
        });

        ['totalCorner', 'totalShots', 'totalShotOnTarget', 'totalShotOffTarget', 'totalBigChances',
         'totalBigChancesMissed', 'totalGoalkeeperSaves', 'totalBlockedShots', 'totalFouls', 
         'totalYCard', 'totalRCard', 'totalBallPossession', 'totalShotsInsideBox', 
         'totalShotsOutsideBox'].forEach(key => {
            acc[key] = acc[key].concat(stats[key] || []); // Concatenación de arrays
        });

        return acc;
    }, { corners: 0, fouls: 0, cardY: 0, cardR: 0, ballPossession: 0, totalShot: 0, shotOnTarget: 0, shotOffTarget: 0, 
         BlockedShots: 0, ShotsInsideBox: 0, ShotsOutsideBox: 0, BigChances: 0, BigChancesMissed: 0, 
         GoalkeeperSaves: 0, goal: 0, goalR: 0, totalCorner: [], totalShots: [], totalShotOnTarget: [], 
         totalShotOffTarget: [], totalBigChances: [], totalBigChancesMissed: [], totalGoalkeeperSaves: [], 
         totalBlockedShots: [], totalFouls: [], totalYCard: [], totalRCard: [], totalBallPossession: [], 
         totalShotsInsideBox: [], totalShotsOutsideBox: [] });
}


const StatisticsTeamV = ({data_, dataH2H, close}) => {
    const [data, setData] = useState({
        idGame: data_.gameId,
        home: { 
            totalGoals: [],
            load: false,
            id: 0, 
            win: 0, 
            tie: 0, 
            loss: 0, 
            matches: 0,
            position: 0,
        }, 
        away: { 
            totalGoals: [],
            load: false,
            id: 0, 
            win: 0, 
            tie: 0, 
            loss: 0, 
            matches: 0,
            position: 0,
        },
        homeInd: { 
            totalGoals: [],
            load: false,
            id: 0, 
            win: 0, 
            tie: 0, 
            loss: 0, 
            matches: 0,
            position: 0,
        }, 
        awayInd: { 
            totalGoals: [],
            load: false,
            id: 0, 
            win: 0, 
            tie: 0, 
            loss: 0, 
            matches: 0,
            position: 0,
        },
        statistics: {
            home:{
                load: false,
                goal: 0,
                goalR: 0,
            }, 
            away: { 
                load: false,
                goal: 0,
                goalR: 0,
            },
            homeH2H:{
                load: false,
                goal: Math.round(dataH2H.homeGoal / dataH2H.match),
                goalR: Math.round(dataH2H.awayGoal / dataH2H.match),
            }, 
            awayH2H: { 
                load: false,
                goal: Math.round(dataH2H.awayGoal / dataH2H.match),
                goalR: Math.round(dataH2H.homeGoal / dataH2H.match),
            },
            homeInd:{
                load: false,
                goal: 0,
                goalR: 0,
            }, 
            awayInd: { 
                load: false,
                goal: 0,
                goalR: 0,
            },
        }
    });
    const [dataSt, setDataSt] = useState({
        statistics: {
            homeH2H:{
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            }, 
            awayH2H: { 
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            },
            home:{
                load: false,
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            }, 
            away: { 
                load: false,
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            },
            homeInd:{
                load: false,
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            }, 
            awayInd: { 
                load: false,
                fouls: 0,
                corners: 0,
                yCard: 0,
                rCard: 0,
                ballPossession: 0,
                shotOnTarget: 0,
                shotOffTarget: 0,
                BlockedShots: 0,
                ShotsInsideBox: 0,
                ShotsOutsideBox: 0,
                BigChances: 0,
                BigChancesMissed: 0,
                GoalkeeperSaves: 0,
                goal: 0,
                totalShot: 0,
            },
        }
    });
    const [arrGamesHomeGlobal_, setArrGamesHomeGlobal] = useState([]);
    const [arrGamesAwayGlobal_, setArrGamesAwayGlobal] = useState([]);
    const [arrGamesHomeInd_, setArrGamesHomeInd] = useState([]);
    const [arrGamesAwayInd_, setArrGamesAwayInd] = useState([]);

    useEffect(() => {
        let $data = {
            idGame: data_.gameId,
            home: { 
                totalGoals: [],
                totalGoalsR: [],
                load: false,
                id: 0, 
                win: 0, 
                tie: 0, 
                loss: 0, 
                matches: 0,
                position: 0,
            }, 
            away: { 
                totalGoals: [],
                totalGoalsR: [],
                load: false,
                id: 0, 
                win: 0, 
                tie: 0, 
                loss: 0, 
                matches: 0,
                position: 0,
            },
            homeH2H:{ 
                win: dataH2H.home.win, 
                tie: dataH2H.home.tie, 
                loss: dataH2H.home.loss, 
                matches: dataH2H.match,
            },
            awayH2H:{ 
                win: dataH2H.away.win, 
                tie: dataH2H.away.tie, 
                loss: dataH2H.away.loss, 
                matches: dataH2H.match,
            },
            homeInd: { 
                totalGoals: [],
                totalGoalsR: [],
                load: false,
                id: 0, 
                win: 0, 
                tie: 0, 
                loss: 0, 
                matches: 0,
                position: 0,
            }, 
            awayInd: { 
                totalGoals: [],
                totalGoalsR: [],
                load: false,
                id: 0, 
                win: 0, 
                tie: 0, 
                loss: 0, 
                matches: 0,
                position: 0,
            },
            statistics: {
                home:{
                    load: false,
                    goal: 0,
                    goalR: 0,
                }, 
                away: { 
                    load: false,
                    goal: 0,
                    goalR: 0,
                },
                homeH2H:{
                    load: false,
                    goal: Math.round(dataH2H.homeGoal / dataH2H.match),
                    goalR: Math.round(dataH2H.awayGoal / dataH2H.match),
                }, 
                awayH2H: { 
                    load: false,
                    goal: Math.round(dataH2H.awayGoal / dataH2H.match),
                    goalR: Math.round(dataH2H.homeGoal / dataH2H.match),
                },
                homeInd:{
                    load: false,
                    goal: 0,
                    goalR: 0,
                }, 
                awayInd: { 
                    load: false,
                    goal: 0,
                    goalR: 0,
                },
            }
        };
        let homeSearchData = true;
        let awaySearchData = true;
        async function fetchHomeData() {
            let page = 0;
            let arrGames = [];
            let year = new Date(data_.timestamp * 1000);
            
            while(homeSearchData) {
                
                if(arrGames.length >= 10) {
                    break;
                }
                const response = await axios.get(`https://api.sofascore.com/api/v1/team/${data_.homeId}/events/last/${page}`);
                if(homeSearchData) {
                    const dataHome = response.data;
                    if(dataHome.events.length > 0) {
                        dataHome.events.map(game => {
                            let gameDate = new Date(game.startTimestamp * 1000);
                            let dateGame = getFormattedDate(year);
                            let dateGameN = getFormattedDate(gameDate);
                            if(Object.keys(game).includes('season') && !arrGames.some(obj => obj.game === game.id) && game.id != data_.gameId && game.status.type == 'finished' && compareDates(dateGameN, dateGame) ) {  
                                if(game.season.id == data_.season && arrGames.length < 10){
                                    arrGames.push(game);

                                } else {
                                    homeSearchData = false;
                                } 
                            }
                        });
                    }
                }
                page += 1;  
            }

            return arrGames;
        }
        async function fetchAwayData() {
            let page = 0;
            let arrGame = [];
            let year = new Date(data_.timestamp * 1000); 
             
            while(awaySearchData) {
                
                if(arrGame.length >= 10) {
                    break;
                }
                const response = await axios.get(`https://api.sofascore.com/api/v1/team/${data_.awayId}/events/last/${page}`);
                if(awaySearchData) {
                    const dataHome = response.data;
                    if(dataHome.events.length > 0) {
                        dataHome.events.map(game => {
                            let gameDate = new Date(game.startTimestamp * 1000);
                            let dateGame = getFormattedDate(year);
                            let dateGameN = getFormattedDate(gameDate);
                            if(Object.keys(game).includes('season') && !arrGame.some(obj => obj.game === game.id) && game.id != data_.gameId && game.status.type == 'finished' && compareDates(dateGameN, dateGame) ) {  
                                if(game.season.year == `${(year.getFullYear() - 2).toString().substring(2)}/${(year.getFullYear() - 1).toString().substring(2)}` || game.season.year == (year.getFullYear() - 1)){
                                    awaySearchData = false;
                                } 
                                if(game.season.id == data_.season && arrGame.length < 10){

                                    arrGame.push(game)

                                }
                            }
                        });
                    }
                }
                page += 1; 
            } 

            return arrGame;
        }

        async function fetchData() {
            let [homeData, awayData] = await Promise.all([fetchHomeData(), fetchAwayData()]);
            let minGamesCount = Math.min(homeData.length, awayData.length);
            homeData = homeData.slice(0, minGamesCount);
            awayData = awayData.slice(0, minGamesCount);

            
            let arrGamesHomeGlobal = [];
            let arrGamesHomeInd = [];
            let goalFH = 0, goalRH = 0, goalFHInd = 0, goalRHInd = 0;
            let arrGamesAwayGlobal = [];
            let arrGamesAwayInd = [];
            let goalF = 0, goalR = 0, goalFInd = 0, goalRInd = 0;

            let totalGlobalH = 0, totalIndH = 0, totalGlobalA = 0, totalIndA = 0, totalGlobal = 0, totalInd = 0;

            homeData.forEach(game => {
                if(game.homeTeam.id == data_.homeId) {
                    if(arrGamesHomeGlobal.length < 10) {
                        totalGlobalH += 1;
                        totalIndH += 1;
                    } else {
                        totalIndH += 1;
                    }
                } 
                if(game.awayTeam.id == data_.homeId) {
                    if(arrGamesHomeGlobal.length < 10){
                        totalGlobalH += 1;
                    }
                }
            });
            awayData.forEach(game => {
                if(game.awayTeam.id == data_.awayId) {
                    if(arrGamesAwayGlobal.length < 10) {
                        totalGlobalA += 1;
                        totalIndA += 1;
                    }
                } 
                if(game.homeTeam.id == data_.awayId) {
                    if(arrGamesAwayGlobal.length < 10) {
                        totalGlobalA += 1;
                    }
                } 
            });
 
            if(totalGlobalH < totalGlobalA){
                if(totalGlobalH > 10) {
                    totalGlobal = 10;
                } else {
                    totalGlobal = totalGlobalH;
                }
            } else {
                if(totalGlobalA > 10) {
                    totalGlobal = 10;
                } else {
                    totalGlobal = totalGlobalA;
                }
            }
            if(totalIndH < totalIndA){
                if(totalIndH > 10) {
                    totalInd = 10;
                } else {
                    totalInd = totalIndH;
                }
            } else {
                if(totalIndA > 10) {
                    totalInd = 10;
                } else {
                    totalInd = totalIndA;
                }
            }

 
            homeData.forEach(game => {
                let homeScore = typeof game.homeScore.display !== 'undefined' ? game.homeScore.display : typeof game.homeScore.current !== 'undefined' ? game.homeScore.current : 0;
                let awayScore = typeof game.awayScore.display !== 'undefined' ? game.awayScore.display : typeof game.awayScore.current !== 'undefined' ? game.awayScore.current : 0;
                if(game.homeTeam.id == data_.homeId) {
                    if(arrGamesHomeGlobal.length < 10) {
                        arrGamesHomeGlobal.push({'game': game.id, 'isHome': true});  
                        arrGamesHomeInd.push({'game': game.id, 'isHome': true});     
                        $data.home.totalGoals.push(homeScore);
                        $data.home.totalGoalsR.push(awayScore);

                        goalFH += homeScore; 
                        goalRH += awayScore;                       
                        if(game.winnerCode == 1) {
                            $data.home.win += 1;
                            $data.home.matches += 1;
                        } 
                        if(game.winnerCode == 2) {
                            $data.home.loss += 1;
                            $data.home.matches += 1;
                        }
                        if(game.winnerCode == 3) {
                            $data.home.tie += 1;
                            $data.home.matches += 1;
                        } 
                        if(arrGamesHomeInd.length <= totalInd) {
                            $data.homeInd.totalGoals.push(homeScore);
                            $data.homeInd.totalGoalsR.push(awayScore);
                            goalFHInd += homeScore;
                            goalRHInd += awayScore;                             
                            if(game.winnerCode == 1) {
                                $data.homeInd.win += 1;
                                $data.homeInd.matches += 1;
                            } 
                            if(game.winnerCode == 2) {
                                $data.homeInd.loss += 1;
                                $data.homeInd.matches += 1;
                            }
                            if(game.winnerCode == 3) {
                                $data.homeInd.tie += 1;
                                $data.homeInd.matches += 1;
                            } 
                        }
                    } else {
                        if(arrGamesHomeInd.length <= totalInd) {
                            $data.homeInd.totalGoals.push(homeScore);
                            $data.homeInd.totalGoalsR.push(awayScore);
                            goalFHInd += homeScore;
                            goalRHInd += awayScore;                 
                            if(game.winnerCode == 1) {
                                $data.homeInd.win += 1;
                                $data.homeInd.matches += 1;
                            } 
                            if(game.winnerCode == 2) {
                                $data.homeInd.loss += 1;
                                $data.homeInd.matches += 1;
                            }
                            if(game.winnerCode == 3) {
                                $data.homeInd.tie += 1;
                                $data.homeInd.matches += 1;
                            }
                            arrGamesHomeInd.push({'game': game.id, 'isHome': true});
                        }
                    }
                } 
                if(game.awayTeam.id == data_.homeId) {
                    if(arrGamesHomeGlobal.length < 10){
                        arrGamesHomeGlobal.push({'game': game.id, 'isHome': false});    
                        $data.home.totalGoals.push(awayScore);
                        $data.home.totalGoalsR.push(homeScore);
                        goalFH += awayScore;
                        goalRH += homeScore; 
                        if(game.winnerCode == 1) {
                            $data.home.loss += 1;
                            $data.home.matches += 1;
                        } 
                        if(game.winnerCode == 2) {
                            $data.home.win += 1;
                            $data.home.matches += 1;
                        }
                        if(game.winnerCode == 3) {
                            $data.home.tie += 1;
                            $data.home.matches += 1;
                        }
                    }
                }
            });
            $data.home.load = true;
            $data.statistics.home.load = true;
            $data.statistics.home.goal = Math.round(goalFH / $data.home.matches);
            $data.statistics.home.goalR = Math.round(goalRH / $data.home.matches);
            $data.homeInd.load = true;
            $data.statistics.homeInd.load = true;
            $data.statistics.homeInd.goal = Math.round(goalFHInd / $data.homeInd.matches);
            $data.statistics.homeInd.goalR = Math.round(goalRHInd / $data.homeInd.matches);
            setArrGamesHomeGlobal(arrGamesHomeGlobal);
            setArrGamesHomeInd(arrGamesHomeInd);


            awayData.forEach(game => {
                let homeScore = typeof game.homeScore.display !== 'undefined' ? game.homeScore.display : typeof game.homeScore.current !== 'undefined' ? game.homeScore.current : 0;
                let awayScore = typeof game.awayScore.display !== 'undefined' ? game.awayScore.display : typeof game.awayScore.current !== 'undefined' ? game.awayScore.current : 0;
                let totalScore = Number(homeScore) + Number(awayScore);

                if(game.awayTeam.id == data_.awayId) {
                    if(arrGamesAwayGlobal.length < 10) {
                        arrGamesAwayGlobal.push({'game': game.id, 'isHome': false});
                        arrGamesAwayInd.push({'game': game.id, 'isHome': false}); 
                        $data.away.totalGoals.push(awayScore);
                        $data.away.totalGoalsR.push(homeScore);
                        goalF += awayScore;            
                        goalR += homeScore;
                        if(game.winnerCode == 1) {
                            $data.away.loss += 1;
                            $data.away.matches += 1;
                        }
                        if(game.winnerCode == 2) {
                            $data.away.win += 1;
                            $data.away.matches += 1;
                        }
                        if(game.winnerCode == 3) {
                            $data.away.tie += 1;
                            $data.away.matches += 1;
                        }
                        if(arrGamesAwayInd.length <= totalInd){
                            $data.awayInd.totalGoals.push(awayScore);
                            $data.awayInd.totalGoalsR.push(homeScore);
                            goalFInd += awayScore;
                            goalRInd += homeScore;
                            if(game.winnerCode == 1) {
                                $data.awayInd.loss += 1;
                                $data.awayInd.matches += 1;
                            }
                            if(game.winnerCode == 2) {
                                $data.awayInd.win += 1;
                                $data.awayInd.matches += 1;
                            }
                            if(game.winnerCode == 3) {
                                $data.awayInd.tie += 1;
                                $data.awayInd.matches += 1;
                            }
                        }
                    } else {
                        if(arrGamesAwayInd.length <= totalInd){
                            $data.awayInd.totalGoals.push(awayScore);
                            $data.awayInd.totalGoalsR.push(homeScore);
                            arrGamesAwayInd.push({'game': game.id, 'isHome': false});
                            goalFInd += awayScore;
                            goalRInd += homeScore;
                            if(game.winnerCode == 1) {
                                $data.awayInd.loss += 1;
                                $data.awayInd.matches += 1;
                            }
                            if(game.winnerCode == 2) {
                                $data.awayInd.win += 1;
                                $data.awayInd.matches += 1;
                            }
                            if(game.winnerCode == 3) {
                                $data.awayInd.tie += 1;
                                $data.awayInd.matches += 1;
                            }
                        }
                    }
                } 
                if(game.homeTeam.id == data_.awayId) {
                    if(arrGamesAwayGlobal.length < 10) {
                        $data.away.totalGoals.push(homeScore);
                        $data.away.totalGoalsR.push(awayScore);
                        arrGamesAwayGlobal.push({'game': game.id, 'isHome': true});
                        goalF += homeScore;            
                        goalR += awayScore;
                        if(game.winnerCode == 1) {
                            $data.away.win += 1;
                            $data.away.matches += 1;
                        }
                        if(game.winnerCode == 2) {
                            $data.away.loss += 1;
                            $data.away.matches += 1;
                        }
                        if(game.winnerCode == 3) {
                            $data.away.tie += 1;
                            $data.away.matches += 1;
                        }
                    }
                } 
            });
            $data.away.load = true;
            $data.statistics.away.load = true;
            $data.statistics.away.goal = Math.round(goalF / $data.away.matches);
            $data.statistics.away.goalR = Math.round(goalR / $data.away.matches);
            $data.awayInd.load = true;
            $data.statistics.awayInd.load = true;
            $data.statistics.awayInd.goal = Math.round(goalFInd / $data.awayInd.matches);
            $data.statistics.awayInd.goalR = Math.round(goalRInd / $data.awayInd.matches);

            setArrGamesAwayGlobal(arrGamesAwayGlobal);
            setArrGamesAwayInd(arrGamesAwayInd);


            setData($data);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if(data.home.load && data.away.load && data.homeInd.load && data.awayInd.load) {
            if(arrGamesHomeGlobal_.length > 0 && arrGamesAwayGlobal_.length > 0) {
                let homeH2HInit = initializeStatistics();
                let awayH2HInit = initializeStatistics();
                let homeInit = initializeStatistics();
                let awayInit = initializeStatistics();
                let homeIndInit = initializeStatistics();
                let awayIndInit = initializeStatistics();
                const StatisticsFetchData = async () => {
                    let $dataSt = {
                        statistics: {
                            homeH2H: homeH2HInit, 
                            awayH2H: awayH2HInit,
                            home: homeInit, 
                            away: awayInit,
                            homeInd: homeIndInit, 
                            awayInd: awayIndInit,
                        }
                    };

                    const [statsHome, statsAway, statsHomeInd, statsAwayInd] = await Promise.all([
                        processGameStatistics(arrGamesHomeGlobal_, arrGamesAwayGlobal_.length),
                        processGameStatistics(arrGamesAwayGlobal_, arrGamesHomeGlobal_.length),
                        processGameStatistics(arrGamesHomeInd_, arrGamesAwayInd_.length),
                        processGameStatistics(arrGamesAwayInd_, arrGamesHomeInd_.length)
                    ]);

                    // console.log(statsHome);
                    // console.log(statsAway);
                    // console.log(statsHomeInd);
                    // console.log(statsAwayInd);

                    ['homeH2H', 'awayH2H', 'home', 'away', 'homeInd', 'awayInd'].forEach(section => {
                        if (arrGamesHomeGlobal_.length > 0 && $dataSt.statistics[section].load) {
                            if(section == 'homeH2H'){
                                calculatePerGameStats($dataSt.statistics[section], dataH2H.statistics.home, dataH2H.match);
                                addStatistics($dataSt.statistics[section], dataH2H.statistics.home);
                                $dataSt.statistics.homeH2H.totalGoalsR = dataH2H.statistics.home.totalGoalsR;
                            }
                            // console.log("") 
                            // console.log($dataSt)
                            // console.log("")
                            if(section == 'awayH2H'){
                                console.log(dataH2H.statistics.away) 
                                calculatePerGameStats($dataSt.statistics[section], dataH2H.statistics.away, dataH2H.match);
                                addStatistics($dataSt.statistics[section], dataH2H.statistics.away);
                                $dataSt.statistics.awayH2H.totalGoalsR = dataH2H.statistics.away.totalGoalsR;
                            }
                            if(section == 'home'){
                                calculatePerGameStats($dataSt.statistics[section], statsHome, data.home.matches);
                                addStatistics($dataSt.statistics[section], statsHome);
                            }
                            if(section == 'away'){
                                calculatePerGameStats($dataSt.statistics[section], statsAway, data.away.matches);
                                addStatistics($dataSt.statistics[section], statsAway);
                            }
                            if(section == 'homeInd'){
                                calculatePerGameStats($dataSt.statistics[section], statsHomeInd, data.homeInd.matches);
                                addStatistics($dataSt.statistics[section], statsHomeInd);
                            }
                            if(section == 'awayInd'){
                                calculatePerGameStats($dataSt.statistics[section], statsAwayInd, data.awayInd.matches);
                                addStatistics($dataSt.statistics[section], statsAwayInd);
                            }
                        }
                      });
                    // console.log($dataSt)
                    // console.log("")
                    // console.log("")
                    // console.log("HOME SHOT: " + (statsHomeInd.shotOnTarget + statsHome.shotOnTarget + dataH2H.statistics.home.shotOnTarget));
                    // console.log("AWAY SHOT: " + (statsAwayInd.shotOnTarget + statsAway.shotOnTarget + dataH2H.statistics.away.shotOnTarget));
                    // console.log("HOME GOAL: " + (dataH2H.statistics.home.goal + statsHome.goal + statsHomeInd.goal));
                    // console.log("AWAY GOAL: " + (dataH2H.statistics.away.goal + statsAway.goal + statsAwayInd.goal));
                    // console.log("HOME GOALR: " + (dataH2H.statistics.home.goalR + statsHome.goalR + statsHomeInd.goalR));
                    // console.log("AWAY GOALR: " + (dataH2H.statistics.away.goalR + statsAway.goalR + statsAwayInd.goalR));
                    // console.log(statsHomeInd.goal, statsHome.goal, dataH2H.statistics.home.goal);
                    // console.log(statsAwayInd.goal, statsAway.goal, dataH2H.statistics.away.goal);
                    // console.log("")
                    // console.log(statsHomeInd.goalR, statsHome.goalR, dataH2H.statistics.home.goalR);
                    // console.log(statsAwayInd.goalR, statsAway.goalR, dataH2H.statistics.away.goalR);
                    setDataSt($dataSt);
                        
                } 
        
                StatisticsFetchData();
    
            }
            // close();
        }
    }, [arrGamesHomeGlobal_, arrGamesAwayGlobal_, arrGamesHomeInd_, arrGamesAwayInd_]);
    return (
        <div>
        {(data.home.load && data.away.load && data.homeInd.load && data.awayInd.load && dataSt.statistics.home.load) && (
            <DetailedAnalysis data={data} dataSt={dataSt} dataH2H={dataH2H} data_={data_} />
        ) }
        <TableContainer>
            <Typography style={{ 'text-align': 'center' }} variant="h6">Tabla de Estadísticas General</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Equipo</TableCell>
                        <TableCell>Partidos</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Ganadas</TableCell>
                        <TableCell>Empates</TableCell>
                        <TableCell>Perdidas</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Gol F</TableCell>
                        <TableCell>Gol R</TableCell>
                        <TableCell>Corners</TableCell>
                        <TableCell>Fouls</TableCell>
                        <TableCell>T. Amarilla</TableCell>
                        <TableCell>T. Roja</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{data_.homeTeam}</TableCell>
                        <TableCell>{data.home.matches}</TableCell>
    
                        <TableCell>-</TableCell>
    
                        {data.home.win > data.away.win && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.win}</TableCell>
                        ) || data.home.win === data.away.win && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.win}</TableCell>
                        )|| data.home.win < data.away.win && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.win}</TableCell>
                        )}
    
                        {data.home.tie > data.away.tie && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.tie}</TableCell>
                        ) || data.home.tie === data.away.tie && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.tie}</TableCell>
                        ) || data.home.tie < data.away.tie && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.tie}</TableCell>
                        )}
    
                        {data.home.loss < data.away.loss && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.home.loss}</TableCell>
                        ) || data.home.loss === data.away.loss && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.home.loss}</TableCell>
                        ) || data.home.loss > data.away.loss && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.home.loss}</TableCell>
                        )}

                        <TableCell>-</TableCell>

                        
                        {Number(data.statistics.home.goal) > Number(data.statistics.away.goal) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.home.goal)}</TableCell>
                        ) || Number(data.statistics.home.goal) === Number(data.statistics.away.goal) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.home.goal)}</TableCell>
                        ) || Number(data.statistics.home.goal) < Number(data.statistics.away.goal) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.statistics.home.goal)}</TableCell>
                        )}

                        {Number(data.statistics.home.goalR) < Number(data.statistics.away.goalR) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.home.goalR)}</TableCell>
                        ) || Number(data.statistics.home.goalR) === Number(data.statistics.away.goalR) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.home.goalR)}</TableCell>
                        ) || Number(data.statistics.home.goalR) > Number(data.statistics.away.goalR) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.statistics.home.goalR)}</TableCell>
                        )}

                        {Number(dataSt.statistics.home.corners) > Number(dataSt.statistics.away.corners) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.home.corners)}</TableCell>
                        ) || Number(dataSt.statistics.home.corners) === Number(dataSt.statistics.away.corners) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.home.corners)}</TableCell>
                        ) || Number(dataSt.statistics.home.corners) < Number(dataSt.statistics.away.corners) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.home.corners)}</TableCell>
                        )}

                        {Number(dataSt.statistics.home.fouls) < Number(dataSt.statistics.away.fouls) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.home.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.home.fouls) === Number(dataSt.statistics.away.fouls) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.home.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.home.fouls) > Number(dataSt.statistics.away.fouls) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.home.fouls)}</TableCell>
                        )}

                        {Number(dataSt.statistics.home.yCard) < Number(dataSt.statistics.away.yCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.home.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.home.yCard) === Number(dataSt.statistics.away.yCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.home.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.home.yCard) > Number(dataSt.statistics.away.yCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.home.yCard)}</TableCell>
                        )}

                        {Number(dataSt.statistics.home.rCard) < Number(dataSt.statistics.away.rCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.home.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.home.rCard) === Number(dataSt.statistics.away.rCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.home.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.home.rCard) > Number(dataSt.statistics.away.rCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.home.rCard)}</TableCell>
                        )}
    
    
                    </TableRow>
                    <TableRow>
                        <TableCell>{data_.awayTeam}</TableCell>
                        <TableCell>{data.away.matches}</TableCell>
    
                        <TableCell>-</TableCell>
    
                        {data.away.win > data.home.win && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.win}</TableCell>
                        ) || data.away.win === data.home.win && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.win}</TableCell>
                        )|| data.away.win < data.home.win && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.win}</TableCell>
                        )}
    
                        {data.away.tie > data.home.tie && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.tie}</TableCell>
                        ) || data.away.tie === data.away.tie && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.tie}</TableCell>
                        ) || data.away.tie < data.home.tie && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.tie}</TableCell>
                        )}
    
                        {data.away.loss < data.home.loss && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.away.loss}</TableCell>
                        ) || data.away.loss === data.home.loss && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.away.loss}</TableCell>
                        ) || data.away.loss > data.home.loss && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.away.loss}</TableCell>
                        )}

                        <TableCell>-</TableCell>
                        
                        {Number(data.statistics.away.goal) > Number(data.statistics.home.goal) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.away.goal)}</TableCell>
                        ) || Number(data.statistics.away.goal) === Number(data.statistics.home.goal) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.away.goal)}</TableCell>
                        )|| Number(data.statistics.away.goal) < Number(data.statistics.home.goal) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.statistics.away.goal}</TableCell>
                        )}
                        
                        {Number(data.statistics.away.goalR) < Number(data.statistics.home.goalR) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.away.goalR)}</TableCell>
                        ) || Number(data.statistics.away.goalR) === Number(data.statistics.home.goalR) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.away.goalR)}</TableCell>
                        )|| Number(data.statistics.away.goalR) > Number(data.statistics.home.goalR) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.statistics.away.goalR}</TableCell>
                        )}
                                
                        {Number(dataSt.statistics.away.corners) > Number(dataSt.statistics.home.corners) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.away.corners)}</TableCell>
                        ) || Number(dataSt.statistics.away.corners) === Number(dataSt.statistics.home.corners) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.away.corners)}</TableCell>
                        )|| Number(dataSt.statistics.away.corners) < Number(dataSt.statistics.home.corners) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.away.corners}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.away.fouls) < Number(dataSt.statistics.home.fouls) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.away.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.away.fouls) === Number(dataSt.statistics.home.fouls) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.away.fouls)}</TableCell>
                        )|| Number(dataSt.statistics.away.fouls) > Number(dataSt.statistics.home.fouls) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.away.fouls}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.away.yCard) < Number(dataSt.statistics.home.yCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.away.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.away.yCard) === Number(dataSt.statistics.home.yCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.away.yCard)}</TableCell>
                        )|| Number(dataSt.statistics.away.yCard) > Number(dataSt.statistics.home.yCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.away.yCard}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.away.rCard) < Number(dataSt.statistics.home.rCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.away.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.away.rCard) === Number(dataSt.statistics.home.rCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.away.rCard)}</TableCell>
                        )|| Number(dataSt.statistics.away.rCard) > Number(dataSt.statistics.home.rCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.away.rCard}</TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        
        <TableContainer>
            <Typography style={{ 'text-align': 'center' }} variant="h6">Tabla de Estadísticas Individual</Typography>
            <Table>
               <TableHead>
                    <TableRow>
                        <TableCell>Equipo</TableCell>
                        <TableCell>Partidos</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Ganadas</TableCell>
                        <TableCell>Empates</TableCell>
                        <TableCell>Perdidas</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Gol F</TableCell>
                        <TableCell>Gol R</TableCell>
                        <TableCell>Corners</TableCell>
                        <TableCell>Fouls</TableCell>
                        <TableCell>T. Amarilla</TableCell>
                        <TableCell>T. Roja</TableCell>
                    </TableRow>
               </TableHead>
               <TableBody>
                    <TableRow>
                        <TableCell>{data_.homeTeam}</TableCell>
                        <TableCell>{data.homeInd.matches}</TableCell>
    
                        <TableCell>-</TableCell>
        
                        {data.homeInd.win > data.awayInd.win && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.homeInd.win}</TableCell>
                        ) || data.homeInd.win === data.awayInd.win && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.homeInd.win}</TableCell>
                        )|| data.homeInd.win < data.awayInd.win && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.homeInd.win}</TableCell>
                        )}
    
                        {data.homeInd.tie > data.awayInd.tie && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.homeInd.tie}</TableCell>
                        ) || data.homeInd.tie === data.awayInd.tie && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.homeInd.tie}</TableCell>
                        ) || data.homeInd.tie < data.awayInd.tie && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.homeInd.tie}</TableCell>
                        )}
    
                        {data.homeInd.loss < data.awayInd.loss && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.homeInd.loss}</TableCell>
                        ) || data.homeInd.loss === data.awayInd.loss && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.homeInd.loss}</TableCell>
                        ) || data.homeInd.loss > data.awayInd.loss && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.homeInd.loss}</TableCell>
                        )}

                        <TableCell>-</TableCell>

                        
                        {Number(data.statistics.homeInd.goal) > Number(data.statistics.awayInd.goal) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.homeInd.goal)}</TableCell>
                        ) || Number(data.statistics.homeInd.goal) === Number(data.statistics.awayInd.goal) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.homeInd.goal)}</TableCell>
                        ) || Number(data.statistics.homeInd.goal) < Number(data.statistics.awayInd.goal) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.statistics.homeInd.goal)}</TableCell>
                        )}

                        {Number(data.statistics.homeInd.goalR) < Number(data.statistics.awayInd.goalR) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.homeInd.goalR)}</TableCell>
                        ) || Number(data.statistics.homeInd.goalR) === Number(data.statistics.awayInd.goalR) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.homeInd.goalR)}</TableCell>
                        ) || Number(data.statistics.homeInd.goalR) > Number(data.statistics.awayInd.goalR) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(data.statistics.homeInd.goalR)}</TableCell>
                        )}

                        {Number(dataSt.statistics.homeInd.corners) > Number(dataSt.statistics.awayInd.corners) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.homeInd.corners)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.corners) === Number(dataSt.statistics.awayInd.corners) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.homeInd.corners)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.corners) < Number(dataSt.statistics.awayInd.corners) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.homeInd.corners)}</TableCell>
                        )}

                        {Number(dataSt.statistics.homeInd.fouls) < Number(dataSt.statistics.awayInd.fouls) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.homeInd.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.fouls) === Number(dataSt.statistics.awayInd.fouls) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.homeInd.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.fouls) > Number(dataSt.statistics.awayInd.fouls) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.homeInd.fouls)}</TableCell>
                        )}

                        {Number(dataSt.statistics.homeInd.yCard) < Number(dataSt.statistics.awayInd.yCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.homeInd.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.yCard) === Number(dataSt.statistics.awayInd.yCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.homeInd.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.yCard) > Number(dataSt.statistics.awayInd.yCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.homeInd.yCard)}</TableCell>
                        )}

                        {Number(dataSt.statistics.homeInd.rCard) < Number(dataSt.statistics.awayInd.rCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.homeInd.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.rCard) === Number(dataSt.statistics.awayInd.rCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.homeInd.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.homeInd.rCard) > Number(dataSt.statistics.awayInd.rCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{Number(dataSt.statistics.homeInd.rCard)}</TableCell>
                        )}
    
                    </TableRow>
                    <TableRow>
                        <TableCell>{data_.awayTeam}</TableCell>
                        <TableCell>{data.awayInd.matches}</TableCell>
    
                        <TableCell>-</TableCell>
    
                        {data.awayInd.win > data.homeInd.win && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.awayInd.win}</TableCell>
                        ) || data.awayInd.win === data.homeInd.win && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.awayInd.win}</TableCell>
                        )|| data.awayInd.win < data.homeInd.win && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.awayInd.win}</TableCell>
                        )}
    
                        {data.awayInd.tie > data.homeInd.tie && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.awayInd.tie}</TableCell>
                        ) || data.awayInd.tie === data.awayInd.tie && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.awayInd.tie}</TableCell>
                        ) || data.awayInd.tie < data.homeInd.tie && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.awayInd.tie}</TableCell>
                        )}
    
                        {data.awayInd.loss < data.homeInd.loss && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{data.awayInd.loss}</TableCell>
                        ) || data.awayInd.loss === data.homeInd.loss && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{data.awayInd.loss}</TableCell>
                        ) || data.awayInd.loss > data.homeInd.loss && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.awayInd.loss}</TableCell>
                        )}

                        <TableCell>-</TableCell>
                        
                        {Number(data.statistics.awayInd.goal) > Number(data.statistics.homeInd.goal) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.awayInd.goal)}</TableCell>
                        ) || Number(data.statistics.awayInd.goal) === Number(data.statistics.homeInd.goal) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.awayInd.goal)}</TableCell>
                        )|| Number(data.statistics.awayInd.goal) < Number(data.statistics.homeInd.goal) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.statistics.awayInd.goal}</TableCell>
                        )}
                        
                        {Number(data.statistics.awayInd.goalR) < Number(data.statistics.homeInd.goalR) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(data.statistics.awayInd.goalR)}</TableCell>
                        ) || Number(data.statistics.awayInd.goalR) === Number(data.statistics.homeInd.goalR) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(data.statistics.awayInd.goalR)}</TableCell>
                        )|| Number(data.statistics.awayInd.goalR) > Number(data.statistics.homeInd.goalR) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{data.statistics.awayInd.goalR}</TableCell>
                        )}
                                
                        {Number(dataSt.statistics.awayInd.corners) > Number(dataSt.statistics.homeInd.corners) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.awayInd.corners)}</TableCell>
                        ) || Number(dataSt.statistics.awayInd.corners) === Number(dataSt.statistics.homeInd.corners) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.awayInd.corners)}</TableCell>
                        )|| Number(dataSt.statistics.awayInd.corners) < Number(dataSt.statistics.homeInd.corners) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.awayInd.corners}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.awayInd.fouls) < Number(dataSt.statistics.homeInd.fouls) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.awayInd.fouls)}</TableCell>
                        ) || Number(dataSt.statistics.awayInd.fouls) === Number(dataSt.statistics.homeInd.fouls) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.awayInd.fouls)}</TableCell>
                        )|| Number(dataSt.statistics.awayInd.fouls) > Number(dataSt.statistics.homeInd.fouls) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.awayInd.fouls}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.awayInd.yCard) < Number(dataSt.statistics.homeInd.yCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.awayInd.yCard)}</TableCell>
                        ) || Number(dataSt.statistics.awayInd.yCard) === Number(dataSt.statistics.homeInd.yCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.awayInd.yCard)}</TableCell>
                        )|| Number(dataSt.statistics.awayInd.yCard) > Number(dataSt.statistics.homeInd.yCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.awayInd.yCard}</TableCell>
                        )}
                        
                        {Number(dataSt.statistics.awayInd.rCard) < Number(dataSt.statistics.homeInd.rCard) && (
                        <TableCell style={{ 'background-color': 'green', color: "white" }}>{Number(dataSt.statistics.awayInd.rCard)}</TableCell>
                        ) || Number(dataSt.statistics.awayInd.rCard) === Number(dataSt.statistics.homeInd.rCard) && (
                            <TableCell style={{ 'background-color': 'orange', color: "white" }}>{Number(dataSt.statistics.awayInd.rCard)}</TableCell>
                        )|| Number(dataSt.statistics.awayInd.rCard) > Number(dataSt.statistics.homeInd.rCard) && (
                            <TableCell style={{ 'background-color': 'red', color: "white" }}>{dataSt.statistics.awayInd.rCard}</TableCell>
                        )}
                    </TableRow>
               </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
};


export default StatisticsTeamV;